import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { Formik, Form, useFormikContext, useField } from 'formik'
import { Flex } from "@chakra-ui/react";
import { leituraActions } from "../reducer";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import InputCheck from "../../sistema/components/InputCheck";
import { Leitura } from "../types/leitura";
import { LeiturasDados } from "../components/LeiturasDados";
import { LeiturasSensores } from "../components/LeituraSensores";
import { LeiturasResponsaveis } from "../components/LeituraResponsaveis";
import InputNumber from "../../sistema/components/InputNumber";
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import { mensagemErro } from "../../utils/toasts";
import { novaLeituraSensor } from "../data/leitura_sensor";

export const LeituraEdit = () => {
  useIsAuth();

  const { t } = useTranslation()
  const { id } = useParams();

  const leitura = useSelector((state: RootState) => state.leituras.leitura)
  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(leituraActions.leituraDadosSelectsInputs())
    dispatch(leituraActions.leituraRequest(id))
  }, [dispatch, id])

  const ClienteUnidadeInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_id) {
      return (
        <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditUnidadeNone')}</option>
        </InputSelect>
      );
    }

    return (
      <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades.filter((i) => i.cliente_id == values.cliente_id).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {i.cpf_cnpj} - {i.cidade} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
        ))}
      </InputSelect>
    );
  }

  const ClienteUnidadeSiloInput = () => {
    const formik = useFormikContext();
    const [, { value: valueConfiguracao }, { setValue: setValueConfiguracao }] = useField('tipo_configuracao');
    const [, { value: valueSensores }, { setValue: setValuSensores }] = useField('sensores');
    const values = formik.values as Leitura;

    if (!values?.cliente_unidade_id) {
      return (
        <InputSelect label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditSiloNone')}</option>
        </InputSelect>
      );
    }

    const onChangeLocal = (val: string) => {
      const silo = leituraDados?.clientes_unidades_silos.find((i) => i.id == val as any)?.tipo_silo;
      if (silo && silo.trim() == 'Cilindrico') {
        setValueConfiguracao(8)
        onChangeTipoConfiguracao(8, setValuSensores, valueSensores);
      }
    }

    return (
      <InputSelect onChangeVal={onChangeLocal} label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades_silos.filter((i => i.cliente_unidade_id == values.cliente_unidade_id)).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {t(`ClienteSilos${i.tipo_silo}`)}</option>
        ))}
      </InputSelect>
    );
  }

  const getTipoSiloGraneleiro = (tipo_configuracao: number) => {
    // if (!leituraDados?.clientes_unidades_silos) return "";

    // const siloEncontrado = leituraDados.clientes_unidades_silos.find((i: any) => i.id == silo_id);

    // if (!siloEncontrado) return "";

    return tipoConfiguracao(tipo_configuracao)?.imagem ?? '';

    // if (siloEncontrado.tipo_silo.trim() == "Graneleiro") {
    //   return SiloGraneleiro;
    // }

    // if (siloEncontrado.tipo_silo.trim() == "Prédio") {
    //   return siloPredio;
    // }

    // return "";
  }

  const getTipoSilo = (silo_id: string) => {
    if (!leituraDados?.clientes_unidades_silos) return "";

    const siloEncontrado = leituraDados.clientes_unidades_silos.find((i: any) => i.id == silo_id);

    if (!siloEncontrado) return "";

    return siloEncontrado.tipo_silo.trim()
  }

  const LeiturasSensoresForm = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    return (
      <LeiturasSensores tipo_silo={getTipoSiloGraneleiro(values.tipo_configuracao)} />
    )
  }

  const InputTipoLeitura = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    const updateVal = (val: string) => {
      formik.setValues({
        ...formik.values as any,
        tipo_leitura: val,
        range_calculo_concentracao: val && val.trim() == 'PH3 (Fosfina)' ? 400 : 50,
        range_calculo_concentracao2: val && val.trim() == 'PH3 (Fosfina)' ? 600 : 60,
        range_calculo_concentracao3: val && val.trim() == 'PH3 (Fosfina)' ? 800 : 70,
        range_eficiencia: val && val.trim() == 'PH3 (Fosfina)' ? 2000 : 60,
      })
    }

    return (
      <InputSelect onChangeVal={updateVal} label={t('LeiturasEditTipo')} name="tipo_leitura" width={tamanhoCampo}>
        <option value={'PH3 (Fosfina)'}>{t('DashboardNomeFosfina')}</option>
        <option value={'O3 (Ozônio)'}>{t('DashboardNomeOzonio')}</option>
      </InputSelect>
    )
  }

  const onChangeTipoConfiguracao = (val: any, setValue: any, value: any) => {
    const tipo = tipoConfiguracao(val)

    if (!tipo) return;

    if (value.length != tipo.qt) {
      const sensores = [];

      for (let index = 0; index < tipo.qt; index++) {
        const novoSensor = novaLeituraSensor();

        sensores.push({
          ...novoSensor,
          sensor: `P${index + 1}`,
          posicao: index + 1,
          profundidade: (tipo?.profundidade && tipo?.profundidade[`c${index + 1}`]) ? tipo?.profundidade[`c${index + 1}`] : 0,
          configuracao: (tipo?.posicao && tipo?.posicao[`c${index + 1}`]) ? tipo?.posicao[`c${index + 1}`] : 0,
        })
      }

      const values = value.map((i: any) => {
        return {
          ...i,
          deleted: true,
          changed: true,
        }
      }).filter((i: any) => !!i.id);

      setValue([
        ...sensores,
        ...values,
      ])
    }
  }

  const InputTipoConfiguracao = ({ values }: { values: Leitura }) => {
    const [, { value }, { setValue }] = useField('sensores');

    return (
      <InputSelect onChangeVal={(val) => onChangeTipoConfiguracao(val, setValue, value)} label={t('LeiturasEditTipoConfiguracao')} name="tipo_configuracao" width={tamanhoCampo}>
        {tipoConfiguracao(0, getTipoSilo(values.cliente_unidade_silo_id as string)).map((i: any) => (
          <option value={i.value} key={i.value}>{i.name}</option>
        ))}
      </InputSelect>
    )
  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const tamanhoCampo = isMobile ? '100%' : '33%';

  return (
    <Layout>
      <ListHeader label={t('LeiturasListTituloMin')}>
        <DefaultButton ml={4} to="/leituras"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {leitura && <Formik
        initialValues={leitura}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            tipo_configuracao: 'required',
            range_calculo_concentracao: 'required',
            range_calculo_concentracao2: 'required',
            range_calculo_concentracao3: 'required',
            range_eficiencia: 'required',
            equipamento_id: 'required',
            cliente_unidade_silo_id: 'required',
            tipo_leitura: 'required',
          }, values, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          if ((tipoConfiguracao(values.tipo_configuracao)?.qt ?? 0) != (values.sensores ?? []).filter((i) => !i.deleted).length) {
            mensagemErro(t('LeiturasMinimoSensor', { valor: tipoConfiguracao(values.tipo_configuracao)?.qt }))
            // return;
          }

          console.log("leitura form", values);
          dispatch(leituraActions.saveLeituraRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              <InputField label={t('LeiturasListData')} type="datetime-local" name="data" autoFocus width={tamanhoCampo} />
              <InputField label={t('LeiturasEditOrdemServico')} name="ordem_servico" width={tamanhoCampo} />
              <InputSelect label={t('LeiturasEditProduto')} name="produto" width={tamanhoCampo}>
                <option value={'Trigo'}>{t('DashboardTrigo')} (0,765 PH KG/M³)</option>
                <option value={'Milho'}>{t('DashboardMilho')} (0,7 PH KG/M³)</option>
                <option value={'Soja'}>{t('DashboardSoja')} (0,77 PH KG/M³)</option>
                <option value={'Sorgo'}>{t('DashboardSorgo')} (0,72 PH KG/M³)</option>
                <option value={'Cevada'}>{t('DashboardCevada')} (0,58 PH KG/M³)</option>
                <option value={'Triguilho'}>{t('DashboardTriguilho')} (0,6 PH KG/M³)</option>
                <option value={'Farelo'}>{t('DashboardFarelo')} (0,25 PH KG/M³)</option>
                <option value={'Farinha'}>{t('DashboardFarinha')} (0,77 PH KG/M³)</option>
                <option value={'Arroz Limpo'}>{t('DashboardArroz Limpo')} (0,77 PH KG/M³)</option>
                <option value={'Arroz Casca'}>{t('DashboardArroz Casca')} (0,35 PH KG/M³)</option>
                <option value={'Feijao'}>{t('DashboardFeijao')} (0,8 PH KG/M³)</option>
                <option value={'Aveia'}>{t('DashboardAveia')} (0,5 PH KG/M³)</option>
                <option value={'Amendoin'}>{t('DashboardAmendoin')} (0,88 PH KG/M³)</option>
              </InputSelect>
              <InputField label={t('DashboardPdfVolume')} type="number" name="volume" width={tamanhoCampo} />

              <InputTipoLeitura />
              <InputSelect label={t('ClienteEditTitulo')} name="cliente_id" width={tamanhoCampo}>
                {leituraDados && leituraDados.clientes.map((i) => (
                  <option value={i.id} key={i.id}>{i.nome} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
                ))}
              </InputSelect>
              <ClienteUnidadeInput />
              <ClienteUnidadeSiloInput />
              <InputSelect label={t('EquipamentoListTituloMin')} name="equipamento_id" width={tamanhoCampo}>
                {leituraDados && leituraDados.equipamentos.map((i) => (
                  <option value={i.id} key={i.id}>{i.nome_referencia} ({i.endereco_registro})</option>
                ))}
              </InputSelect>
              <InputNumber decimalPlaces={0} label={t('LeiturasEditRangeConcentracao') + " 1"} name="range_calculo_concentracao" width={tamanhoCampo} />
              <InputNumber decimalPlaces={0} label={t('LeiturasEditRangeConcentracao') + " 2"} name="range_calculo_concentracao2" width={tamanhoCampo} />
              <InputNumber decimalPlaces={0} label={t('LeiturasEditRangeConcentracao') + " 3"} name="range_calculo_concentracao3" width={tamanhoCampo} />
              <InputNumber decimalPlaces={0} label={t('LeiturasEditRangeEficiencia')} name="range_eficiencia" width={tamanhoCampo} />

              <InputTipoConfiguracao values={values} />

              <InputNumber decimalPlaces={0} label={t('LeiturasEditRange48')} name="range_reaplicacao" width={tamanhoCampo} />

              <InputField label={t('LeiturasEditAbertura')} type="datetime-local" name="data_abertura" width={tamanhoCampo} />
              <InputField label={t('LeiturasEditFechamento')} type="datetime-local" name="data_fechamento" width={tamanhoCampo} />
              <InputField disabled label={t('LeiturasEditTempoExposicaoH')} type="number" name="tempo_exposicao" width={tamanhoCampo} />
              <InputField label={t('LeiturasEditInicioHermeticidade')} type="datetime-local" name="data_inicio_hermeticidade" width={tamanhoCampo} />
              <InputCheck mt={8} name="e_leitura_de_6_silos" label={t('LeiturasEditLeituraEDe6Silos')} width={tamanhoCampo} />
              <InputCheck mt={8} name="e_leitura_de_silo_cilindrico_mas_com_preparacao_predio" label={t('LeiturasEditLeituraEDeCilindricoMasComPreparacaoDePredio')} width={tamanhoCampo} />
              <InputCheck mt={8} name="finalizado" label={t('LeiturasEditLeituraFinalizada')} width={tamanhoCampo} />
            </Flex>

            <LeiturasSensoresForm />
            <LeiturasResponsaveis />
            <LeiturasDados />

            <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('LeiturasListTituloMin')}</DefaultButton>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}
