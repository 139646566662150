import { AvaliacaoInicialInseto } from "../types/avaliacao_inicial_inseto";

export const novaAvaliacaoInicialInseto = (): AvaliacaoInicialInseto => {
  return {
    avaliacao_inicial_id: "",
    sequencial: 0,
    qt_sitophilus: 0,
    qt_rhyzopertha: 0,
    qt_tribolium: 0,
    qt_oryzaephilus: 0,
    qt_cryptolestes: 0,
    imagem: "",
  };
};
