export const formatCep = (documento: string | number): string => {
  if (typeof documento != "string" && typeof documento != "number") {
    documento = "";
  }
  if (typeof documento == "number") {
    documento = documento.toString();
  }
  if (documento.length === 8) {
    return documento.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  }
  return '';
};
