import { Button, ButtonProps } from "@chakra-ui/react"
import config from "../../../config"
import { useNavigate } from "react-router-dom";

interface IProps extends ButtonProps {
  to?: string;
}

export const DefaultButton = ({ children, to, ...props }: IProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!to) return;

    navigate(to);
  }

  return (
    <Button onClick={onClick} bg={config.defaultColor} _hover={{ backgroundColor: "#0c3a14" }} {...props} colorScheme="blackAlpha">{children}</Button>
  )
}