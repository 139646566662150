import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { ButtonGroup, Flex, Heading, Img, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import ReactSpeedometer from "react-d3-speedometer";
import { FiEye, FiInfo } from "react-icons/fi";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { addHours, differenceInHours, differenceInMinutes } from "date-fns";
import { ReactECharts } from "./ReactECharts";
import { getDados as getDadosGraficoLinha } from "../components/DashboardGraficoLinha";
import { LeituraDados } from "../types/leitura_dados";
import imagesUrl from "../../utils/imagesUrl";

const check = imagesUrl + 'check.png';
const rostoAmarelo = imagesUrl + "rostoAmarelo.png";
const rostoLaranja = imagesUrl + "rostoLaranja.png";
const rostoVerdeClaro = imagesUrl + "rostoVerdeClaro.png";
const rostoVerdeEscuro = imagesUrl + "rostoVerdeEscuro.png";
const rostoVermelho = imagesUrl + "rostoVermelho.png";

export const DashboardConcentracaoMedia = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);

  const { t } = useTranslation()

  const customColors = () => {
    const colors: any = ["#FF6347", "#FFD700", "#00FF7F"]; // vermelho, amarelo, verde, azul

    if (getMaxValue() == 96) {
      return [
        ...colors,
        "#4169E1",
      ]
    }

    if (getMaxValue() == 120) {
      return [
        ...colors,
        "#4169E1",
        "#4169E1",
      ]
    }

    if (getMaxValue() == 144) {
      return [
        ...colors,
        "#4169E1",
        "#4169E1",
        "#4169E1",
      ]
    }

    return colors;
  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const resultadosFosfina = [
    {
      name: t('DashboardFosfinaResultado1Titulo'),
      value: t('DashboardFosfinaResultado1Descricao'),
    },
    {
      name: t('DashboardFosfinaResultado2Titulo'),
      value: t('DashboardFosfinaResultado2Descricao'),
    },
    {
      name: t('DashboardFosfinaResultado3Titulo'),
      value: t('DashboardFosfinaResultado3Descricao'),
    },
    {
      name: t('DashboardFosfinaResultado4Titulo'),
      value: t('DashboardFosfinaResultado4Descricao'),
    },
  ]

  const resultadosOzonio = [
    {
      name: t('DashboardOzonioResultado1Titulo'),
      value: t('DashboardOzonioResultado1Descricao'),
    },
    {
      name: t('DashboardOzonioResultado2Titulo'),
      value: t('DashboardOzonioResultado2Descricao'),
    },
    {
      name: t('DashboardOzonioResultado3Titulo'),
      value: t('DashboardOzonioResultado3Descricao'),
    },
    {
      name: t('DashboardOzonioResultado4Titulo'),
      value: t('DashboardOzonioResultado4Descricao'),
    },
  ]

  const resultados = eFosfina ? resultadosFosfina : resultadosOzonio;

  const tempoExposicao = (dashboard?.tempo_exposicao ?? 0);
  const range1 = dashboard?.last_leitura?.range_calculo_concentracao ?? 0;
  const range2 = dashboard?.last_leitura?.range_calculo_concentracao2 ?? 0;
  const range3 = dashboard?.last_leitura?.range_calculo_concentracao3 ?? 0;
  const range1String = dashboard?.tempo_exposicao_string ?? 0;
  const range2String = dashboard?.tempo_exposicao_2_string ?? 0;
  const range3String = dashboard?.tempo_exposicao_3_string ?? 0;

  const [selectRange, setSelectRange] = useState(-1)

  useEffect(() => {
    if (selectRange === -1 && range3) {
      setSelectRange(range3)
    }
  }, [range3, selectRange])

  const getValue = () => {
    if (selectRange == range1) {
      return dashboard?.tempo_exposicao ?? 0
    }
    if (selectRange == range2) {
      return parseFloat(dashboard?.tempo_exposicao_2 ?? '0')
    }
    if (selectRange == range3) {
      return parseFloat(dashboard?.tempo_exposicao_3 ?? '0')
    }

    return 0;
  }

  const getMaxValue = () => {
    if (getValue() > 120) {
      return 144;
    }
    if (getValue() > 96) {
      return 120;
    }

    return 96;
  }

  const customStops = () => {
    if (getValue() > 120) {
      return [0, 24, 48, 72, 96, 120, 144];
    }
    if (getValue() > 96) {
      return [0, 24, 48, 72, 96, 120];
    }

    return [0, 24, 48, 72, 96];
  }


  const { isOpen, onOpen, onClose } = useDisclosure()

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const getDados = () => {
    const dados = (dashboard?.data ?? [])


    const dadosFormatados = dados.map((i) => {
      let qtItens = 0;
      let valorTotal = 0;

      for (let index = 1; index <= 16; index++) {
        if (temOPontoConfigurado(index)) {
          qtItens += 1;
          valorTotal += (i as any)[`c${index}`] as any
        }
      }

      return {
        dataHora: i.data,
        media: qtItens == 0 ? 0 : valorTotal / qtItens,
      }
    })

    if (eFosfina) {
      if (!dashboard?.last_leitura?.data_inicio_hermeticidade) return [];
   
      const retorno: { dataHora: string; media: number }[] = [];
      dadosFormatados.forEach((i) => {
        if (new Date(i.dataHora) >= new Date(dashboard?.last_leitura.data_inicio_hermeticidade)) {
          retorno.push(i)
        }
      })

      return retorno;
    } else {
      const retorno: { dataHora: string; media: number }[] = [];
      dadosFormatados.forEach((i) => {
        retorno.push(i)
      })

      return retorno;
    }
  }

  const getDadosPorIntervalo12Horas = () => {
    const dados = getDados();

    let primeiroItem = dados[0];

    if (!primeiroItem) return null;

    const retorno = [];

    retorno.push({
      '0': parseInt(primeiroItem.media.toFixed(0)),
    })

    for (let index = 1; index <= 70; index++) {
      let valor = 0;
      const horaAtual = 12 * index;
      for (const i of dados) {
        if (new Date(i.dataHora) >= addHours(new Date(primeiroItem.dataHora), horaAtual)) {
          valor = i.media;
          break;
        }
      }

      retorno.push({
        [`${horaAtual}`]: parseInt(valor.toFixed(0)),
      })
    }

    return retorno;
  }

  const [concentracaoAvaliada, setConcentracaoAvaliada] = useState(-1);
  const [tempoAvaliado, setTempoAvaliado] = useState(-1);

  useEffect(() => {
    if (concentracaoAvaliada === -1 && range3) {
      setConcentracaoAvaliada(range3)
    }
  }, [range3, concentracaoAvaliada])

  useEffect(() => {
    if (tempoAvaliado === -1 && dashboard?.data && dashboard?.data.length > 1) {
      setTempoAvaliado(120)
      // parseInt(differenceInHours(new Date(dashboard.data[dashboard.data.length - 1].data), new Date(dashboard.data[0].data)).toFixed(0))
    }
  }, [range1, tempoAvaliado, dashboard?.data])

  const getHorasGraficoLinha = () => {
    const dados = getDadosPorIntervalo12Horas();

    if (!dados) return [];

    const retorno: string[] = [];

    dados.forEach((i) => {
      Object.keys(i).forEach((j) => {
        if (tempoAvaliado > parseInt(j)) {
          retorno.push(`${j}h`)
        }
      })
    })

    return retorno;
  }

  const getValoresGraficoLinha = () => {
    const dados = getDadosPorIntervalo12Horas();

    if (!dados) return [];

    const retorno: string[] = [];

    dados.forEach((i) => {
      Object.keys(i).forEach((j) => {
        if (tempoAvaliado > parseInt(j)) {
          retorno.push(`${(i as any)[j]}`)
        }
      })
    })

    return retorno;
  }

  const getSeriesGraficoLinha = () => {
    const grafico: any = [];

    grafico.push({
      name: `${t('DashboardConcentracaoMediaGraficoConcentracao')}`,
      type: 'line',
      stack: `${t('DashboardConcentracaoMediaGraficoConcentracao')}`,
      data: getValoresGraficoLinha(),
      smooth: true,
    })

    const concentracaoDefinida: any = [];
    getValoresGraficoLinha().forEach((i) => {
      concentracaoDefinida.push(`${concentracaoAvaliada}`);
    })

    grafico.push({
      name: `${t('DashboardConcentracaoMediaGraficoConcentracaoDefinida')}`,
      type: 'line',
      stack: `${t('DashboardConcentracaoMediaGraficoConcentracaoDefinida')}`,
      data: concentracaoDefinida,
      smooth: true,
      color: 'red'
    })

    return grafico;
  }

  const data: any = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
    },
    legend: {
      data: [`${t('DashboardConcentracaoMediaGraficoConcentracao')}`, `${t('DashboardConcentracaoMediaGraficoConcentracaoDefinida')}`],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getHorasGraficoLinha(),
    },
    yAxis: {
      type: 'value'
    },
    series: getSeriesGraficoLinha(),
  }


  const getRangeExposicao = () => {
    const dados = getDados();

    let tempoTotal = 0;
    let inicioPeriodo: any = null;

    dados.forEach((i) => {
      const media = i.media;

      if (media >= concentracaoAvaliada) {
        if (!inicioPeriodo) {
          inicioPeriodo = new Date(i.dataHora);
        }
      } else {
        if (inicioPeriodo) {
          const fimPeriodo: any = new Date(i.dataHora)
          const tempoPeriodo = fimPeriodo - inicioPeriodo;
          tempoTotal += tempoPeriodo
          inicioPeriodo = null
        }
      }
    })

    if (inicioPeriodo) {
      const fimPeriodo: any = new Date(dados[dados.length - 1].dataHora)
      const tempoPeriodo = fimPeriodo - inicioPeriodo;
      tempoTotal += tempoPeriodo;
    }

    return tempoTotal == 0 ? 0 : tempoTotal / 60 / 60 / 1000;
  }

  function decimalParaHora(decimal: number) {
    // Arredonda o número decimal para 2 casas decimais
    decimal = Number(decimal.toFixed(2));

    // Calcula as horas, minutos e segundos
    const horas = Math.floor(decimal);
    const minutosDecimal = (decimal - horas) * 60;
    const minutos = Math.floor(minutosDecimal);

    // Formate a hora no formato HH:MM:SS
    const horaFormatada = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;

    return horaFormatada;
  }

  const Legendas = ({ tempoExposicao = 0 }) => {
    const [toltip1Opem, setToltip1Opem] = useState(false)
    const [toltip2Opem, setToltip2Opem] = useState(false)
    const [toltip3Opem, setToltip3Opem] = useState(false)
    const [toltip4Opem, setToltip4Opem] = useState(false)

    return (
      <>
        {tempoExposicao > 24 && tempoExposicao <= 48 && (<Flex wrap={"wrap"}>
          <Tooltip isOpen={toltip1Opem} label={resultados[0].value}>
            <Flex fontSize={"10px"} fontWeight={"700"} onMouseEnter={() => setToltip1Opem(true)}
              onMouseLeave={() => setToltip1Opem(false)}
              onClick={() => setToltip1Opem(true)} cursor={"pointer"} bg={'#FF6347'} color={"white"} py={0} px={2} borderRadius={5}>
              <Flex textAlign={"center"} direction={"column"} alignItems={"center"} width={"full"}>
                <ButtonGroup alignItems={"center"}><FiInfo fontSize={"10px"} />&nbsp;24 h</ButtonGroup>
                <span>{resultados[0].name}</span>
              </Flex>
            </Flex>
          </Tooltip>
        </Flex>)}

        {tempoExposicao > 48 && tempoExposicao <= 72 && (<Flex wrap={"wrap"}>
          <Tooltip isOpen={toltip2Opem} label={resultados[1].value}>
            <Flex fontSize={"10px"} fontWeight={"700"} overflowWrap={"break-word"} onMouseEnter={() => setToltip2Opem(true)}
              onMouseLeave={() => setToltip2Opem(false)}
              onClick={() => setToltip2Opem(true)} cursor={"pointer"} bg={'#FFD700'} color={"white"} py={0} px={2} borderRadius={5}>
              <Flex textAlign={"center"} direction={"column"} alignItems={"center"} width={"full"}>
                <ButtonGroup alignItems={"center"}><FiInfo fontSize={"10px"} />&nbsp;48 h</ButtonGroup>
                <span>{resultados[1].name}</span>
              </Flex>
            </Flex>
          </Tooltip>
        </Flex>)}


        {tempoExposicao > 72 && tempoExposicao <= 96 && (<Flex wrap={"wrap"}>
          <Tooltip isOpen={toltip3Opem} label={resultados[2].value}>
            <Flex fontSize={"10px"} fontWeight={"700"} onMouseEnter={() => setToltip3Opem(true)}
              onMouseLeave={() => setToltip3Opem(false)}
              onClick={() => setToltip3Opem(true)} cursor={"pointer"} bg={'#00FF7F'} color={"white"} py={0} px={2} borderRadius={5}>
              <Flex textAlign={"center"} direction={"column"} alignItems={"center"} width={"full"}>
                <ButtonGroup alignItems={"center"}><FiInfo fontSize={"10px"} />&nbsp;72 h</ButtonGroup>
                <span>{resultados[2].name}</span>
              </Flex>
            </Flex>
          </Tooltip>
        </Flex>)}

        {tempoExposicao > 96 && (<Flex wrap={"wrap"}>
          {getMaxValue() >= 96 && <>
            <Tooltip isOpen={toltip4Opem} label={resultados[3].value}>
              <Flex fontSize={"10px"} fontWeight={"700"} onMouseEnter={() => setToltip4Opem(true)}
                onMouseLeave={() => setToltip4Opem(false)}
                onClick={() => setToltip4Opem(true)} cursor={"pointer"} bg={'#4069E1'} color={"white"} py={0} px={2} borderRadius={5}>
                <Flex textAlign={"center"} direction={"column"} alignItems={"center"} width={"full"}>
                  <ButtonGroup alignItems={"center"}><FiInfo fontSize={"10px"} />&nbsp;96 h</ButtonGroup>
                  <span>{resultados[3].name}</span>
                </Flex>
              </Flex>
            </Tooltip>
          </>}
        </Flex>)}
      </>
    )
  }

  const getEficienciaPonto = (index: number) => {
    if (!getLeituraComBaseNaHoraDeterminada()) return 0;

    const value = (getLeituraComBaseNaHoraDeterminada() as any)[`c${index}`];

    return (value * 100 / concentracaoAvaliada) > 100 ? 100 : value * 100 / concentracaoAvaliada;
  }

  const getEficienciaGeral = () => {
    let total = 0;
    let qt = 0;

    for (let index = 1; index <= 16; index++) {
      if (temOPontoConfigurado(index)) {
        total += getEficienciaPonto(index);
        qt += 1;;
      }
    }

    return (total / qt) > 100 ? 100 : (total / qt);
  }

  const getPontosAtivos = () => {
    const retorno = [];

    for (let index = 1; index <= 16; index++) {
      if (temOPontoConfigurado(index)) {
        retorno.push(`P${index} - ${t('DashboardEficiência')}: ${getEficienciaPonto(index).toFixed(0)}%`)
      }
    }

    return retorno;
  }

  const getColorGraficoBarra = (valor: number) => {
    if (!concentracaoAvaliada && concentracaoAvaliada != 0) {
      return 'yellow';
    }

    if (valor < concentracaoAvaliada) {
      return '#FF6347';
    }

    if (valor >= (concentracaoAvaliada + (concentracaoAvaliada * 0.2))) {
      return '#4169E1'
    }

    return '#00FF7F'
  }

  const getLeituraComBaseNaHoraDeterminada = () => {
    if (!dashboard?.last_leitura) return null;

    const leituraDados = getDadosGraficoLinha(dashboard?.data ?? [], dashboard?.last_leitura)

    let leituraDadosDeterminado: LeituraDados | null = null;

    leituraDados.forEach((i) => {
      if ((i?.hora ?? 0) < tempoAvaliado) leituraDadosDeterminado = i;
    })

    return leituraDadosDeterminado;
  }

  const getDataGraficoBarra = () => {
    const retorno = [];

    if (!getLeituraComBaseNaHoraDeterminada()) return [];

    for (let index = 1; index <= 16; index++) {
      if (temOPontoConfigurado(index)) {
        const value = (getLeituraComBaseNaHoraDeterminada() as any)[`c${index}`];

        retorno.push({
          value,
          itemStyle: {
            color: getColorGraficoBarra(value),
          }
        })
      }
    }

    return retorno;
  }

  const dataGraficoLinha: any = {
    backgroundColor: 'transparent',
    title: {
      show: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '1%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: getPontosAtivos(),
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: t('DashbordConcentracao') + ' (ppm)',
        type: 'bar',
        barWidth: '80%',
        data: getDataGraficoBarra(),
        animationEasing: 'elasticOut',
        animationDelay: function (idx: any) {
          return Math.random() * 200;
        },
      }
    ]
  };

  const getPorcentagemDentroDoRange = () => {
    const dados = getDados();

    if (dados.length <= 1) return 0;

    let quantidadeDentro = 0;
    let quantidadeTotal = 0;
    let primeiroItem = dados[0];
    for (const item of dados) {
      if (differenceInHours(new Date(item.dataHora), new Date(primeiroItem.dataHora)) <= tempoAvaliado) {
        quantidadeTotal += 1;

        if (item.media >= concentracaoAvaliada) {
          quantidadeDentro += 1;
        }
      }
    }

    return quantidadeDentro == 0 ? 0 : ((quantidadeDentro * 100) / quantidadeTotal) > 100 ? 100 : ((quantidadeDentro * 100) / quantidadeTotal);
  }

  const retorna2 = (valor: number) => {
    if (valor < 10) {
      return `0${valor.toFixed(0)}`
    }

    return valor.toFixed(0);
  }

  const getTempoExposicaoGeral = (apenasQuantidadeHoras = false) => {
    if (!dashboard?.data) return 0;
    if (dashboard.data.length <= 1) return 0;

    const minutes = differenceInMinutes(new Date(dashboard.data[dashboard.data.length - 1].data as any), new Date(dashboard.data[0].data))

    const minutesSobra = minutes % 60;
    const horas = minutes / 60;

    if (apenasQuantidadeHoras) return horas;

    return `${retorna2(horas)}:${retorna2(minutesSobra)}`
  }

  const getPorcentagemTempoExposto = () => {
    const totalHorasSelecionado = tempoAvaliado;
    const totalHorasFeita = getTempoExposicaoGeral(true) as any;
    const totalPorcentagemDentroDoRange = getPorcentagemDentroDoRange();

    if (!totalHorasSelecionado || !totalHorasFeita || !totalPorcentagemDentroDoRange) {
      return {
        porcentagem: 0,
        totalHorasFeitas: 0,
        totalHorasFaltando: 0,
      }
    }

    const porcentagemFeita = (totalHorasFeita * 100) / totalHorasSelecionado;

    return {
      porcentagem: porcentagemFeita > 100 ? 100 : porcentagemFeita < 0 ? 0 : porcentagemFeita,
      totalHorasFeitas: totalHorasFeita >= 0 ? totalHorasFeita : 0,
      totalHorasFaltando: (totalHorasSelecionado - totalHorasFeita) >= 0 ? (totalHorasSelecionado - totalHorasFeita) : 0,
    }
  }

  const dataGraficoRedondo2 = {
    tooltip: {
      trigger: 'item',
      valueFormatter: (value: number) => `${value}h`,
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: getPorcentagemTempoExposto().totalHorasFeitas.toFixed(0), name: t('DashboardConcentracaoMediaGraficoCircularPerformanceTempoTempoExposto'), itemStyle: { color: '#00FF7F' } },
          { value: getPorcentagemTempoExposto().totalHorasFaltando.toFixed(0), name: t('DashboardConcentracaoMediaGraficoCircularPerformanceTempoTempoRestante'), itemStyle: { color: '#FFD700' } },
        ]
      }
    ]
  };

  const dataGraficoRedondo1 = {
    tooltip: {
      trigger: 'item',
      valueFormatter: (value: string) => `${parseFloat(value)}%`,
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: getPorcentagemDentroDoRange().toFixed(2), name: t('DashboardConcentracaoMediaGraficoCircularPerformanceDentroDoRange'), itemStyle: { color: '#00FF7F' } },
          { value: (100 - getPorcentagemDentroDoRange()).toFixed(2), name: t('DashboardConcentracaoMediaGraficoCircularPerformanceForaDoRange'), itemStyle: { color: '#FFD700' } },
        ]
      }
    ]
  };

  const hexVermelho = '#d53a23'
  const hexLaranja = '#f78208'
  const hexAmarelo = '#facd11'
  const hexVerde = '#b0cc05'
  const hexVerdeEscuro = '#5ebc0d'

  const rostos = [
    {
      porcentagem: '100%',
      imagem: check,
      texto: t('TextoExelente'),
      cor: hexVerdeEscuro,
      min: 100,
      max: 100,
    },
    {
      porcentagem: '96-99%',
      imagem: rostoVerdeEscuro,
      texto: t('TextoOtimo'),
      cor: hexVerdeEscuro,
      min: 96,
      max: 99,
    },
    {
      porcentagem: '90-95%',
      imagem: rostoVerdeClaro,
      texto: t('TextoBom'),
      cor: hexVerde,
      min: 90,
      max: 95,
    },
    {
      porcentagem: '85-89%',
      imagem: rostoAmarelo,
      texto: t('TextoNaoRecomendado'),
      cor: hexAmarelo,
      min: 85,
      max: 89,
    },
    {
      porcentagem: '80-84%',
      imagem: rostoLaranja,
      texto: t('TextoRegular'),
      cor: hexLaranja,
      min: 80,
      max: 84,
    },
    {
      porcentagem: '0-79%',
      imagem: rostoVermelho,
      texto: t('TextoRuim'),
      cor: hexVermelho,
      min: 0,
      max: 79,
    },
  ];

  const getRostoAtual = (porcentagem: number) => {
    return rostos.find((i) => porcentagem <= i.max && porcentagem >= i.min);
  }

  const [toltipInfoPerformanceGeralOpen, setToltipInfoPerformanceGeralOpen] = useState(false)
  const [toltipInfoMediaDos2Open, setToltipInfoMediaDos2Open] = useState(false)

  return (
    <Flex id="dashboard-tempo-concentracao" width={"full"} justifyContent={"center"} alignItems={"center"} direction={"column"} maxH={isMobile ? '10000000px' : '190px'} minH={eFosfina ? "190px" : "217px"} >
      <Text px={1} position={"relative"} fontSize="0.8rem" color="#404244" fontWeight="700">{t('DashboardTempoConcentracao')}</Text>
      <Text px={1} py={0} position={"relative"} fontSize="0.8rem" color="#404244" fontWeight="700">{eFosfina ? t('DashboardTempoConcentracaoAcimaDe') : t('DashboardTempoConcentracaoCom')} <select value={selectRange} onChange={(event) => setSelectRange(parseInt(event.target.value))}>
        <option value={range1}>{range1}ppm</option>
        <option value={range2}>{range2}ppm</option>
        <option value={range3}>{range3}ppm</option>
      </select>
        <DefaultButton className="no-print" size={"xs"} onClick={onOpen}><FiEye /> </DefaultButton>
      </Text>

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardConcentracaoMediaTitulo')}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex width={'100%'} wrap={"wrap"} alignItems={"center"} height={isMobile ? 'auto' : "auto"} justifyContent={"center"}>
              <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"}>
                <Flex width={"full"} wrap={"wrap"}>
                  <Flex width={isMobile ? '100%' : '35%'} wrap={"wrap"}>
                    <Flex width={"full"} wrap={"wrap"} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                      <Flex direction={"column"} justifyContent={"center"} textAlign={"center"} borderRadius={"15px"} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} p={1} mb={2}>
                        <Text color={"#404244"} fontWeight={700} fontSize={"14px"}>
                          {t('DashboardConcentracaoMediaTituloConcentracaoTotal')}
                        </Text>
                        <Text fontWeight={400} fontSize={"10px"}>
                          {t('DashboardConcentracaoMediaTituloConcentracaoTotalDetalhe')}
                        </Text>
                        <Text>
                          {getTempoExposicaoGeral()} {t('DashboardAmostraHoras')}
                        </Text>
                      </Flex>
                      <Text fontSize={"16px"}>{t('DashboardConcentracaoMediaASerAvaliada1')}</Text>
                      <Text fontSize={"16px"}>{t('DashboardConcentracaoMediaASerAvaliada2')}</Text>
                      <Input value={concentracaoAvaliada} onChange={(evt) => setConcentracaoAvaliada(parseInt(evt.target.value))} type="number" height={"30px"} borderRadius={0} width={"100px"} />
                      <Text mt={4} fontSize={"16px"}>{t('DashboardConcentracaoMediaASerAvaliadaTempo1')}</Text>
                      <Text fontSize={"16px"}>{t('DashboardConcentracaoMediaASerAvaliadaTempo2')}</Text>
                      <Input value={tempoAvaliado} onChange={(evt) => setTempoAvaliado(parseInt(evt.target.value))} type="number" height={"30px"} borderRadius={0} width={"100px"} />
                    </Flex>
                  </Flex>
                  <Flex width={isMobile ? '100%' : '65%'} wrap={"wrap"}>
                    <Flex width={"full"} wrap={"wrap"}>
                      <Heading color={"#404244"} mx={"auto"} mb={1} size={"xs"}>{t('DashboardConcentracaoMediaGraficoConcentracaoTitulo')} </Heading>
                      <ReactECharts option={data} style={{ height: '250px' }} />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex width={"full"} wrap={"wrap"}>
                  <Flex width={isMobile ? '100%' : '35%'} wrap={"wrap"}>

                    <Flex width={"full"} wrap={"wrap"} justifyContent={"center"} direction={"column"} alignItems={"center"}>
                      <Text px={1} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardTempoConcentracao')}</Text>
                      <Text px={1} py={0} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{eFosfina ? t('DashboardTempoConcentracaoAcimaDe') : t('DashboardTempoConcentracaoCom')} {concentracaoAvaliada >= 0 ? concentracaoAvaliada : 0}ppm</Text>

                      <ReactSpeedometer
                        value={getRangeExposicao()}
                        minValue={0}
                        maxValue={getMaxValue()}
                        needleColor="#888"
                        valueTextFontSize="0"
                        startColor={customColors()[0]}
                        endColor={customColors()[customColors().length - 1]}
                        segments={getMaxValue() == 120 ? 5 : 4}
                        customSegmentStops={customStops()}
                        segmentColors={customColors()}
                        height={90}
                        width={150}
                        needleHeightRatio={0.7}
                        ringWidth={30}
                        needleTransitionDuration={1000}
                        labelFontSize="8"
                      />
                      <Text>{decimalParaHora(getRangeExposicao())} {t('DashboardHoras')} </Text>
                      <Legendas tempoExposicao={getRangeExposicao()} />
                    </Flex>
                  </Flex>
                  <Flex width={isMobile ? '100%' : '65%'} wrap={"wrap"}>
                    <Flex position={"relative"} width={"full"} wrap={"wrap"}>
                      <Heading left={'50%'} transform={"translateX(-50%)"} position={"absolute"} color={"#404244"} top={5} mx={"auto"} mb={0} size={"xs"}>{t('DashboardConcentracaoMediaGraficoBarraTitulo')} </Heading>
                      <ReactECharts option={dataGraficoLinha} style={{ height: '200px' }} />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width={isMobile ? '100%' : "50%"} wrap={"wrap"}>

                <Flex width={"full"} wrap={"wrap"} mt={10}>
                  <Flex py={4} width={isMobile ? '100%' : '50%'} wrap={"wrap"} p={1}>
                    <Flex width={"full"} wrap={"wrap"} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"15px"} justifyContent={"center"} direction={"column"} alignItems={"center"}>
                      <Text mt={2} px={1} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularPerformance1')}</Text>
                      <Text px={1} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularPerformance2')}</Text>

                      <Text color={"#404244"} fontSize={"35px"} fontWeight={700}>
                        {getPorcentagemDentroDoRange().toFixed(0)}%
                      </Text>

                      <Text color={"#404244"} fontSize={"12px"} fontWeight={700}>
                        {t('DashboardConcentracaoMediaGraficoCircularPerformanceSutitulo')}: {concentracaoAvaliada >= 0 ? concentracaoAvaliada : 0}ppm
                      </Text>

                      <ReactECharts option={dataGraficoRedondo1 as any} style={{ height: '250px' }} />
                    </Flex>
                  </Flex>
                  <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"} p={1}>
                    <Flex py={4} width={"full"} wrap={"wrap"} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"15px"} justifyContent={"center"} direction={"column"} alignItems={"center"}>
                      <Text px={1} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularPerformanceTempo1')}</Text>
                      <Text px={1} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularPerformanceTempo2')}</Text>

                      <Text color={"#404244"} fontSize={"35px"} fontWeight={700}>
                        {getPorcentagemTempoExposto().porcentagem.toFixed(0)}%
                      </Text>

                      <Text color={"#404244"} fontSize={"12px"} fontWeight={700}>
                        {t('DashboardConcentracaoMediaGraficoCircularPerformanceTempoReferencia')}: {getPorcentagemTempoExposto().totalHorasFaltando.toFixed(0)}h
                      </Text>

                      <ReactECharts option={dataGraficoRedondo2 as any} style={{ height: '250px' }} />
                    </Flex>
                  </Flex>

                </Flex>
              </Flex>
              <Flex mb={isMobile ? "200px" : "0"} width={'100%'} wrap={"wrap"} justifyContent={"space-around"}>
                <Flex wrap={"wrap"} p={1} pl={isMobile ? 1 : '150px'}>
                  <Flex position={"relative"} wrap={"wrap"} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"15px"} justifyContent={"center"} alignItems={"center"}>
                    <Flex px={isMobile ? 1 : 6} wrap={"wrap"} direction={"column"}>
                      <Text px={1} position={"relative"} fontSize="1.4rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularPerformanceGeral')}</Text>
                      <ReactECharts option={{
                        series: [
                          {
                            type: 'gauge',
                            startAngle: 90,
                            endAngle: -270,
                            pointer: {
                              show: false
                            },
                            progress: {
                              show: true,
                              overlap: false,
                              roundCap: true,
                              clip: false,
                              itemStyle: {
                                borderWidth: 1,
                                borderColor: 'gray',
                                color: getRostoAtual(parseInt((getEficienciaGeral()).toFixed(0)))?.cor
                              }
                            },
                            axisLine: {
                              lineStyle: {
                                width: 20
                              }
                            },
                            splitLine: {
                              show: false,
                              distance: 0,
                              length: 15
                            },
                            axisTick: {
                              show: false
                            },
                            axisLabel: {
                              show: false,
                              distance: 100
                            },
                            data: [
                              {
                                value: getEficienciaGeral().toFixed(0),
                                name: '',
                                title: {
                                  offsetCenter: ['0%', '-18%']
                                },
                                detail: {
                                  valueAnimation: true,
                                  offsetCenter: ['0%', '0%']
                                }
                              },
                            ],
                            title: {
                              fontSize: 14
                            },
                            detail: {
                              width: 30,
                              height: 8,
                              fontSize: 25,
                              color: getRostoAtual(parseInt((getEficienciaGeral()).toFixed(0)))?.cor,
                              borderColor: getRostoAtual(parseInt((getEficienciaGeral()).toFixed(0)))?.cor,
                              borderRadius: 20,
                              borderWidth: 0,
                              formatter: '{value}%',
                            }
                          }
                        ]
                      } as any
                      } style={{ height: '200px' }} />

                      <Flex wrap={"wrap"} position={"absolute"} top={"10px"} right={"10px"}>
                        <Tooltip isOpen={toltipInfoPerformanceGeralOpen} label={t('PerformanceMediaComBaseNaConcentracaoAvaliada')}>
                          <Flex fontSize={"10px"} fontWeight={"700"} overflowWrap={"break-word"} onMouseEnter={() => setToltipInfoPerformanceGeralOpen(true)}
                            onMouseLeave={() => setToltipInfoPerformanceGeralOpen(false)}
                            onClick={() => setToltipInfoPerformanceGeralOpen(true)} cursor={"pointer"}>
                            <FiInfo size="25px" />
                          </Flex>
                        </Tooltip>
                      </Flex>

                    </Flex>
                    <Flex px={4} wrap={"wrap"}>
                      {rostos.map((i) => (
                        <Flex mt={2} alignItems={"center"} textAlign={"center"} width={"33%"} key={i.porcentagem} direction={"column"}>
                          <Text fontSize={"10px"}>{i.porcentagem}</Text>
                          <Img mx={"auto"} width={"35px"} height={"auto"} src={i.imagem} />
                          <Text fontSize={"10px"}>{i.texto}</Text>
                        </Flex>
                      ))}
                    </Flex>

                  </Flex>
                </Flex>
                <Flex width={isMobile ? '100%' : 'auto'} wrap={"wrap"} pr={isMobile ? 1 : '140px'}>
                  <Flex width={isMobile ? '100%' : 'auto'} px={isMobile ? 0 : '50px'} position={"relative"} wrap={"wrap"} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"15px"} justifyContent={"center"} alignItems={"center"}>
                    <Flex px={isMobile ? 1 : 6} wrap={"wrap"} direction={"column"}>
                      <Text px={1} position={"relative"} fontSize="1.4rem" color="#404244" fontWeight="700">{t('DashboardConcentracaoMediaGraficoCircularDashboardMédia')}</Text>

                      <ReactECharts option={{
                        series: [
                          {
                            type: 'gauge',
                            startAngle: 90,
                            endAngle: -270,
                            pointer: {
                              show: false
                            },
                            progress: {
                              show: true,
                              overlap: false,
                              roundCap: true,
                              clip: false,
                              itemStyle: {
                                borderWidth: 1,
                                borderColor: 'gray',
                                color: getRostoAtual(parseInt((((getPorcentagemTempoExposto().porcentagem + getPorcentagemDentroDoRange()) / 2)).toFixed(0)))?.cor
                              }
                            },
                            axisLine: {
                              lineStyle: {
                                width: 20
                              }
                            },
                            splitLine: {
                              show: false,
                              distance: 0,
                              length: 15
                            },
                            axisTick: {
                              show: false
                            },
                            axisLabel: {
                              show: false,
                              distance: 100
                            },
                            data: [
                              {
                                value: ((getPorcentagemTempoExposto().porcentagem + getPorcentagemDentroDoRange()) / 2).toFixed(0),
                                name: '',
                                title: {
                                  offsetCenter: ['0%', '-18%']
                                },
                                detail: {
                                  valueAnimation: true,
                                  offsetCenter: ['0%', '0%']
                                }
                              },
                            ],
                            title: {
                              fontSize: 14
                            },
                            detail: {
                              width: 30,
                              height: 8,
                              fontSize: 25,
                              color: getRostoAtual(parseInt((((getPorcentagemTempoExposto().porcentagem + getPorcentagemDentroDoRange()) / 2)).toFixed(0)))?.cor,
                              borderColor: getRostoAtual(parseInt((((getPorcentagemTempoExposto().porcentagem + getPorcentagemDentroDoRange()) / 2)).toFixed(0)))?.cor,
                              borderRadius: 20,
                              borderWidth: 0,
                              formatter: '{value}%',
                            }
                          }
                        ]
                      } as any
                      } style={{ height: '200px' }} />

                      <Flex wrap={"wrap"} position={"absolute"} top={"10px"} right={"10px"}>
                        <Tooltip isOpen={toltipInfoMediaDos2Open} label={t('PerformanceMediaComBaseNas2Medias')}>
                          <Flex fontSize={"10px"} fontWeight={"700"} overflowWrap={"break-word"} onMouseEnter={() => setToltipInfoMediaDos2Open(true)}
                            onMouseLeave={() => setToltipInfoMediaDos2Open(false)}
                            onClick={() => setToltipInfoMediaDos2Open(true)} cursor={"pointer"}>
                            <FiInfo size="25px" />
                          </Flex>
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {getMaxValue() < 120 && <ReactSpeedometer
        value={getValue()}
        minValue={0}
        maxValue={getMaxValue()}
        needleColor="#888"
        valueTextFontSize="0"
        startColor={customColors()[0]}
        endColor={customColors()[customColors().length - 1]}
        segments={getMaxValue() == 120 ? 5 : 4}
        customSegmentStops={customStops()}
        segmentColors={customColors()}
        height={isMobile ? 110 : 70}
        width={isMobile ? 160 : 130}
        needleHeightRatio={0.7}
        ringWidth={30}
        needleTransitionDuration={1000}
        labelFontSize="8"
      />}
      {getMaxValue() == 120 && <ReactSpeedometer
        value={getValue()}
        minValue={0}
        maxValue={getMaxValue()}
        needleColor="#888"
        valueTextFontSize="0"
        startColor={customColors()[0]}
        endColor={customColors()[customColors().length - 1]}
        segments={getMaxValue() == 120 ? 5 : 4}
        customSegmentStops={customStops()}
        segmentColors={customColors()}
        height={isMobile ? 110 : 70}
        width={isMobile ? 160 : 130}
        needleHeightRatio={0.7}
        ringWidth={30}
        needleTransitionDuration={1000}
        labelFontSize="8"
      />}
      {getMaxValue() == 144 && <ReactSpeedometer
        value={getValue()}
        minValue={0}
        maxValue={getMaxValue()}
        needleColor="#888"
        valueTextFontSize="0"
        startColor={customColors()[0]}
        endColor={customColors()[customColors().length - 1]}
        segments={getMaxValue() == 120 ? 5 : 4}
        customSegmentStops={customStops()}
        segmentColors={customColors()}
        height={isMobile ? 110 : 70}
        width={isMobile ? 160 : 130}
        needleHeightRatio={0.7}
        ringWidth={30}
        needleTransitionDuration={1000}
        labelFontSize="8"
      />}
      <Flex width={"full"} justifyContent={"center"} mb={2}>
        <Text fontSize="0.9rem" color="#404244" fontWeight="700">
          {selectRange == range1 && <>
            {range1String ?? '00:00'} {range1 < 1 ? t('DashboardMinutos') : t('DashboardHoras')}
          </>}
          {selectRange == range2 && <>
            {range2String ?? '00:00'} {range2 < 1 ? t('DashboardMinutos') : t('DashboardHoras')}
          </>}
          {selectRange == range3 && <>
            {range3String ?? '00:00'} {range3 < 1 ? t('DashboardMinutos') : t('DashboardHoras')}
          </>}
        </Text>
      </Flex>

      <Legendas tempoExposicao={tempoExposicao} />
    </Flex>
  )
}