import './Layout.css';
import React, { useEffect, ReactNode, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  useColorMode,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  ModalFooter,
  ModalBody,
  ButtonGroup,
} from '@chakra-ui/react';
import {
  FiTrendingUp,
  FiMenu,
  FiUsers,
  FiList,
  FiPackage,
  FiLayers,
  FiLogOut,
  FiDownload,
  FiPlus,
  FiAlignJustify,
  FiBook,
  FiBatteryCharging,
  FiBookOpen,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { sistemaActions } from '../recucer';
import { DashboardInsetos } from '../../leituras/components/DashboardInsetos';
import { DashboardDadosCliente } from '../../leituras/components/DashboardDadosCliente';
import { DashboardToxinas } from '../../leituras/components/DashboardToxinas';
import { usePWAInstall } from 'react-use-pwa-install';
import { useTranslation } from "react-i18next";
import { LinguagemPadrao } from './LinguagemPadrao';
import { DashboardEquipamentoEdit } from '../../leituras/components/DashboardEquipamentoEdit';
import { FaTruck } from 'react-icons/fa';
import { DefaultButton } from './DefaultButton';
import { ListHeaderSecondary } from './ListHeaderSecondary';
import { Divisor } from './Divisor';
import { DesenvolvimentoButton } from './DesenvolvimentoButton';
import { QrCodeAcesso } from '../../leituras/components/QrCodeAcesso';
import { QrCodeLink } from './QrCodeLink';
import { ButtonLink } from './ButtonLink';

interface LinkItemProps {
  name: string;
  href: string;
  icon: IconType;
  somenteAdmin: boolean;
  operadorTemAcesso: boolean;
  className?: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: FiTrendingUp, href: '/dashboard', somenteAdmin: false, operadorTemAcesso: true },
  { name: 'Avaliações Iniciais', icon: FiBookOpen, href: '/avaliacoes-iniciais', somenteAdmin: true, operadorTemAcesso: true },
  { name: 'Preparações Iniciais', icon: FiBookOpen, href: '/preparacoes-iniciais', somenteAdmin: true, operadorTemAcesso: true },
  { name: 'Usuários', icon: FiUsers, href: '/usuarios', somenteAdmin: true, operadorTemAcesso: false },
  { name: 'Clientes', icon: FiList, href: '/clientes', somenteAdmin: true, operadorTemAcesso: true },
  { name: 'Equipamentos', icon: FiPackage, href: '/equipamentos', somenteAdmin: true, operadorTemAcesso: false },
  { name: 'Leituras', icon: FiLayers, href: '/leituras', somenteAdmin: true, operadorTemAcesso: true },
  { name: 'Insetos', icon: FiAlignJustify, href: '/insetos', somenteAdmin: true, operadorTemAcesso: false },
  { name: 'Avaliação Questões', icon: FiBook, href: '/avaliacao-questoes', somenteAdmin: true, operadorTemAcesso: false },
  { name: 'Tipos de Fosfina', icon: FiBatteryCharging, href: '/tipo-fosfina', somenteAdmin: true, operadorTemAcesso: false },
  { name: 'Caminhões', icon: FaTruck, href: '/caminhao', somenteAdmin: true, operadorTemAcesso: false },
];

export default function Layout({ children, id, padding = 4, showInternetStatus = false }: { children: ReactNode; id?: string; padding?: number; showInternetStatus?: boolean; }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const networkOnline = useSelector((state: RootState) => state.sistema.networkOnline);
  const { colorMode, toggleColorMode } = useColorMode()
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
    dispatch(sistemaActions.setIsInstalled(false));
  }, [colorMode, toggleColorMode])


  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      {showInternetStatus && <Box textAlign={"center"} color={"white"} bg={networkOnline ? '#00FF7F' : '#FF6347'} height={"25px"} width={"full"}>
        {networkOnline ? t('NetworkStatusOn') : t('NetworkStatusOff')}
      </Box>}
      <Box bg={useColorModeValue('gray.100', 'gray.900')} ml={{ base: 0, md: 60 }} p={padding} id={id}>
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const InstallButton = () => {
  const install = usePWAInstall()

  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleBeforeInstallPrompt = (event: any) => {
    // Prevent Chrome 76 and later from showing the mini-infobar
    event.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(event);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        // Reset the deferred prompt variable
        setDeferredPrompt(null);
      });
    }
  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const { t } = useTranslation();


  if (iOS()) {
    return <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <Flex wrap="wrap" width="full" borderBottom="1px solid #a0a0a0" pb={2}>
              <Text fontSize="1.3rem" color="#404244" fontWeight="700">
                Instale o aplicativo Ecozone
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width={"full"}>
              <Flex width={"full"} wrap={"wrap"}>
                <ButtonGroup alignItems={"center"}>
                  <Img style={{ width: "30px" }} src='https://upload.wikimedia.org/wikipedia/commons/c/c8/Ei-share-apple.svg' />
                  1) {t('MenuInstalarAppParte1')}
                </ButtonGroup>
                <ButtonGroup mt={2} alignItems={"center"}>
                  <FiPlus fontSize={"30px"} />
                  2) {t('MenuInstalarAppParte2')}  ""
                </ButtonGroup>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} ml={2} mt={2} colorScheme={"gray"}>{t('DashboardVoltar')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <NavItem onClick={onOpen} icon={FiDownload} href='#'>
        {t('MenuBaixarApp')}
      </NavItem>
    </>
  }

  return (
    <NavItem onClick={install} icon={FiDownload} href='#'>
      {t('MenuBaixarApp')}
    </NavItem>
  );
};

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const user = useSelector((state: RootState) => state.sistema.user);
  const isInstalled = useSelector((statae: RootState) => statae.sistema.isInstalled);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const location = useLocation();
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';
  const { t } = useTranslation();

  return (
    <Box
      overflowY={"auto"}
      boxShadow="0px 0px 10px 2px rgba(0, 0, 0, 0.2)"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h={{ base: '100px', md: '150px' }} alignItems="center" mx="8" justifyContent="space-between">
        <Img mx={"auto"} height={{ base: '80px', md: 'auto' }} src={config.urlLogo} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <LinguagemPadrao />
      {LinkItems.filter((i) => {
        if ((user?.e_operador as any) == 1) {
          if (i.operadorTemAcesso) {
            return true;
          }
        }

        if (i.somenteAdmin && (user?.administrador as any) == 0) return false;

        return true;
      }).map((link) => (
        <Box width={"full"} key={link.name}>
          <NavItem icon={link.icon} href={link.href ? link.href : '#'}>
            {t(`Menu${link.name.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')}`)}
          </NavItem>
          {link.href === '/dashboard' && !isMobile && location.pathname.includes(link.href) && <>
            <DashboardDadosCliente />
            <Flex mt={2} width={"full"} justifyContent={"space-around"} wrap={"wrap"}>
              <DashboardEquipamentoEdit />
              <DesenvolvimentoButton />
              <Flex width={"full"} my={1} />
              {dashboard?.last_leitura?.token && <Flex flexDir={"column"} wrap={"wrap"} width={"full"} px={2}>
                <QrCodeAcesso leitura_token={dashboard?.last_leitura?.token ?? ''} />
{/*                
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://drive.google.com/drive/u/2/folders/1LdnGlcq5xYkh8aGeWM3xI_YYgA6fGyOZ">Planejamento e documentação</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSf7_TTqER7_0bVl0rSyCO1bijDrOUrTtMK3AZjStHhiWFoYpg/viewform?usp=sf_link" height={"32px"}>Formulário de Planejamento <br /> Executivo</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSeZXSHyVQxuwbD7K4PMSeOHb9cezv6MPXKLwjpt0Fly00rg1w/viewform?usp=sf_link" height={"32px"}>Formulário de Inspeções <br /> e Manutenções</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSd23NUhErEJLz16UGyNhhNlffEbyB7zgWq2YITRg3VCV_jC5g/viewform" height={"32px"}>Revisão de itens <br /> obrigatórios caminhões</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSfkx-5S375dvnFFTWBXGYRn5fGnhJefof8ELfGIxtJTXEFHIQ/viewform?usp=sf_link" height={"32px"}>Avaliação preliminar de <br /> riscos - Fosfina (TecZ)</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLScUNMqort-R7fw8qdj772qERGzZC0axDgdcg-h5RpsPNk1BYw/viewform?usp=sf_link" height={"32px"}>Avaliação preliminar de <br /> riscos - Profilaxia</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/13zgecfaxF3KRen7CerXkxn0r78rIupWsx-t3qreMQqE/edit?usp=drivesdk">Verificação de EPI's</ButtonLink>}
                {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQlDtDlyyLceN1mSZjZzVq72Vxl-tkoQEKLJ-PokEh3hd7XKXy3ADL7pd9gAEjjJbfytu1lL5p9iBv5/pubhtml" height={"32px"}>Planejamento Executivo Fumigação <br /> Versão do Cliente</ButtonLink>}
                {(user?.administrador == 0 && user?.e_operador == 0 && user?.e_desenvolvedor == 0) && <ButtonLink href="https://drive.google.com/drive/u/2/folders/1XQCcMYz9f2sG5t2KFGFj1WwX56f1LrBf">Documentação</ButtonLink>}
                {(user?.administrador == 0 && user?.e_operador == 0 && user?.e_desenvolvedor == 0) && <ButtonLink href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQlDtDlyyLceN1mSZjZzVq72Vxl-tkoQEKLJ-PokEh3hd7XKXy3ADL7pd9gAEjjJbfytu1lL5p9iBv5/pubhtml">Planejamento Executivo Fumigação</ButtonLink>} */}
              </Flex>}
            </Flex>

            {eFosfina && <DashboardInsetos />}
            {!eFosfina && <DashboardToxinas />}
          </>}
        </Box>
      ))}

      {!isInstalled && <InstallButton />}
      <NavItem icon={FiLogOut} href={'/logout'}>
        {t(`MenuSair`)}
      </NavItem>
      {/* <ConfigureInstallApp /> */}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Link onClick={() => navigate(href)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        my={1}
        bg={location.pathname.includes(href) ? '#134e1d' : ''}
        color={location.pathname.includes(href) ? 'white' : ''}
        align="center"
        px="4"
        py="1"
        mx="2"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#134e1d',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        <Img height={{ base: '60px', md: 'auto' }} src={config.urlLogo} />
      </Text>
    </Flex>
  );
};