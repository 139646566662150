import { ClienteUnidade } from "../types/cliente_unidade";

export const novoClienteUnidade = (): ClienteUnidade => {
  return {
    cliente_id: "",
    nome_referencia: "",
    cpf_cnpj: "",
    endereco: "",
    numero: "",
    cep: "",
    cidade: "",
    uf: "",
    ativo: true,
    silos: [],
    responsaveis: [],
    imagem: "",
    imagem_logo: "",
    latitude: "",
    longitude: "",
  };
};
