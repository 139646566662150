import { useField, Formik, Form } from "formik";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState } from 'react'
import validateForm from "../../utils/validateForm";
import { FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { LeituraResponsavel } from "../types/leitura_responsavel";
import { novaLeituraResponsavel } from "../data/leitura_responsavel";
import InputSelect from "../../sistema/components/InputSelect";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const LeiturasResponsaveis = ({ name = 'responsaveis'}) => {
  const [, { value }, { setValue }] = useField(name);
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const { t } = useTranslation()

  const headers: TableHeaders<LeituraResponsavel>[] = [
    {
      label: t('LeiturasSensoresRespNome'),
      wrapped: true,
      render: (item) => item.usuario?.name,
    },
    {
      label: t('LeiturasSensoresRespCelular'),
      wrapped: true,
      render: (item) => item.usuario?.celular
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>}</>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<LeituraResponsavel | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novaLeituraResponsavel())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: LeituraResponsavel) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push({
        ...form,
        usuario: leituraDados?.administradores.find((i: any) => i.id == form.user_id) || null,
      });
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
      usuario: leituraDados?.administradores.find((i: any) => i.id == form.user_id) || null,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)

  return (
    <>
      <ListHeaderSecondary label={t('LeiturasSensoresRespTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNovo')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('LeiturasSensoresRespTituloMin') : t('SistemaNovo') + ' ' + t('LeiturasSensoresRespTituloMin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm(
                  {
                    user_id: 'required',
                  }
                  , val, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveModal(val);
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <InputSelect name="user_id" label={t('LeiturasSensoresRespTituloMin')}>
                      {leituraDados && leituraDados.administradores.map((i) => (
                        <option key={i.id} value={i.id}>{i.name} - {i.celular}</option>
                      ))}
                    </InputSelect>
                    <DefaultButton type="submit" mt={4}>{t('SistemaSalvar')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}