import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const PaginaNaoEncontrada = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [])

  return (
    <></>
  )
}