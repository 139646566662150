import { Badge, Box } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

export const corFosfina = [
  '#FF6347',
  '#FFD700',
  '#00FF7F',
  '#4169E1',
  '#845EC2',
  'red'
]

export const corOzonio = [
  '#FF6347',
  '#FFD700',
  '#00FF7F',
  '#4169E1',
  '#8a2be2',
]

export const Legendas = ({ large = false }) => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras)

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const escalaFosfina = [
    '0 - 399',
    '400 - 1199',
    '1200 - 1999',
    '2000 - 3000',
    '3001 - 4000',
    '4001 - 5000',
  ]

  const escalaOzonio = [
    '0 - 40',
    '41 - 50',
    '51 - 60',
    '61 - 80',
    '> 81'
  ]

  const cores = eFosfina ? corFosfina : corOzonio;
  const escala = eFosfina ? escalaFosfina : escalaOzonio;

  return (
    <Box display="flex" justifyContent={"space-between"} mt={isMobile ? 2 : 0}>
      <Badge fontWeight={"500"} fontSize={large ? '20px' : '10px'} bg={cores[0]} color={"white"} mr={isMobile ? 1 : 2} borderRadius={5}>{escala[0]}</Badge>
      <Badge fontWeight={"500"} fontSize={large ? '20px' : '10px'} bg={cores[1]} color={"black"} mr={isMobile ? 1 : 2} borderRadius={5}>{escala[1]}</Badge>
      <Badge fontWeight={"500"} fontSize={large ? '20px' : '10px'} bg={cores[2]} color={"white"} mr={isMobile ? 1 : 2} borderRadius={5}>{escala[2]}</Badge>
      <Badge fontWeight={"500"} fontSize={large ? '20px' : '10px'} bg={cores[3]} color={"white"} mr={isMobile ? 1 : 2} borderRadius={5}>{escala[3]}</Badge>
    </Box>
  )
}