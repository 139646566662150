import imagesUrl from "../../utils/imagesUrl";

const graneleiro1 = imagesUrl + "armazenamentos/graneleiro1.png";
const graneleiro1c1 = imagesUrl + "armazenamentos/graneleiro1c1.png";
const graneleiro1c2 = imagesUrl + "armazenamentos/graneleiro1c2.png";
const graneleiro1c3 = imagesUrl + "armazenamentos/graneleiro1c3.png";
const graneleiro1c4 = imagesUrl + "armazenamentos/graneleiro1c4.png";
const graneleiro1c5 = imagesUrl + "armazenamentos/graneleiro1c5.png";
const graneleiro1c6 = imagesUrl + "armazenamentos/graneleiro1c6.png";

const graneleiro2 = imagesUrl + "armazenamentos/graneleiro2.png";
const graneleiro2c1 = imagesUrl + "armazenamentos/graneleiro2c1.png";
const graneleiro2c2 = imagesUrl + "armazenamentos/graneleiro2c2.png";
const graneleiro2c3 = imagesUrl + "armazenamentos/graneleiro2c3.png";
const graneleiro2c4 = imagesUrl + "armazenamentos/graneleiro2c4.png";
const graneleiro2c5 = imagesUrl + "armazenamentos/graneleiro2c5.png";
const graneleiro2c6 = imagesUrl + "armazenamentos/graneleiro2c6.png";

const graneleiro3 = imagesUrl + "armazenamentos/graneleiro3.png";
const graneleiro3c1 = imagesUrl + "armazenamentos/graneleiro3c1.png";
const graneleiro3c2 = imagesUrl + "armazenamentos/graneleiro3c2.png";
const graneleiro3c3 = imagesUrl + "armazenamentos/graneleiro3c3.png";
const graneleiro3c4 = imagesUrl + "armazenamentos/graneleiro3c4.png";
const graneleiro3c5 = imagesUrl + "armazenamentos/graneleiro3c5.png";
const graneleiro3c6 = imagesUrl + "armazenamentos/graneleiro3c6.png";
const graneleiro3c7 = imagesUrl + "armazenamentos/graneleiro3c7.png";
const graneleiro3c8 = imagesUrl + "armazenamentos/graneleiro3c8.png";
const graneleiro3c9 = imagesUrl + "armazenamentos/graneleiro3c9.png";
const graneleiro3c10 = imagesUrl + "armazenamentos/graneleiro3c10.png";
const graneleiro3c11 = imagesUrl + "armazenamentos/graneleiro3c11.png";
const graneleiro3c12 = imagesUrl + "armazenamentos/graneleiro3c12.png";
const graneleiro3c13 = imagesUrl + "armazenamentos/graneleiro3c13.png";
const graneleiro3c14 = imagesUrl + "armazenamentos/graneleiro3c14.png";
const graneleiro3c15 = imagesUrl + "armazenamentos/graneleiro3c15.png";
const graneleiro3c16 = imagesUrl + "armazenamentos/graneleiro3c16.png";

const graneleiro4 = imagesUrl + "armazenamentos/graneleiro4.png";
const graneleiro4c1 = imagesUrl + "armazenamentos/graneleiro4c1.png";
const graneleiro4c2 = imagesUrl + "armazenamentos/graneleiro4c2.png";
const graneleiro4c3 = imagesUrl + "armazenamentos/graneleiro4c3.png";
const graneleiro4c4 = imagesUrl + "armazenamentos/graneleiro4c4.png";
const graneleiro4c5 = imagesUrl + "armazenamentos/graneleiro4c5.png";
const graneleiro4c6 = imagesUrl + "armazenamentos/graneleiro4c6.png";
const graneleiro4c7 = imagesUrl + "armazenamentos/graneleiro4c7.png";
const graneleiro4c8 = imagesUrl + "armazenamentos/graneleiro4c8.png";
const graneleiro4c9 = imagesUrl + "armazenamentos/graneleiro4c9.png";
const graneleiro4c10 = imagesUrl + "armazenamentos/graneleiro4c10.png";
const graneleiro4c11 = imagesUrl + "armazenamentos/graneleiro4c11.png";
const graneleiro4c12 = imagesUrl + "armazenamentos/graneleiro4c12.png";
const graneleiro4c13 = imagesUrl + "armazenamentos/graneleiro4c13.png";
const graneleiro4c14 = imagesUrl + "armazenamentos/graneleiro4c14.png";
const graneleiro4c15 = imagesUrl + "armazenamentos/graneleiro4c15.png";
const graneleiro4c16 = imagesUrl + "armazenamentos/graneleiro4c16.png";

const predio1 = imagesUrl + "armazenamentos/predio1.png";
const predio1c1 = imagesUrl + "armazenamentos/predio1c1.png";
const predio1c2 = imagesUrl + "armazenamentos/predio1c2.png";
const predio1c3 = imagesUrl + "armazenamentos/predio1c3.png";
const predio1c4 = imagesUrl + "armazenamentos/predio1c4.png";
const predio1c5 = imagesUrl + "armazenamentos/predio1c5.png";
const predio1c6 = imagesUrl + "armazenamentos/predio1c6.png";

const predio2 = imagesUrl + "armazenamentos/predio2.png";
const predio2c1 = imagesUrl + "armazenamentos/predio2c1.png";
const predio2c2 = imagesUrl + "armazenamentos/predio2c2.png";
const predio2c3 = imagesUrl + "armazenamentos/predio2c3.png";
const predio2c4 = imagesUrl + "armazenamentos/predio2c4.png";
const predio2c5 = imagesUrl + "armazenamentos/predio2c5.png";
const predio2c6 = imagesUrl + "armazenamentos/predio2c6.png";
const predio2c7 = imagesUrl + "armazenamentos/predio2c7.png";
const predio2c8 = imagesUrl + "armazenamentos/predio2c8.png";
const predio2c9 = imagesUrl + "armazenamentos/predio2c9.png";
const predio2c10 = imagesUrl + "armazenamentos/predio2c10.png";
const predio2c11 = imagesUrl + "armazenamentos/predio2c11.png";
const predio2c12 = imagesUrl + "armazenamentos/predio2c12.png";
const predio2c13 = imagesUrl + "armazenamentos/predio2c13.png";
const predio2c14 = imagesUrl + "armazenamentos/predio2c14.png";
const predio2c15 = imagesUrl + "armazenamentos/predio2c15.png";
const predio2c16 = imagesUrl + "armazenamentos/predio2c16.png";

const silo1a = imagesUrl + "armazenamentos/silo1a.png";
const silo1b = imagesUrl + "armazenamentos/silo1b.png";
const silo1c1 = imagesUrl + "armazenamentos/silo1c1.png";
const silo1c2 = imagesUrl + "armazenamentos/silo1c2.png";
const silo1c3 = imagesUrl + "armazenamentos/silo1c3.png";
const silo1c4 = imagesUrl + "armazenamentos/silo1c4.png";
const silo1c5 = imagesUrl + "armazenamentos/silo1c5.png";
const silo1c6 = imagesUrl + "armazenamentos/silo1c6.png";
const silo1c7 = imagesUrl + "armazenamentos/silo1c7.png";
const silo1c8 = imagesUrl + "armazenamentos/silo1c8.png";
const silo1c9 = imagesUrl + "armazenamentos/silo1c9.png";
const silo1c10 = imagesUrl + "armazenamentos/silo1c10.png";
const silo1c11 = imagesUrl + "armazenamentos/silo1c11.png";
const silo1c12 = imagesUrl + "armazenamentos/silo1c12.png";
const silo1c13 = imagesUrl + "armazenamentos/silo1c13.png";
const silo1c14 = imagesUrl + "armazenamentos/silo1c14.png";
const silo1c15 = imagesUrl + "armazenamentos/silo1c15.png";
const silo1c16 = imagesUrl + "armazenamentos/silo1c16.png";

const silo2a = imagesUrl + "armazenamentos/silo2a.png";
const silo2b = imagesUrl + "armazenamentos/silo2b.png";
const silo2c1 = imagesUrl + "armazenamentos/silo2c1.png";
const silo2c2 = imagesUrl + "armazenamentos/silo2c2.png";
const silo2c3 = imagesUrl + "armazenamentos/silo2c3.png";
const silo2c4 = imagesUrl + "armazenamentos/silo2c4.png";
const silo2c5 = imagesUrl + "armazenamentos/silo2c5.png";
const silo2c6 = imagesUrl + "armazenamentos/silo2c6.png";

const silo6a = imagesUrl + "armazenamentos/silo6a.png";
const silo6b = imagesUrl + "armazenamentos/silo6b.png";
const silo6c1 = imagesUrl + "armazenamentos/silo6c1.png";
const silo6c2 = imagesUrl + "armazenamentos/silo6c2.png";
const silo6c3 = imagesUrl + "armazenamentos/silo6c3.png";
const silo6c4 = imagesUrl + "armazenamentos/silo6c4.png";
const silo6c5 = imagesUrl + "armazenamentos/silo6c5.png";
const silo6c6 = imagesUrl + "armazenamentos/silo6c6.png";
const silo6c7 = imagesUrl + "armazenamentos/silo6c7.png";
const silo6c8 = imagesUrl + "armazenamentos/silo6c8.png";
const silo6c9 = imagesUrl + "armazenamentos/silo6c9.png";
const silo6c10 = imagesUrl + "armazenamentos/silo6c10.png";
const silo6c11 = imagesUrl + "armazenamentos/silo6c11.png";
const silo6c12 = imagesUrl + "armazenamentos/silo6c12.png";
const silo6c13 = imagesUrl + "armazenamentos/silo6c13.png";
const silo6c14 = imagesUrl + "armazenamentos/silo6c14.png";
const silo6c15 = imagesUrl + "armazenamentos/silo6c15.png";
const silo6c16 = imagesUrl + "armazenamentos/silo6c16.png";

const silo7a = imagesUrl + "armazenamentos/silo7a.png";
const silo7b = imagesUrl + "armazenamentos/silo7b.png";
const silo7c1 = imagesUrl + "armazenamentos/silo7c1.png";
const silo7c2 = imagesUrl + "armazenamentos/silo7c2.png";
const silo7c3 = imagesUrl + "armazenamentos/silo7c3.png";
const silo7c4 = imagesUrl + "armazenamentos/silo7c4.png";
const silo7c5 = imagesUrl + "armazenamentos/silo7c5.png";
const silo7c6 = imagesUrl + "armazenamentos/silo7c6.png";

const silo8a = imagesUrl + "armazenamentos/silo8a.png";
const silo8b = imagesUrl + "armazenamentos/silo8b.png";
const silo8c1 = imagesUrl + "armazenamentos/silo8c1.png";
const silo8c2 = imagesUrl + "armazenamentos/silo8c2.png";
const silo8c3 = imagesUrl + "armazenamentos/silo8c3.png";
const silo8c4 = imagesUrl + "armazenamentos/silo8c4.png";
const silo8c5 = imagesUrl + "armazenamentos/silo8c5.png";
const silo8c6 = imagesUrl + "armazenamentos/silo8c6.png";

export const tipoConfiguracao = (valor: number, tipo_silo?: string): any => {
  const array = [
    {
      name: "1 ponto ventilador, 1 ponto bica e 4 pontos superiores",
      value: 1,
      silo: "Graneleiro",
      imagem: graneleiro1,
      qt: 6,
      individual: {
        c1: graneleiro1c1,
        c2: graneleiro1c2,
        c3: graneleiro1c3,
        c4: graneleiro1c4,
        c5: graneleiro1c5,
        c6: graneleiro1c6,
      },
    },
    {
      name: "1 ponto bica e 5 pontos superiores",
      value: 2,
      silo: "Graneleiro",
      imagem: graneleiro2,
      qt: 6,
      individual: {
        c1: graneleiro2c1,
        c2: graneleiro2c2,
        c3: graneleiro2c3,
        c4: graneleiro2c4,
        c5: graneleiro2c5,
        c6: graneleiro2c6,
      },
    },
    {
      name: "2 pontos ventiladores, 1 ponto bica e 13 pontos superiores",
      value: 3,
      silo: "Graneleiro",
      imagem: graneleiro3,
      qt: 16,
      individual: {
        c1: graneleiro3c1,
        c2: graneleiro3c2,
        c3: graneleiro3c3,
        c4: graneleiro3c4,
        c5: graneleiro3c5,
        c6: graneleiro3c6,
        c7: graneleiro3c7,
        c8: graneleiro3c8,
        c9: graneleiro3c9,
        c10: graneleiro3c10,
        c11: graneleiro3c11,
        c12: graneleiro3c12,
        c13: graneleiro3c13,
        c14: graneleiro3c14,
        c15: graneleiro3c15,
        c16: graneleiro3c16,
      }
    },
    {
      name: "2 pontos ventiladores, 3 pontos bica, 11 pontos superiores",
      value: 4,
      silo: "Graneleiro",
      imagem: graneleiro4,
      qt: 16,
      individual: {
        c1: graneleiro4c1,
        c2: graneleiro4c2,
        c3: graneleiro4c3,
        c4: graneleiro4c4,
        c5: graneleiro4c5,
        c6: graneleiro4c6,
        c7: graneleiro4c7,
        c8: graneleiro4c8,
        c9: graneleiro4c9,
        c10: graneleiro4c10,
        c11: graneleiro4c11,
        c12: graneleiro4c12,
        c13: graneleiro4c13,
        c14: graneleiro4c14,
        c15: graneleiro4c15,
        c16: graneleiro4c16,
      }
    },

    {
      name: "6 andares, 1 ponto por andar (6)",
      value: 5,
      silo: "Prédio",
      imagem: predio1,
      qt: 6,
      individual: {
        c1: predio1c1,
        c2: predio1c2,
        c3: predio1c3,
        c4: predio1c4,
        c5: predio1c5,
        c6: predio1c6,
      }
    },
    {
      name: "6 andares, 1° (2pts), 2° (3pts), 3° (2pts), 4° (3pts), 5 (2pts), 6 (3pts) e 1 ponto injeçao",
      value: 6,
      silo: "Prédio",
      imagem: predio2,
      qt: 16,
      individual: {
        c1: predio2c1,
        c2: predio2c2,
        c3: predio2c3,
        c4: predio2c4,
        c5: predio2c5,
        c6: predio2c6,
        c7: predio2c7,
        c8: predio2c8,
        c9: predio2c9,
        c10: predio2c10,
        c11: predio2c11,
        c12: predio2c12,
        c13: predio2c13,
        c14: predio2c14,
        c15: predio2c15,
        c16: predio2c16,
      }
    },
    {
      name: "1 ponto ventilador, 3 pontos bicas e 12 pontos superiores",
      value: 7,
      silo: "Cilindrico",
      imagem: silo1a,
      imagem2: silo1b,
      qt: 16,
      individual: {
        c1: silo1c1,
        c2: silo1c2,
        c3: silo1c3,
        c4: silo1c4,
        c5: silo1c5,
        c6: silo1c6,
        c7: silo1c7,
        c8: silo1c8,
        c9: silo1c9,
        c10: silo1c10,
        c11: silo1c11,
        c12: silo1c12,
        c13: silo1c13,
        c14: silo1c14,
        c15: silo1c15,
        c16: silo1c16,
      }
    },
    {
      name: "1 ponto ventilador, 1 ponto bica e 4 pontos superiores",
      value: 8,
      silo: "Cilindrico",
      imagem: silo2a,
      imagem2: silo2b,
      qt: 6,
      individual: {
        c1: silo2c1,
        c2: silo2c2,
        c3: silo2c3,
        c4: silo2c4,
        c5: silo2c5,
        c6: silo2c6,
      },
      profundidade: {
        c1: 2,
        c2: 0.1,
        c3: 0.1,
        c4: 2,
        c5: 2,
        c6: 2,
      },
      posicao: {
        c1: 'Injeção superior',
        c2: 'Injeção inferior',
        c3: 'Retorno',
        c4: 'Injeção superior',
        c5: 'Injeção superior',
        c6: 'Injeção superior',
      }
    },
    {
      name: "2 pontos ventiladores, 3 pontos bica e 11 pontos superiores",
      value: 12,
      silo: "Cilindrico",
      imagem: silo6a,
      imagem2: silo6b,
      qt: 16,
      individual: {
        c1: silo6c1,
        c2: silo6c2,
        c3: silo6c3,
        c4: silo6c4,
        c5: silo6c5,
        c6: silo6c6,
        c7: silo6c7,
        c8: silo6c8,
        c9: silo6c9,
        c10: silo6c10,
        c11: silo6c11,
        c12: silo6c12,
        c13: silo6c13,
        c14: silo6c14,
        c15: silo6c15,
        c16: silo6c16,
      }
    },
    {
      name: "2 pontos ventiladores, 1 ponto bica e 3 pontos superiores",
      value: 13,
      silo: "Cilindrico",
      imagem: silo7a,
      imagem2: silo7b,
      qt: 6,
      individual: {
        c1: silo7c1,
        c2: silo7c2,
        c3: silo7c3,
        c4: silo7c4,
        c5: silo7c5,
        c6: silo7c6,
      },
    },
    {
      name: "1 ponto bica e 5 pontos superiores",
      value: 14,
      silo: "Cilindrico",
      imagem: silo8a,
      imagem2: silo8b,
      qt: 6,
      individual: {
        c1: silo8c1,
        c2: silo8c2,
        c3: silo8c3,
        c4: silo8c4,
        c5: silo8c5,
        c6: silo8c6,
      },
    },
  ];

  if (valor > 0) {
    return array.find((i) => i.value == valor);
  }

  if (tipo_silo) {
    return array.filter((i) => i.silo == tipo_silo.trim());
  }

  return array;
};
