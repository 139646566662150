import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TLoginRes } from "./saga";
import { User } from "./types/user";
import { Cliente } from "./types/cliente";
import { ApiPaginationRes } from "./types/apiPaginationRes";
import { ListPayload } from "./types/listPayload";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Equipamento } from "./types/equipamento";
import { UsuarioUnidade } from "./types/user_unidades";
import { EquipamentoCiclo } from "./types/equipamento_ciclo";
import { Inseto } from "./types/inseto";
import { AvaliacaoInicialQuestao } from "./types/avaliacao_inicial_questao";
import { TipoFosfina } from "./types/tipo_fosfina";
import { Caminhao } from "./types/caminhao";

type TInitialState = {
  user?: User | null;
  token?: string;
  isLoggingIn?: boolean;
  error?: string;
  success?: string;
  clientes?: ApiPaginationRes<Cliente[]>;
  cliente?: Cliente | null;
  isLoading?: boolean;
  responsaveis?: User[];
  todosClientes?: Cliente[];
  usuarios?: ApiPaginationRes<User[]>;
  usuario?: User | null;
  errorSavingUsuario?: string;
  equipamentos?: ApiPaginationRes<Equipamento[]>;
  equipamento?: Equipamento | null;
  usuarios_unidades?: UsuarioUnidade[];
  isMobile?: boolean;
  isInstalled?: boolean;
  equipamento_ciclos?: EquipamentoCiclo[];
  insetos?: ApiPaginationRes<Inseto[]>;
  inseto?: Inseto | null;
  todosInsetos?: Inseto[];
  avaliacaoQuestoes?: ApiPaginationRes<AvaliacaoInicialQuestao[]>;
  avaliacaoQuestao?: AvaliacaoInicialQuestao | null;
  tiposFosfina?: ApiPaginationRes<TipoFosfina[]>;
  tipoFosfina?: TipoFosfina | null;
  caminhoes?: ApiPaginationRes<Caminhao[]>;
  caminhao?: Caminhao | null;
  networkOnline: boolean;
};

const initialState: TInitialState = {
  networkOnline: true,
};

const reducer = createSlice({
  name: "sistema",
  initialState,
  reducers: {
    loginRequest(
      state,
      _: PayloadAction<{ email: string; password: string; userAgent?: string }>
    ) {
      state.isLoggingIn = true;
      state.error = "";
    },
    loginSuccess(state, { payload }: PayloadAction<TLoginRes>) {
      state.isLoggingIn = false;
      state.user = payload.data.user;
      state.token = payload.data.token;
    },
    setAuthData(state, { payload }: PayloadAction<{ user: User, token: string}>) {
      state.user = payload.user;
      state.token = payload.token;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.isLoggingIn = false;
      state.error = payload;
    },
    logout(state) {
      state.user = null;
      state.token = "";
      state.error = "";
    },
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    clientesRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    clientesRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Cliente[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.clientes = payload;
    },
    clienteRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.cliente = null;
    },
    clienteRequestSuccess(state, { payload }: PayloadAction<Cliente>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.cliente = payload;
    },
    responsaveisRequest(state) {},
    responsaveisRequestSuccess(state, { payload }: PayloadAction<User[]>) {
      state.responsaveis = payload;
    },
    todosClientesRequest(state) {},
    todosClientesRequestSuccess(state, { payload }: PayloadAction<Cliente[]>) {
      state.todosClientes = payload;
    },
    usuariosUnidadesRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    usuariosUnidadesRequestSuccess(
      state,
      { payload }: PayloadAction<UsuarioUnidade[]>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.usuarios_unidades = payload;
    },
    usuariosRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    usuariosRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<User[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.usuarios = payload;
    },
    usuarioRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.usuario = null;
    },
    usuarioRequestSuccess(state, { payload }: PayloadAction<User>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.usuario = payload;
    },
    saveUsuarioRequest(state, _: PayloadAction<User>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveUsuarioRequestSuccess(state, { payload }: PayloadAction<User>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário salvo com sucesso.";
      state.usuario = payload;
      mensagemSucesso("Usuário salvo com sucesso.");
    },
    deleteUsuariosRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteUsuariosRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Usuário apagado com sucesso.";
      mensagemSucesso("Usuário apagado com sucesso.");
    },
    saveClientesRequest(state, { payload }: PayloadAction<Cliente>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveClientesRequestSuccess(state, { payload }: PayloadAction<Cliente>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Cliente salvo com sucesso.";
      state.cliente = payload;
      mensagemSucesso("Cliente salvo com sucesso.");
    },
    deleteClientesRequest(state, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteClientesRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Cliente apagado com sucesso.";
      mensagemSucesso("Cliente apagado com sucesso.");
    },
    equipamentosRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    equipamentosRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Equipamento[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.equipamentos = payload;
    },
    equipamentoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.equipamento = null;
    },
    equipamentoRequestSuccess(state, { payload }: PayloadAction<Equipamento>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Equipamento salvo com sucesso.";
      state.equipamento = payload;
    },
    equipamentoUltimasLeiturasRequest(
      state,
      _: PayloadAction<string | number>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    equipamentoUltimasLeiturasRequestSuccess(
      state,
      { payload }: PayloadAction<EquipamentoCiclo[]>
    ) {
      state.isLoading = false;
      state.error = "";
      state.equipamento_ciclos = payload;
    },
    equipamentoUltimasLeiturasDeleteRequest(
      state,
      _: PayloadAction<{ id: string | number; equipamento_id: string | number }>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    equipamentoUltimasLeiturasDeleteRequestSuccess(
      state,
      { payload }: PayloadAction<EquipamentoCiclo[]>
    ) {
      state.isLoading = false;
      state.error = "";
      state.equipamento_ciclos = payload;
      mensagemSucesso("Leitura deletada com sucesso.");
    },
    saveEquipamentosRequest(state, { payload }: PayloadAction<Equipamento>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveEquipamentosRequestSuccess(
      state,
      { payload }: PayloadAction<Equipamento>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Equipamento salvo com sucesso.";
      state.equipamento = payload;
      mensagemSucesso("Equipamento salvo com sucesso.");
    },
    deleteEquipamentosRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteEquipamentosRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Equipamento apagado com sucesso.";
      mensagemSucesso("Equipamento apagado com sucesso.");
    },
    insetosRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    insetosRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Inseto[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.insetos = payload;
    },
    insetoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.inseto = null;
    },
    insetoRequestSuccess(state, { payload }: PayloadAction<Inseto>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.inseto = payload;
    },
    saveInsetosRequest(state, { payload }: PayloadAction<Inseto>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveInsetosRequestSuccess(state, { payload }: PayloadAction<Inseto>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.inseto = payload;
      mensagemSucesso("Salvo com sucesso.");
    },
    deleteInsetosRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteInsetosRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Deletado.";
      mensagemSucesso("Deletado com sucesso.");
    },
    avaliacaoQuestoesRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    avaliacaoQuestoesRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<AvaliacaoInicialQuestao[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.avaliacaoQuestoes = payload;
    },
    avaliacaoQuestaoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.avaliacaoQuestao = null;
    },
    avaliacaoQuestaoSuccess(
      state,
      { payload }: PayloadAction<AvaliacaoInicialQuestao>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.avaliacaoQuestao = payload;
    },
    saveAvaliacaoQuestaoRequest(
      state,
      { payload }: PayloadAction<AvaliacaoInicialQuestao>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveAvaliacaoQuestaoRequestSuccess(
      state,
      { payload }: PayloadAction<AvaliacaoInicialQuestao>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.avaliacaoQuestao = payload;
      mensagemSucesso("Salvo com sucesso.");
    },
    deleteAvaliacaoQuestaoRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteAvaliacaoQuestaoRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Deletado.";
      mensagemSucesso("Deletado com sucesso.");
    },
    tiposFosfinaRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    tiposFosfinaRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<TipoFosfina[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.tiposFosfina = payload;
    },
    tipoFosfinaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.tipoFosfina = null;
    },
    tipoFosfinaSuccess(state, { payload }: PayloadAction<TipoFosfina>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.tipoFosfina = payload;
    },
    saveTipoFosfinaRequest(state, { payload }: PayloadAction<TipoFosfina>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveTipoFosfinaRequestSuccess(
      state,
      { payload }: PayloadAction<TipoFosfina>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.tipoFosfina = payload;
      mensagemSucesso("Salvo com sucesso.");
    },
    deleteTipoFosfinaRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteTipoFosfinaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Deletado.";
      mensagemSucesso("Deletado com sucesso.");
    },

    caminhoesRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    caminhoesRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Caminhao[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.caminhoes = payload;
    },
    caminhaoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.caminhao = null;
    },
    caminhaoRequestSuccess(state, { payload }: PayloadAction<Caminhao>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.caminhao = payload;
    },
    saveCaminhaoRequest(state, { payload }: PayloadAction<Caminhao>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveCaminhaoRequestSuccess(state, { payload }: PayloadAction<Caminhao>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.caminhao = payload;
      mensagemSucesso("Salvo com sucesso.");
    },
    deleteCaminhaoRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteCaminhaoRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Deletado.";
      mensagemSucesso("Deletado com sucesso.");
    },
    setaIsMobile(state, { payload }: PayloadAction<boolean>) {
      state.isMobile = payload;
    },
    setIsInstalled(state, { payload }: PayloadAction<boolean>) {
      state.isInstalled = payload;
    },
    setNetworkOnline(state, { payload }: PayloadAction<boolean>) {
      state.networkOnline = payload;
    },
  },
});

export const sistemaActions = reducer.actions;

export default reducer;
