import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import { MultiSelect } from "react-multi-select-component";

export type InputMultiSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  options: {
    label: string;
    value: string | number;
  }[];
  bgNone?: boolean;
};

const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  label,
  options,
  bgNone = false,
  size: _,
  width,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props as any);
  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <MultiSelect
        options={options}
        value={field.value}
        onChange={setValue}
        labelledBy="Selecione..."
        disableSearch={true}
        hasSelectAll={false}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputMultiSelect;
