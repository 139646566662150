import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuth } from "../../hooks/useIsAuth";
import Layout from "../components/Layout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

const Home = () => {
  useIsAuth();

  const isLogged = useSelector((state: RootState) => !!state.sistema?.user)

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      navigate('/dashboard')
    }
    return
  }, [location, navigate, isLogged])

  return (
    <Layout><></></Layout>
  )
}

export default Home;
