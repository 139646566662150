import axios, { AxiosError } from "axios";
import { AxiosRequestConfig } from "axios";
import getEnv from "./getEnv";

export const APIURL: string = getEnv("REACT_APP_API_HOST");

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status == 401) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export const apiNoAuthCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: APIURL,
    headers: {
      ...config.headers,
    },
  });
