import React, { ChangeEvent } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useField } from "formik";

type InputCheckProps = CheckboxProps & {
  name: string;
  label: string;
};

const InputCheckInteiro: React.FC<InputCheckProps> = ({
  label,
  width,
  size: _,
  ...props
}) => {
  const setInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked ? '1' : '0')
  }
  const [{ onChange, ...field }, { error }, { setValue }] = useField(props.name);
  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      <Checkbox onChange={setInputValue} {...field} {...props} id={field.name} isChecked={field.value == '1'}>
        {label}
      </Checkbox>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}

    </FormControl>
  );
};

export default InputCheckInteiro;