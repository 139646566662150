import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Formik, Form } from 'formik'
import validateForm from "../../utils/validateForm";
import InputField from "../components/InputField";
import InputCheck from "../components/InputCheck";
import { Flex } from "@chakra-ui/react";
import { ClienteUnidades } from "../components/ClienteUnidades";
import { useTranslation } from "react-i18next";

export const ClienteEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const cliente = useSelector((state: RootState) => state.sistema.cliente)
  const error = useSelector((state: RootState) => state.sistema.error)
  const success = useSelector((state: RootState) => state.sistema.success)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.clienteRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  return (
    <Layout>
      <ListHeader label={t('ClienteEditTitulo')}>
        <DefaultButton ml={4} to="/clientes"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {cliente && <Formik
        initialValues={cliente}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            'nome': 'required',
          }, values, t);

          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }
          console.log("cliente form", values);
          dispatch(sistemaActions.saveClientesRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        <Form>
          <Flex width="full" wrap={"wrap"}>
            <InputField label={t('ClienteEditInputNome')} name="nome" autoFocus />
            <InputCheck label={t('ClienteEditInputAtivo')} name="ativo" />

            <ClienteUnidades />
          </Flex>
          <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('ClienteEditTitulo')}</DefaultButton>
        </Form>
      </Formik>}
    </Layout>
  )
}