import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext, useField } from 'formik'
import { Box, Button, Flex, FormControl, FormLabel, Grid, Heading, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, Select, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { leituraActions } from "../reducer";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import { Leitura } from "../types/leitura";
import { LeiturasResponsaveis } from "../components/LeituraResponsaveis";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import { formatData } from "../../utils/data";
import { AvaliacaoInicial } from "../types/avaliacao_inicial";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import InputNumber from "../../sistema/components/InputNumber";
import { AvaliacaoInicialImagem } from "../types/avaliacao_inicial_imagem";
import InputImage from "../../sistema/components/InputImage";
import { novaAvaliacaoInicialImagem } from "../data/avaliacao_inicial_imagem";
import { FiX } from "react-icons/fi";
import { useIndexedDB } from 'react-indexed-db-hook';
import { formatValor } from "../../utils/formatValor";
import { TableHeaders } from "../../sistema/components/ResponsiveTable";
import TableFixed from "../../sistema/components/TableFixed";
import { AvaliacaoInicialAssinaturas } from "../components/AvaliacaoInicialAssinaturas";
import { AvaliacaoInicialProdutoArmazenado } from "../components/AvaliacaoInicialProdutoArmazenado";
import InputRadio from "../../sistema/components/InputRadio";
import { useDeleteImagesIndexDb } from "../../hooks/useDeleteImagesIndexDb";
import { AvaliacaoInicialEstruturalForm } from "../components/AvaliacaoInicialEstruturalForm";
import InputImageCameraDireta from "../../sistema/components/InputImageCameraDireta";
import { AvaliacaoInicialInseto } from "../types/avaliacao_inicial_inseto";
import { mensagemErro } from "../../utils/toasts";
import imagesUrl from "../../utils/imagesUrl";

const corpoSilo = imagesUrl + 'armazenamentos/avaliacao_silo_corpo.png'

export const AvaliacaoInicialEdit = () => {
  useIsAuth();
  useDeleteImagesIndexDb();

  const { t } = useTranslation()
  const { id } = useParams();

  const avaliacaoInicial = useSelector((state: RootState) => state.leituras.avaliacaoInicial)
  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(leituraActions.avaliacaoInicialRequest(id))
  }, [dispatch, id])

  const [cameraOnline, setCameraOnline] = useState(false);
  const [tipoCamera, setTipoCamera] = useState('direta');

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  useEffect(() => {
    if (!navigator.mediaDevices || !isMobile) {
      setCameraOnline(false);
      console.log('Images -> setCameraOnline false');
    } else {
      console.log('Images -> navigator.mediaDevices');
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          console.log('Images -> navigator.mediaDevices true');
          setCameraOnline(true);
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch(function (error) {
          console.log('Images -> navigator.mediaDevices false', error);
          setCameraOnline(false);
        });
    }
  }, [])

  const usaCameraDireta = !!isMobile && cameraOnline && tipoCamera == 'direta';

  const ClienteUnidadeInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_id) {
      return (
        <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditUnidadeNone')}</option>
        </InputSelect>
      );
    }

    return (
      <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades.filter((i) => i.cliente_id == values.cliente_id).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {i.cpf_cnpj} - {i.cidade} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
        ))}
      </InputSelect>
    );
  }

  const ClienteUnidadeSiloInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_unidade_id) {
      return (
        <InputSelect label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditSiloNone')}</option>
        </InputSelect>
      );
    }

    const onChangeSilo = (val: string) => {
      const siloEncontrado = (leituraDados?.clientes_unidades_silos?.find((i) => i.id == val as any) ?? null);
      if (!siloEncontrado) {
        formik.setFieldValue('silo', null);
        return;
      }
      formik.setFieldValue('silo', {
        ...siloEncontrado,
        tipo_transporta: siloEncontrado.tipo_transporta ? siloEncontrado.tipo_transporta : '',
      });
    }

    return (
      <InputSelect onChangeVal={onChangeSilo} label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades_silos.filter((i => i.cliente_unidade_id == values.cliente_unidade_id)).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {t(`ClienteSilos${i.tipo_silo}`)}</option>
        ))}
      </InputSelect>
    );
  }

  const tamanhoCampo = isMobile ? '100%' : '33.33%';

  const InputLeituraSelect = () => {
    const formik = useFormikContext();

    const onChangeLocal = (val: string) => {
      const leitura = leituraDados?.leituras.find((i) => i.id as any == val);

      if (!val) return;

      formik.setFieldValue(`cliente_id`, leitura?.cliente_id);
      formik.setFieldValue(`cliente_unidade_id`, leitura?.cliente_unidade_id);
      formik.setFieldValue(`cliente_unidade_silo_id`, leitura?.cliente_unidade_silo_id);
      formik.setFieldValue(`ordem_servico`, leitura?.ordem_servico);
      formik.setFieldValue(`volume`, leitura?.volume);
      formik.setFieldValue(`produto`, (leitura?.produto ?? '').trim());

      console.log('InputLeituraSelect', leitura)
    }

    return (
      <InputSelect onChangeVal={onChangeLocal} label={t('DashboardStatusLeitura')} name="leitura_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.leituras.map((i) => (
          <option value={i.id} key={i.id}>{i.cliente?.nome} - {formatData(i.data)} </option>
        ))}
      </InputSelect>
    )
  }

  const podeVerAvaliacaoEstrutural = (values: AvaliacaoInicial) => {
    return !!values.cliente_unidade_silo_id;
  }



  const SubmitFormInput = () => {
    // const formik = useFormikContext()

    // useEffect(() => {
    //   const interval = setInterval(async () => {
    //     console.log("SubmitFormInput");
    //     if (formik.values && !(formik.values as any)['id']) {
    //       await formik.setFieldValue('mostra_mensagem_sucesso', false);
    //       setTimeout(async () => {
    //         await formik.submitForm();
    //       }, 100)
    //     }
    //   }, 9900);

    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, []);

    return (
      <></>
    )
  }

  const { add, getAll } = useIndexedDB('images');



  const getQuantidadeImagem = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name).length;
  }

  const getImagensDoTipo = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name);
  }

  const getImagensDoTipoNaoDeletadas = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name).filter((i) => !i.deleted);
  }

  const getImagensDiferenteDoTipo = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia != name);
  }

  const onDeleteIamge = (index: number, name: string, imagens: AvaliacaoInicialImagem[], setImagens: any) => {
    const values = JSON.parse(JSON.stringify(getImagensDoTipo(name, imagens))).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(getImagensDoTipo(name, imagens))).filter((i: any) => i?.deleted);
    const resto = JSON.parse(JSON.stringify(getImagensDiferenteDoTipo(name, imagens)));

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setImagens([
        ...values,
        ...valuesDeleted,
        ...resto,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setImagens([
      ...values,
      ...valuesDeleted,
      ...resto,
    ])
  }

  const salvarImagem = async (base64: string) => {
    return new Promise((resolve) => {
      add({ name: base64 }).then(
        (event: any) => {
          console.log("ID Generated: ", event);
          resolve(event)
        },
        (error) => {
          resolve('');
          console.log(error);
        },
      );
    })
  }

  const podeVerAvaliacaoHermeticidade = (values: AvaliacaoInicial) => {
    return podeVerAvaliacaoEstrutural(values);
  }

  const AvaliacaoHermeticidadeForm = () => {
    const [, { value: imagens }, { setValue: setImagens }] = useField('imagens');

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentItem, setCurrentItem] = useState<AvaliacaoInicialImagem | null>(null);

    const onOpenImagens = (campo: string) => {
      setCurrentItem({
        ...novaAvaliacaoInicialImagem(),
        campo_referencia: campo,
      })
      onOpen()
    }

    const [imagesDb, setImagesDb] = useState<any>([]);

    let ultimaImagem = "";

    useEffect(() => {
      const getData = async () => {
        if (ultimaImagem != JSON.stringify(imagens)) {
          const data = await getAll();
          setImagesDb(data);
          ultimaImagem = JSON.stringify(imagens);
        }
      }
      console.log(`getData AvaliacaoHermeticidadeForm`)
      getData();
    }, [imagens])

    const avaliacaoHermeticidadeInputs = [
      {
        name: 'avaliacao_hermeticidade_calcadas',
        label: t('AvaliacaoInicialHermeticidadeCalçadas'),
        itens: [
            0, 0.25, 1, 1.75, 2.5
        ]
      },
      {
        name: 'avaliacao_hermeticidade_ventiladores',
        label: t('AvaliacaoInicialHermeticidadeVentiladores'),
        itens: [
            0, 0.25, 0.75, 1.25, 1.75
        ]
      },
      {
        name: 'avaliacao_hermeticidade_anel_inferior',
        label: t('AvaliacaoInicialHermeticidadeAnelinferior'),
        itens: [
            0, 0.25, 1, 1.75, 2.5
        ]
      },
      {
        name: 'avaliacao_hermeticidade_anel_superior',
        label: t('AvaliacaoInicialHermeticidadeAnelsuperior'),
        itens: [
          0, 0.75, 1.25, 1.75
        ]
      },
      {
        name: 'avaliacao_hermeticidade_montante',
        label: t('AvaliacaoInicialHermeticidadeMontante'),
        itens: [
          0, 0.25, 0.75, 1.25
        ]
      },
      {
        name: 'avaliacao_hermeticidade_chapa',
        label: t('AvaliacaoInicialHermeticidadeChapa'),
        itens: [
          0, 0.25, 0.75, 1.25
        ]
      },
      {
        name: 'avaliacao_hermeticidade_parafuso',
        label: t('AvaliacaoInicialHermeticidadeParafuso'),
        itens: [
          0, 0.25, 0.5, 0.75
        ]
      },
      {
        name: 'avaliacao_hermeticidade_tunel',
        label: t('AvaliacaoInicialHermeticidadeTúnel'),
        itens: [
          0, 0.75, 1.25, 1.75
        ]
      },
      {
        name: 'avaliacao_hermeticidade_bicas',
        label: t('AvaliacaoInicialHermeticidadeBicas'),
        itens: [
          0, 0.75, 1.5
        ]
      },
    ]

    const tamanhoCampo = isMobile ? '50%' : '33.33%';

    const onSaveImagem = async (values: AvaliacaoInicialImagem) => {
      console.log(values, `onSaveImagem`);
      setImagens([
        {
          ...values,
          imagemId: await salvarImagem(values.imagem),
          imagem: '',
          changed: true,
        },
        ...(imagens ?? []),
      ])
      setCurrentItem(null);
      setTimeout(() => {
        setCurrentItem({
          ...novaAvaliacaoInicialImagem(),
          campo_referencia: values.campo_referencia,
        })
      }, 100)
    }

    interface ILegenda {
      name: string;
      itens: number[];
    }

    const legendaHermetização: ILegenda[] = [
      {
        name: t('AvaliacaoInicialHermeticidadeCalçadas'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_calcadas ?? 0,
          avaliacaoInicial?.hermeticidade_regular_calcadas ?? 0,
          avaliacaoInicial?.hermeticidade_bom_calcadas ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_calcadas ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeVentiladores'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_ventiladores ?? 0,
          avaliacaoInicial?.hermeticidade_regular_ventiladores ?? 0,
          avaliacaoInicial?.hermeticidade_bom_ventiladores ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_ventiladores ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeAnelinferior'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_anel_inferior ?? 0,
          avaliacaoInicial?.hermeticidade_regular_anel_inferior ?? 0,
          avaliacaoInicial?.hermeticidade_bom_anel_inferior ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_anel_inferior ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeAnelsuperior'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_anel_superior ?? 0,
          avaliacaoInicial?.hermeticidade_regular_anel_superior ?? 0,
          avaliacaoInicial?.hermeticidade_bom_anel_superior ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_anel_superior ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeMontante'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_montante ?? 0,
          avaliacaoInicial?.hermeticidade_regular_montante ?? 0,
          avaliacaoInicial?.hermeticidade_bom_montante ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_montante ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeChapa'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_chapa ?? 0,
          avaliacaoInicial?.hermeticidade_regular_chapa ?? 0,
          avaliacaoInicial?.hermeticidade_bom_chapa ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_chapa ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeParafuso'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_parafuso ?? 0,
          avaliacaoInicial?.hermeticidade_regular_parafuso ?? 0,
          avaliacaoInicial?.hermeticidade_bom_parafuso ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_parafuso ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeTúnel'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_tunel ?? 0,
          avaliacaoInicial?.hermeticidade_regular_tunel ?? 0,
          avaliacaoInicial?.hermeticidade_bom_tunel ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_tunel ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadeBicas'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_bicas ?? 0,
          avaliacaoInicial?.hermeticidade_regular_bicas ?? 0,
          avaliacaoInicial?.hermeticidade_bom_bicas ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_bicas ?? 0,
        ],
      },
      {
        name: t('AvaliacaoInicialHermeticidadePerdaMaxima'),
        itens: [
          avaliacaoInicial?.hermeticidade_critico_perda_maxima ?? 0,
          avaliacaoInicial?.hermeticidade_regular_perda_maxima ?? 0,
          avaliacaoInicial?.hermeticidade_bom_perda_maxima ?? 0,
          avaliacaoInicial?.hermeticidade_otimo_perda_maxima ?? 0,
        ],
      },
    ]

    const headers: TableHeaders<ILegenda>[] = [
      {
        label: "",
        wrapped: !isMobile,
        render: (item) => item.name,
      },
      {
        label: t('AvaliacaoInicialHermeticidadeCrítico'),
        wrapped: !isMobile,
        render: (item) => `${formatValor(item.itens[0])}%`,
      },
      {
        label: t('AvaliacaoInicialHermeticidadeRegular'),
        wrapped: !isMobile,
        render: (item) => `${formatValor(item.itens[1])}%`,
      },
      {
        label: t('AvaliacaoInicialHermeticidadeBom'),
        wrapped: !isMobile,
        render: (item) => `${formatValor(item.itens[2])}%`,
      },
      {
        label: t('AvaliacaoInicialHermeticidadeÓtimo'),
        wrapped: !isMobile,
        render: (item) => `${formatValor(item.itens[3])}%`,
      },
    ];

    const formik = useFormikContext();

    const onUpdateHermeticidade = (val: string, campo: string) => {
      val = parseFloat(val) >= 0 ? val : '0';

      const avaliacao_hermeticidade_calcadas = parseFloat(campo == 'avaliacao_hermeticidade_calcadas' ? val : (formik.values as any).avaliacao_hermeticidade_calcadas ?? '0');
      const avaliacao_hermeticidade_ventiladores = parseFloat(campo == 'avaliacao_hermeticidade_ventiladores' ? val : (formik.values as any).avaliacao_hermeticidade_ventiladores ?? '0');
      const avaliacao_hermeticidade_anel_inferior = parseFloat(campo == 'avaliacao_hermeticidade_anel_inferior' ? val : (formik.values as any).avaliacao_hermeticidade_anel_inferior ?? '0');
      const avaliacao_hermeticidade_anel_superior = parseFloat(campo == 'avaliacao_hermeticidade_anel_superior' ? val : (formik.values as any).avaliacao_hermeticidade_anel_superior ?? '0');
      const avaliacao_hermeticidade_montante = parseFloat(campo == 'avaliacao_hermeticidade_montante' ? val : (formik.values as any).avaliacao_hermeticidade_montante ?? '0');
      const avaliacao_hermeticidade_chapa = parseFloat(campo == 'avaliacao_hermeticidade_chapa' ? val : (formik.values as any).avaliacao_hermeticidade_chapa ?? '0');
      const avaliacao_hermeticidade_parafuso = parseFloat(campo == 'avaliacao_hermeticidade_parafuso' ? val : (formik.values as any).avaliacao_hermeticidade_parafuso ?? '0');
      const avaliacao_hermeticidade_tunel = parseFloat(campo == 'avaliacao_hermeticidade_tunel' ? val : (formik.values as any).avaliacao_hermeticidade_tunel ?? '0');
      const avaliacao_hermeticidade_bicas = parseFloat(campo == 'avaliacao_hermeticidade_bicas' ? val : (formik.values as any).avaliacao_hermeticidade_bicas ?? '0');

      formik.setFieldValue('porcentagem_indice_hermticidade',
        (avaliacao_hermeticidade_bicas >= 0 ? avaliacao_hermeticidade_bicas : 0) +
        (avaliacao_hermeticidade_tunel >= 0 ? avaliacao_hermeticidade_tunel : 0) +
        (avaliacao_hermeticidade_parafuso >= 0 ? avaliacao_hermeticidade_parafuso : 0) +
        (avaliacao_hermeticidade_chapa >= 0 ? avaliacao_hermeticidade_chapa : 0) +
        (avaliacao_hermeticidade_montante >= 0 ? avaliacao_hermeticidade_montante : 0) +
        (avaliacao_hermeticidade_calcadas >= 0 ? avaliacao_hermeticidade_calcadas : 0) +
        (avaliacao_hermeticidade_ventiladores >= 0 ? avaliacao_hermeticidade_ventiladores : 0) +
        (avaliacao_hermeticidade_anel_inferior >= 0 ? avaliacao_hermeticidade_anel_inferior : 0) +
        (avaliacao_hermeticidade_anel_superior >= 0 ? avaliacao_hermeticidade_anel_superior : 0)
      )
    }

    return (
      <Flex wrap={"wrap"} width={"full"} justifyContent={"center"}>
        <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ListHeaderSecondary label={t('AvaliacaoInicialFotos')}>
                <DefaultButton onClick={() => { setCurrentItem(null); onClose(); }} ml={2}>{t('DashboardVoltar')}</DefaultButton>
              </ListHeaderSecondary>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody mb={2}>
              {currentItem && <Formik
                initialValues={currentItem}
                enableReinitialize
                onSubmit={(values, { setErrors }) => {
                  const validation = validateForm({
                    imagem: 'required',
                  }, values, t)
                  if (Object.keys(validation).length > 0) {
                    setErrors(validation)
                    return;
                  }

                  onSaveImagem(values)
                }}
              >
                {({ values }) => (
                  <Form>
                    <Flex width="full" direction={"column"} wrap={"wrap"} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
                      {usaCameraDireta ? <InputImageCameraDireta label={t(`AvaliacaoInicialFoto`)} name="imagem" /> : <InputImage label={t(`AvaliacaoInicialFoto`)} name="imagem" />}
                      <DefaultButton mt={4} type="submit">
                        {t('AvaliacaoInicialAdicionar')}
                      </DefaultButton>
                    </Flex>
                  </Form>)}

              </Formik>}

              {getImagensDoTipoNaoDeletadas(currentItem?.campo_referencia ?? '', imagens).length > 0 && <>
                <ListHeaderSecondary label={t(`AvaliacaoInicialJaAdicionadas`)} />
                <Box width={`full`}></Box>
                <Grid templateColumns='repeat(2, 1fr)' gap={3} width={"full"}>
                  {getImagensDoTipoNaoDeletadas(currentItem?.campo_referencia ?? '', imagens).map((i: AvaliacaoInicialImagem, index: number) => (
                    <Flex key={`${index}`} p={3} position={"relative"}>
                      <Img src={!i.imagemId ? i.imagem : imagesDb.find((j: any) => j.id == i.imagemId)?.name} />
                      <Button onClick={() => onDeleteIamge(index, i.campo_referencia, imagens, setImagens)} title={t(`AvaliacaoInicialRemoverFoto`)} size={"sm"} colorScheme="red" position={"absolute"} top={"5px"} right={"5px"}>
                        <FiX />
                      </Button>
                    </Flex>
                  ))}
                </Grid>
              </>}
            </ModalBody>
          </ModalContent>
        </Modal>

        <ListHeaderSecondary label={t('AvaliacaoInicialAvaliacaoHermeticidade')} />
        <Flex width={"full"} wrap={"wrap"} />
        {avaliacaoHermeticidadeInputs.map((i) => (
          <Flex width={tamanhoCampo} key={i.name} pr={isMobile ? 0 : 8}>
            <InputSelect onChangeVal={(val) => onUpdateHermeticidade(val, i.name)} label={i.label} name={i.name}>
              {i.itens.map((j, index) => (
                <option key={`${index}`} value={j.toFixed(2)}>{formatValor(j)}%</option>
              ))}
            </InputSelect>
            <DefaultButton onClick={() => onOpenImagens(i.name)} mt={9} position={"relative"}>
              {t('AvaliacaoInicialFotos')}
              <Text fontWeight={700} borderRadius={"50px"} color={"black"} p={1} px={2} bg={"white"} fontSize={"11px"} position={"absolute"} top={"-7px"} right={"-3px"}>
                {getQuantidadeImagem(i.name, imagens)}
              </Text>
            </DefaultButton>
          </Flex>
        ))}
        <Flex width={"full"} />
        <Flex width={isMobile ? '70%' : '33.33%'} pr={isMobile ? 0 : 8}>
          <InputNumber decimalPlaces={2} disabled name="porcentagem_indice_hermticidade" label={t('AvaliacaoInicialHermeticidadeInice')} />
        </Flex>

        <ListHeaderSecondary label={t('AvaliacaoInicialHermeticidadeLegenda')} />
        {legendaHermetização && <TableFixed ThfontSize={isMobile ? '9px' : '16px'} fontSize={isMobile ? '8px' : '18px'} headers={headers} data={legendaHermetização} />}
      </Flex>
    )
  }

  const SiloTemperaturas = ({ values }: { values: AvaliacaoInicial }) => {
    const [, { value: pontuacao_temperatura }, { setValue: setpontuacao_temperatura }] = useField('pontuacao_temperatura');

    useEffect(() => {
      const temperaturasTotais = values.silo_temperatura_1 * 1 + values.silo_temperatura_2 * 1 + values.silo_temperatura_3 * 1 + values.silo_temperatura_4 * 1 + values.silo_temperatura_5 * 1 + values.silo_temperatura_6 * 1 + values.silo_temperatura_7 * 1 + values.silo_temperatura_8 * 1 + values.silo_temperatura_9 * 1

      if (!temperaturasTotais) {
        setpontuacao_temperatura(0);
        return;
      }

      const media = temperaturasTotais / 9;

      if (media <= 18) {
        setpontuacao_temperatura(0);
        return;
      }
      if (media <= 25) {
        setpontuacao_temperatura(1);
        return;
      }
      if (media <= 35) {
        setpontuacao_temperatura(2);
        return;
      }
      if (media <= 45) {
        setpontuacao_temperatura(3);
        return;
      }
      setpontuacao_temperatura(4);
    }, [
      setpontuacao_temperatura,
      values.silo_temperatura_1,
      values.silo_temperatura_2,
      values.silo_temperatura_3,
      values.silo_temperatura_4,
      values.silo_temperatura_5,
      values.silo_temperatura_6,
      values.silo_temperatura_7,
      values.silo_temperatura_8,
      values.silo_temperatura_9
    ]);

    return (
      <>
        <Flex width={"full"} justifyContent={"center"}>
          <Heading size={"md"} mt={6} mb={2} mx={"auto"}>
            {t("AvaliacaoInicialTemperaturasDoSilo")}
          </Heading>
        </Flex>
        <Flex justifyContent={"center"}>
          <Flex position={"relative"}>
            <Img src={corpoSilo} width={"370px"} />
            <Flex alignItems={"center"} position={"absolute"} top={"70px"} left={"40px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_1" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"70px"} left={"150px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_2" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"70px"} left={"260px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_3" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"150px"} left={"40px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_4" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"150px"} left={"150px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_5" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"150px"} left={"260px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_6" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"230px"} left={"40px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_7" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"230px"} left={"150px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_8" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
            <Flex alignItems={"center"} position={"absolute"} top={"230px"} left={"260px"}>
              <InputNumber decimalPlaces={0} height={"40px"} width={"70px"} name="silo_temperatura_9" />
              <Text color={"white"} fontWeight={800} fontSize={"20px"}>°C</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex mx={"auto"} maxW={"200px"}>
          <InputNumber decimalPlaces={2} label={t('AvaliacaoInicialUmidadeRelativa')} name="silo_umidade_relativa" />
        </Flex>
      </>
    )
  }

  const Pontuacoes = ({ values }: { values: AvaliacaoInicial }) => {
    const pontuacaoTotal = values.pontuacao_insetos * 1 + values.pontuacao_temperatura * 1 + values.pontuacao_impurezas * 1;

    const dentroDoRange = (range1: number, range2: number) => {
      return pontuacaoTotal >= range1 && pontuacaoTotal <= range2;
    }

    return (
      <>
        <ListHeaderSecondary label={t('AvaliacaoInicialCriteriosAvaliacao')} />
        <Flex width={"full"} />

        <Grid mt={4} templateColumns={`repeat(${isMobile ? 1 : 3}, 1fr)`} gap={4} width={"full"}>
          <Flex bg={"white"} borderRadius={"15px"} wrap={"wrap"} direction={"column"} justifyContent={"center"}>
            <Flex borderTopRadius={"15px"} bg={'#d3d3d3'} width={"full"} justifyContent={"center"}>
              <Heading size={"md"} mx={"auto"} mt={3} mb={4}>
                {t('AvaliacaoInicialNumeroInsetos')}
              </Heading>
            </Flex>
            <InputRadio textAlign={"center"} p={4} name="pontuacao_insetos" justifyContent={"center"}>
              <Stack direction={'column'} justifyContent={"center"}>
                <Radio value="0">
                  {t('AvaliacaoInicialNumeroInsetos0')}
                </Radio>
                <Radio value="1">
                  {t('AvaliacaoInicialNumeroInsetos1')}
                </Radio>
                <Radio value="2">
                  {t('AvaliacaoInicialNumeroInsetos2')}
                </Radio>
                <Radio value="3">
                  {t('AvaliacaoInicialNumeroInsetos3')}
                </Radio>
                <Radio value="4">
                  {t('AvaliacaoInicialNumeroInsetos4')}
                </Radio>
              </Stack>
            </InputRadio>
          </Flex>

          <Flex bg={"white"} borderRadius={"15px"} wrap={"wrap"} direction={"column"} justifyContent={"center"}>
            <Flex borderTopRadius={"15px"} bg={'#d3d3d3'} width={"full"} justifyContent={"center"}>
              <Heading size={"md"} mx={"auto"} mt={3} mb={4}>
                {t('AvaliacaoInicialTemperaturaMédia')}
              </Heading>
            </Flex>
            <InputRadio textAlign={"center"} p={4} name="pontuacao_temperatura" justifyContent={"center"}>
              <Stack direction={'column'} justifyContent={"center"}>
                <Radio value="0">
                  {t('AvaliacaoInicialTemperaturaMedia0')}
                </Radio>
                <Radio value="1">
                  {t('AvaliacaoInicialTemperaturaMedia1')}
                </Radio>
                <Radio value="2">
                  {t('AvaliacaoInicialTemperaturaMedia2')}
                </Radio>
                <Radio value="3">
                  {t('AvaliacaoInicialTemperaturaMedia3')}
                </Radio>
                <Radio value="4">
                  {t('AvaliacaoInicialTemperaturaMedia4')}
                </Radio>
              </Stack>
            </InputRadio>
          </Flex>

          <Flex bg={"white"} borderRadius={"15px"} wrap={"wrap"} direction={"column"} justifyContent={"center"}>
            <Flex borderTopRadius={"15px"} bg={'#d3d3d3'} width={"full"} justifyContent={"center"}>
              <Heading size={"md"} mx={"auto"} mt={3} mb={4}>
                {t('AvaliacaoInicialImpurezas')}
              </Heading>
            </Flex>
            <InputRadio textAlign={"center"} p={4} name="pontuacao_impurezas" justifyContent={"center"}>
              <Stack direction={'column'} justifyContent={"center"}>
                <Radio value="0">
                  {t('AvaliacaoInicialImpurezas0')}
                </Radio>
                <Radio value="1">
                  {t('AvaliacaoInicialImpurezas1')}
                </Radio>
                <Radio value="2">
                  {t('AvaliacaoInicialImpurezas2')}
                </Radio>
                <Radio value="3">
                  {t('AvaliacaoInicialImpurezas3')}
                </Radio>
                <Radio value="4">
                  {t('AvaliacaoInicialImpurezas4')}
                </Radio>
              </Stack>
            </InputRadio>
          </Flex>
        </Grid>

        <Flex mt={4} width={"full"} wrap={"wrap"} justifyContent={"space-around"}>
          <Flex mt={2} maxW={isMobile ? '600px' : "300px"} wrap={"wrap"} borderRadius={"15px"} border="1px solid #d3d3d3">
            <Flex fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} justifyContent={"center"} p={2} width={"full"} borderTopRadius={"15px"} textAlign={"center"}>
              {t('AplicacaoInicialPontuacaoProduto')}
            </Flex>
            <Flex bg={"white"} width={"80%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {t('AplicacaoInicialPontuacaoInsetos')}
            </Flex>
            <Flex bg={"white"} width={"20%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {values.pontuacao_insetos}
            </Flex>
            <Flex bg={"white"} width={"80%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {t('AplicacaoInicialPontuacaoTemperatura')}
            </Flex>
            <Flex bg={"white"} width={"20%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {values.pontuacao_temperatura}
            </Flex>
            <Flex bg={"white"} width={"80%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {t('AplicacaoInicialPontuacaoImpurezas')}
            </Flex>
            <Flex bg={"white"} width={"20%"} p={2} wrap={"wrap"} borderBottom="1px solid #d3d3d3">
              {values.pontuacao_impurezas}
            </Flex>
            <Flex fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} justifyContent={"center"} width={"80%"} p={2} wrap={"wrap"} borderBottomLeftRadius={"15px"}>
              {t('AplicacaoInicialPontuacaoTotal')}
            </Flex>
            <Flex width={"20%"} p={2} wrap={"wrap"} fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} borderBottomRightRadius={"15px"}>
              {pontuacaoTotal}
            </Flex>
          </Flex>

          <Flex mt={2} maxW={isMobile ? '600px' : "300px"} wrap={"wrap"} borderRadius={"15px"} border="1px solid #d3d3d3">
            <Flex fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} justifyContent={"center"} p={2} width={"full"} borderTopRadius={"15px"} textAlign={"center"}>
              {t('AplicacaoInicialIndiceProduto')}
            </Flex>
            <Flex width={"80%"} p={2} wrap={"wrap"} bg={dentroDoRange(0, 3) ? "green.100" : 'white'} borderBottom={dentroDoRange(0, 3) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(0, 3) ? '3px solid green' : ''} borderLeft={dentroDoRange(0, 3) ? '3px solid green' : ''}>
              {t('AplicacaoInicialIndiceProduto1')}
            </Flex>
            <Flex width={"20%"} p={2} wrap={"wrap"} bg={dentroDoRange(0, 3) ? "green.100" : 'white'} justifyContent={"center"} borderBottom={dentroDoRange(0, 3) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(0, 3) ? '3px solid green' : ''} borderRight={dentroDoRange(0, 3) ? '3px solid green' : ''}>
              99,99%
            </Flex>
            <Flex width={"80%"} p={2} wrap={"wrap"} bg={dentroDoRange(4, 6) ? "green.100" : 'white'} borderBottom={dentroDoRange(4, 6) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(4, 6) ? '3px solid green' : ''} borderLeft={dentroDoRange(4, 6) ? '3px solid green' : ''}>
              {t('AplicacaoInicialIndiceProduto2')}
            </Flex>
            <Flex width={"20%"} p={2} wrap={"wrap"} bg={dentroDoRange(4, 6) ? "green.100" : 'white'} justifyContent={"center"} borderBottom={dentroDoRange(4, 6) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(4, 6) ? '3px solid green' : ''} borderRight={dentroDoRange(4, 6) ? '3px solid green' : ''}>
              97,50%
            </Flex>
            <Flex width={"80%"} p={2} wrap={"wrap"} bg={dentroDoRange(7, 9) ? "green.100" : 'white'} borderBottom={dentroDoRange(7, 9) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(7, 9) ? '3px solid green' : ''} borderLeft={dentroDoRange(7, 9) ? '3px solid green' : ''}>
              {t('AplicacaoInicialIndiceProduto3')}
            </Flex>
            <Flex width={"20%"} p={2} wrap={"wrap"} bg={dentroDoRange(7, 9) ? "green.100" : 'white'} justifyContent={"center"} borderBottom={dentroDoRange(7, 9) ? '3px solid green' : '1px solid #d3d3d3'} borderTop={dentroDoRange(7, 9) ? '3px solid green' : ''} borderRight={dentroDoRange(7, 9) ? '3px solid green' : ''}>
              95,00%
            </Flex>
            <Flex width={"80%"} p={2} wrap={"wrap"} borderBottomLeftRadius={"15px"} bg={dentroDoRange(10, 12) ? "green.100" : 'white'} borderBottom={dentroDoRange(10, 12) ? '3px solid green' : ''} borderTop={dentroDoRange(10, 12) ? '3px solid green' : ''} borderLeft={dentroDoRange(10, 12) ? '3px solid green' : ''}>
              {t('AplicacaoInicialIndiceProduto4')}
            </Flex>
            <Flex width={"20%"} p={2} wrap={"wrap"} borderBottomRightRadius={"15px"} bg={dentroDoRange(10, 12) ? "green.100" : 'white'} justifyContent={"center"} borderBottom={dentroDoRange(10, 12) ? '3px solid green' : ''} borderTop={dentroDoRange(10, 12) ? '3px solid green' : ''} borderRight={dentroDoRange(10, 12) ? '3px solid green' : ''}>
              92,50%
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  const [efetividadeFinalProjetada, setEfetividadeFinalProjetada] = useState(0);

  const AvaliacaoMassaGraos = ({ values }: { values: AvaliacaoInicial }) => {
    const getPontuacao = (valor: number) => {
      if (valor == 1) return 0.5;
      return 0;
    }

    const getPontuacaoTotal = () => {
      return getPontuacao((values.massa_graos_compactacao as any) * 1) + getPontuacao((values.massa_graos_valculas as any) * 1) + getPontuacao((values.massa_graos_nivelamento as any) * 1)
    }

    const pontuacaoTotal = values.pontuacao_insetos * 1 + values.pontuacao_temperatura * 1 + values.pontuacao_impurezas * 1;

    const dentroDoRange = (range1: number, range2: number) => {
      return pontuacaoTotal >= range1 && pontuacaoTotal <= range2;
    }

    const getIndiceProduto = () => {
      if (dentroDoRange(0, 3)) {
        return 99.99;
      }
      if (dentroDoRange(4, 6)) {
        return 97.5;
      }
      if (dentroDoRange(7, 9)) {
        return 95;
      }
      if (dentroDoRange(10, 12)) {
        return 92.5;
      }
      return 0;
    }

    const ApontuacaoTotal = getPontuacaoTotal()
    const OIniceProduto = getIndiceProduto()

    useEffect(() => {
      setEfetividadeFinalProjetada(OIniceProduto - (values.porcentagem_indice_hermticidade * 1) - ApontuacaoTotal)
    }, [values.porcentagem_indice_hermticidade, ApontuacaoTotal, OIniceProduto])

    return (
      <>
        <Flex width={"full"} mt={4} />
        <ListHeaderSecondary label={t('AvaliacaoInicialCriteriosMassaGraos')} />
        <Flex width={"full"} />

        <Grid my={4} templateColumns={`repeat(${isMobile ? 1 : 4}, 1fr)`} gap={1} width={"full"}>
          <Flex bg={"white"} mt={2} maxW={"400px"} wrap={"wrap"} borderRadius={"15px"}>
            <Flex alignItems={"center"} fontWeight={600} textAlign={"center"} bg={"#d3d3d3"} justifyContent={"center"} borderTopRadius={"15px"} width={"100%"} p={2} wrap={"wrap"}>
              {t('AvaliacaoInicialCriteriosMassaGraosCompactaçãoMassaGraos')}
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"} width={"100%"} py={4} px={2} wrap={"wrap"}>
              <InputRadio textAlign={"center"} name="massa_graos_compactacao" justifyContent={"center"}>
                <Stack direction={'row'} justifyContent={"center"}>
                  <Radio value={(0).toFixed(0)}>
                    0,0% ({t('AvaliacaoInicialCriteriosMassaGraosBoa')})
                  </Radio>
                  <Radio value={(1).toFixed(0)}>
                    0,5% ({t('AvaliacaoInicialCriteriosMassaGraosAceitável')})
                  </Radio>
                  <Radio value={(10).toFixed(0)}>
                    {t('AvaliacaoInicialCriteriosMassaGraosInapto')}
                  </Radio>
                </Stack>
              </InputRadio>
            </Flex>
          </Flex>

          <Flex bg={"white"} mt={2} maxW={"400px"} wrap={"wrap"} borderRadius={"15px"}>
            <Flex alignItems={"center"} fontWeight={600} textAlign={"center"} bg={"#d3d3d3"} justifyContent={"center"} borderTopRadius={"15px"} width={"100%"} p={2} wrap={"wrap"}>
              {t('AvaliacaoInicialCriteriosMassaGraosCompactaçãoValvulas')}
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"} width={"100%"} py={4} px={2} wrap={"wrap"}>
              <InputRadio textAlign={"center"} name="massa_graos_valculas" justifyContent={"center"}>
                <Stack direction={'row'} justifyContent={"center"}>
                  <Radio value={(0).toFixed(0)}>
                    0,0% ({t('AvaliacaoInicialCriteriosMassaGraosBoa')})
                  </Radio>
                  <Radio value={(1).toFixed(0)}>
                    0,5% ({t('AvaliacaoInicialCriteriosMassaGraosAceitável')})
                  </Radio>
                  <Radio value={(10).toFixed(0)}>
                    {t('AvaliacaoInicialCriteriosMassaGraosInapto')}
                  </Radio>
                </Stack>
              </InputRadio>
            </Flex>
          </Flex>

          <Flex bg={"white"} mt={2} maxW={"400px"} wrap={"wrap"} borderRadius={"15px"}>
            <Flex alignItems={"center"} fontWeight={600} textAlign={"center"} bg={"#d3d3d3"} justifyContent={"center"} borderTopRadius={"15px"} width={"100%"} p={2} wrap={"wrap"}>
              {t('AvaliacaoInicialCriteriosMassaGraosNivelamento')}
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"} width={"100%"} py={4} px={2} wrap={"wrap"}>
              <InputRadio textAlign={"center"} name="massa_graos_nivelamento" justifyContent={"center"}>
                <Stack direction={'row'} justifyContent={"center"}>
                  <Radio value={(0).toFixed(0)}>
                    0,0% ({t('AvaliacaoInicialCriteriosMassaGraosBoa')})
                  </Radio>
                  <Radio value={(1).toFixed(0)}>
                    0,5% ({t('AvaliacaoInicialCriteriosMassaGraosAceitável')})
                  </Radio>
                  <Radio value={(10).toFixed(0)}>
                    {t('AvaliacaoInicialCriteriosMassaGraosInapto')}
                  </Radio>
                </Stack>
              </InputRadio>
            </Flex>
          </Flex>

          <Flex bg={"white"} mt={2} maxW={"400px"} wrap={"wrap"} borderRadius={"15px"}>
            <Flex fontSize={"20px"} fontWeight={600} textAlign={"center"} bg={"#d3d3d3"} justifyContent={"center"} borderTopRadius={"15px"} width={"100%"} p={2} wrap={"wrap"}>
              {t('AvaliacaoInicialCriteriosMassaGraosIndice')}
            </Flex>
            <Flex fontSize={"25px"} fontWeight={700} justifyContent={"center"} alignItems={"center"} width={"100%"} py={4} px={2} wrap={"wrap"}>
              {formatValor(getPontuacaoTotal())}%
            </Flex>
          </Flex>
        </Grid>

        <Flex wrap={"wrap"} width={"full"} justifyContent={"center"}>
          <Flex mt={2} maxW={isMobile ? '600px' : "400px"} wrap={"wrap"} borderRadius={"15px"} border="1px solid #d3d3d3">
            <Flex fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} justifyContent={"center"} p={2} width={"full"} borderTopRadius={"15px"} textAlign={"center"}>
              {t('AvaliacaoInicialEfetifidadeProjetada')}
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AplicacaoInicialIndiceProduto')} (+)
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(getIndiceProduto())}%
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialHermeticidadeInice')} (-)
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(values.porcentagem_indice_hermticidade * 1)}%
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialCriteriosMassaGraosIndice')} (-)
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(getPontuacaoTotal())}%
            </Flex>
            <Flex borderBottomLeftRadius={"15px"} alignItems={"center"} width={"75%"} p={2} wrap={"wrap"} bg={"#d3d3d3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialEfetifidadeProjetadaFinal')}
            </Flex>
            <Flex borderBottomRightRadius={"15px"} fontSize={"25px"} fontWeight={600} width={"25%"} p={2} color={"red.700"} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(efetividadeFinalProjetada)}%
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  const [isLoadingLocal, setisLoadingLocal] = useState(false);

  const SubmitFormButton = () => {
    const formik = useFormikContext();

    const onSubmit = async () => {
      await formik.setFieldValue('mostra_mensagem_sucesso', true);
      await formik.submitForm();
    }

    return (
      <DefaultButton onClick={onSubmit} isLoading={isLoadingLocal} type="button">{t('SistemaSalvar')} {t('AvaliacaoInicialTituloEdit')}</DefaultButton>
    )
  }

  const tableDescription = [
    {
      name: t('MatrizRiscoDescricaoA'),
      value: 'A',
    },
    {
      name: t('MatrizRiscoDescricaoB'),
      value: 'B',
    },
    {
      name: t('MatrizRiscoDescricaoC'),
      value: 'C',
    },
    {
      name: t('MatrizRiscoDescricaoD'),
      value: 'D',
    },
    {
      name: t('MatrizRiscoDescricaoE'),
      value: 'E',
    },
  ]

  const table = [
    {
      col1: {
        name: 'E',
        description: <Text pl={3} fontSize="12px"> {tableDescription[4].name} </Text>,
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col3: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
      col4: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
      col5: {
        name: t('MatrizRiscoCRÍTICO'),
        bg: 'red',
      },
      col6: {
        name: t('MatrizRiscoCRÍTICO'),
        bg: 'red',
      },
    },
    {
      col1: {
        name: 'D',
        description: <Text pl={3} fontSize="12px"> {tableDescription[3].name} </Text>,
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col3: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col4: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
      col5: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
      col6: {
        name: t('MatrizRiscoCRÍTICO'),
        bg: 'red',
      },
    },
    {
      col1: {
        name: 'C',
        description: <Text pl={3} fontSize="12px"> {tableDescription[2].name} </Text>,
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoBAIXO'),
        bg: 'green',
      },
      col3: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col4: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col5: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
      col6: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
    },
    {
      col1: {
        name: 'B',
        description: <Text pl={3} fontSize="12px"> {tableDescription[1].name} </Text>,
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoBAIXO'),
        bg: 'green',
      },
      col3: {
        name: t('MatrizRiscoBAIXO'),
        bg: 'green',
      },
      col4: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col5: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col6: {
        name: t('MatrizRiscoALTO'),
        bg: 'orange',
      },
    },
    {
      col1: {
        name: 'A',
        description: <Text pl={3} fontSize="12px"> {tableDescription[0].name} </Text>,
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoINEXISTENTE'),
        bg: 'cyan',
      },
      col3: {
        name: t('MatrizRiscoBAIXO'),
        bg: 'green',
      },
      col4: {
        name: t('MatrizRiscoBAIXO'),
        bg: 'green',
      },
      col5: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
      col6: {
        name: t('MatrizRiscoEMINENTE'),
        bg: 'yellow',
      },
    },
    {
      col1: {
        name: t('MatrizRiscoInsetosCriterio'),
        bg: 'white',
      },
      col2: {
        name: t('MatrizRiscoInsetosNaoIdentificado'),
        bg: 'white',
      },
      col3: {
        name: t('MatrizRiscoInsetosAte10'),
        bg: 'white',
      },
      col4: {
        name: t('MatrizRiscoInsetos10e20'),
        bg: 'white',
      },
      col5: {
        name: t('MatrizRiscoInsetos20a30'),
        bg: 'white',
      },
      col6: {
        name: t('MatrizRiscoInsetosIncontavel'),
        bg: 'white',
      },
    },
    {
      col1: {
        name: t('AvaliacaoInicialIndice'),
        bg: 'white',
      },
      col2: {
        name: 1,
        bg: 'white',
      },
      col3: {
        name: 2,
        bg: 'white',
      },
      col4: {
        name: 3,
        bg: 'white',
      },
      col5: {
        name: 4,
        bg: 'white',
      },
      col6: {
        name: 5,
        bg: 'white',
      },
    },

  ];

  const TabelaMatrizRisco = () => {
    const valoresLetras = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
    }
    const formik = useFormikContext();

    const values: AvaliacaoInicial = formik.values as AvaliacaoInicial;

    const [, { value: matriz_indice_risco }, { setValue: setmatriz_indice_risco }] = useField('matriz_indice_risco');

    const itemAtualSelecionado = (letra: string) => {
      if (!matriz_indice_risco) return false;
      if ((valoresLetras as any)[(letra ?? '').trim()] == parseInt(matriz_indice_risco).toFixed(0)) {
        return true;
      }

      return false;
    }

    return (
      <Flex width={"full"} wrap={"wrap-reverse"}>
        <Flex width={isMobile ? '100%' : '35%'} wrap={"wrap"} pr={isMobile ? 0 : 2}>
          <Flex width={"full"} wrap={"wrap"}>
            {tableDescription.map((i, index) => (
              <Flex key={`${index}`} cursor={"pointer"} onClick={() => setmatriz_indice_risco(((valoresLetras as any)[i.value] as any) ?? 0)} alignItems={"center"} width={"full"} wrap={"wrap"} p={2} bg={itemAtualSelecionado(i.value) ? 'green.100' : "white"} border={itemAtualSelecionado(i.value) ? '2px solid green' : "1px solid #d3d3d3"} borderRadius={"10px"} my={1} >
                <Text fontWeight={700}>
                  {i.value}
                </Text>
                <Flex pl={4}>
                  {i.name}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex width={isMobile ? '100%' : '65%'} alignItems={"center"} wrap={"wrap"}>
          <Text mx={"auto"} fontWeight={"bold"}>
            {t('MatrizRiscoTitulo')}
          </Text>
          <table style={{ width: '100%', fontSize: '12px' }}>
            <tbody>
              {table.map((tab, indexTab) => (
                <tr style={{ width: '100%' }} key={`${indexTab}`}>
                  {Object.keys(tab).map((item: any, indexItem) => (
                    <td key={`${indexItem}`} style={{ alignItems: 'center', fontSize: item == 'col1' ? '16px' : isMobile ? '10px' : '12px', width: !isMobile ? (item == 'col1' ? '40%' : '10%') : (item == 'col1' ? '10%' : '18%'), padding: '7px ' + isMobile ? '3px' : "10px", backgroundColor: (tab as any)[item]?.bg, color: (tab as any)[item]?.bg == 'white' ? 'black' : 'white', border: '1px solid black', fontWeight: '600' }}>
                      <Flex alignItems={"center"} width={"100%"} justifyContent={item == 'col1' ? 'left' : 'center'} textAlign={item == 'col1' ? 'left' : 'center'}>
                        {(tab as any)[item].name} {!isMobile && ((tab as any)[item]?.description ?? '')}
                      </Flex>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Flex>
      </Flex>

    )
  }

  const tableLegenda = [
    {
      col1: {
        bg: 'cyan',
        name: t('MatrizRiscoInexistente'),
        align: 'center',
      },
      col2: {
        bg: 'white',
        name: t('MatrizRiscoInexistenteDescricao'),
        align: 'left',
      },
      col3: {
        bg: 'white',
        name: t('MatrizRiscoInexistenteDescricaoRealizar'),
        align: 'left',
      },
    },
    {
      col1: {
        bg: 'green',
        name: t('MatrizRiscoRiscoRiscoRiscoBaixo'),
        align: 'center',
      },
      col2: {
        bg: 'white',
        name: t('MatrizRiscoRiscoRiscoRiscoBaixoDescricao'),
        align: 'left',
      },
      col3: {
        bg: 'white',
        name: t('MatrizRiscoRiscoRiscoRiscoBaixoDescricaoRealizar'),
        align: 'left',
      },
    },
    {
      col1: {
        bg: 'yellow',
        name: t('MatrizRiscoRiscoRiscoEminente'),
        align: 'center',
      },
      col2: {
        bg: 'white',
        name: t('MatrizRiscoRiscoRiscoEminenteDescricao'),
        align: 'left',
      },
      col3: {
        bg: 'white',
        name: t('MatrizRiscoRiscoRiscoEminenteDescricaoRealizar'),
        align: 'left',
      },
    },
    {
      col1: {
        bg: 'orange',
        name: t('MatrizRiscoRiscoAlto'),
        align: 'center',
      },
      col2: {
        bg: 'white',
        name: t('MatrizRiscoRiscoAltoDescricao'),
        align: 'left',
      },
      col3: {
        bg: 'white',
        name: t('MatrizRiscoRiscoAltoDescricaoRealizar'),
        align: 'left',
      },
    },
    {
      col1: {
        bg: 'red',
        name: t('MatrizRiscoCritico'),
        align: 'center',
      },
      col2: {
        bg: 'white',
        name: t('MatrizRiscoCriticoDescricao'),
        align: 'left',
      },
      col3: {
        bg: 'white',
        name: t('MatrizRiscoCriticoDescricaoRealizar'),
        align: 'left',
      },
    }
  ]

  const valoresLetras = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
  }

  const TabelaMatrizRiscoLegenda = () => {
    const formik = useFormikContext();

    const values: AvaliacaoInicial = formik.values as AvaliacaoInicial;

    const itemAtualSelecionado = (letra: string) => {
      if ((valoresLetras as any)[(letra ?? '').trim()] == parseInt(values.matriz_indice_risco as any).toFixed(0)) {
        return true;
      }

      return false;
    }

    return (
      <Flex width={"full"} wrap={"wrap"}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                {t('MatrizRiscoLEGENDA')}
              </th>
              <th style={{ textAlign: 'center' }}>
                {t('MatrizRiscoPROBABILIDADE')}
              </th>
              <th style={{ textAlign: 'center' }}>
                {t('MatrizRiscoAÇÃONECESSÁRIA')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableLegenda.map((tab, indexTab) => (
              <tr style={{ width: '100%', fontSize: '12px' }} key={`${indexTab}`}>
                {Object.keys(tab).map((item: any, indexItem) => (
                  <td key={`${indexItem}`} style={{ fontSize: (tab as any)[item]?.bg == 'white' ? '12px' : '16px', textAlign: (tab as any)[item]?.align, width: item == 'col1' ? '10%' : '18%', padding: '7px 10px', backgroundColor: item == 'col1' && itemAtualSelecionado((tab as any)[item].name) ? 'green' : (tab as any)[item]?.bg, color: (tab as any)[item]?.bg == 'white' ? 'black' : 'white', border: '1px solid black', fontWeight: '600' }}>
                    {(tab as any)[item].name}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    )
  }

  const MatrizDeRisco = () => {
    return (
      <Flex width={"full"} wrap={"wrap"}>
        <TabelaMatrizRisco />
        <Flex width={"full"} wrap={"wrap"}>
          <TabelaMatrizRiscoLegenda />
        </Flex>
      </Flex>
    )
  }

  const AvaliacaoMassaGraosFinal = ({ values }: { values: AvaliacaoInicial }) => {
    const getTotalInsetos = (origem: string) => {
      let quantidade = 0;
      (((values as any)[origem] as AvaliacaoInicialInseto[]) ?? []).filter((i) => !i.deleted).forEach((inseto) => {
        quantidade += (inseto.qt_cryptolestes * 1 + inseto.qt_oryzaephilus * 1 + inseto.qt_rhyzopertha * 1 + inseto.qt_sitophilus * 1 + inseto.qt_tribolium * 1)
      })
      return quantidade;
    }

    const getEfetividadeFinalProjetada = () => {
      if (getTotalInsetos('insetos') <= 0) return 0;
      return ((1 - (getTotalInsetos('insetos_final') / getTotalInsetos('insetos'))) * 100);
    }

    return (
      <>
        <Flex width={"full"} mt={4} />
        <ListHeaderSecondary label={t('AvaliacaoInicialCriteriosMassaGraos')} />
        <Flex width={"full"} />

        <Flex wrap={"wrap"} width={"full"} justifyContent={"center"}>
          <Flex mt={2} maxW={isMobile ? '600px' : "600px"} wrap={"wrap"} borderRadius={"15px"} border="1px solid #d3d3d3">
            <Flex fontWeight={700} fontSize={"18px"} bg={"#d3d3d3"} justifyContent={"center"} p={2} width={"full"} borderTopRadius={"15px"} textAlign={"center"}>
              {t('AvaliacaoInicialCalculoResultadoFinal')}
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialTotalInsetosInicial')}
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {getTotalInsetos('insetos')}
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialTotalInsetosAposAplicacao')}
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {getTotalInsetos('insetos_final')}
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialEfetifidadeProjetadaFinal')} (C)
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(efetividadeFinalProjetada)}%
            </Flex>
            <Flex width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} alignItems={"center"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialResultadoInfalAtingido')}
            </Flex>
            <Flex width={"25%"} p={2} wrap={"wrap"} fontSize={"25px"} color={"red.700"} fontWeight={600} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(getEfetividadeFinalProjetada())}%
            </Flex>
            <Flex borderBottomLeftRadius={"15px"} alignItems={"center"} width={"75%"} p={2} wrap={"wrap"} bg={"#e3e3e3"} borderBottom={"1px solid #d3d3d3"}>
              {t('AvaliacaoInicialDiferencaRealizadaProjetada')}
            </Flex>
            <Flex borderBottomRightRadius={"15px"} width={"25%"} p={2} wrap={"wrap"} bg={"white"} borderBottom={"1px solid #d3d3d3"}>
              {formatValor(getEfetividadeFinalProjetada() - efetividadeFinalProjetada)}%
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Layout showInternetStatus={true}>
      <ListHeader label={t('AvaliacaoInicialTituloEdit')}>
        <DefaultButton ml={4} to="/avaliacoes-iniciais"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {avaliacaoInicial && <Formik
        initialValues={avaliacaoInicial}
        enableReinitialize
        onSubmit={async (values, { setErrors }) => {
          const validation = validateForm({
            cliente_id: 'required',
            cliente_unidade_id: 'required',
            cliente_unidade_silo_id: 'required',
          }, values, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            mensagemErro("Preencha todos os campos antes de salvar.");
            return;
          }


          if (values.id) {
            const imagesDb = await getAll();

            console.log("leitura form", values);
            dispatch(leituraActions.saveAvaliacaoInicialRequest({
              ...values,
              changed: true,
              imagens: values.imagens?.map((i) => {
                return {
                  ...i,
                  imagem: i.imagemId
                    ? imagesDb.find((j: any) => j.id == i.imagemId)?.name ?? ''
                    : i.imagem,
                };
              }),
              assinaturas: values.assinaturas?.map((k) => {
                return {
                  ...k,
                  assinatura: k.assinaturaId
                    ? imagesDb.find((j: any) => j.id == k.assinaturaId)?.name ?? ''
                    : k.assinatura,
                };
              }),
              insetos: values.insetos?.map((k) => {
                return {
                  ...k,
                  imagem: k.imagemId
                    ? imagesDb.find((j: any) => j.id == k.imagemId)?.name ?? ''
                    : k.imagem,
                };
              }),
              insetos_final: values.insetos_final?.map((k) => {
                return {
                  ...k,
                  imagem: k.imagemId
                    ? imagesDb.find((j: any) => j.id == k.imagemId)?.name ?? ''
                    : k.imagem,
                };
              }),
            }));
            return;
          }
          setisLoadingLocal(true)
          console.log("leitura form", values);
          dispatch(leituraActions.saveAvaliacaoInicialLocalRequest({
            ...values,
            changed: true,
          }));

          setTimeout(() => {
            setisLoadingLocal(false);
          }, 1000)
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              {isMobile && <FormControl p={1} width={tamanhoCampo}>
                <FormLabel fontSize={"16px"} mb={0} mt={2}>{t('AvaliacaoInicialTipoCamera')}</FormLabel>
                <Select bg={'white'}
                  color={'black'}
                  borderRadius={2} onChange={(evt) => setTipoCamera(evt.target.value)} value={tipoCamera}>
                  <option value="direta">{t('AvaliacaoInicialTipoCamera1')} </option>
                  <option value="opcoes">{t('AvaliacaoInicialTipoCamera2')} </option>
                </Select>
              </FormControl>}
              <InputField label={t('LeiturasListData')} type="datetime-local" name="data_hora" autoFocus width={tamanhoCampo} />
              <InputLeituraSelect />
              <InputField label={t('LeiturasEditOrdemServico')} name="ordem_servico" width={tamanhoCampo} />
              <InputSelect label={t('ClienteEditTitulo')} name="cliente_id" width={tamanhoCampo}>
                {leituraDados && leituraDados.clientes.map((i) => (
                  <option value={i.id} key={i.id}>{i.nome} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
                ))}
              </InputSelect>
              <ClienteUnidadeInput />
              <ClienteUnidadeSiloInput />
              <InputSelect label={t('LeiturasEditProduto')} name="produto" width={tamanhoCampo}>
                <option value={'Trigo'}>{t('DashboardTrigo')} (0,765 PH KG/M³)</option>
                <option value={'Milho'}>{t('DashboardMilho')} (0,7 PH KG/M³)</option>
                <option value={'Soja'}>{t('DashboardSoja')} (0,77 PH KG/M³)</option>
                <option value={'Sorgo'}>{t('DashboardSorgo')} (0,72 PH KG/M³)</option>
                <option value={'Cevada'}>{t('DashboardCevada')} (0,58 PH KG/M³)</option>
                <option value={'Triguilho'}>{t('DashboardTriguilho')} (0,6 PH KG/M³)</option>
                <option value={'Farelo'}>{t('DashboardFarelo')} (0,25 PH KG/M³)</option>
                <option value={'Farinha'}>{t('DashboardFarinha')} (0,77 PH KG/M³)</option>
                <option value={'Arroz Limpo'}>{t('DashboardArroz limpo')} (0,77 PH KG/M³)</option>
                <option value={'Arroz Casca'}>{t('DashboardArroz Casca')} (0,35 PH KG/M³)</option>
                <option value={'Feijao'}>{t('DashboardFeijao')} (0,8 PH KG/M³)</option>
                <option value={'Aveia'}>{t('DashboardAveia')} (0,5 PH KG/M³)</option>
                <option value={'Amendoin'}>{t('DashboardAmendoin')} (0,88 PH KG/M³)</option>
              </InputSelect>
              <InputField label={t('DashboardPdfVolume')} type="number" name="volume" width={tamanhoCampo} />
            </Flex>

            <LeiturasResponsaveis />

            {podeVerAvaliacaoEstrutural(values) && <AvaliacaoInicialEstruturalForm usaCameraDireta={usaCameraDireta} />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoHermeticidadeForm />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoInicialAssinaturas etapa="inicial" />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoInicialProdutoArmazenado nameTitulo="AvaliacaoInicialAvaliacaoProdutoArmazenado" nameInsetos="insetos" />}
            {podeVerAvaliacaoHermeticidade(values) && <MatrizDeRisco />}
            {podeVerAvaliacaoHermeticidade(values) && <SiloTemperaturas values={values} />}
            {podeVerAvaliacaoHermeticidade(values) && <Pontuacoes values={values} />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoMassaGraos values={values} />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoInicialProdutoArmazenado nameTitulo="AvaliacaoInicialAvaliacaoFinalProdutoArmazenado" nameInsetos="insetos_final" />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoMassaGraosFinal values={values} />}
            {podeVerAvaliacaoHermeticidade(values) && <AvaliacaoInicialAssinaturas etapa="final" />}
            {podeVerAvaliacaoHermeticidade(values) && <InputField textarea={true} rows={3} label={t('AvaliacaoInicialObservacoes')} name="observacoes" />}
            <Flex width={"full"} mb={"100px"} />

            <SubmitFormInput />

            <Box width={"full"} position={"fixed"} p={4} bg={"white"} marginLeft={"-17px"} bottom={0} borderTop={"2px solid #134e1d"} zIndex={10}>
              <SubmitFormButton />
            </Box>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}
