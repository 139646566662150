import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Flex, Text } from "@chakra-ui/react";
import ReactSpeedometer from "react-d3-speedometer";
import { formatValor } from "../../utils/formatValor";
import { useTranslation } from "react-i18next";

export const DashboardEficienciaReducao = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);

  const getValue = (ultimaAnalise = false) => {

    if (!dashboard?.last_leitura?.amostras) return {
      valor: 0,
      porcentagem: 0,
    };

    const leiturasTipoInicial = dashboard.last_leitura?.amostras?.filter((i) => i.tipo_leitura == 'Inicial') ?? [];
    let mediaIniciais = 0;
    let somaIniciais = 0;
    for (const leitura of leiturasTipoInicial) {
      somaIniciais += leitura.concentracao
    }
    mediaIniciais = somaIniciais / leiturasTipoInicial.length;

    if (!mediaIniciais) return {
      valor: 0,
      porcentagem: 0,
    };

    const ultimaLeitura = dashboard.last_leitura.amostras[dashboard.last_leitura.amostras?.length - 1];

    if (!ultimaLeitura) return {
      valor: 0,
      porcentagem: 0,
    };

    const ultimasDoMesmoTipo = dashboard.last_leitura.amostras.filter((i) => i.qt_hora == ultimaLeitura.qt_hora && i.qt_hora > 0);

    if (ultimasDoMesmoTipo.length == 0) return {
      valor: 0,
      porcentagem: 0,
    };

    let somasFinais = 0;
    for (const leitura of ultimasDoMesmoTipo) {
      somasFinais += parseFloat(leitura.concentracao as any)
    }
    let mediaFinais = somasFinais / ultimasDoMesmoTipo.length;
    // console.log(somasFinais, ultimasDoMesmoTipo.length)
    if (!mediaFinais) return {
      valor: 0,
      porcentagem: 0,
    };
    console.log('11111111')
    if (ultimaAnalise) {
      return  {
        valor: mediaFinais,
        porcentagem: 0,
      };;
    }

    const valorFinal = mediaIniciais - mediaFinais;
    console.log('11111111221')
    if (valorFinal < 0) return {
      valor: 0,
      porcentagem: 0,
    };

    const porcentagem = 100 - (mediaFinais * 100) / mediaIniciais;

    return {
      valor: valorFinal,
      porcentagem: porcentagem > 0 ? porcentagem : 0,
    };
  }

  const {t} = useTranslation()

  const getStatus = (valor: number) => {
    if (valor <= 800) {
      return {
        message: t('DashboardEficienciaReducaoEmbarque'),
        color: 'green',
      }
    }

    if (valor <= 1500) {
      return {
        message: t('DashboardEficienciaReducaoEmbarque'),
        color: '#FFD700',
      }
    }

    if (valor <= 1800) {
      return {
        message: t('DashboardEficienciaReducaoLimiteEmbarque'),
        color: 'yellow',
      }
    }

    if (valor <= 2000) {
      return {
        message: t('DashboardEficienciaReducaoForaPadrao'),
        color: '#FF6347',
      }
    }

    if (valor <= 5000) {
      return {
        message: t('DashboardEficienciaReducaoForaPadrao'),
        color: 'red',
      }
    }

    return {
      message: '',
      color: '',
    }
  }

  return (
    <Flex position={"relative"} width={"full"} pt={3} alignItems={"center"} direction={"column"} maxH={isMobile ? '1000px' : '190px'} minH={isMobile ? "190px" : "205px"} >
      <Text position={"relative"} fontSize="0.9rem" color="#404244" fontWeight="700">{t('DashboardEficienciaReducaoTitulo')}</Text>
      <ReactSpeedometer
        value={getValue(true).valor}
        minValue={0}
        maxValue={getValue(true).valor > 3000 ? 5000 : 3000}
        needleColor="#888"
        valueTextFontSize="0"
        startColor={'green'}
        endColor={'green'}
        segments={4}
        customSegmentStops={[0, 800, 1500, 1800, 2000, getValue(true).valor > 3000 ? 5000 : 3000]}
        segmentColors={['green', '#FFD700', 'yellow', '#FF6347', 'red']}
        height={100}
        width={160}
        needleHeightRatio={0.7}
        ringWidth={35}
        needleTransitionDuration={1000}
        labelFontSize="6"
      />

      <Flex zIndex={2} position={"absolute"} bottom={9} bgColor={"white"} p={2} pt={2} borderRadius={"1000px 1000px 1000px 1000px"}>
        <Text color={"#404244"} fontWeight={"700"} fontSize={"18px"}>
          {formatValor(getValue().porcentagem)}%
        </Text>
      </Flex>
      <Flex width={"65px"} height={"60px"} bgColor={"white"} position={"absolute"} bottom={5} borderRadius={"50%"}>

      </Flex>


      <Flex alignItems={"center"} bgColor={getStatus(getValue(true).valor).color} position={"absolute"} bottom={1} py={1} px={4} textAlign={"center"} borderRadius={"10px 10px 10px 10px"} color={"white"}>
        <Text fontWeight={"600"} fontSize={"14px"}>
          {getStatus(getValue(true).valor).message}
        </Text>
      </Flex>
    </Flex>
  )
}