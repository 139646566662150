import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { UserForm } from "../components/UserForm";
import { UserUnidades } from "../components/UserUnidades";
import { useTranslation } from "react-i18next";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { Userlogins } from "../types/user_logins";
import { formatData } from "../../utils/data";
import { ListHeaderSecondary } from "../components/ListHeaderSecondary";

export const UsuarioEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const usuario = useSelector((state: RootState) => state.sistema.usuario || null)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.usuarioRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  const headers: TableHeaders<Userlogins>[] = [
    {
      label: t('UserLoginsData'),
      wrapped: true,
      render: (item) => formatData(item.created_at as string),
      notClicable: true,
    },
    {
      label: t('UserLoginsDispositivo'),
      wrapped: false,
      render: (item) => item.user_agent,
      notClicable: true,
    },
  ];

  return (
    <Layout>
      <ListHeader label={t('UsuarioEdit')}>
        <DefaultButton ml={4} to="/usuarios">{t('SistemaVoltar')}</DefaultButton>
        {id && id !== 'novo' && <UserUnidades user_id={id} />}
      </ListHeader>

      <Loader isLoading={isLoading} />
      <UserForm currentItem={usuario} />

      {usuario?.acessos && <ListHeaderSecondary label={t('UserAcessos')} />}
      {usuario?.acessos && <ResponsiveTable isSmall headers={headers} data={usuario.acessos} />}
    </Layout>
  )
}