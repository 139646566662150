interface IValidationFields {
  [key: string]: string;
}

const validateForm = (validation: IValidationFields, form_values: any, t: any) => {
  let object = {};

  Object.keys(validation).forEach(element => {
    const [type, valor] = validation[element].split('|');

    if (type === 'required' && !form_values[element]) {
      object = {
        ...object,
        [element]: t('SistemaCampoObrigatorio')
      }
    }

    if (type === 'min' && (!form_values[element] || form_values[element].length < parseInt(valor))) {
      object = {
        ...object,
        [element]: t('SistemaTamanhoMinimo', { valor:valor })
      }
    }
  });

  if (Object.keys(object).length === 0) {
    return {};
  }

  return object;
}

export default validateForm;