import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Box, Button, ButtonGroup, Flex, Heading, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import { useTranslation } from 'react-i18next';
import { DashboardNomeLeitura } from "./DashboardNomeLeitura";
import { DashboardCard } from "./DashboardCard";
import { DashboardLegendaCores } from "./DashboardLegendaCores";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { FiCornerRightDown, FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { novaLeituraInjecao } from "../data/leitura_injecao";
import { useState, useEffect } from 'react'
import { LeituraInjecao } from "../types/leitura_injecao";
import { Form, Formik, useFormikContext } from "formik";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import InputNumber from "../../sistema/components/InputNumber";
import validateForm from "../../utils/validateForm";
import { leituraActions } from "../reducer";
import { formatData, getCurrentData } from "../../utils/data";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { differenceInMinutes } from "date-fns";
import { mensagemErro } from "../../utils/toasts";
import { ReactECharts } from "./ReactECharts";
import { LeituraDadosSelectsInputs } from "../types/leitura_dados_selects_inputs";
import { getGifSiloVolume } from "../arrays/gifs_silo_volume";
import { DashboardAmostras } from "./DashboardAmostras";
import { DashboardAplicacao } from "./DashboardAplicacao";
import { LeituraAplicacao } from "../types/leitura_aplicacao";
import { FaSync } from "react-icons/fa";
import { Sinaleiro } from "./Sinaleiro";
import { AvaliacaoInicialQuestao } from "../../sistema/types/avaliacao_inicial_questao";
import { DashboardAvaliacaoInicial } from "./DashboardAvaliaçãoInicial";
import { formatValor } from "../../utils/formatValor";
import imagesUrl from "../../utils/imagesUrl";

const passagem_bloqueada = imagesUrl + "passagem_bloqueada.jpg";
const avaliacao_silo_inteiro = imagesUrl + 'armazenamentos/avaliacao_silo_inteiro.jpg';
const tecz = imagesUrl + "logos/tecz.png";
const systemz = imagesUrl + "logos/systemz.png";
const ozoclean = imagesUrl + "logos/ozoclean.png";
const proz = imagesUrl + "logos/proz.png";
const siloCilindrico = imagesUrl + "armazenamentos/cilindrico_padrao.jpg";
const cilindrico1Fluindo = imagesUrl + "armazenamentos/cilindrico_fluindo_ozonio.gif";
const cilindrico1FluindoFosfina = imagesUrl + "armazenamentos/cilindrico_fluindo_fosfina.gif";
const cilindrico_maquina_fluindo = imagesUrl + "armazenamentos/cilindrico_maquina_fluindo.gif";
const cilindrico_maquina_fixo = imagesUrl + "armazenamentos/cilindrico_maquina_fixo.jpg";
const predio_fluindo = imagesUrl + "armazenamentos/predio_fluindo.gif";
const predio_parado = imagesUrl + "armazenamentos/predio_parado.gif";
const predio__maquina_fluindo = imagesUrl + "armazenamentos/predio__maquina_fluindo.gif";
const predio__maquina_parada = imagesUrl + "armazenamentos/predio__maquina_parada.gif";
const ventilador = imagesUrl + "armazenamentos/ventilador.png";
const compressor = imagesUrl + "armazenamentos/compressor.png";
const siloPontoCentral = imagesUrl + "armazenamentos/silo_ponto_central.png";

export interface IDadosInjecao {
  volumeArmazenamento: number;
  quantidadeKgInjetada: number;
  volumeAcumulado: number;
  tempoAplicacaoHoras: number;
  tempoAplicacaoMinutos: number;
  tempoAplicacaoString: string;
  tipoFosfina: string;
  quantidadeJaLotado: number;
  porcentagemAtual: number;
  vazao_ventilador: number;
  vazao_compressor: number;
}

// interface IProps {
//   isOpenInjecaoCapa: boolean;
//   onOpenInjecaoCapa: () => void;
//   onCloseInjecaoCapa: () => void;
//   isOpenAddInjecao: boolean;
//   onOpenAddInjecao: () => void;
//   onCloseAddInjecao: () => void;
// }

const retorna2 = (valor: number) => {
  if (valor < 10) {
    return `0${valor.toFixed(0)}`
  }

  return valor.toFixed(0);
}
export const getDadosInjetado = (dadosSelects: LeituraDadosSelectsInputs | null, injecoes: LeituraInjecao[], volume: any, aplicacoes: LeituraAplicacao[]) => {
  const dados = {
    volumeArmazenamento: volume,
    quantidadeKgInjetada: 0,
    volumeAcumulado: 0,
    tempoAplicacaoHoras: 0,
    tempoAplicacaoMinutos: 0,
    tempoAplicacaoString: '0',
    tipoFosfina: '',
    quantidadeJaLotado: 0,
    porcentagemAtual: 0,
    porcentagemTotal: 0,
    vazao_ventilador: 0,
    vazao_compressor: 0,
  }

  if (injecoes.length > 1) {
    let qtMinutos = 0;
    for (const aplicacao of aplicacoes) {
      qtMinutos += differenceInMinutes(aplicacao.data_fim ? new Date(aplicacao.data_fim) : new Date(), new Date(aplicacao.data_inicio))
    }

    for (const aplicacao of aplicacoes) {
      let ultimoItem = null;

      const injecoesFiltradas = injecoes.filter((i) => new Date(i.data_hora) <= (aplicacao.data_fim ? new Date(aplicacao.data_fim) : new Date()) && new Date(i.data_hora) >= new Date(aplicacao.data_inicio))

      for (const injecao of injecoesFiltradas) {
        const tipoFosfina = dadosSelects?.tipos_fosfina?.find((i) => i.id == injecao.tipo_fosfina_id as any);
        dados.quantidadeKgInjetada += (parseInt(tipoFosfina?.gramas_por_garrafa as any ?? '0') / 1000);
        dados.tipoFosfina = tipoFosfina?.nome ?? '';

        const tempoEmMinutos = qtMinutos;// differenceInMinutes(new Date(injecoesFiltradas[injecoesFiltradas.length - 1].data_hora), new Date(injecoesFiltradas[0].data_hora))
        dados.tempoAplicacaoHoras = parseInt((tempoEmMinutos / 60) as any);
        dados.tempoAplicacaoMinutos = parseInt((tempoEmMinutos % 60) as any);
        dados.tempoAplicacaoString = `${retorna2(parseInt(dados.tempoAplicacaoHoras.toFixed(2)))}:${retorna2(parseInt(dados.tempoAplicacaoMinutos.toFixed(2)))}`
        dados.vazao_compressor = parseFloat(injecao.vazao_compressor as any);
        dados.vazao_ventilador = parseFloat(injecao.vazao_ventilador as any);

        if (ultimoItem) {

          const diferenteUltimaLeitura = differenceInMinutes(new Date(injecao.data_hora), new Date(ultimoItem.data_hora));
          const vazao = parseFloat(ultimoItem.vazao_compressor as any) + parseFloat(ultimoItem.vazao_ventilador as any);
          const circulouEmM3 = (vazao * diferenteUltimaLeitura) / 60

          dados.volumeAcumulado += circulouEmM3;
        }

        ultimoItem = injecao;
      }
    }

    dados.volumeAcumulado = parseInt(dados.volumeAcumulado.toFixed(0))

    dados.quantidadeJaLotado = parseInt((dados.volumeAcumulado / dados.volumeArmazenamento) as any)
    const quantidadeSobra = dados.volumeAcumulado % dados.volumeArmazenamento;
    dados.porcentagemAtual = (quantidadeSobra * 100) / dados.volumeArmazenamento
    dados.porcentagemTotal = (dados.quantidadeJaLotado * 100) + dados.porcentagemAtual;
  }

  return dados;
}

export const DashboardUltimaLeitura = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const leiturasState = useSelector((state: RootState) => state.leituras);

  interface Item {
    valor: number;
    data: string;
    ponto: string;
    posicaoPonto: number;
  }
  const { t } = useTranslation();
  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const temOPontoConfiguracao = (configuracao: string) => {
    return dashboard?.sensores?.find((i) => (i.configuracao ?? '').trim() == configuracao);
  }

  const getLeituras = (): Item[] => {
    if (!dashboard?.last_data || !dashboard?.sensores) return [
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 1`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 2`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 3`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 4`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 5`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 6`,
        posicaoPonto: 0,
      },
    ];

    let grafico: any = [];

    const last_data: any = dashboard.last_data;

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        grafico.push({
          valor: last_data[`c${i}`],
          data: last_data[`t${i}`],
          ponto: `${t('DashboardPonto')} ${i} ${temOPontoConfiguracao('Retorno')?.posicao == i ? `(${t('DashboardRetorno')})` :
            temOPontoConfiguracao('Injeção superior')?.posicao == i ? `(${t('DashboardInpecaoSuperior')})` :
              temOPontoConfiguracao('Injeção inferior')?.posicao == i ? `(${t('DashboardInpecaoInferior')})` : ''
            }`,
          posicaoPonto: i,
        },)
      }
    }

    return grafico;
  };





  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';



  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const injecoes = leiturasState?.injecoes ?? [];

  const estaFluindo = !eFosfina ? true : !dashboard?.last_leitura?.data_inicio_hermeticidade

  const { isOpen: isOpenInjecaoCapa, onOpen: onOpenInjecaoCapa, onClose: onCloseInjecaoCapa } = useDisclosure()
  const { isOpen: isOpenAddInjecao, onOpen: onOpenAddInjecao, onClose: onCloseAddInjecao } = useDisclosure()
  const { isOpen: isOpenVerMais, onOpen: onOpenVerMais, onClose: onCloseVerMais } = useDisclosure()

  // const pontoSuperior = temOPontoConfiguracao('Injeção superior') && eFosfina ? getLeituras().find((i) => i.posicaoPonto == temOPontoConfiguracao('Injeção superior')?.posicao as any) : null;
  // const pontoInferior = temOPontoConfiguracao('Injeção inferior') && eFosfina ? getLeituras().find((i) => i.posicaoPonto == temOPontoConfiguracao('Injeção inferior')?.posicao as any) : null;
  // const pontoRetorno = temOPontoConfiguracao('Retorno') && eFosfina ? getLeituras().find((i) => i.posicaoPonto == temOPontoConfiguracao('Retorno')?.posicao as any) : null;

  const [currentItem, setCurrentItem] = useState<LeituraInjecao | null>(null);

  const getOrdenacoes = () => {
    const retorno = [];

    for (let index = 1; index <= 8; index++) {
      retorno.push({
        name: index,
        value: index
      })
    }

    return retorno;
  }
  const dadosSelects = useSelector((state: RootState) => state.leituras?.leituraDadosSelectsInputs);

  const ComponenteSiloVolumeTotalJaCirculado = ({ width = "70%", minhH = "200px"}) => {
    return (
        <Flex justifyContent={"center"} wrap={"wrap"} p={2} minH={minhH} width={width} direction={"column"} alignItems={"center"}>
        <Text fontSize={"12px"}>
          {t('DashboardInjecoesVolumeTotalJaCirculado1')} {t('DashboardInjecoesVolumeTotalJaCirculado2')}
        </Text>
        <Text fontWeight={600} fontSize={"14px"}>
          {formatValor(getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).volumeAcumulado ?? '0', 0)} m³
        </Text>
        <Box p={2} textAlign={"center"} width={"full"} height={"160px"}>
          <Img mx={"auto"} height={"160px"} width={"130px"} src={getGifSiloVolume(getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).porcentagemAtual, dashboard?.last_leitura?.silo?.tipo_silo ?? '')} />
        </Box>
      </Flex>
    )
  }

  const numeroGarrafa = (values: LeituraInjecao) => {
    if (!injecoes.length) return 1;

    const mesmoReatorECamihao = injecoes.filter((i) => i.caminhao_id == values.caminhao_id && i.numero_reator == values.numero_reator);

    if (!mesmoReatorECamihao.length) return 1;

    return mesmoReatorECamihao[mesmoReatorECamihao.length - 1].numero_garrafa + 1;
  }

  const CaminhaoInput = () => {
    const formik = useFormikContext();

    const ajustaGarrafa = (caminhao_id: string) => {
      const values: LeituraInjecao = formik.values as LeituraInjecao;

      formik.setValues({
        ...values,
        caminhao_id,
        numero_garrafa: numeroGarrafa({ ...values, caminhao_id })
      })
    }

    return (
      <InputSelect onChangeVal={ajustaGarrafa} labelSize="14px" width={isMobile ? '50%' : '33.33%'} label={t('DashboardInjecaoCaminhao')} name="caminhao_id">
        {dadosSelects?.tipos_fosfina && dadosSelects.caminhoes.filter((i) => i.ativo == 1).map((i) => (
          <option value={i.id} key={`${i.id}`}>{i.codigo} ({i.descricao})</option>
        ))}
      </InputSelect>
    )
  }

  const onOpenModalInjecao = (id = 'novo') => {
    if (id == 'novo') {
      const novoItem = novaLeituraInjecao();
      if (injecoes.length) {
        novoItem.caminhao_id = injecoes[injecoes.length - 1].caminhao_id;
        novoItem.numero_reator = injecoes[injecoes.length - 1].numero_reator;
        novoItem.tipo_fosfina_id = injecoes[injecoes.length - 1].tipo_fosfina_id;
        novoItem.vazao_compressor = injecoes[injecoes.length - 1].vazao_compressor;
        novoItem.vazao_ventilador = injecoes[injecoes.length - 1].vazao_ventilador;
      }
      novoItem.numero_garrafa = numeroGarrafa(novoItem);
      novoItem.data_hora = getCurrentData()
      novoItem.leitura_id = dashboard?.last_leitura.id as any;
      setCurrentItem(novoItem)
      onOpenAddInjecao()
      return;
    }

    const item = injecoes.find((i) => i.id == id as any);

    if (!item) {
      mensagemErro("Injeção não encontrada");
      return;
    }

    setCurrentItem(item)
    onOpenAddInjecao()
  }

  const ReatorInput = () => {
    const formik = useFormikContext();

    const ajustaGarrafa = (numero_reator: number) => {
      const values: LeituraInjecao = formik.values as LeituraInjecao;

      formik.setValues({
        ...values,
        numero_reator,
        numero_garrafa: numeroGarrafa({ ...values, numero_reator })
      })
    }

    return (
      <InputSelect onChangeVal={(val) => ajustaGarrafa(parseInt(val))} labelSize="14px" width={isMobile ? '50%' : '33.33%'} label={t('DashboardInjecaoNumeroReator')} name="numero_reator">
        {getOrdenacoes().map((i) => (
          <option value={i.value} key={`${i.value}`}>{i.name}</option>
        ))}
      </InputSelect>
    )
  }

  const dispatch = useDispatch();
  const isLoadingInjecao = useSelector((state: RootState) => state.leituras.isLoadingInjecao);

  useEffect(() => {
    if (!isLoadingInjecao) {
      onCloseAddInjecao();
      return;
    }
  }, [isLoadingInjecao, onCloseAddInjecao])

  const groupBy = (items: any, key: any) => items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const getInjecoesFormatadas = () => {
    const porCaminhao = groupBy(injecoes, 'caminhao_id');

    Object.keys(porCaminhao).forEach((i) => {
      porCaminhao[i] = groupBy(porCaminhao[i], 'numero_reator');

      Object.keys(porCaminhao[i]).forEach((reator) => {
        porCaminhao[i][reator] = porCaminhao[i][reator].map((item: LeituraInjecao, index: number) => {
          let tempo = 0;

          if (index > 0) {
            const ultimoItem = porCaminhao[i][reator][index - 1];

            tempo = parseInt((differenceInMinutes(new Date(item.data_hora), new Date(ultimoItem.data_hora))).toFixed(0));
          }

          return {
            ...item,
            tempo,
          }
        })
      })
    })

    return porCaminhao;
  }

  const headers: TableHeaders<LeituraInjecao>[] = [
    {
      label: t('DashboardInjecaoNumeroGarrafa'),
      render: (item) => item.numero_garrafa,
    },
    {
      label: t('DashboardInjecaoTempo'),
      wrapped: true,
      render: (item) => `${item.tempo} min`,
    },
    {
      label: t('DashboardInjecaoDataHora'),
      wrapped: true,
      render: (item) => formatData(item.data_hora),
    },
    {
      label: t('DashboardInjecaoVazaoVentilador'),
      wrapped: true,
      render: (item) => `${item.vazao_ventilador} m³/h`,
    },
    {
      label: t('DashboardInjecaoVazaoCompressor'),
      wrapped: true,
      render: (item) => `${item.vazao_compressor} m³/h`,
    },
    {
      label: t('DashboardInjecaoConcentracaoInjecao'),
      render: (item) => `${item.concentracao_injecao} ppm`,
    },
    {
      label: t('DashboardInjecaoConcentracaoRetorno'),
      render: (item) => `${item.concentracao_retorno} ppm`,
    },
    {
      label: t('DashboardInjecaoTipoFosfina'),
      render: (item) => `${dadosSelects?.tipos_fosfina?.find((i) => i.id == item.tipo_fosfina_id as any)?.nome}`,
    },
    {
      label: t('SistemaAções'),
      render: (item: any, index: any) => <>
        <ButtonGroup>
          {podePreencher() && <Button colorScheme="blackAlpha" size={"xs"} onClick={() => onOpenModalInjecao(item.id)}><FiEdit /></Button>}
          {podePreencher() && (user?.e_desenvolvedor as any) == 1 && <Button colorScheme="red" size={"xs"} onClick={() => onDeleteInjecao(item)}><FiTrash /></Button>}
        </ButtonGroup>
      </>,
      notClicable: true,
    },
  ];

  const headersInjecao: TableHeaders<IDadosInjecao>[] = [
    {
      label: `${t('DashboardInjecaoVolumeArmazenamento')}`,
      render: (item) => `${formatValor(item.volumeArmazenamento ?? '0')} m³`,
    },
    {
      label: `${t('DashboardInjecaoQTFosfinaInjetada')}`,
      render: (item) => `${item.quantidadeKgInjetada} kg`,
    },
    {
      label: `${t('DashboardInjecaoColumeGasph3Acumulado')}`,
      render: (item) => `${formatValor(item.volumeAcumulado ?? '0', 0)} m³`,
    },
    {
      label: `${t('DashboardInjecaoTempoAplicacao')}`,
      render: (item) => `${item.tempoAplicacaoString} h`,
    },
    {
      label: `${t('DashboardInjecaoTipoDeFosfina')}`,
      render: (item) => `${item.tipoFosfina}`,
    },
    {
      label: `${t('DashboardInjecaoTrocasTotaisVolume')}`,
      render: (item) => `${item.quantidadeJaLotado} ${t('DashboardInjecaoVezes')}`,
    },
  ];

  interface IProps {
    children: any;
    width: string;
    id?: string;
  }

  const ModalItem = ({ children, width, id }: IProps) => {
    return (
      <Box id={id} width={width} p={1}>
        <Box bg={"white"} borderRadius={25} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"}>
          {children}
        </Box>
      </Box>
    )
  }

  const onDeleteInjecao = (item: LeituraInjecao) => {
    dispatch(leituraActions.saveLeituraInjecaoRequest({
      ...item,
      changed: true,
      deleted: true,
    }))
  }



  const aplicacaoComAtmosferaCompleta = () => {
    const valorInjecao = parseFloat(injecoes[injecoes.length - 1]?.concentracao_injecao as any ?? '0');
    const valorRetorno = parseFloat(injecoes[injecoes.length - 1]?.concentracao_retorno as any ?? '0');

    if (valorInjecao == 0 || valorRetorno == 0) return false;

    const valorReferencia = valorInjecao - (valorInjecao * 0.3);

    return valorRetorno > valorReferencia;
  }

  const getHorasGraficoLinha = () => {
    return injecoes.map((i) => formatData(i.data_hora));
  }

  const getSeriesGraficoLinha = () => {
    return [
      {
        name: `${t('DashboardInjecoesVerMaisInjecao')}`,
        type: 'line',
        stack: `${t('DashboardInjecoesVerMaisInjecao')}`,
        data: injecoes.map((i) => i.concentracao_injecao),
        smooth: true,
      },
      {
        name: `${t('DashboardInjecoesVerMaisRetorno')}`,
        type: 'line',
        stack: `${t('DashboardInjecoesVerMaisRetorno')}`,
        data: injecoes.map((i) => i.concentracao_retorno),
        smooth: true,
        color: 'red',
      }
    ]
  }

  const user = useSelector((state: RootState) => state.sistema.user);
  const podePreencher = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const dataGraficoLinha: any = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
    },
    legend: {
      data: [`${t('DashboardInjecoesVerMaisInjecao')}`, `${t('DashboardInjecoesVerMaisRetorno')}`],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getHorasGraficoLinha(),
    },
    yAxis: {
      type: 'value'
    },
    series: getSeriesGraficoLinha(),
  }

  const larguraGrande = window.innerWidth >= 1920;

  const userAdmin = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  enum Estagio {
    injecao = 'injecao',
    preparacao = 'preparacao',
  };

  const [estagio, setEstagio] = useState<Estagio>((eFosfina ? ((dashboard?.last_leitura?.injecoes ?? []).length ? Estagio.injecao : Estagio.preparacao) : Estagio.injecao));

  const getRespostaQuestao = (questao: AvaliacaoInicialQuestao) => {
    const respostas = (dashboard?.avaliacao_inicial_questoes_respondidas ?? []);

    return respostas.find((j) => j.avaliacao_inicial_questao_id == questao.id)?.status ?? 0;
  }

  const getTotalFeito = (origem: string) => {
    const dados: any = (dashboard?.preparacaoInicial?.questoesFormatadas as any) ?? null;
    // const respostas = (dashboard?.avaliacao_inicial_questoes_respondidas ?? []);

    if (!dados) return 0;

    // const total = dados.length;
    let respondidas = 0;

    dados[origem].forEach((i: any) => {
      if (i?.resposta?.status == 3) {
        respondidas += 1;
      } else if (i?.resposta && i?.resposta?.data_fim && i?.resposta?.data_inicio && i?.resposta?.status > 0) {
        respondidas += 1;
      }
    })

    const retorno = (respondidas * 100) / dados[origem].length;

    if (isNaN(retorno)) return 0;

    return parseFloat(retorno.toFixed(2));
  }

  const gaugeData = [
    {
      value: getTotalFeito('teto'),
      name: t('AvaliacaoInicialQuestaoEditTeto'),
      title: {
        offsetCenter: ['0%', '-30%']
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '-15%']
      }
    },
    {
      value: getTotalFeito('corpo'),
      name: t('AvaliacaoInicialQuestaoEditCorpo'),
      title: {
        offsetCenter: ['0%', '0%']
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '13%']
      }
    },
    {
      value: getTotalFeito('base'),
      name: t('AvaliacaoInicialQuestaoEditBase'),
      title: {
        offsetCenter: ['0%', '27%']
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '40%']
      }
    }
  ];

  const getResultadoQuestaoIndividual = (questao_nome: string) => {
    const questoesFormatadas = (dashboard?.preparacaoInicial?.questoesFormatadas);

    if (!questoesFormatadas) return 0;

    const dados: any = questoesFormatadas['predio'];

    if (!dados) return 0;

    const questao = dados.find((i: any) => i.nome_questao.trim() == questao_nome);

    const resultado = parseInt(questao?.resposta?.status ?? '0');

    if (resultado > 100) return 100;

    return resultado;
  }

  const graficoGeralPredioData = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#464646'
          }
        },
        axisLine: {
          lineStyle: {
            width: 22
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [
          {
            value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialVedacao'),
            name: t('DashboardAvaliacaoInicialVedacao'),
            title: {
              offsetCenter: ['0%', '-45%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '-31%']
            }
          },
          {
            value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialMontagemInjecaoSilos'),
            name: t('DashboardAvaliacaoInicialMontagemInjecaoSilos'),
            title: {
              offsetCenter: ['0%', '-15%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '-3%']
            }
          },
          {
            value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialProfilaxia'),
            name: t('DashboardAvaliacaoInicialProfilaxia'),
            title: {
              offsetCenter: ['0%', '12%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '25%']
            }
          },
          {
            value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialTermoNebulização'),
            name: t('DashboardAvaliacaoInicialTermoNebulização'),
            title: {
              offsetCenter: ['0%', '41%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '53%']
            }
          }
        ],
        title: {
          fontSize: 9.9
        },
        detail: {
          width: 20,
          height: 5,
          fontSize: 10,
          color: 'inherit',
          borderColor: 'inherit',
          borderRadius: 20,
          borderWidth: 1,
          formatter: '{value}%',
        }
      }
    ]
  }



  const graficoGeralData = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#464646'
          }
        },
        axisLine: {
          lineStyle: {
            width: 30
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 40
        },
        data: gaugeData,
        title: {
          fontSize: 8
        },
        detail: {
          width: 20,
          height: 1,
          fontSize: 8,
          color: 'inherit',
          borderColor: 'inherit',
          borderRadius: 20,
          borderWidth: 1,
          formatter: '{value}%',
        }
      }
    ]
  };

  const cores = [
    '',
    'red',
    'yellow',
    'green'
  ]

  const [tooltipSwitchTela, setTooltipSwitchTela] = useState(false)

  const eLeituraDeCilindricoComPreparacaoDePredio = (dashboard?.last_leitura?.e_leitura_de_silo_cilindrico_mas_com_preparacao_predio as any) == 1;

  const sinaleirosFiltrados: AvaliacaoInicialQuestao[] = (dashboard?.avaliacao_inicial_questoes?.sinaleiro ?? []).filter((i) => ((dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio') || eLeituraDeCilindricoComPreparacaoDePredio) ? i.nao_mostra_no_predio == 0 : true);
  const ePredio = dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio';
  const podeAplicar = getResultadoQuestaoIndividual('DashboardAvaliacaoInicialProfilaxia') == 100 && getResultadoQuestaoIndividual('DashboardAvaliacaoInicialTermoNebulização') == 100 && getResultadoQuestaoIndividual('DashboardAvaliacaoInicialMontagemInjecaoSilos') == 100 && getResultadoQuestaoIndividual('DashboardAvaliacaoInicialVedacao') == 100;

  const podeAplicarSilo = getTotalFeito('teto') == 100 && getTotalFeito('corpo') == 100 && getTotalFeito('base') == 100;

  const aplicacaoEmAndamento = (dashboard?.last_leitura?.aplicacoes ?? []).some((i) => !i.data_fim);

  const ModalPassagemBloquada = ({ position = 'absolute', top = '30%', left = '50%' }) => {
    return (
      <Flex fontSize={"13px"} maxW={"80px"} wrap="wrap" top={top} left={left} justifyContent={"center"} transform={"translateX(-50%)"} position={position as any} borderRadius={"15px"} border={"2px solid gray"} pt={1} alignItems={"center"}>
        <Img mx={"auto"} src={passagem_bloqueada} maxW={"60px"} height={"auto"} />
        <Text textAlign={"center"} width={"full"} fontSize={"13px"} fontWeight={700}>
          {t('DashboardCondicaoAplicacaoTeczAplicacaoNaoLiberadaPlacaSilo')}
        </Text>
      </Flex>
    )
  }

  return (
    <>
      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenAddInjecao} onClose={onCloseAddInjecao} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardInjecoesRegistrar')}>
              <DefaultButton onClick={onCloseAddInjecao} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                const validation = validateForm({
                  numero_reator: 'required',
                  vazao_ventilador: 'required',
                  concentracao_injecao: 'required',
                  data_hora: 'required',
                  tipo_fosfina_id: 'required',
                  caminhao_id: 'required',
                }, values, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                console.log("injecao form", values);

                dispatch(leituraActions.saveLeituraInjecaoRequest({
                  ...values,
                  changed: true,
                }))
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    <InputField labelSize="14px" width={isMobile ? '50%' : '33.33%'} label={t('DashboardInjecaoNumeroGarrafa')} disabled name="numero_garrafa" />
                    <InputSelect labelSize="14px" width={isMobile ? '50%' : '33.33%'} label={t('DashboardInjecaoTipoFosfina')} name="tipo_fosfina_id">
                      {dadosSelects?.tipos_fosfina && dadosSelects.tipos_fosfina.filter((i) => i.ativo == 1).map((i) => (
                        <option value={i.id} key={`${i.id}`}>{i.nome} ({i.gramas_por_garrafa} {t('DashboardGramas')})</option>
                      ))}
                    </InputSelect>
                    <CaminhaoInput />
                    <ReatorInput />
                    <InputNumber type="tel" decimalPlaces={0} labelSize="14px" label={t('DashboardInjecaoVazaoVentilador')} width={isMobile ? '50%' : '33.33%'} name="vazao_ventilador" />
                    <InputNumber type="tel" decimalPlaces={0} labelSize="14px" label={t('DashboardInjecaoVazaoCompressor')} width={isMobile ? '50%' : '33.33%'} name="vazao_compressor" />
                    <InputNumber type="tel" decimalPlaces={0} labelSize="14px" label={t('DashboardInjecaoConcentracaoInjecao')} width={isMobile ? '50%' : '33.33%'} name="concentracao_injecao" />
                    <InputNumber type="tel" decimalPlaces={0} labelSize="14px" label={t('DashboardInjecaoConcentracaoRetorno')} width={isMobile ? '50%' : '33.33%'} name="concentracao_retorno" />
                    <InputField labelSize="14px" width={'100%'} type="datetime-local" label={t('DashboardInjecaoDataHora')} name="data_hora" />
                  </Flex>
                  <Flex width={"full"} justifyContent={"space-between"}>
                    <Button mt={4} colorScheme={"blackAlpha"} ml={2} onClick={onCloseAddInjecao}>{t('DashboardFechar')}</Button>
                    <DefaultButton disabled={isLoadingInjecao} isLoading={isLoadingInjecao} mt={4} type="submit">
                      {t('DashboardSalvar')}
                    </DefaultButton>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenInjecaoCapa} onClose={onCloseInjecaoCapa} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardInjecoes')}>
              <DefaultButton onClick={onCloseInjecaoCapa} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex maxW={"600px"} wrap="wrap" mx={"auto"}>
              {userAdmin() && eFosfina &&
                <DashboardAplicacao />
              }
            </Flex>

            <Flex width={"full"} wrap={"wrap"}>
              {podePreencher() && <DefaultButton mx="auto" onClick={() => onOpenModalInjecao()}> <FiCornerRightDown />&nbsp; {t('DashboardInjecoesRegistrar')} </DefaultButton>}
              <Flex width={"full"} />
              {injecoes.length == 0 ? <>
                <Text mx={"auto"} mt={2}>{t('UsuarioUnidadesNenhumaEncontrada')} </Text>
              </> : <>
                {Object.keys(getInjecoesFormatadas()).map((i, index) => (
                  <Flex width={"full"} wrap={"wrap"} key={`${index}`}>
                    <Flex width={"full"} mt={4}></Flex>
                    <ListHeaderSecondary label={`${t('DashboardInjecaoCaminhao')} ${dadosSelects?.caminhoes?.find((j) => j.id == i as any)?.codigo} (${dadosSelects?.caminhoes.find((j) => j.id == i as any)?.descricao})`} />
                    {Object.keys(getInjecoesFormatadas()[i]).map((reator, index2) => (
                      <Flex width={"full"} wrap={"wrap"} key={`${index2}`}>
                        <Heading color={"#404244"} size={"md"} mt={1} width={"full"}>
                          {t('DashboardInjecaoNumeroReator')}: {reator}
                        </Heading>

                        <ResponsiveTable isSmall headers={headers} data={getInjecoesFormatadas()[i][reator]} />
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </>}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenVerMais} onClose={onCloseVerMais} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardInjecaoInstantanea')}>
              <DefaultButton onClick={onCloseVerMais} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex mx={"auto"} maxH={isMobile ? '100000vh' : "100000vh"} my={"auto"} justifyContent={"center"} maxW={"1300px"} wrap={"wrap"}>
              <Flex width={isMobile ? "100%" : '50%'} wrap={'wrap'} justifyContent={"center"}>
                <Flex width={"100%"} wrap={'wrap'}>
                  <ModalItem width={isMobile ? '100%' : "50%"}>
                    <Flex width={"full"} wrap={"wrap"} justifyContent={"space-around"} minH={"215px"}>
                      <Flex wrap={"wrap"} direction={"column"}>
                        <Heading mt={2} color={"#404244"} mx={"auto"} size={"sm"}>
                          {t('DashboardInjecoesVerMaisInjecao')}
                        </Heading>
                        <DashboardCard positionText="50%" mostraMaior={true} value={{
                          valor: parseFloat(injecoes[injecoes.length - 1]?.concentracao_injecao as any ?? '0'),
                          data: '',
                          ponto: '',
                          posicaoPonto: 1
                        }} />
                      </Flex>
                      {!ePredio && <Flex wrap={"wrap"} direction={"column"}>
                        <Heading mt={2} color={"#404244"} mx={"auto"} size={"sm"}>
                          {t('DashboardInjecoesVerMaisRetorno')}
                        </Heading>
                        <DashboardCard positionText="50%" mostraMaior={true} value={{
                          valor: parseFloat(injecoes[injecoes.length - 1]?.concentracao_retorno as any ?? '0'),
                          data: '',
                          ponto: '',
                          posicaoPonto: 1
                        }} />
                      </Flex>}
                      <Flex width={"full"} justifyContent={"center"} mt={4}>
                        <Heading mb={2} color={"#404244"} size={"md"}>
                          {t('DashboardInjecoesVerMaisUltimoRegistro')}
                        </Heading>
                      </Flex>
                    </Flex>
                  </ModalItem>
                  <ModalItem width={isMobile ? '100%' : "50%"}>
                    <Flex wrap={"wrap"} width={"full"}>
                      <Flex justifyContent={"center"} wrap={"wrap"} pl={3} py={2} minH={"215px"} width={(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 ? "30%" : "100%"} direction={"column"} alignItems={"center"}>
                        <Text fontSize={"12px"}>
                          {t('DashboardInjecoesVolumePH3Injetado1')}
                        </Text>
                        <Text fontSize={"12px"}>
                          {t('DashboardInjecoesVolumePH3Injetado2')}
                        </Text>
                        <Text fontSize={"18px"} fontWeight={700} color={"green.800"}>
                          {getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).porcentagemTotal.toFixed(0)}%
                        </Text>
                        <Flex
                          flexDirection="column"
                          alignItems="center"
                          borderWidth="1px"
                          borderColor="gray.300"
                          borderRadius="md"
                          overflow="hidden"
                          h="120px"
                          w="50px"
                          maxH="120px"
                          maxW="50px"
                          position={"relative"}
                        >
                          <Text position={"absolute"} fontWeight={700} top={"30px"} fontSize={"30px"} opacity={"0.7"}>
                            {getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).quantidadeJaLotado}x <br />
                          </Text>
                          <Text position={"absolute"} fontWeight={700} top={"65px"} fontSize={"13px"} opacity={"0.7"}>
                            {t('DashboardCheio')}
                          </Text>
                          <Box
                            className="bar" // Adicione a classe de animação aqui
                            bg="gray.300"
                            h={`${100 - getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).porcentagemAtual}%`}
                            w="100%"
                            backgroundImage="repeating-linear-gradient(0deg, transparent, transparent 5px, rgba(0, 0, 0, 0.1) 5px, rgba(0, 0, 0, 0.1) 10px)"
                          ></Box>
                          <Box
                            backgroundImage="repeating-linear-gradient(0deg, rgba(11, 156, 49, 1), rgba(11, 156, 49, 1) 5px, rgba(11, 156, 48, 0.8) 5px, rgba(11, 156, 48, 0.8) 10px)"
                            h={`${getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).porcentagemAtual}%`}
                            w="100%"
                          ></Box>
                        </Flex>
                      </Flex>
                      {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <ComponenteSiloVolumeTotalJaCirculado />}
                    </Flex>
                  </ModalItem>

                  {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <ModalItem width={'100%'}>
                  <Flex width={"full"} wrap={"wrap"} justifyContent={"space-evenly"}>
                    {[1,2,3,4, 5, 6].map((item, key) => {
                        return (
                            <ComponenteSiloVolumeTotalJaCirculado key={`${key}`} width="auto" minhH="auto" />
                        )
                    })}
                    </Flex> 
                  </ModalItem>}
                </Flex>
                <Flex alignItems={"center"} mt={4} borderRadius={"15px"} width={"full"} wrap={"wrap"} borderTop={"2px solid #d3d3d3"} borderRight={"2px solid #d3d3d3"} borderLeft={"2px solid #d3d3d3"}>
                  {headersInjecao.map((header, index) => (
                    <Flex key={`${index}`} width={"full"} wrap={"wrap"}>
                      <Flex alignItems={"center"} borderBottomLeftRadius={index == headersInjecao.length - 1 ? '15px' : '0'} borderTopRadius={index == 0 ? "15px" : '0'} bgColor={index % 2 ? '#f3f3f3' : 'white'} borderBottom={"1px solid #d3d3d3"} p={1} width={"60%"} wrap={"wrap"}>
                        <Text fontSize={"14px"} color={"404244"} fontWeight={400}>{header.label as string}</Text>
                      </Flex>
                      <Flex borderBottomRightRadius={index == headersInjecao.length - 1 ? '15px' : '0'} borderTopRadius={index == 0 ? "15px" : '0'} bgColor={index % 2 ? '#f3f3f3' : 'white'} borderBottom={"1px solid #d3d3d3"} p={1} width={"40%"} wrap={"wrap"}>
                        <Text color={"404244"} fontWeight={500}>{header.render(getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []), 1) as any}</Text>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <Flex width={isMobile ? "full" : '50%'} wrap={"wrap"} justifyContent={"center"}>
                <Flex alignItems={"start"} width={"full"} wrap={"wrap"} direction={"column"}>
                  {aplicacaoComAtmosferaCompleta() && <Heading mb={4} color="green.700" mx={"auto"} size={"md"}>
                    {t('DashboardInjecoesAplicacaoAtmosferaCompleta')}
                  </Heading>}

                  <Heading mt={2} color={"#404244"} mx={"auto"} size={"sm"}>
                    {t('DashboardConcentracaoMediaAplicacaoPH3')}
                  </Heading>

                  <ReactECharts option={dataGraficoLinha} style={{ height: '200px' }} />
                </Flex>
                <Flex mt={isMobile ? 4 : 0} position={"relative"} width={'100%'} wrap={"wrap"}>
                  <Flex position={isMobile ? 'relative' : "absolute"} left={"50%"} top={"-50px"} transform={"translateX(-40%)"} width={isMobile ? '100%' : '100%'} mx={"auto"} justifyContent={"center"}>
                    <Flex justifyContent={"center"} wrap={"wrap"} alignItems={"center"} textAlign={"center"} position={"relative"} justifyItems={"center"} width={'100%'} height={"300px"} py={4}>

                      {dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio' && <>
                        {!estaFluindo && <img style={{ margin: isMobile ? 'auto auto auto -30px' : 'auto', width: isMobile ? '90%' : 'auto', height: isMobile ? 'auto' : '350px' }} src={predio__maquina_parada} />}
                        {estaFluindo && <img style={{ margin: isMobile ? 'auto auto auto -30px' : 'auto', width: isMobile ? '90%' : 'auto', height: isMobile ? 'auto' : '350px' }} src={predio__maquina_fluindo} />}
                      </>}
                      {dashboard?.last_leitura?.silo?.tipo_silo != 'Prédio' && <>
                        {!estaFluindo && <img style={{ margin: 'auto', width: isMobile ? '70%' : 'auto', height: isMobile ? 'auto' : '300px' }} src={cilindrico_maquina_fixo} />}
                        {estaFluindo && <img style={{ margin: 'auto', width: isMobile ? '70%' : 'auto', height: isMobile ? 'auto' : '300px' }} src={cilindrico_maquina_fluindo} />}
                      </>}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex mb={isMobile ? "200px" : "0"} width={"full"} wrap={"wrap"} justifyContent={"start"}>

                <ModalItem width={isMobile ? '100%' : "25%"}>
                  <Flex p="2" width={"full"} wrap={"wrap"} mt={isMobile ? 0 : '50px'}>
                    <Box textAlign={"center"} width={"50%"} height={"100px"}>
                      <Img width={"110px"} height={"auto"} src={compressor} margin={"auto"} />
                    </Box>
                    <Flex width={"50%"} direction={"column"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                      <Text fontSize={"14px"}>
                        {t('DashboardInjecaoVazaoCompressor')}
                      </Text>
                      <Text fontSize={"18px"} fontWeight={700}>
                        {getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).vazao_compressor} m³/h
                      </Text>
                    </Flex>
                  </Flex>
                </ModalItem>
                <ModalItem width={isMobile ? '100%' : "25%"}>
                  <Flex p="2" width={"full"} wrap={"wrap"} mt={isMobile ? 0 : '50px'}>
                    <Box textAlign={"center"} width={"50%"} height={"100px"}>
                      <Img width={"80px"} height={"auto"} src={ventilador} margin={"auto"} />
                    </Box>
                    <Flex width={"50%"} direction={"column"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                      <Text fontSize={"14px"}>
                        {t('DashboardInjecaoVazaoVentilador')}
                      </Text>
                      <Text fontSize={"18px"} fontWeight={700}>
                        {getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).vazao_ventilador} m³/h
                      </Text>
                    </Flex>
                  </Flex>
                </ModalItem>
              </Flex>


            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex minH={isMobile ? 'auto' : "435px"} wrap={"wrap"} justifyContent={"space-around"}>
        {isMobile && <Img width={"80px"} height={"80px"} src={eFosfina ? tecz : ozoclean} mx={"auto"} />}
        {isMobile && <Img width={"80px"} height={"80px"} src={systemz} mx={"auto"} />}
        {isMobile && (ePredio || eLeituraDeCilindricoComPreparacaoDePredio) && estagio == Estagio.preparacao && <Img width={"80px"} height={"80px"} zIndex={999} src={proz} mx={"auto"} />}

        <Flex position={"relative"} width={"full"} justifyContent={"center"}>
          {estagio == Estagio.injecao ?
            <Text ml={isMobile ? 2 : 0} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700" pt={2}>{t('DashboardInjecaoInstantanea')} <DashboardNomeLeitura /></Text>
            :
            <Text ml={isMobile ? 2 : 0} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700" pt={2}>{t('DashboardStatusDePreparacao')}</Text>
          }
          {eFosfina && estagio == Estagio.injecao && <DefaultButton ml={1} mt={2} className="no-print" size={"xs"} onClick={onOpenInjecaoCapa}> <FiCornerRightDown />&nbsp;{t('DashboardInjecoes')} </DefaultButton>}
          {eFosfina && estagio == Estagio.injecao && <DefaultButton ml={1} mt={2} mr={isMobile ? 2 : 0} className="no-print" size={"xs"} onClick={onOpenVerMais}> <FiEye />&nbsp;{t('DashboardVerMais')} </DefaultButton>}
          {!isMobile && <Img width={"70px"} height={"70px"} zIndex={999} src={eFosfina ? tecz : ozoclean} position={"absolute"} top={estagio == Estagio.preparacao ? "5px" : "35px"} left={"20px"} />}
          {!isMobile && <Img width={"70px"} height={"70px"} zIndex={999} src={systemz} position={"absolute"} top={estagio == Estagio.preparacao ? "5px" : "35px"} left={"100px"} />}
          {!isMobile && (ePredio || eLeituraDeCilindricoComPreparacaoDePredio) && estagio == Estagio.preparacao && <Img width={"50px"} height={"50px"} zIndex={999} src={proz} position={"absolute"} top={"80px"} left={"20px"} />}


          {eFosfina && <Tooltip cursor={"pointer"} label={estagio == Estagio.injecao ? t('DashboardVerStatusDePreparacao') : t('DashboardVerInjecaoInstantanea')} isOpen={tooltipSwitchTela}>
            <Flex p={"5px"} _hover={{
              backgroundColor: '#d3d3d3',
              borderRadius: '1000px'
            }} onMouseEnter={() => setTooltipSwitchTela(true)}
              onMouseLeave={() => setTooltipSwitchTela(false)}
              onClick={() => { setTooltipSwitchTela(true); setEstagio(estagio == Estagio.injecao ? Estagio.preparacao : Estagio.injecao) }} cursor={"pointer"} position={"absolute"} top={isMobile ? '40px' : "10px"} zIndex={999} right={"10px"} my={"auto"} ml={2} wrap={"wrap"}>
              <FaSync size="20px" />
            </Flex>
          </Tooltip>}

          {/* </Flex> */}

        </Flex>

        {estagio == Estagio.injecao ?
          <Flex wrap={"wrap"} width={"full"}>
            <Flex width={"100%"}>
              <Flex alignItems={eFosfina && !isMobile ? 'center' : "end"} position={"relative"} wrap="wrap" width={"100%"} justifyContent={!eFosfina && !isMobile ? 'left' : 'space-around'} py={4} pl={!eFosfina && !isMobile ? '3' : 0}>
                <Flex wrap={"wrap"} alignItems={"center"} textAlign={"center"} position={"relative"} justifyItems={"center"} width={isMobile || (dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 ? '100%' : eFosfina ? "70%" : "70%"} height={"250px"} py={4}>
                  {dashboard?.last_leitura?.silo?.tipo_silo == 'Cilindrico' && (dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <>
                    {!estaFluindo && <img style={{ margin: 'auto', width: 'auto', height: '250px' }} src={siloCilindrico} />}
                    {estaFluindo && <img style={{ margin: 'auto', width: 'auto', height: '250px' }} src={eFosfina ? cilindrico1FluindoFosfina : cilindrico1Fluindo} />}
                  </>}
                  {dashboard?.last_leitura?.silo?.tipo_silo == 'Cilindrico' && (dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <>
                    <Flex width={"full"} wrap={"wrap"} justifyContent={"space-evenly"}>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 1</Text>
                        </Box>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 2</Text>
                        </Box>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 3</Text>
                        </Box>
                    </Flex>
                    <Flex width={"full"} wrap={"wrap"} justifyContent={"space-evenly"}>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 4</Text>
                        </Box>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 5</Text>
                        </Box>
                        <Box>
                            <Img style={{ margin: 'auto', width: 'auto', height: '100px' }} src={siloPontoCentral} />
                            <Text textTransform={"uppercase"} color={"gray.400"} fontSize={"xs"} fontWeight={"700"}>{t('DashboardPdfPonto')} 6</Text>
                        </Box>
                    </Flex>
                  </>}
                  {dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio' && <>
                    {!estaFluindo && <img style={{ margin: isMobile ? 'auto' : '-50px auto auto 100px', width: 'auto', height: larguraGrande ? '320px' : '280px' }} src={predio_parado} />}
                    {estaFluindo && <img style={{ margin: isMobile ? 'auto' : '-50px auto auto 100px', width: 'auto', height: larguraGrande ? '320px' : '280px' }} src={predio_fluindo} />}
                  </>}
                  {dashboard?.last_leitura?.silo?.tipo_silo != 'Cilindrico' && dashboard?.last_leitura?.silo?.tipo_silo != 'Prédio' && <img style={{ margin: 'auto', height: '250px' }} src={tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem as any} />}
                </Flex>
                {!eFosfina && !isMobile && <Flex maxH={"400px"} overflowY={"hidden"} width={'48%'} position={"absolute"} top={"-85px"} right={"0px"} direction={"column"}>
                  {!eFosfina && <DashboardAmostras />}
                </Flex>}

                <Flex width={isMobile ? '100%' : 'auto'} wrap="wrap" mt={isMobile ? 8 : 0} direction={isMobile ? 'initial' : ((dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 ? "initial" : "column")} justifyContent={isMobile ? 'space-around' : 'center'}>
                  {eFosfina && aplicacaoEmAndamento &&
                    <DashboardCard telaGrande={larguraGrande} positionText="50%" mostraMaior={true} value={{
                      valor: parseFloat(injecoes[injecoes.length - 1]?.concentracao_injecao as any ?? '0'),
                      data: '',
                      ponto: t('DashboardInjecoesVerMaisInjecao'),
                      posicaoPonto: 1
                    }} />
                  }
                  {eFosfina && aplicacaoEmAndamento && !ePredio && <br />}
                  {eFosfina && aplicacaoEmAndamento && !ePredio &&
                    <DashboardCard telaGrande={larguraGrande} positionText="50%" mostraMaior={true} value={{
                      valor: parseFloat(injecoes[injecoes.length - 1]?.concentracao_retorno as any ?? '0'),
                      data: '',
                      ponto: t('DashboardInjecoesVerMaisRetorno'),
                      posicaoPonto: 1
                    }} />
                  }
                  {/* {pontoRetorno &&
                  <DashboardCard positionText="50%" mostraMaior={true} value={{
                    ...pontoRetorno,
                    ponto: t('DashboardRetorno')
                  }} />
                } */}
                </Flex>
                {/*                   
              <Flex width={"60%"} wrap="wrap" justifyContent="space-between">
                {getLeituras().slice(0, 6).map((i) => (
                  <DashboardCard key={i.ponto} value={i} />
                ))}
              </Flex>
              <Flex width={"100%"} wrap="wrap" justifyContent="space-between">
                {getLeituras().slice(6).map((i) => (
                  <DashboardCard key={i.ponto} value={i} />
                ))}
              </Flex> */}
              </Flex>
            </Flex>
            {eFosfina && <Flex pb={isMobile ? 4 : 0} height={isMobile ? "45px" : "35px"} width={"100%"} wrap="wrap">
              <DashboardLegendaCores />
            </Flex>}
          </Flex>
          :
          <Flex pb={isMobile ? '120px' : 0} wrap={"wrap"} width={"full"} position={'relative'}>
            <Flex height={"60%"} wrap={"wrap"} width={"full"} position={'relative'}>
              {podeAplicarSilo && !isMobile && !ePredio && <ModalPassagemBloquada />}
              <Flex width={isMobile ? '100%' : "50%"} marginTop={"20px"}>
                {(dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio' || eLeituraDeCilindricoComPreparacaoDePredio) ?
                  <ReactECharts option={graficoGeralPredioData as any} style={{ height: '270px', position: isMobile ? 'relative' : 'absolute', top: '10px', left: isMobile ? '0' : '-120px' }} />
                  :
                  <ReactECharts option={graficoGeralData as any} style={{ height: '220px' }} />
                }
              </Flex>
              <Box width={isMobile ? '100%' : "50%"}>
                <Img mx={"auto"} marginTop={"15px"} height={"230px"} src={(dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio' || eLeituraDeCilindricoComPreparacaoDePredio) ? predio_parado : avaliacao_silo_inteiro} />
              </Box>
            </Flex>
            {podeAplicarSilo && isMobile && !ePredio && <ModalPassagemBloquada position="relative" top="0" />}
            <Flex mt={isMobile ? '30px' : ''} maxH={"35%"} wrap={"wrap"} width={(ePredio && !isMobile) ? "50%" : "full"} position={'relative'}>
              {sinaleirosFiltrados.map((i, index) => (
                <Flex key={`${index}`} width={isMobile ? '50%' : `${((ePredio && !isMobile) ? 50 : 100) / sinaleirosFiltrados.length}%`} direction={"column"} justifyContent={"start"} mx={"auto"} textAlign={"center"}>
                  <Box mx={"auto"}>
                    <Sinaleiro largura="25px" cor={cores[getRespostaQuestao(i)]} />
                  </Box>
                  <Text textAlign={"center"} fontSize={"12px"}>
                    {t(i.nome_questao)}
                  </Text>
                </Flex>
              ))}
            </Flex>
            {ePredio && <Flex maxH={"35%"} wrap={"wrap"} width={isMobile ? '100%' : "50%"}>
              <Flex width={isMobile ? '100%' : "70%"} mx={"auto"} wrap={"wrap"} direction={"column"} textAlign={"center"} justifyContent={"center"}>
                <Sinaleiro largura="25px" cor={podeAplicar ? 'green' : 'red'} mx="auto" />

                <Text fontSize={"12px"}>
                  {t('DashboardCondicaoAplicacaoTecz')}
                </Text>

                <Text fontSize={"13px"} color={podeAplicar ? 'green' : 'red'} fontWeight={700}>
                  {podeAplicar ? t('DashboardCondicaoAplicacaoTeczAplicacaoLiberada') : t('DashboardCondicaoAplicacaoTeczAplicacaoNaoLiberada')}
                </Text>
              </Flex>
              {podeAplicar && <Flex wrap={"wrap"} width={isMobile ? '100%' : "30%"} direction={"column"} pr={1} textAlign={"center"} justifyContent={"center"}>
                <Flex wrap="wrap" width={"full"} zIndex={100000} borderRadius={"15px"} border={"2px solid gray"} pt={1} alignItems={"center"}>
                  <Img mx={"auto"} src={passagem_bloqueada} maxW={"60px"} height={"auto"} />
                  <Text fontSize={"13px"} fontWeight={700} mr={isMobile ? '20px' : ''}>
                    {t('DashboardCondicaoAplicacaoTeczAplicacaoNaoLiberadaPlaca')}
                  </Text>
                </Flex>
              </Flex>}
            </Flex>}

            <Flex left={"50%"} transform={"translateX(-50%)"} position={"absolute"} top={"0px"} zIndex={2}>
              {!isMobile && <DashboardAvaliacaoInicial />}
            </Flex>
          </Flex>
        }
      </Flex>
    </>
  );
};
