export const generateUuid = () => {
  return Math.random().toString(16).slice(2);
};

export const replaceAllFunction = (
  string: string,
  search: string,
  replacement: string
) => {
  return (string ?? "").replace(new RegExp(search, "g"), replacement);
};
