import { LeituraInjecao } from "../types/leitura_injecao";

export const novaLeituraInjecao = (): LeituraInjecao => {
  return {
    numero_garrafa: 0,
    numero_reator: 0,
    vazao_ventilador: 0,
    vazao_compressor: 0,
    concentracao_injecao: 0,
    concentracao_retorno: 0,
    data_hora: "",
    leitura_id: "",
    tipo_fosfina_id: "",
    caminhao_id: "",
  };
};
