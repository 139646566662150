import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { ButtonGroup, Flex, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, keyframes, useDisclosure } from "@chakra-ui/react";
import { Sinaleiro } from "./Sinaleiro";
import { formatData } from "../../utils/data";
import { formatValor } from "../../utils/formatValor";
import { useTranslation } from 'react-i18next'
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { FiEye } from "react-icons/fi";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { IDadosInjecao, getDadosInjetado } from "./DashboardUltimaLeitura";
import { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { useState } from "react";
import { LeituraDadosSelectsInputs } from "../types/leitura_dados_selects_inputs";

export const DashboardSemaforo = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const { t } = useTranslation();

  const status_semaforo = dashboard?.status_semaforo ?? {
    cor: 'green',
    valor: 0,
    data: '',
  }

  const getNecessidade = (concentracaoDesejada = 1800, concentracaoMediaAtual = status_semaforo.valor, retornaApenasValor = false) => {
    const volumeSilo = parseFloat(dashboard?.last_leitura?.volume as any ?? '0');

    if (volumeSilo === 0) return '0';

    const valorFixoConcentracao = 750; // concentração / m³

    let result = concentracaoDesejada - (concentracaoMediaAtual);

    result = result / valorFixoConcentracao;

    result = result * 3; // 3 valor fixo do peso da pastilha de fosfina

    result = result * volumeSilo;

    if (retornaApenasValor) {
      return result / 1000;
    }

    return formatValor(result / 1000);
  }

  const getNecessidadeVolume = (concentracaoDesejada: number, retornaApenasValor = false) => {
    if (retornaApenasValor) {
      return (getNecessidade(concentracaoDesejada, getMediaConcentracaoGeral(), true) as any * 1000) / 9
    }
    return formatValor((getNecessidade(concentracaoDesejada, getMediaConcentracaoGeral(), true) as any * 1000) / 9)
  }

  const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

  const injecoes = useSelector((state: RootState) => state.leituras.injecoes)

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const getMedia = (last_data: any) => {
    let somaPontos = 0;
    let qtPontos = 0;

    if (!dashboard?.sensores || !dashboard?.last_data) {
      return {
        somaPontos,
        qtPontos,
      }
    }

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        somaPontos += parseFloat(last_data[`c${i}`]);
        qtPontos += 1;

      }
    }

    return {
      somaPontos,
      qtPontos,
    }
  }

  const getMediaConcentracaoInjetada = () => {
    let valorTotal = 0;
    let quantidade = 0;

    for (const injecao of (injecoes ?? [])) {
      valorTotal += parseFloat(injecao.concentracao_injecao as any);
      quantidade += 1;
    }

    return (valorTotal / quantidade) >= 0 ? valorTotal / quantidade : 0;
  }

  const getMediaConcentracaoGeral = () => {
    let valorTotal = 0;
    let quantidade = 0;

    for (const data of (dashboard?.data ?? [])) {
      const valores = getMedia(data);

      valorTotal += (valores.somaPontos / valores.qtPontos)
      quantidade += 1
    }

    return valorTotal / quantidade;
  }

  const [alvoMedioNecessario, setAlvoMedioNecessario] = useState(600);

  const animation = status_semaforo.cor == 'yellow' || status_semaforo.cor == 'red' ? `${blinkAnimation} 1s infinite` : ''

  const { isOpen, onOpen, onClose } = useDisclosure()

  const getDadosGraficoBarra = () => {
    const mediaConcentracaoGeral = getMediaConcentracaoGeral();

    const vermelhoFim = mediaConcentracaoGeral - mediaConcentracaoGeral * 0.1;
    const amareloFim = mediaConcentracaoGeral;

    return {
      posicaoAtual: (((getMediaConcentracaoGeral() * 100) / alvoMedioNecessario) > 100 ? 100 : ((getMediaConcentracaoGeral() * 100) / alvoMedioNecessario)).toFixed(2),
      tamanhoVermelho: (vermelhoFim * 100 / (mediaConcentracaoGeral + 10)).toFixed(2),
      tamanhoAmarelo: ((amareloFim * 100 / (mediaConcentracaoGeral + 10)) - vermelhoFim * 100 / (mediaConcentracaoGeral + 10)).toFixed(2),
    }
  }

  const getCorAtual = () => {
    const dados = getDadosGraficoBarra();

    const posicaoAtual = parseFloat(dados.posicaoAtual);
    const tamanhoVermelho = parseFloat(dados.tamanhoVermelho);
    const tamanhoAmarelo = parseFloat(dados.tamanhoAmarelo);

    if (posicaoAtual < tamanhoVermelho) {
      return 'red'
    }

    if (posicaoAtual < (tamanhoAmarelo + tamanhoVermelho)) {
      return 'yellow'
    }

    return 'green'
  }

  const headersInjecao: TableHeaders<IDadosInjecao>[] = [
    {
      label: `${t('DashboardInjecaoColumeGasph3Acumulado')}`,
      render: (item) => `${formatValor(item.volumeAcumulado ?? '0', 0)} m³`,
    },
    {
      label: `${t('DashboardInjecaoTrocasTotaisVolume')}`,
      render: (item) => `${item.quantidadeJaLotado} ${t('DashboardInjecaoVezes')}`,
    },
    {
      label: `${t('DashboardAvaliacaoReaplicacaoMediaInjecao')}`,
      render: (item) => `${getMediaConcentracaoInjetada().toFixed(0)} ppm`,
    },
    {
      label: `${t('DashboardAvaliacaoReaplicacaoMediaConcentracao')}`,
      render: (item) => `${getMediaConcentracaoGeral().toFixed(0)} ppm`,
    },
    {
      label: `${t('DashboardAvaliacaoReaplicacaoAlvoMedioNecessario')}`,
      render: (item) => (<ButtonGroup><Input size={"sm"} width={"100px"} type="number" onChange={(evt) => setAlvoMedioNecessario(parseInt(evt.target.value))} value={alvoMedioNecessario} /> ppm </ButtonGroup>),
    },
    {
      label: `${t('DashboardInjecaoQTFosfinaInjetada')}`,
      render: (item) => `${item.quantidadeKgInjetada} kg`,
    },

  ];

  const dadosSelects = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)

  return (
    <Flex id="dashboard-condicao-reaplicacao" width={"full"} pt={3} alignItems={"center"} direction={"column"} maxH={isMobile ? '1000px' : '120px'} minH={"120px"} >
      <Text position={"relative"} fontSize={t('DashboardCondicaoReaplicacao').length > 30 ? "0.6rem" : "0.9rem"} color="#404244" fontWeight="700">{t('DashboardCondicaoReaplicacao')}</Text>
      <Text py={0} position={"relative"} fontSize="0.7rem" color="#404244" fontWeight="600">{`< ${dashboard?.last_leitura?.range_reaplicacao}ppm < 96h`}  </Text>

      <Flex width={"full"} wrap={"wrap"} pl={4} justifyContent={"center"}>
        <Flex alignItems={"center"} width={"30%"} justifyContent={isMobile ? "right" : 'left'}>
          <Sinaleiro animation={animation} cor={status_semaforo.cor} />
          <DefaultButton className="no-print" ml={2} p={0} size={"xs"} onClick={onOpen}><FiEye /></DefaultButton>
        </Flex>
        <Flex width={"70%"} direction={"column"} alignItems={"center"} justifyContent={"left"}>
          {status_semaforo.cor == 'green' &&
            <><Text fontSize={"10px"} fontWeight={700} color={"green"}>
              {t('DashboardSemNecessidade')}
            </Text>
              <Text fontSize={"10px"} fontWeight={700} color={"green"}>
                {t('DashboardDeReaplicar')}
              </Text>
            </>}
          {status_semaforo.cor == 'yellow' && <>
            <Flex bgColor='yellow'>
              <Text animation={animation} borderRadius={"10px"} px={2} fontSize={"14px"} fontWeight={700} color={"white"}>
                {t('DashboardAtencao')}
              </Text>
            </Flex>
          </>}
          {status_semaforo.cor == 'red' && <>
            <Text animation={animation} fontSize={"10px"} fontWeight={700} color={"red"}>
              {t('DashboardReaplicar')}
            </Text>
            <Text fontSize={"10px"} fontWeight={700} color={"red"}>
              {getNecessidade()}Kg
            </Text>
          </>}
          <Text fontSize={"12px"} fontWeight={700} color={"#404244"}>
            {status_semaforo.valor}ppm
          </Text>
          <Text fontSize={"8px"} fontWeight={500} color={"#404244"}>
            {formatData(status_semaforo.data, 'dd/MM/yyyy HH:mm')}
          </Text>
        </Flex>
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <ListHeaderSecondary label={t("DashboardAvaliacaoReaplicacao")}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"}>
              <Flex borderRadius={"15px"} width={"full"} wrap={"wrap"} borderTop={"2px solid #d3d3d3"} borderRight={"2px solid #d3d3d3"} borderLeft={"2px solid #d3d3d3"}>
                {headersInjecao.map((header, index) => (
                  <Flex key={`${index}`} width={"full"} wrap={"wrap"}>
                    <Flex alignItems={"center"} borderBottomLeftRadius={index == headersInjecao.length - 1 ? '15px' : '0'} borderTopRadius={index == 0 ? "15px" : '0'} bgColor={index % 2 ? '#f3f3f3' : 'white'} borderBottom={"1px solid #d3d3d3"} p={1} width={"60%"} wrap={"wrap"}>
                      <Text fontSize={"14px"} color={"#404244"} fontWeight={400}>{header.label as string}</Text>
                    </Flex>
                    <Flex borderBottomRightRadius={index == headersInjecao.length - 1 ? '15px' : '0'} borderTopRadius={index == 0 ? "15px" : '0'} bgColor={index % 2 ? '#f3f3f3' : 'white'} borderBottom={"1px solid #d3d3d3"} p={1} width={"40%"} wrap={"wrap"}>
                      <Text color={"#404244"} fontWeight={500}>{header.render(getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes ?? [], dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []), 1) as any}</Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>

              <Heading color={"#404244"} mt={2} size={"md"} mx={"auto"}>
                {t('DashboardAvaliacaoReaplicacaoQuantidadeReaplicado', { value: getNecessidade(alvoMedioNecessario, getMediaConcentracaoGeral(), true) as any < 0 ? 0 : getNecessidade(alvoMedioNecessario, getMediaConcentracaoGeral()) })}
              </Heading>
              <Heading color={"#404244"} mt={2} size={"md"} mx={"auto"}>
                {t('DashboardAvaliacaoReaplicacaoVolumeReaplicado', { value: getNecessidadeVolume(alvoMedioNecessario, true) as any < 0 ? 0 : getNecessidadeVolume(alvoMedioNecessario) })}
              </Heading>

              <Flex mt={8} width={"full"} wrap={"wrap"}>
                <Flex justifyContent={"center"} width={isMobile ? '100%' : '30%'} wrap={"wrap"}>
                  <Sinaleiro largura="25px" animation={animation} cor={getCorAtual()} />
                </Flex>
                <Flex width={isMobile ? '100%' : '70%'} wrap={"wrap"}>
                  <Flex position={"relative"} justifyContent={"right"} width={`${parseFloat(getDadosGraficoBarra().posicaoAtual) - 10}%`} wrap={"wrap"}>
                    <Text fontSize={"12px"} fontWeight={700} position={"absolute"} top={"-15px"} right={"-25px"}>{getDadosGraficoBarra().posicaoAtual}%</Text>
                  </Flex>
                  <Flex borderRadius={"15px"} width={"90%"} height={"35px"}>
                    <Flex height={"35px"} bg={"#d53a23"} width={`${getDadosGraficoBarra().tamanhoVermelho}%`}></Flex>
                    <Flex height={"35px"} bg={"#facd11"} width={`${getDadosGraficoBarra().tamanhoAmarelo}%`}></Flex>
                    <Flex height={"35px"} bg={"#b0cc05"} width={`${100 - (parseFloat(getDadosGraficoBarra().tamanhoVermelho) + parseFloat(getDadosGraficoBarra().tamanhoAmarelo))}%`}></Flex>
                  </Flex>
                  <Flex height={"35px"} bg={"#b0cc05"} width={`10%`}></Flex>
                  <Flex justifyContent={"space-between"} width={"full"} wrap={"wrap"}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                      <small key={`${i}`}>
                        {i * 10}%
                      </small>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Flex>
  )
}