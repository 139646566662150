import { Inseto } from "../types/inseto";

export const novoInseto = (): Inseto => {
  return {
    imagem: "",
    nome: "",
    horas_mortalidade: 0,
    ppm_mortalidade: 0,
    descricao_html: "",
    ativo: true,
    imagem_grande: false,
    tipo: 1,
    descricao_html_espanhol: "",
    descricao_html_alemao: "",
    descricao_html_ingles: "",
    resistencia: "",
    larvaimagem: "",
    larva_horas_mortalidade: 0,
    larva_ppm_mortalidade: 0,
  };
};
