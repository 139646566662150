import { Button, CircularProgress, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useTranslation } from "react-i18next";
import { FaQrcode } from "react-icons/fa";
import QRCode from 'react-qr-code';
import { useState } from "react";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { apiNoAuthCall } from "../../utils/apiNoAuthCall";

interface QrCodeAcessoProps {
  leitura_token: string;
}

export const QrCodeAcesso = ({ leitura_token }: QrCodeAcessoProps) => {
  const user = useSelector((state: RootState) => state.sistema?.user);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const { t } = useTranslation();

  const [isGerandoLink, setGerandoLink] = useState(false);
  const [linkAcesso, setLinkAcesso] = useState('');

  const onGeraQrCodeAcesso = async () => {
    if (!leitura_token) return;

    setGerandoLink(true);
    try {
      const { data } = await apiNoAuthCall({
        url: 'autentica-usuario-externo',
        method: 'POST'
      });

      console.log(`token=${data.data.token}&to=/dashboard?token=${leitura_token}`);
      setLinkAcesso(`https://app.ecozonedobrasil.com/login-externo?token=${data.data.token}&to=/dashboard?token=${leitura_token}`)
      setGerandoLink(false);
      onOpen();
    } catch (error) {
      setGerandoLink(false);
      mensagemErro(`Ocorreu um erro ao gerar o QRCode para o cliente: ${formatError(error)}`);
    }
  }

  return (
    <>
      {(user?.e_operador == 1 || user?.e_desenvolvedor == 1 || user?.administrador == 1) &&
        <Button my={1} size={"xs"} colorScheme="blue" onClick={onGeraQrCodeAcesso}>
          {isGerandoLink && <CircularProgress size={"10px"} isIndeterminate />} &nbsp; <FaQrcode /> &nbsp; QR Code acesso clientes
        </Button>
      }

      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Mostre o QRCode de acesso abaixo ao cliente'}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"} justifyContent={"center"}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "90%", width: "90%" }}
                value={linkAcesso}
                viewBox={`0 0 256 256`}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}