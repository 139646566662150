import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Formik, Form, useFormikContext } from 'formik'
import validateForm from "../../utils/validateForm";
import InputField from "../components/InputField";
import { Button, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { ListHeaderSecondary } from "../components/ListHeaderSecondary";
import { formatData } from "../../utils/data";
import { EquipamentoCiclo } from "../types/equipamento_ciclo";
import { DashboardCiclo } from "../../leituras/components/DashboardCiclo";
import { ConfirmButton } from "../components/ConfirmButton";
import { FiEye, FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import InputCheckInteiro from "../components/InputCheckInteiro";

export const EquipamentoEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const equipamento = useSelector((state: RootState) => state.sistema.equipamento)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)
  const equipamento_ciclos = useSelector((state: RootState) => state.sistema.equipamento_ciclos)
  const user = useSelector((state: RootState) => state.sistema.user)

  const userIsDesenvolvedor = () => {
    return (user as any)?.e_desenvolvedor == 1;
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.equipamentoRequest(id))
    dispatch(sistemaActions.equipamentoUltimasLeiturasRequest(id))
  }, [dispatch, id])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenHitorico, onOpen: onOpenHitorico, onClose: onCloseHitorico } = useDisclosure()
  const [currentCiclo, setCurrentCiclo] = useState<EquipamentoCiclo | null>(null);
  const { t } = useTranslation()
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const [alterarEmMassa, setAlterarEmMassa] = useState('');

  const ButtonAlteraEmMassa = () => {
    const formik = useFormikContext();

    const alteraMultiploEmMassa = () => {
      const values = formik.values;

      for (let index = 1; index <= 16; index++) {
        formik.setFieldValue(`multiplo_p${index}`, alterarEmMassa)
      }
    }

    return (
      <Button ml={1} onClick={alteraMultiploEmMassa} colorScheme="blackAlpha">{t('EquipamentoEditMultiplosAlterar')}</Button>
    )
  }

  return (
    <Layout>
      <ListHeader label={t('EquipamentoListTituloMin')}>
        <DefaultButton ml={4} to="/equipamentos"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {equipamento && <Formik
        initialValues={equipamento}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            'nome_referencia': 'required',
          }, values, t);

          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }
          console.log("equipamento form", values);
          dispatch(sistemaActions.saveEquipamentosRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        <Form>
          <Flex width="full" wrap={"wrap"}>
            <InputField label={t('EquipamentoListNome')} name="nome_referencia" autoFocus width={'50%'} />
            <InputField label={t('EquipamentoListMAC')} name="endereco_registro" width={'50%'} />
            <InputField type="number" name="quantidade_leitura_vida_util" label={t('EquipamentoEditQtVidaUtil')} width={isMobile ? '50%' : '25%'} />
            <InputField type="number" name="quantidades_leituras_feitas" label={t('EquipamentoEditQtRealizadas')} width={isMobile ? '50%' : '25%'} />

            <InputField type="number" name="tempo_espera_um_cilo_e_outro" label={t('EquipamentoTempoEntreUmCicloEOutro')} width={isMobile ? '50%' : '25%'} />
            <InputField type="number" name="tempo_espera_purge" label={t('EquipamentoTempoPurge')} width={isMobile ? '50%' : '25%'} />
            <InputField type="number" name="tempo_espera_inicio_leitura" label={t('EquipamentoTempoLeitura')} width={isMobile ? '50%' : '25%'} />
            <InputField type="number" name="tempo_espera_por_comando_enviado" label={t('EquipamentoTempoComandoEnviadoModbus')} width={isMobile ? '50%' : '25%'} />

            {userIsDesenvolvedor() && <>
              <InputCheckInteiro name="ajuste_ppm_automatico" label="Ajuste automático?" />
              <InputField width={isMobile ? '100%' : '25%'} name="ajuste_ppm_automatico_calculo" label="Cálculo ajuste automático" />
            </>}

            {userIsDesenvolvedor() && <ListHeaderSecondary label={t('EquipamentoEditMultiplos')} />}

            {userIsDesenvolvedor() && <Flex mt={1} width={"full"} wrap={"wrap"}>
              <Input bg={"white"} placeholder={t('EquipamentoEditMultiplosDefinir')} onChange={(evt) => setAlterarEmMassa(evt.target.value)} width={isMobile ? '50%' : '25%'} />
              <ButtonAlteraEmMassa />
            </Flex>}
            {userIsDesenvolvedor() && <Text width={"full"}> {t('EquipamentoEditMultiplosDefinirPadraoFosfina', { valor: '-169) * 6.01' })} </Text>}
            {userIsDesenvolvedor() && <Text width={"full"}> {t('EquipamentoEditMultiplosDefinirPadraoOzonio', { valor: '-160) * 9.5' })} </Text>}

            {userIsDesenvolvedor() && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((i) => (
              <InputField key={i} name={`multiplo_p${i}`} label={t('EquipamentoEditMultiplo', { valor: i })} width={isMobile ? '50%' : '10%'} />
            ))}
          </Flex>
          <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('EquipamentoListTituloMin')}</DefaultButton>
          {id && <Button ml={2} colorScheme="blackAlpha" mt={2} onClick={onOpen}>{t('EquipamentoEditÚltimasLeituras')}</Button>}

          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
            <ModalOverlay />
            <ModalContent top={-10}>
              <ModalHeader>
                <ListHeaderSecondary label={t('EquipamentoEditÚltimasLeituras')} />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Table width={"full"} variant="striped" colorScheme="green" size={"sm"}>
                  <Thead>
                    <Tr>
                      <Th>{t('EquipamentoEditUltimasData')}</Th>
                      <Th>{t('EquipamentoEditUltimasFINALIZADO')}</Th>
                      <Th>{t('EquipamentoEditUltimasITENS')}</Th>
                      <Th>{t('EquipamentoEditUltimasAÇÕES')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {equipamento_ciclos && equipamento_ciclos.map((i) => (<Tr key={i.id} cursor={"pointer"}>
                      <Td onClick={() => { setCurrentCiclo(i); onOpenHitorico() }}>
                        {formatData(i.created_at as string, 'dd/MM/yyyy HH:mm:ss')}
                      </Td>
                      <Td onClick={() => { setCurrentCiclo(i); onOpenHitorico() }}>
                        {i.finalizado == '1' ? t('SistemaSim') : t('SistemaNão')}
                      </Td>
                      <Td onClick={() => { setCurrentCiclo(i); onOpenHitorico() }}>
                        {i.itens.length}
                      </Td>
                      <Td>
                        <Button size={"sm"} mr={1} colorScheme="green" onClick={() => { setCurrentCiclo(i); onOpenHitorico() }}><FiEye /></Button>
                        <ConfirmButton confirm={() => dispatch(sistemaActions.equipamentoUltimasLeiturasDeleteRequest({
                          id: i.id as number,
                          equipamento_id: i.equipamento_id,
                        }))}><FiTrash /> </ConfirmButton>
                      </Td>
                    </Tr>))}
                  </Tbody>
                </Table>

                <DefaultButton onClick={onClose} colorScheme="blackAlpha" type="submit" mt={2}>{t('SistemaFechar')}</DefaultButton>

                <Modal closeOnOverlayClick={false} isOpen={isOpenHitorico} onClose={onCloseHitorico} size={"2xl"}>
                  <ModalOverlay />
                  <ModalContent top={-10}>
                    <ModalHeader>
                      <ListHeaderSecondary label={t('DashboardStatusHistórico')} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {currentCiclo && <DashboardCiclo onClose={onCloseHitorico} ciclo={currentCiclo} />}
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Form>
      </Formik>}
    </Layout>
  )
}