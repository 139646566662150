import { useField, Formik, Form } from "formik";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { DefaultButton } from "./DefaultButton";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState } from 'react'
import validateForm from "../../utils/validateForm";
import { FiTrash } from "react-icons/fi";
import { ClienteUnidadeSilo } from "../types/cliente_unidade_silo";
import InputField from "./InputField";
import InputNumber from "./InputNumber";
import { novoClienteUnidadeSilo } from "../data/cliente_unidade_silo";
import InputSelect from "./InputSelect";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import InputImage from "./InputImage";
import { useTranslation } from "react-i18next";

export const ClienteUnidadesSilos = () => {
  const [, { value }, { setValue }] = useField('silos');
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const { t } = useTranslation()

  const headers: TableHeaders<ClienteUnidadeSilo>[] = [
    {
      label: "N°",
      wrapped: false,
      render: (item) => item.numero,
    },
    {
      label: t('ClienteEditUnidadeListNome'),
      wrapped: false,
      render: (item) => item.nome_referencia,
    },
    {
      label: t('ClienteSilosTipo'),
      wrapped: false,
      render: (item) => t(`ClienteSilos${item.tipo_silo}`),
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>}</>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<ClienteUnidadeSilo | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novoClienteUnidadeSilo())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: ClienteUnidadeSilo) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const tamanhoCampo = isMobile ? '100%' : '50%';

  return (
    <>
      <ListHeaderSecondary label={t('ClienteSilosTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNovo')} {t('ClienteSilosTituloMin')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('ClienteSilosTituloMin') : t('SistemaNovo') + ' ' + t('ClienteSilosTituloMin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ nome_referencia: 'required' }, val, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                // const siloExistenteMesmoNumero = value.some((i: ClienteUnidadeSilo) => i.nome_referencia != val.nome_referencia && i.numero == val.numero);

                // if (siloExistenteMesmoNumero) {
                //   mensagemErro(t('ClienteSilosValidacaoIgual'))
                //   return;
                // }

                onSaveModal(val);
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <InputSelect width={tamanhoCampo} name="numero" label={t('ClienteSilosNúmero')}>
                      {Array.from({ length: 150 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                            {i + 1}
                        </option>
                      ))}
                    </InputSelect>
                    <InputField width={tamanhoCampo} name="nome_referencia" label={t('ClienteEditUnidadeListNome')} />
                    <InputSelect width={tamanhoCampo} name="tipo_silo" label={t('ClienteSilosTipo')}>
                      <option value={"Cilindrico"}>{t(`ClienteSilosCilindrico`)}</option>
                      <option value={"Graneleiro"}>{t(`ClienteSilosGraneleiro`)}</option>
                      <option value={"Prédio"}>{t(`ClienteSilosPrédio`)}</option>
                    </InputSelect>
                    <InputNumber width={tamanhoCampo} decimalPlaces={2} label={t('ClienteSilosCapacidade')} name="capacidade" />
                    {values.tipo_silo && <InputNumber width={tamanhoCampo} decimalPlaces={2} label={t('ClienteSilosAltura')} name="altura" />}
                    {(values.tipo_silo == 'Cilindrico') && <InputNumber width={tamanhoCampo} decimalPlaces={2} label={t('ClienteSilosDiâmetro')} name="diametro" />}
                    {(values.tipo_silo == 'Graneleiro' || values.tipo_silo == 'Prédio') && <InputNumber width={tamanhoCampo} decimalPlaces={2} label={t('ClienteSilosLargura')} name="largura" />}
                    {(values.tipo_silo == 'Graneleiro' || values.tipo_silo == 'Prédio') && <InputNumber width={tamanhoCampo} decimalPlaces={2} label={t('ClienteSilosComprimento')} name="comprimento" />}
                    <Flex width={"full"} />
                    <InputImage name="imagem" label={t('ClienteSilosFotoArmazenamento')} />

                    <hr style={{ width: '100%' }} />
                    <InputNumber decimalPlaces={0} type="tel" width={tamanhoCampo} label={t('AvaliacaoInicialValvulasQuantidade')} name="valvulas_quantidade" />
                    <InputNumber decimalPlaces={0} width={tamanhoCampo} label={t('AvaliacaoInicialValvulasDiametro')} name="valvulas_diametro" />
                    <InputField width={tamanhoCampo} label={t('AvaliacaoInicialTipoTransportador')} name="tipo_transporta" />
                    <InputNumber decimalPlaces={0} width={tamanhoCampo} label={t('AvaliacaoInicialQuantidadeAcessosTunel')} name="quantidade_acessos" />
                    <InputNumber decimalPlaces={0} width={tamanhoCampo} label={t('AvaliacaoInicialQuantidadeVentiladores')} name="quantidade_ventiladores" />
                    <InputNumber decimalPlaces={0} width={tamanhoCampo} label={t('AvaliacaoInicialQuantidadeCabosTermometria')} name="quantidade_cabos_termometria" />
                    <InputNumber decimalPlaces={0} width={tamanhoCampo} label={t('AvaliacaoInicialQuantidadeRespiros')} name="quantidade_respiros" />
                    <hr style={{ width: '100%' }} />

                    <DefaultButton type="submit" mt={4}>{t('SistemaSalvar')} {t('ClienteSilosTituloMin')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}