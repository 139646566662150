import { useField, Formik, Form } from "formik";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { DefaultButton } from "./DefaultButton";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect } from 'react'
import validateForm from "../../utils/validateForm";
import { ClienteUnidadeResponsavel } from "../types/cliente_unidade_responsavel";
import InputSelect from "./InputSelect";
import { novoClienteUnidadeResponsavel } from "../data/cliente_unidade_responsavel";
import { RootState } from "../../app/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../recucer";
import { ClienteUnidadesResponsaveisNovo } from "./ClienteUnidadesResponsaveisNovo";
import { FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export const ClienteUnidadesResponsaveis = () => {
  const [, { value }, { setValue }] = useField('responsaveis');

  const responsaveis = useSelector((state: RootState) => state.sistema.responsaveis || []);
  const dispatch = useDispatch();
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  useEffect(() => {
    dispatch(sistemaActions.responsaveisRequest())
  }, [dispatch])

  const { t } = useTranslation()

  const headers: TableHeaders<ClienteUnidadeResponsavel>[] = [
    {
      label: t('ClienteEditInputNome'),
      wrapped: false,
      render: (item) => item.usuario?.name,
    },
    {
      label: t('ClienteEditInputCelular'),
      wrapped: true,
      render: (item) => item.usuario?.celular
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>}</>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<ClienteUnidadeResponsavel | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novoClienteUnidadeResponsavel())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: ClienteUnidadeResponsavel) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push({
        ...form,
        usuario: responsaveis.find((i) => i.id == form.user_id),
      });
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
      usuario: responsaveis.find((i) => i.id == form.user_id),
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  return (
    <>
      <ListHeaderSecondary label={t('ClienteResponsaveisTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNovo')} {t('ClienteResponsaveisTituloMin')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('ClienteResponsaveisTituloMin') : t('SistemaNovo') + ' ' + t('ClienteResponsaveisTituloMin')}>
              <ClienteUnidadesResponsaveisNovo />
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ user_id: 'required' }, val, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveModal(val);
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <InputSelect label={t('ClienteResponsaveisTituloMin')} name="user_id">
                      {responsaveis && responsaveis.map((i) => (
                        <option value={i.id} key={i.id}>{i.name} {i.cliente && `(${i.cliente.nome})`}</option>
                      ))}
                    </InputSelect>

                    <DefaultButton type="submit" mt={4}>{t('SistemaSalvar')} {t('ClienteResponsaveisTituloMin')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}