import { FiSettings } from "react-icons/fi";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../../sistema/recucer";
import { Form, Formik } from "formik";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import InputField from "../../sistema/components/InputField";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DashboardFiltros } from "./DashboardFiltros";
import { useTranslation } from 'react-i18next'

export const DashboardEquipamentoEdit = () => {
  const equipamento = useSelector((state: RootState) => state.sistema.equipamento)
  const user = useSelector((state: RootState) => state.sistema.user)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const userAdmin = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const { t } = useTranslation()

  return (
    <>
      <DefaultButton size={'xs'} onClick={onOpen}><FiSettings />&nbsp;
        {t('DashboardFiltros')}
      </DefaultButton>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardFiltros')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DashboardFiltros onClose={onClose} />

            <Flex mt={6}></Flex>

            {userAdmin() && <> <ListHeaderSecondary label={t('DashboardConfigurações')} />
              {equipamento && <Formik
                initialValues={equipamento}
                enableReinitialize
                onSubmit={(values, { setErrors }) => {
                  console.log("equipamento form", values);
                  dispatch(sistemaActions.saveEquipamentosRequest({
                    ...values,
                    changed: true,
                  }));
                }}
              >
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    <InputField type="number" name="tempo_espera_um_cilo_e_outro" label={t('EquipamentoTempoEntreUmCicloEOutro')} width={'50%'} />
                    <InputField type="number" name="tempo_espera_purge" label={t('EquipamentoTempoPurge')} width={'50%'} />
                    <InputField type="number" name="tempo_espera_inicio_leitura" label={t('EquipamentoTempoLeitura')} width={'50%'} />
                    <InputField type="number" name="tempo_espera_por_comando_enviado" label={t('EquipamentoTempoComandoEnviadoModbus')} width={'50%'} />
                  </Flex>
                  <DefaultButton type="submit">{t('DashboardSalvar')} {t('DashboardEquipamento')}</DefaultButton>
                  <Button colorScheme={"blackAlpha"} ml={2} onClick={onClose}>{t('DashboardFechar')}</Button>
                </Form>
              </Formik>}</>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}