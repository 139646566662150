import { RootState } from "../../app/mainReducer"
import { useDispatch, useSelector } from "react-redux"
import { Form, Formik, useField, useFormikContext } from "formik"
import { AvaliacaoInicialQuestao } from "../../sistema/types/avaliacao_inicial_questao"
import InputRadio from "../../sistema/components/InputRadio"
import { novaLeituraAvaliacaoInicial } from "../../sistema/data/leitura_avaliacao_inicial"
import InputField from "../../sistema/components/InputField"
import { Sinaleiro } from "./Sinaleiro"
import { LeituraAvaliacaoInicial } from "../../sistema/types/leitura_avaliacao_inicial"
import { leituraActions } from "../reducer"
import { LeiturasResponsaveis } from "./LeituraResponsaveis"
import { ReactECharts } from "./ReactECharts"
import { useTranslation } from "react-i18next";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import imagesUrl from "../../utils/imagesUrl"

const avaliacao_silo_teto = imagesUrl + 'armazenamentos/avaliacao_silo_teto.jpg'
const avaliacao_silo_inteiro = imagesUrl + 'armazenamentos/avaliacao_silo_inteiro.jpg'
const avaliacao_silo_corpo = imagesUrl + 'armazenamentos/avaliacao_silo_corpo.jpg'
const avaliacao_silo_base = imagesUrl + 'armazenamentos/avaliacao_silo_base.jpg'

export const tiposRespostasPadrao = [
  {
    key: 'DashboardAvaliacaoInicialConforme',
    value: 1,
  },
  {
    key: 'DashboardAvaliacaoInicialNaoConforme',
    value: 2,
  },
  {
    key: 'DashboardAvaliacaoInicialNaoSeAplica',
    value: 3,
  }
]

export const tiposRespostasSinaleiro = [
  {
    key: 'DashboardAvaliacaoInicialVermelho',
    value: 1,
  },
  {
    key: 'DashboardAvaliacaoInicialAmarelo',
    value: 2,
  },
  {
    key: 'DashboardAvaliacaoInicialVerde',
    value: 3,
  }
]

export const PreparacaoInicialForm = ({ questoesExternas, respostasExternas }: {
  questoesExternas?: {
    sinaleiro: AvaliacaoInicialQuestao[];
    teto: AvaliacaoInicialQuestao[];
    corpo: AvaliacaoInicialQuestao[];
    base: AvaliacaoInicialQuestao[];
  },
  respostasExternas?: LeituraAvaliacaoInicial[];
}) => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const user = useSelector((state: RootState) => state.sistema.user);
  const dispatch = useDispatch();

  const questoes = questoesExternas ? questoesExternas : (dashboard?.avaliacao_inicial_questoes ?? null)
  const questoesRespondidas = (respostasExternas ? respostasExternas : (dashboard?.avaliacao_inicial_questoes_respondidas ?? []))

  const questaoFoiRespondida = (resposta: LeituraAvaliacaoInicial) => {
    return resposta.status > 0 && resposta.data_fim && resposta.data_inicio;
  }

  const { t } = useTranslation();

  const podePreencher = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const SinaleiroForm = ({ name, value }: { name: string; value: number }) => {
    const [, , { setValue }] = useField(name);
    const formik = useFormikContext();

    const onClickSinaleiro = (value: number) => {
      if (!podePreencher()) return;
      setValue(value)
      formik.submitForm()
    }

    const cores = [
      '',
      'red',
      'yellow',
      'green'
    ]

    return (
      <Sinaleiro largura="25px" onClick={onClickSinaleiro} cor={cores[value]} />
    )
  }

  const getTotalFeito = (origem: string) => {
    const dados: AvaliacaoInicialQuestao[] = (questoes as any)[origem];

    if (!dados) return;

    const total = dados.length;
    let respondidas = 0;

    dados.forEach((i) => {
      const questaoRespondida = questoesRespondidas.find((j) => j.avaliacao_inicial_questao_id == i.id && j.leitura_id == dashboard?.last_leitura.id);

      if (questaoRespondida && questaoRespondida.data_fim && questaoRespondida.data_inicio && questaoRespondida.status > 0) {
        respondidas += 1;
      }
    })

    return parseFloat(((respondidas * 100) / total).toFixed(2));
  }

  const BlocoQuestoes = ({ questoes, imagemSilo, label, origem }: { questoes: AvaliacaoInicialQuestao[]; imagemSilo: string; mostraPreparacaoTotal?: boolean; label?: string; origem?: string }) => {

    interface QuestoesFormatadas extends AvaliacaoInicialQuestao {
      resposta: LeituraAvaliacaoInicial;
    }

    const questoesFormatadas: QuestoesFormatadas[] = questoes.map((i) => {
      const questaoRespondida = questoesRespondidas.find((j) => j.avaliacao_inicial_questao_id == i.id);

      return {
        ...i,
        resposta: questaoRespondida ? questaoRespondida as any : {
          ...novaLeituraAvaliacaoInicial() as any,
          avaliacao_inicial_questao_id: i.id,
          leitura_id: dashboard?.last_leitura?.id,
        },
      }
    })


    const ButtonResponsaveis = ({ index, count }: { index: number; count: number }) => {
      const { isOpen: isOpenPessoas, onOpen: onOpenPessoas, onClose: onClosePessoas } = useDisclosure()

      return (
        <>
          <DefaultButton onClick={onOpenPessoas} size={"xs"}>{t('DashboardAvaliacaoInicialResponsaveis')} ({count})</DefaultButton>

          <Modal autoFocus={false} returnFocusOnClose={false} isOpen={isOpenPessoas} onClose={onClosePessoas}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isOpenPessoas && <LeiturasResponsaveis name={`questoes[${index}].resposta.pessoas`} />}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blackAlpha" onClick={onClosePessoas}>{t('SistemaVoltar')}</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </>
      )
    }

    const gaugeData = [
      {
        value: getTotalFeito('teto'),
        name: t('AvaliacaoInicialQuestaoEditTeto'),
        title: {
          offsetCenter: ['0%', '-30%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '-15%']
        }
      },
      {
        value: getTotalFeito('corpo'),
        name: t('AvaliacaoInicialQuestaoEditCorpo'),
        title: {
          offsetCenter: ['0%', '0%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '13%']
        }
      },
      {
        value: getTotalFeito('base'),
        name: t('AvaliacaoInicialQuestaoEditBase'),
        title: {
          offsetCenter: ['0%', '27%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '40%']
        }
      }
    ];

    const graficoGeralData = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#464646'
            }
          },
          axisLine: {
            lineStyle: {
              width: 50
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: gaugeData,
          title: {
            fontSize: 14
          },
          detail: {
            width: 30,
            height: 8,
            fontSize: 10,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}%',
          }
        }
      ]
    };

    return (
      <>
        <Flex width={"full"} mt={6}></Flex>
        {label && <ListHeaderSecondary label={label} />}
        <Flex width={"full"} wrap={"wrap-reverse"}>
          <Flex width={isMobile ? "100%" : questoes[0].e_sinaleiro == 1 ? "40%" : "50%"} py={2} top={-10} wrap={"wrap"}>
            <img style={{ margin: 'auto' }} width={isMobile ? '50%' : "100%"} height={"auto"} src={imagemSilo} />
          </Flex>
          <Flex alignItems={"center"} width={isMobile ? "100%" : questoes[0].e_sinaleiro == 1 ? "60%" : "50%"} py={2}>
            {questoes[0].e_sinaleiro == 1 ? <>
              <ReactECharts option={graficoGeralData as any} style={{ height: '400px' }} />
            </> : <>
              <ReactECharts option={{
                series: [
                  {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                      show: false
                    },
                    progress: {
                      show: true,
                      overlap: false,
                      roundCap: true,
                      clip: false,
                      itemStyle: {
                        borderWidth: 1,
                        borderColor: '#464646'
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        width: 20
                      }
                    },
                    splitLine: {
                      show: false,
                      distance: 0,
                      length: 10
                    },
                    axisTick: {
                      show: false
                    },
                    axisLabel: {
                      show: false,
                      distance: 50
                    },
                    data: [
                      {
                        value: getTotalFeito(origem as any),
                        name: origem == 'teto' ? t('AvaliacaoInicialQuestaoEditTeto') : origem == 'corpo' ? t('AvaliacaoInicialQuestaoEditCorpo') : t('AvaliacaoInicialQuestaoEditBase'),
                        title: {
                          offsetCenter: ['0%', '-18%']
                        },
                        detail: {
                          valueAnimation: true,
                          offsetCenter: ['0%', '10%']
                        }
                      },
                    ],
                    title: {
                      fontSize: 14
                    },
                    detail: {
                      width: 30,
                      height: 8,
                      fontSize: 10,
                      color: 'inherit',
                      borderColor: 'inherit',
                      borderRadius: 20,
                      borderWidth: 1,
                      formatter: '{value}%',
                    }
                  }
                ]
              } as any
              } style={{ height: '200px' }} />
            </>}
          </Flex>
        </Flex>
        <Formik
          initialValues={{
            questoes: questoesFormatadas
          }}
          enableReinitialize
          onSubmit={(values, { setErrors }) => {
            console.log("questao form", values);

            const itens: LeituraAvaliacaoInicial[] = [];

            values.questoes.forEach((i) => {
              itens.push({
                ...i.resposta,
                changed: true,
              });
            })

            dispatch(leituraActions.saveAvaliacaoInicial(itens));
          }}
        >
          {({ values, submitForm }) => (
            <Form>
              {values.questoes[0].e_sinaleiro == 0 && values.questoes.map((i, index) => (
                <Flex key={`${index}`} width={"full"} direction={"column"}>
                  <Box bg={questaoFoiRespondida(i.resposta) ? 'green.100' : 'white'} my={1} p={2} borderWidth="1px" borderRadius="lg" boxShadow="md">
                    <Flex width={"100%"} wrap={"wrap"}>
                      <Flex width={isMobile ? '100%' : '50%'} direction={"column"}>
                        <Text fontSize="xl" fontWeight={600}>{t(i.nome_questao)}</Text>
                        <InputRadio isDisabled={!podePreencher()} name={`questoes[${index}].resposta.status`}>
                          <Stack spacing={2}>
                            {tiposRespostasPadrao.map((option, index) => (
                              <Radio key={index} value={(option.value as any).toFixed(0)}>
                                {t(option.key)}
                              </Radio>
                            ))}
                          </Stack>
                        </InputRadio>
                        <Flex bottom={0} mt={isMobile ? 2 : 4}>
                          <ButtonResponsaveis count={i.resposta.pessoas.filter((i) => !i.deleted).length} index={index} />
                        </Flex>

                      </Flex>
                      <Flex width={isMobile ? '100%' : '50%'} direction={"column"}>
                        <InputField disabled={!podePreencher()} type="datetime-local" label={t('DashboardAvaliacaoInicialDataInicio')} height={"20px"} name={`questoes[${index}].resposta.data_inicio`} />
                        <InputField disabled={!podePreencher()} type="datetime-local" label={t('DashboardAvaliacaoInicialDataFim')} name={`questoes[${index}].resposta.data_fim`} />
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              ))}


              {values.questoes[0].e_sinaleiro == 1 ? <>
                <Flex mt={4} justifyContent={"space-around"} width={"full"} wrap={"wrap"} px={isMobile ? '0px' : '100px'}>
                  {values.questoes.map((i, index) => (
                    <Flex key={`${index}`} width={"33%"} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                      <SinaleiroForm value={i.resposta.status} name={`questoes[${index}].resposta.status`} />
                      <Text textAlign={"center"} fontSize={"12px"}>
                        {t(i.nome_questao)}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </> :
                <Flex width={"full"}>
                  {podePreencher() && <DefaultButton width={"full"} type="submit">{t('DashboardSalvar')}</DefaultButton>}
                </Flex>
              }
            </Form>
          )}
        </Formik>
      </>
    )
  }

  return (
    <>
      {questoes && <BlocoQuestoes questoes={questoes.sinaleiro} imagemSilo={avaliacao_silo_inteiro} />}
      {questoes && <BlocoQuestoes origem="teto" questoes={questoes.teto} imagemSilo={avaliacao_silo_teto} label={t('AvaliacaoInicialQuestaoEditTeto')} />}
      {questoes && <BlocoQuestoes origem="corpo" questoes={questoes.corpo} imagemSilo={avaliacao_silo_corpo} label={t('AvaliacaoInicialQuestaoEditCorpo')} />}
      {questoes && <BlocoQuestoes origem="base" questoes={questoes.base} imagemSilo={avaliacao_silo_base} label={t('AvaliacaoInicialQuestaoEditBase')} />}
    </>
  )
}