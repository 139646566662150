import { Box, Button, ButtonGroup } from "@chakra-ui/react"
import { ApiPaginationResWithoutData } from "../types/apiPaginationRes"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import config from "../../../config";

interface IProps {
  info: ApiPaginationResWithoutData;
  paginate: (val: number) => void;
}

export const Pagination = ({ info, paginate }: IProps) => {
  return (
    <>
      {info.last_page > 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <ButtonGroup isAttached variant="solid">
            {info.current_page > 1 && (<Button
              leftIcon={<FiArrowLeft />}
              onClick={() => paginate(info.current_page - 1)}
            >
              Anterior
            </Button>)}
            {[...Array(info.last_page)].map((_, index) => (
              <Button
                key={index}
                onClick={() => paginate(index + 1)}
                bg={info.current_page === index + 1 ? config.defaultColor : ""}
                color={info.current_page === index + 1 ? 'white' : 'black'}
                _hover={info.current_page === index + 1 ? {
                  backgroundColor: config.defaultColor,
                  color: 'white'
                } : {}}
              >
                {index + 1}
              </Button>
            ))}
            {info.current_page < info.last_page && (<Button
              rightIcon={<FiArrowRight />}
              onClick={() => paginate(info.current_page + 1)}
            >
              Próxima
            </Button>)}
          </ButtonGroup>
        </Box>

      )}
    </>

  )
}
