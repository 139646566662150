import { Flex, Text } from "@chakra-ui/react";
import { Form, Formik } from 'formik';
import { DefaultButton } from "./DefaultButton";
import InputField from "./InputField";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface IProps {
  onSubmit: (val: string) => void;
  label: string;
}


export const ListInput = ({ onSubmit, label }: IProps) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{ termo: "" }}
      onSubmit={(values) => {
        onSubmit(values.termo);
      }}
    >
      <Form>
        <Flex my={4} width={{ base: 'full', md: '60%' }}>
          <InputField noPadding={true} placeholder={label} noBorder={true} name="termo" type="text" width="full" />

          <DefaultButton type="submit" borderRadius="0px 10px 10px 0"><FiSearch fontSize="30px" /> <Text ml={2}>{t('DashboardFiltrar')}</Text></DefaultButton>
        </Flex>
      </Form>
    </Formik>
  )
}