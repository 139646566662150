import { Box, Flex, Heading, Img, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { ReactECharts } from "./ReactECharts";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { corFosfina, corOzonio } from "./DashboardLegendas";
import { useState, useEffect } from 'react'
import { DashboardNomeLeitura } from "./DashboardNomeLeitura";
import { useTranslation } from 'react-i18next'
import { DashboardLegendaCores } from "./DashboardLegendaCores";
import { getDados } from "./DashboardGraficoLinha";
import { Leitura } from "../types/leitura";
import { LeituraDados } from "../types/leitura_dados";
import imagesUrl from "../../utils/imagesUrl";
const rostoAmarelo = imagesUrl + "rostoAmarelo.png";
const rostoVerdeEscuro = imagesUrl + "rostoVerdeEscuro.png";
const rostoVermelho = imagesUrl + "rostoVermelho.png";

export const DashboardGraficoPizza = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const { t } = useTranslation()

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const getPontosAtivos = () => {
    let somaPontos = 0;
    let qtPontos = 0;

    if (!dashboard?.sensores || !dashboard?.last_data) {
      return {
        somaPontos,
        qtPontos,
      }
    }

    const last_data: any = dashboard.last_data;

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        somaPontos += parseFloat(last_data[`c${i}`]);
        qtPontos += 1;

      }
    }

    return {
      somaPontos,
      qtPontos,
    }
  }

  const getData = (): number => {
    if (!dashboard?.last_data) {
      return 0;
    }

    return parseInt((getPontosAtivos().somaPontos / getPontosAtivos().qtPontos).toFixed(0));
  }

  const getDataTotal = () => {
    const retorno: { data: string; media: number; id: number }[] = [];

    for (const leitura of (dashboard?.data ?? [])) {
      let somaPontos = 0;
      let qtPontos = 0;

      for (let i = 1; i <= 16; i++) {
        if (temOPontoConfigurado(i)) {
          somaPontos += parseFloat((leitura as any)[`c${i}`]);
          qtPontos += 1;
        }
      }

      retorno.push({
        data: leitura.data,
        media: somaPontos / qtPontos,
        id: leitura.id as number
      })
    }
    retorno.reverse()
    return retorno;
  }

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const getColorMedia = (valor: number) => {
    if (!eFosfina) {
      if (valor <= 40) {
        return corOzonio[0];
      }

      if (valor > 40 && valor <= 50) {
        return corOzonio[1];
      }

      if (valor > 50 && valor <= 60) {
        return corOzonio[2];
      }

      if (valor > 60 && valor <= 80) {
        return corOzonio[3];
      }

      return corOzonio[4];
    }

    if (valor < 400) {
      return corFosfina[0];
    }

    if (valor >= 400 && valor < 1200) {
      return corFosfina[1]
    }

    if (valor >= 1200 && valor < 2000) {
      return corFosfina[2]
    }

    if (valor >= 2000 && valor <= 3000) {
      return corFosfina[3]
    }

    if (valor >= 3001 && valor <= 4000) {
      return corFosfina[4]
    }

    return corFosfina[5]
  }

  const legendaPizza = [
    {
      descricao: `${t('DashboardMenorQue')} 40%`,
      imagem: rostoVermelho,
    },
    {
      descricao: `${t('GraficoPizaLegenda', { v1: '40', v2: '80' })}`,
      imagem: rostoAmarelo,
    },
    {
      descricao: `${t('DashboardMaiorQue')} 80%`,
      imagem: rostoVerdeEscuro,
    }
  ]

  const getColor = (valor: number) => {
    if (valor <= 40) {
      return corFosfina[0];
    }

    if (valor > 40 && valor <= 80) {
      return corFosfina[1];
    }

    // if (valor > 60 && valor <= 80) {
    //   return corFosfina[3];
    // }

    return corFosfina[2];
  }

  const [rangeEficiencia, setRangeEficiencia] = useState(-1);

  useEffect(() => {
    if (dashboard?.last_leitura?.range_eficiencia) {
      setRangeEficiencia(dashboard?.last_leitura?.range_eficiencia)
    }
  }, [dashboard?.last_leitura?.range_eficiencia])

  useEffect(() => {
    if (rangeEficiencia === -1 && dashboard?.last_leitura?.range_eficiencia) {
      setRangeEficiencia(dashboard?.last_leitura?.range_eficiencia)
    }
  }, [dashboard?.last_leitura?.range_eficiencia, rangeEficiencia])

  const getLeitura = (origem: string, dinamico = false): number => {
    if (!dashboard?.last_data) return 0;

    let last_data = dashboard.last_data as any;

    if (dinamico && idSelecionado != -1) {
      if (dashboard.data.find((i) => i.id == idSelecionado)) {
        last_data = dashboard.data.find((i) => i.id == idSelecionado);
      }
    }

    const valor = (parseFloat(last_data[origem] ?? 0));

    const porcentagem = (100 * valor) / (rangeEficiencia ?? 0);

    return porcentagem > 100 ? 100 : parseInt(porcentagem.toFixed(0));
  }

  const [idSelecionado, setidSelecionado] = useState(-1);

  const getMediaSelecionada = () => {
    if (!dashboard?.data) return 0;

    const data = getDataTotal().find((i) => i.id == idSelecionado);

    if (!data) return 0;

    return data.media;
  }

  useEffect(() => {
    if (idSelecionado == -1 && dashboard?.last_data) {
      setidSelecionado(dashboard.last_data.id as number)
    }
  }, [idSelecionado, dashboard?.last_data])

  const sensoresAtivos = (dinamico = false): any => {
    let grafico: any = [];
    let qt = 0;
    let valorTotal = 0;

    if (!dashboard?.sensores) {
      return {
        grafico,
        qt,
        valorTotal,
      }
    }

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        const valorLeitura = getLeitura(`c${i}`, dinamico);

        grafico.push(
          { value: valorLeitura, name: `${t('DashboardPonto')} ${i}`, itemStyle: { color: getColor(valorLeitura) } }
        )
        qt += 1;
        valorTotal += valorLeitura;
      }
    }


    return {
      grafico,
      qt,
      valorTotal,
    }
  }

  const getEficienciaGeral = (dinamico = false) => {
    const dados = sensoresAtivos(dinamico);

    const porcentagemMedia = parseInt((dados.valorTotal / dados.qt).toFixed(0));

    if (porcentagemMedia > 100) return 100;

    if (!porcentagemMedia) return 0;

    return porcentagemMedia ?? 0;
  }

  const data: any = {
    backgroundColor: 'transparent',
    title: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      valueFormatter: (value: string) => `${parseFloat(value)}%`,
    },
    visualMap: {
      show: false,
    },
    series: [
      {
        name: `${t('DashboardEficiência')} (%) - ${t('DashboardReferência')} ${rangeEficiencia ?? 0}ppm`,
        type: 'pie',
        radius: '90%',
        center: ['50%', '50%'],
        data: sensoresAtivos().grafico.sort(function (a: any, b: any) {
          return a.value - b.value;
        }),
        roseType: 'radius',
        label: {
          color: 'black'
        },
        labelLine: {
          lineStyle: {
            color: 'black'
          },
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        itemStyle: {
          color: '#c23531',
          shadowBlur: 30,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx: any) {
          return Math.random() * 200;
        }
      }
    ]
  };

  const data2: any = {
    backgroundColor: 'transparent',
    title: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      valueFormatter: (value: string) => `${parseFloat(value)}%`,
    },
    visualMap: {
      show: false,
    },
    series: [
      {
        name: `${t('DashboardEficiência')} (%) - ${t('DashboardReferência')} ${rangeEficiencia ?? 0}ppm`,
        type: 'pie',
        radius: '90%',
        center: ['50%', '50%'],
        data: sensoresAtivos(true).grafico.sort(function (a: any, b: any) {
          return a.value - b.value;
        }),
        roseType: 'radius',
        label: {
          color: 'black'
        },
        labelLine: {
          lineStyle: {
            color: 'black'
          },
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        itemStyle: {
          color: '#c23531',
          shadowBlur: 30,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx: any) {
          return Math.random() * 200;
        }
      }
    ]
  };

  const { isOpen, onOpen, onClose } = useDisclosure()

  const leituraDados = getDados(dashboard?.data ?? [], dashboard?.last_leitura as Leitura);

  const getMediaPontos = (dado: LeituraDados) => {
    if (!dado) return 0;

    let somaTotal = 0;
    let qt = 0;
    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        qt++;
        somaTotal += (dado as any)[`c${i}`] ?? 0;
      }
    }

    return somaTotal / qt;
  }

  return (
    <Box width={isMobile ? "100%" : "30%"} position={"relative"} p={1} minH={"280px"}>
      <Box bg={"white"} borderRadius={25} p={1} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} height={"100%"}>
        <Box display={isMobile ? '' : 'flex'}>
          <Box position={"relative"} borderRadius={"15px"} bg={"white"} id="dashboard-eficiencia" width={"100%"}>
            <DefaultButton className="no-print" size={"xs"} mb={isMobile ? 2 : 0} onClick={onOpen} ml={isMobile ? 1 : 4} mt={isMobile ? 8 : 3}>{t('DashboardExpandir')}</DefaultButton>
            <Flex top={isMobile ? "45px" : "35px"} position={"absolute"} left={"50%"} transform={"translateX(-50%)"} zIndex={1}>
              <DashboardLegendaCores mx="0px" />
            </Flex>
            <Flex position={"absolute"} left={"50%"} top={4} transform={"translateX(-50%)"} zIndex={1}>
              <Text pl={isMobile ? 2 : 0} fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardEficiência')} <DashboardNomeLeitura /></Text>
            </Flex>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"3xl"}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <ListHeaderSecondary label={t('DashboardEficiência')}>
                    <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
                  </ListHeaderSecondary>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody my={2}>
                  <Box mx={"auto"} mb={4}>
                    <DashboardLegendaCores fontSize={isMobile ? '8px' : "15px"} mx={isMobile ? '0' : '2px'} />
                  </Box>
                  <Flex width={"full"} wrap={'wrap'} alignItems={"center"} justifyContent={"center"}>
                    <Flex width={isMobile ? '100%' : '40%'} px={"40px"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                      <Flex boxShadow="lg" py={2} px={6} textAlign={"center"} bg={getColorMedia(getMediaSelecionada())} direction={"column"} borderRadius={25}>
                        <Text fontSize="1.1rem" color="white" fontWeight="400">{t('DashboardMédia')}: {getMediaSelecionada().toFixed(0)}ppm</Text>
                        <Heading color={"white"} size={"md"} mb={4}> {t('DashboardEficiência')} {getEficienciaGeral(true)}% </Heading>
                      </Flex>


                      <Text> {t('DashboardReferência')} (ppm): </Text>
                      <Input color={"black"} type="number" bg={"white"} onChange={(evt) => setRangeEficiencia(typeof parseInt(evt.target.value) === 'number' ? parseInt(evt.target.value) : 0)} value={rangeEficiencia} />


                      <Text mt={2}>{t('DashboardStatusLeitura')}: </Text>
                      <Select value={idSelecionado.toString()} onChange={(evt) => setidSelecionado(parseFloat(evt.target.value))}>
                        {leituraDados.map((i) => (
                          <option value={i.id?.toString()} key={`${i.id}`}>{i.hora} h - {t('DashboardMédia')}: {getMediaPontos(i).toFixed(0)}ppm </option>
                        ))}
                      </Select>
                    </Flex>
                    <Flex width={isMobile ? '100%' : '60%'} direction={"column"}>
                      <Flex width={'100%'} wrap={'wrap'}>
                        <ReactECharts option={data2} style={{ height: '250px' }} />
                      </Flex>

                      <Flex justifyContent={"center"} width={'100%'} wrap={'wrap'}>
                        {legendaPizza.map((i, indexKey) => (
                          <Flex key={indexKey} p={2} wrap={"wrap"} gap={2} direction={"column"} justifyContent={"center"}>
                            <Img mx={"auto"} src={i.imagem} maxW={"30px"} height={"auto"} />
                            <small>
                              {i.descricao}
                            </small>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Flex width={"full"} mt={6} justifyContent={"center"}>
              <Flex boxShadow="lg" py={2} px={6} textAlign={"center"} bg={getColorMedia(getData())} direction={"column"} borderRadius={25}>
                <Text fontSize="0.8rem" color="white" fontWeight="400">{t('DashboardMediaUltimaLeitura')}: {getData()}ppm</Text>
                <Text fontSize="1.1rem" color="white" fontWeight={"700"}>{t('DashboardEficiência')} {getEficienciaGeral()}%</Text>
                <Text fontSize="0.6rem" color="white" fontWeight="700"> {t('DashboardReferência')}: <Input color={"black"} type="number" bg={"white"} width={"70px"} colorScheme="blackAlpha" size={"xs"} onChange={(evt) => setRangeEficiencia(typeof parseInt(evt.target.value) === 'number' ? parseInt(evt.target.value) : 0)} value={rangeEficiencia} /> ppm</Text>
              </Flex>
            </Flex>

            <ReactECharts option={data} style={{ height: '190px' }} />
          </Box>

        </Box>
      </Box>
    </Box>
  )
}