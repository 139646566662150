import { Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../recucer";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { DefaultButton } from "./DefaultButton";
import { UsuarioUnidade } from "../types/user_unidades";
import { useTranslation } from "react-i18next";

interface IProps {
  user_id: string
}

export const UserUnidades = ({ user_id }: IProps) => {
  const [getUnidades, setGetUnidades] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const usuarios_unidades = useSelector((state: RootState) => state.sistema.usuarios_unidades);
  const { t } = useTranslation();

  useEffect(() => {
    if (!getUnidades) return;

    dispatch(sistemaActions.usuariosUnidadesRequest(user_id))
    onOpen();
    setGetUnidades(false);
  }, [dispatch, getUnidades, onOpen, user_id])

  const headers: TableHeaders<UsuarioUnidade>[] = [
    {
      label: t('UsuarioUnidadesNome'),
      wrapped: false,
      render: (item) => item.cliente_nome,
      notClicable: true,
    },
    {
      label: t('UsuarioUnidadesNomeUnidade'),
      wrapped: false,
      render: (item) => item.unidade_nome,
      notClicable: true,
    },
    {
      label: t('UsuarioUnidadesCPFCNPJUnidade'),
      wrapped: false,
      render: (item) => item.unidade_cpf_cnpj,
      notClicable: true,
    },
    {
      label: t('UsuarioUnidadesUnidadeAtiva'),
      wrapped: false,
      render: (item) => item.unidade_ativa.trim() == 'Sim' ? t('SistemaSim') : t('SistemaNão'),
      notClicable: true,
    },
    {
      label: t('SistemaAções'),
      wrapped: false,
      render: (item, index) => <DefaultButton onClick={() => window.open(`/clientes/${item.cliente_id}`, '_blank')}>
        {t('SistemaVer')}
      </DefaultButton>,
      notClicable: true,
    },
  ];

  return (
    <>
      <Button ml={2} colorScheme="blackAlpha" onClick={() => setGetUnidades(true)}>{t('UsuarioUnidadesTitulo')}</Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={t('UsuarioUnidadesTitulo')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {usuarios_unidades && <ResponsiveTable isSmall headers={headers} data={usuarios_unidades} />}
            {usuarios_unidades && usuarios_unidades.length === 0 && <Heading size={"md"} ml={2} mt={6}>{t('UsuarioUnidadesNenhumaEncontrada')}</Heading>}
          </ModalBody>
          <ModalFooter>
            <Button ml={2} mt={2} colorScheme='blackAlpha' onClick={onClose}>{t('SistemaFechar')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}