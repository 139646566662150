import { getCurrentData } from "../../utils/data";
import { LeituraAplicacao } from "../types/leitura_aplicacao";

export const novaLeituraAplicacao = (tipo = "Aplicação"): LeituraAplicacao => {
  return {
    tipo,
    leitura_id: 0,
    data_inicio: getCurrentData(),
    data_fim: "",
    quantidade: 0,
  }
}