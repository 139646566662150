import './DashboardInsetos.css';
import { Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../sistema/components/DefaultButton';
import { DashboardVerMais } from './DashboardVerMais';
import { ListHeaderSecondary } from '../../sistema/components/ListHeaderSecondary';
import ReactSpeedometer from 'react-d3-speedometer';
import { useState } from 'react';

export const DashboardInsetos = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras);


  const getIsBlocked = (hours: number, ppm: number) => {
    if (ppm == 400) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_400 ?? '0')) > hours;
    }

    if (ppm == 500) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_500 ?? '0')) > hours;
    }

    if (ppm == 600) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_600 ?? '0')) > hours;
    }

    if (ppm == 700) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_700 ?? '0')) > hours;
    }

    if (ppm == 800) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_800 ?? '0')) > hours;
    }
    return false;
  }

  const getAllTime = (ppm: number) => {
    if (ppm == 400) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_400 ?? '0'));
    }

    if (ppm == 500) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_500 ?? '0'));
    }

    if (ppm == 600) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_600 ?? '0'));
    }

    if (ppm == 700) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_700 ?? '0'));
    }

    if (ppm == 800) {
      return (parseFloat(dashboard?.dashboard_leituras?.tempo_exposicao_800 ?? '0'));
    }

    return 0;
  }

  const { t, i18n } = useTranslation();

  const getDescricaoDetalhada = (form: any) => {
    if (i18n.language == 'de') {
      return form.descricaoHtmlAlemao
    }
    if (i18n.language == 'es') {
      return form.descricaoHtmlEspanhol
    }
    if (i18n.language == 'en') {
      return form.descricaoHtmlIngles
    }
    return form.descricaoHtml
  }

  const insetos = (dashboard?.leituraDadosSelectsInputs?.insetos ?? []).map((i) => ({
    nome: i.nome,
    imagem: i.imagem,
    ppm: i.ppm_mortalidade,
    ppmText: `> ${i.ppm_mortalidade}ppm`,
    hoursMin: i.horas_mortalidade,
    hoursText: `> ${i.horas_mortalidade}h`,
    isBlocked: getIsBlocked(i.horas_mortalidade, i.ppm_mortalidade),
    imagem_grande: i.imagem_grande == 1,
    tipo: i.tipo,
    tipo_nome: t(`InsetosEditTipo${i.tipo}`),
    mostraTitulo: i.mostra_titulo,
    allTime: getAllTime(i.ppm_mortalidade),
    descricaoHtml: i.descricao_html,
    descricaoHtmlAlemao: i.descricao_html_alemao,
    descricaoHtmlEspanhol: i.descricao_html_espanhol,
    descricaoHtmlIngles: i.descricao_html_ingles,
    resistencia: i.resistencia,
    larvaimagem: i.larvaimagem,
    larva_horas_mortalidade: i.larva_horas_mortalidade,
    larva_horas_mortalidade_text: `> ${i.larva_horas_mortalidade}h`,
    larva_ppm_mortalidade: i.larva_ppm_mortalidade,
    larva_ppm_mortalidade_text: `> ${i.larva_ppm_mortalidade}ppm`,
    larva_allTime: getAllTime(i.larva_ppm_mortalidade),
    larva_isBlocked: getIsBlocked(i.larva_horas_mortalidade, i.larva_ppm_mortalidade),
  }))


  const { isOpen, onOpen, onClose } = useDisclosure()


  const getCustomSegmentStops = (item: any) => {
    const maxValue = item.allTime;
    const hoursMin = item.hoursMin;

    return [0, hoursMin - 30, hoursMin, maxValue < hoursMin ? hoursMin + 50 : maxValue + 50];
  }

  const getCustomSegmentStopsLarva = (item: any) => {
    const maxValue = item.larva_allTime;
    const hoursMin = item.larva_horas_mortalidade;

    return [0, hoursMin - 30, hoursMin, maxValue < hoursMin ? hoursMin + 50 : maxValue + 50];
  }

  const InsetoView = ({ i, onOpen }: { i: any; onOpen: () => void; }) => {

    return (
      <>
        <Flex pb={4} onClick={() => onOpen()} bg={'#f3f3f3'} _hover={{ backgroundColor: '#d3d3d3' }} borderRadius={'15px'} cursor={"pointer"} direction={"column"} alignItems={"center"} width={"full"} position={"relative"} px={1}>
          <Flex alignItems={"center"} height={'140px'}>
            <img style={{ margin: 'auto' }} width={"35%"} height={"auto"} src={i.imagem} />
            {parseInt(i.hoursMin - i.allTime as any) <= 0 && <div style={{ margin: 'auto' }} className={'pare-maior'}></div>}
          </Flex>
          <Box height={'60px'} width={"100%"} textAlign={"center"}>
            <Text fontSize={"12px"} fontWeight={"800"}><b>{i.nome}</b></Text>
            <Text fontSize={"12px"}><b>{i.ppmText.replace('>', t('InsetosEditMaiorQue'))}</b></Text>
            <Text fontSize={"12px"}><b>{i.hoursText.replace('>', t('InsetosEditMinimoDe'))}</b></Text>
          </Box>
          <Box position={"relative"} textAlign={"center"}>
            <ReactSpeedometer
              value={i.allTime}
              minValue={0}
              maxValue={getCustomSegmentStops(i)[getCustomSegmentStops(i).length - 1]}
              needleColor="#888"
              startColor={'red'}
              endColor={'green'}
              segments={4}
              needleHeightRatio={0.6}
              needleTransitionDuration={1000}
              customSegmentStops={getCustomSegmentStops(i)}
              segmentColors={['#C34A36', '#F9F871', '#00C9A7']}
              height={100}
              width={140}
              ringWidth={30}
              labelFontSize={isMobile ? "10" : "6"}
              valueTextFontSize="0"
              customSegmentLabels={[
                {
                  text: t('DashboardVivo'),
                  position: 'OUTSIDE',
                  color: '#555',
                },
                {
                  text: '',
                  position: 'OUTSIDE',
                  color: '#555',
                  fontSize: '19px',
                },
                {
                  text: t('DashboardMorto'),
                  position: 'OUTSIDE',
                  color: '#555',
                },
              ] as any}
            />
            <Text mx={"auto"} fontSize={(window?.innerWidth ?? 0) >= 1920 ? '16px' : "12px"} fontWeight={600}> {parseInt(i.hoursMin - i.allTime as any) <= 0 ? t('InsetosEditParaMorto') : `${parseInt(i.hoursMin - i.allTime as any)}h ${t('InsetosEditParaMorrer')}`} </Text>
          </Box>

          {!!i.larvaimagem && <>
            <Box position={"relative"} textAlign={"center"}>
              <Flex alignItems={"center"} height={'140px'}>
                <img style={{ margin: 'auto' }} width={"65%"} height={"auto"} src={i.larvaimagem} />
                {parseInt(i.larva_horas_mortalidade - i.larva_allTime as any) <= 0 && <div style={{ margin: 'auto' }} className={'pare-maior'}></div>}
              </Flex>
              <Box height={'60px'} width={"100%"} textAlign={"center"}>
                <Text fontSize={"12px"} fontWeight={"800"}><b> {t('DashboardLarvaPupaOvos')} </b></Text>
                <Text fontSize={"12px"}><b>{i.larva_ppm_mortalidade_text.replace('>', t('InsetosEditMaiorQue'))}</b></Text>
                <Text fontSize={"12px"}><b>{i.larva_horas_mortalidade_text.replace('>', t('InsetosEditMinimoDe'))}</b></Text>
              </Box>

              <ReactSpeedometer
                value={i.larva_allTime}
                minValue={0}
                maxValue={getCustomSegmentStopsLarva(i)[getCustomSegmentStopsLarva(i).length - 1]}
                needleColor="#888"
                startColor={'red'}
                endColor={'green'}
                segments={4}
                needleHeightRatio={0.6}
                needleTransitionDuration={1000}
                customSegmentStops={getCustomSegmentStopsLarva(i)}
                segmentColors={['#C34A36', '#F9F871', '#00C9A7']}
                height={100}
                width={140}
                ringWidth={30}
                labelFontSize={isMobile ? "10" : "6"}
                valueTextFontSize="0"
                customSegmentLabels={[
                  {
                    text: t('DashboardVivo'),
                    position: 'OUTSIDE',
                    color: '#555',
                  },
                  {
                    text: '',
                    position: 'OUTSIDE',
                    color: '#555',
                    fontSize: '19px',
                  },
                  {
                    text: t('DashboardMorto'),
                    position: 'OUTSIDE',
                    color: '#555',
                  },
                ] as any}
              />
              <Text fontSize={(window?.innerWidth ?? 0) >= 1920 ? '16px' : "12px"} mx={"auto"} fontWeight={600}> {parseInt(i.larva_horas_mortalidade - i.larva_allTime as any) <= 0 ? t('InsetosEditParaMorto') : `${parseInt(i.larva_horas_mortalidade - i.larva_allTime as any)}h ${t('InsetosEditParaMorrer')}`} </Text>
            </Box>
          </>}
        </Flex>
      </>
    )
  }

  const [currentItem, setCurrentItem] = useState<any>(null);
  const { isOpen: isOpenDescricao, onOpen: onOpenDescricao, onClose: onCloseDescricao } = useDisclosure()

  return (
    <Flex id='dashboard-mortalidade-insetos' boxShadow={isMobile ? '' : "0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"10px"} mb={4} mt={2} px={4} width={"full"} wrap={"wrap"} justifyContent={"center"}>
      <Text mb={2} fontWeight={700} fontSize={"16px"}>{t('DashboardMortalidadeDosInsetos')}</Text>

      <Flex width={"full"} wrap={"wrap"}>
        {insetos.filter((i) => i.resistencia.trim() == 'menor').map((i, index) => (
          <Flex alignItems={"center"} position={"relative"} my={2} px={1} width={i.imagem_grande ? '100%' : "50%"} key={`${index}`}>
            <img width={"25%"} height={"auto"} src={i.imagem} />
            <Box width={"75%"} textAlign={"center"}>
              <Text fontSize={"10px"}><b>{i.nome}</b></Text>
              {/* <Text fontSize={"10px"}><b>{i.ppmText}</b></Text>
              <Text fontSize={"10px"}><b>{i.hoursText}</b></Text> */}
            </Box>
            {i.isBlocked && <div className={isMobile ? 'pare2' : 'pare'}></div>}
          </Flex>
        ))}
      </Flex>

      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenDescricao} onClose={onCloseDescricao} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentItem && <ListHeaderSecondary label={currentItem.nome}>
              <DefaultButton onClick={onCloseDescricao} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"}>
              {currentItem && <img style={{ margin: 'auto' }} width={"15%"} height={"auto"} src={currentItem.imagem} />}
            </Flex>
            <Flex width={"full"} />
            <Box px={6} my={2}>
              {currentItem && <div dangerouslySetInnerHTML={{ __html: getDescricaoDetalhada(currentItem) }} />}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            <Button colorScheme='blackAlpha' onClick={() => { window.open('https://api.ecozonedobrasil.com/storage/reports/fonteinsetos.pdf', '_blank') }} ml={2}>{t('DashboardFonte')}</Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"}>

              <Flex pr={isMobile ? 0 : 2} width={isMobile ? '100%' : '50%'} wrap={"wrap"} justifyContent={"initial"} alignItems={"start"}>
                <Box width={"full"}>
                  <ListHeaderSecondary label={`${t('DashboardMortalidadeDosInsetos')} - ${t('InsetosEditResistenciamenor')}`} />
                </Box>
                <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"}>
                  <Heading mx={"auto"} color={"#404244"} size={"md"}> {t(`InsetosEditTipo1`)}</Heading>
                  <Flex width={"full"} wrap={"wrap"} justifyContent={"center"} alignItems={"initial"}>
                    {insetos.filter((i) => i.tipo == 1 && i.resistencia == "menor").map((i, index) => {
                      return (
                        <Flex alignItems={"initial"} key={`${index}`} wrap={"wrap"} p={"2px"} width={i.imagem_grande ? '100%' : (isMobile ? '50%' : `${100 / 3}%`)}>
                          <InsetoView onOpen={() => { setCurrentItem(i); onOpenDescricao() }} i={i} />
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>

                <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"}>
                  <Heading color={"#404244"} mx={"auto"} size={"md"}> {t(`InsetosEditTipo2`)}</Heading>
                  <Flex width={"full"} wrap={"wrap"} justifyContent={"center"}>
                    {insetos.filter((i) => i.tipo == 2 && i.resistencia == "menor").map((i, index) => {
                      return (
                        <Flex key={`${index}`} wrap={"wrap"} p={"2px"} justifyContent={"center"} alignItems={"center"} width={i.imagem_grande ? '100%' : (isMobile ? '50%' : `${100 / 3}%`)}>
                          <InsetoView onOpen={() => { setCurrentItem(i); onOpenDescricao() }} i={i} />
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>
              </Flex>

              <Flex pl={isMobile ? 0 : 2} width={isMobile ? '100%' : '50%'} wrap={"wrap"} justifyContent={"initial"} alignItems={"start"}>
                <Box width={"full"}>
                  <ListHeaderSecondary label={`${t('DashboardMortalidadeDosInsetos')} - ${t('InsetosEditResistenciamaior')}`} />
                </Box>
                <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"}>
                  <Heading mx={"auto"} color={"#404244"} size={"md"}> {t(`InsetosEditTipo1`)}</Heading>
                  <Flex width={"full"} wrap={"wrap"} justifyContent={"center"} alignItems={"initial"}>
                    {insetos.filter((i) => i.tipo == 1 && i.resistencia == "maior").map((i, index) => {
                      return (
                        <Flex alignItems={"initial"} key={`${index}`} wrap={"wrap"} p={"2px"} width={i.imagem_grande ? '100%' : (isMobile ? '50%' : `${100 / 3}%`)}>
                          <InsetoView onOpen={() => { setCurrentItem(i); onOpenDescricao() }} i={i} />
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>

                <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"}>
                  <Heading color={"#404244"} mx={"auto"} size={"md"}> {t(`InsetosEditTipo2`)}</Heading>
                  <Flex width={"full"} wrap={"wrap"} justifyContent={"center"}>
                    {insetos.filter((i) => i.tipo == 2 && i.resistencia == "maior").map((i, index) => {
                      return (
                        <Flex key={`${index}`} wrap={"wrap"} p={"2px"} justifyContent={"center"} alignItems={"center"} width={i.imagem_grande ? '100%' : (isMobile ? '50%' : `${100 / 3}%`)}>
                          <InsetoView onOpen={() => { setCurrentItem(i); onOpenDescricao() }} i={i} />
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>



          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex mx="auto" mb={1}>
        <DashboardVerMais onOpen={onOpen} />
      </Flex>
    </Flex>
  )
}