import { ClienteUnidadeSilo } from "../types/cliente_unidade_silo";

export const novoClienteUnidadeSilo = (): ClienteUnidadeSilo => {
  return {
    cliente_unidade_id: "",
    nome_referencia: "",
    altura: "0",
    diametro: "0",
    largura: "0",
    comprimento: "0",
    tipo_silo: "",
    capacidade: "0",
    numero: 1,
    imagem: "",
    valvulas_quantidade: 0,
    valvulas_diametro: 0,
    tipo_transporta: "",
    quantidade_acessos: 0,
    quantidade_ventiladores: 0,
    quantidade_cabos_termometria: 0,
    quantidade_respiros: 0,
  };
};
