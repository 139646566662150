import { RadioGroup, RadioGroupProps } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

type InputRadioProps = RadioGroupProps & {
  name: string;
};

const InputRadio: React.FC<InputRadioProps> = ({
  size: _,
  children,
  ...props
}) => {
  const [field, {value}, { setValue }] = useField(props.name);
  return (
    <RadioGroup
      {...field}
      {...props}
      onChange={(val) => {
        setValue(parseInt(val));
      }}
      value={value.toString()}
      id={field.name}
    >
      {children}
    </RadioGroup>
  );
};

export default InputRadio;