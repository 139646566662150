import { Box, Flex, Img, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { leituraActions } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useEffect } from "react";
import { differenceInHours } from "date-fns";
import { formatData } from "../../utils/data";
import imagesUrl from "../../utils/imagesUrl";
const corpoSilo = imagesUrl + 'armazenamentos/avaliacao_silo_corpo.png'
const Sitophilus = imagesUrl + "sitophilus.png"
const Rhyzopertha = imagesUrl + "rhyzopertia.png"
const Tribollium = imagesUrl + "tribolium.png"
const Oryzaephilus = imagesUrl + "oryzaephilus.png"
const Cryptolestes = imagesUrl + "cryptolestes.png"
const avaliacao_silo_inteiro = imagesUrl + 'armazenamentos/avaliacao_silo_inteiro.jpg'
const avaliacao_silo_corpo = imagesUrl + 'armazenamentos/avaliacao_silo_corpo.jpg'
const avaliacao_silo_base = imagesUrl + 'armazenamentos/avaliacao_silo_base.jpg'
const avaliacao_silo_teto = imagesUrl + 'armazenamentos/avaliacao_silo_teto.jpg'

export const AvaliacaoInicialStatus = ({ buscaQuestoes = false }: {buscaQuestoes?: boolean}) => {
  const { t } = useTranslation();
  const avaliacaoInicialStatus = useSelector((state: RootState) => state.leituras.avaliacaoInicialStatus);
  const isLoading = useSelector((state: RootState) => state.leituras.isLoadigAvaliacaoStatus);
  const internerOn = useSelector((state: RootState) => state.sistema.networkOnline);
  const dispatch = useDispatch();

  const statuses = [
    {
      label: `${t('ClientesListTitulo')} / ${t('DashboardDadosDoSilo')}`,
    },
    {
      label: t('DashboardPdfLeituras'),
    },
  ];

  const onBuscarDados = () => {
    dispatch(leituraActions.leituraDadosSelectsInputs())
    if (buscaQuestoes) {
      dispatch(leituraActions.preparacaoInicialDados())
    }
  }

  useEffect(() => {
    if (!avaliacaoInicialStatus || internerOn) {
      dispatch(leituraActions.leituraDadosSelectsInputs())
      if (buscaQuestoes) {
        dispatch(leituraActions.preparacaoInicialDados())
      }
      return;
    } else {
      // 24 horas de cache
      if (differenceInHours(new Date(avaliacaoInicialStatus.last_update), new Date()) >= 24) {
        dispatch(leituraActions.leituraDadosSelectsInputs())
        if (buscaQuestoes) {
          dispatch(leituraActions.preparacaoInicialDados())
        }
      }
    }
  }, [avaliacaoInicialStatus, dispatch, buscaQuestoes])

  const getStatusCor = () => {
    if (!avaliacaoInicialStatus?.last_update) {
      return 'vermelho';
    }

    if (differenceInHours(new Date(avaliacaoInicialStatus.last_update), new Date()) >= 24) {
      return 'vermelho';
    }

    return 'verde'
  };

  function CirculoSinalizacao({ cor }: { cor: string }) {
    // Estilo comum para todos os círculos
    const estiloCirculo = {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: "18px",
    };

    // Estilo condicional com base na cor passada como prop
    const estiloCor = {
      backgroundColor: cor === "verde" ? "green" : "red",
      color: "white",
    };

    return (
      <Box {...estiloCirculo} {...estiloCor}>
      </Box>
    );
  }

  return (
    <Flex mt={2} bg={"white"} p={4} maxW={"500px"} mx={"auto"} wrap={"wrap"} borderRadius={"25px"}>
      <Flex width={"full"} direction={"column"}>
        {statuses.map((i, index) => (
          <Flex key={`${index}`} width={"full"} wrap={"wrap"} alignItems={"center"}>
            <CirculoSinalizacao cor={getStatusCor()} /> &nbsp;
            {i.label}
          </Flex>
        ))}
      </Flex>

      {internerOn && <DefaultButton size={"xs"} mx={"auto"} isLoading={isLoading} onClick={onBuscarDados}>
        {t('AvaliacaoInicialBuscarDados')}
      </DefaultButton>}

      <Text textAlign={"center"} fontSize={"10px"} mx={"auto"} mt={1}>
        {t('AvaliacaoInicialBuscarDadosInfo')} {avaliacaoInicialStatus?.last_update ? <>{t('AvaliacaoInicialBuscarDadosInfoUltimaAtualizacaoEm', { value: formatData(avaliacaoInicialStatus.last_update) })}</> : <>{t('AvaliacaoInicialBuscarDadosInfoCliqueEmBuscarDados')} </>}
      </Text>

      <Flex width={"full"} justifyContent={"space-between"}>
        <Img src={corpoSilo} width={"0.1px"} height={"0.1px"} />
        <Img src={Sitophilus} width={"0.1px"} height={"0.1px"} />
        <Img src={Rhyzopertha} width={"0.1px"} height={"0.1px"} />
        <Img src={Tribollium} width={"0.1px"} height={"0.1px"} />
        <Img src={Oryzaephilus} width={"0.1px"} height={"0.1px"} />
        <Img src={Cryptolestes} width={"0.1px"} height={"0.1px"} />
        <Img src={avaliacao_silo_inteiro} width={"0.1px"} height={"0.1px"} />
        <Img src={avaliacao_silo_corpo} width={"0.1px"} height={"0.1px"} />
        <Img src={avaliacao_silo_base} width={"0.1px"} height={"0.1px"} />
        <Img src={avaliacao_silo_teto} width={"0.1px"} height={"0.1px"} />
      </Flex>
    </Flex>
  )
}