import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { IDadosPreparacaoInicial, leituraActions } from "./reducer";
import { novaLeitura } from "./data/leitura";
import { Leitura } from "./types/leitura";
import { ListPayload } from "../sistema/types/listPayload";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { LeituraDadosSelectsInputs } from "./types/leitura_dados_selects_inputs";
import { DashboardLeitura } from "./types/dashboard_leitura";
import { DashboardFiltro } from "./types/dashboard_filtro";
import { store } from "../app/mainReducer";
import { EquipamentoCiclo } from "../sistema/types/equipamento_ciclo";
import { LeituraAmostra } from "./types/leitura_amostra";
import { LeituraAplicacao } from "./types/leitura_aplicacao";
import { LeituraAvaliacaoInicial } from "../sistema/types/leitura_avaliacao_inicial";
import { LeituraInjecao } from "./types/leitura_injecao";
import { AvaliacaoInicial } from "./types/avaliacao_inicial";
import { novaAvaliacaoInicial } from "./data/avaliacao_inicial";
import { PreparacaoInicial } from "./types/preparacao_inicial";
import { novaPreparacaoInicial } from "./data/preparacao_inicia";

function* saveLeituraRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<Leitura> = yield call(apiCall, {
      url: `leituras?include=dados,sensores,responsaveis,responsaveis.usuario`,
      method: "post",
      data: payload,
    });
    let data: any = {
      ...res.data,
      finalizado: (res.data.finalizado as any) == "1" ? true : false,
      e_leitura_de_6_silos: (res.data.e_leitura_de_6_silos as any) == "1" ? true : false,
      e_leitura_de_silo_cilindrico_mas_com_preparacao_predio: (res.data.e_leitura_de_silo_cilindrico_mas_com_preparacao_predio as any) == "1" ? true : false,
    };
    for (const field of Object.keys(data)) {
      if (data[field] === null) {
        data[field] = "";
      }
    }
    yield put(
      leituraActions.saveLeituraRequestSuccess(data)
    );
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* deleteLeituraRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `leituras/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(leituraActions.deleteLeituraRequestSuccess());
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* leiturasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Leitura[]>> = yield call(
      apiCall,
      {
        url: `leituras?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(leituraActions.leiturasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* leituraRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(leituraActions.leituraRequestSuccess(novaLeitura()));
      return;
    }

    const res: AxiosResponse<Leitura> = yield call(apiCall, {
      url: `leituras/${payload}?include=dados,sensores,responsaveis,responsaveis.usuario`,
      method: "get",
      data: payload,
    });
    let data: any = {
      ...res.data,
      finalizado: (res.data.finalizado as any) == "1" ? true : false,
      e_leitura_de_6_silos: (res.data.e_leitura_de_6_silos as any) == "1" ? true : false,
      e_leitura_de_silo_cilindrico_mas_com_preparacao_predio: (res.data.e_leitura_de_silo_cilindrico_mas_com_preparacao_predio as any) == "1" ? true : false,
    };
    for (const field of Object.keys(data)) {
      if (data[field] === null) {
        data[field] = "";
      }
    }
    yield put(
      leituraActions.leituraRequestSuccess(data)
    );
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* leituraDadosSelectsInputsWorker() {
  try {
    const res: AxiosResponse<LeituraDadosSelectsInputs> = yield call(apiCall, {
      url: `leituras-dados-selects-inputs`,
      method: "get",
    });
    yield put(leituraActions.leituraDadosSelectsInputsSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* preparacaoInicialDadosWorker() {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `preparacao-inicial-questoes-formatadas/novo`,
      method: "get",
    });
    yield put(leituraActions.preparacaoInicialDadosSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* dashboardLeiturasRequestWorker({
  payload,
}: PayloadAction<DashboardFiltro>) {
  const filtro = store.getState().leituras.filtros_atuais;

  try {
    const res: AxiosResponse<DashboardLeitura> = yield call(apiCall, {
      url: `dashboard-leituras?data_inicial=${
        filtro?.data_inicial
      }&hora_inicial=${filtro?.hora_inicial}&data_final=${
        filtro?.data_final
      }&hora_final=${filtro?.hora_final}&cliente_id=${
        filtro?.cliente_id
      }&cliente_unidade_id=${
        filtro?.cliente_unidade_id
      }&cliente_unidade_silo_id=${
        filtro?.cliente_unidade_silo_id
      }&equipamento_id=${filtro?.equipamento_id}&somente_em_aberto=${
        filtro?.somente_em_aberto
      }&ordem_servico=${filtro?.ordem_servico ?? ""}&leitura_id=${
        filtro?.leitura_id ?? ""
      }&leitura_token=${filtro?.leitura_token ?? ""}`,
      
      method: "get",
    });
    yield put(leituraActions.dashboardLeiturasRequestSuccess(res.data));
    yield put(
      leituraActions.leiturasInjecaoRequestSuccess(
        res.data.last_leitura?.injecoes ?? []
      )
    );
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* equipamentoCicleRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<EquipamentoCiclo> = yield call(apiCall, {
      url: `dashboard-leituras-ciclo-atual?mac=${payload}`,
      method: "get",
    });
    yield put(leituraActions.equipamentoCicleRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* leiturasAmostrasSaveRequestWorker({
  payload,
}: PayloadAction<LeituraAmostra>) {
  try {
    const res: AxiosResponse<LeituraAmostra> = yield call(apiCall, {
      url: `leituras-amostras`,
      method: "post",
      data: payload,
    });
    yield put(leituraActions.leiturasAmostrasSaveRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* startHermeticidadeRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<{ data: string }> = yield call(apiCall, {
      url: `starta-hermeticidade/${payload}`,
      method: "put",
      data: payload,
    });
    yield put(leituraActions.startHermeticidadeRequestSuccess(res.data.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* saveAvaliacaoInicialWorker({
  payload,
}: PayloadAction<LeituraAvaliacaoInicial[]>) {
  try {
    const res: AxiosResponse<LeituraAvaliacaoInicial[]> = yield call(apiCall, {
      url: `leitura-avaliacao-inicial`,
      method: "post",
      data: {
        respostas: payload,
      },
    });
    yield put(leituraActions.saveAvaliacaoInicialSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* leiturasAplicacoesSaveRequestWorker({
  payload,
}: PayloadAction<LeituraAplicacao>) {
  try {
    const res: AxiosResponse<LeituraAplicacao> = yield call(apiCall, {
      url: `leituras-aplicacoes`,
      method: "post",
      data: payload,
    });
    yield put(leituraActions.leiturasAplicacoesSaveRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* saveLeituraInjecaoRequestWorker({
  payload,
}: PayloadAction<LeituraInjecao>) {
  try {
    const res: AxiosResponse<LeituraInjecao> = yield call(apiCall, {
      url: `leituras-injecoes`,
      method: "post",
      data: payload,
    });
    yield put(leituraActions.saveLeituraInjecaoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* avaliacoesIniciaisRequestWorker({
  payload,
}: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<AvaliacaoInicial[]>> = yield call(
      apiCall,
      {
        url: `avaliacoes-iniciais?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(leituraActions.avaliacoesIniciaisRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* avaliacaoInicialRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(
        leituraActions.avaliacaoInicialRequestSuccess(novaAvaliacaoInicial())
      );
      return;
    }

    const avaliacoesTemporarias =
      store.getState().leituras.avaliacoesParaEnviar ?? [];

    const avaliacaoEncontrada = avaliacoesTemporarias.find(
      (i) => i.tempId == payload
    );

    if (avaliacaoEncontrada) {
      yield put(
        leituraActions.avaliacaoInicialRequestSuccess(avaliacaoEncontrada)
      );
      return;
    }

    const res: AxiosResponse<AvaliacaoInicial> = yield call(apiCall, {
      url: `avaliacoes-iniciais/${payload}?include=silo,responsaveis,responsaveis.usuario,cliente,unidade,leitura,imagens,insetos,insetos_final,assinaturas`,
      method: "get",
      data: payload,
    });
    yield put(leituraActions.avaliacaoInicialRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(error));
  }
}

function* deleteAvaliacaoInicialRequestWorker({
  payload,
}: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `avaliacoes-iniciais/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(leituraActions.deleteAvaliacaoInicialRequestSuccess());
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* saveAvaliacaoInicialRequestWorker({
  payload,
}: PayloadAction<AvaliacaoInicial>) {
  try {
    const res: AxiosResponse<AvaliacaoInicial> = yield call(apiCall, {
      url: `avaliacoes-iniciais?include=silo,responsaveis,responsaveis.usuario,cliente,unidade,leitura,imagens,insetos,insetos_final,assinaturas`,
      method: "post",
      data: {
        ...payload,
        silo: !payload.silo
          ? null
          : {
              ...payload.silo,
              store_belongs_to: true,
              changed: true,
            },
      },
    });
    yield put(
      leituraActions.saveAvaliacaoInicialRequestSuccess({
        avaliacaoAntiga: payload,
        avaliacaoNova: res.data,
      })
    );
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* preparacoesIniciaisRequestWorker({
  payload,
}: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<PreparacaoInicial[]>> =
      yield call(apiCall, {
        url: `preparacoes?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      });
    yield put(leituraActions.preparacoesIniciaisRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* preparacaoInicialRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      const dados = store.getState().leituras.dadosPreparacaoInicial;

      yield put(
        leituraActions.preparacaoInicialRequestSuccess(
          novaPreparacaoInicial({ questoesFormatadas: dados as any })
        )
      );
      return;
    }

    const res: AxiosResponse<PreparacaoInicial> = yield call(apiCall, {
      url: `preparacoes/${payload}?include=cliente,unidade&append=questoesFormatadas`,
      method: "get",
      data: payload,
    });
    yield put(leituraActions.preparacaoInicialRequestSuccess(res.data));
  } catch (error: any) {
    yield put(leituraActions.error(error));
  }
}

function* deletePreparacaoInicialWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `preparacoes/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(leituraActions.deletePreparacaoInicialSuccess(payload));
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

function* savePreparacaoInicialWorker({
  payload,
}: PayloadAction<PreparacaoInicial>) {
  try {
    const res: AxiosResponse<PreparacaoInicial> = yield call(apiCall, {
      url: `preparacoes?include=cliente,unidade&append=questoesFormatadas`,
      method: "post",
      data: payload,
    });
    yield put(
      leituraActions.savePreparacaoInicialSuccess({
        avaliacaoAntiga: payload,
        avaliacaoNova: res.data,
      })
    );
  } catch (error: any) {
    yield put(leituraActions.error(formatError(error)));
  }
}

export function* leiturasSaga() {
  yield all([
    takeLatest(
      "leituras/leiturasAplicacoesSaveRequest",
      leiturasAplicacoesSaveRequestWorker
    ),
  ]);
  yield all([takeLatest("leituras/leiturasRequest", leiturasRequestWorker)]);
  yield all([takeLatest("leituras/leituraRequest", leituraRequestWorker)]);
  yield all([
    takeLatest("leituras/saveLeituraRequest", saveLeituraRequestWorker),
  ]);
  yield all([
    takeLatest("leituras/deleteLeituraRequest", deleteLeituraRequestWorker),
  ]);
  yield all([
    takeLatest(
      "leituras/leituraDadosSelectsInputs",
      leituraDadosSelectsInputsWorker
    ),
  ]);
  yield all([
    takeLatest(
      "leituras/dashboardLeiturasRequest",
      dashboardLeiturasRequestWorker
    ),
  ]);
  yield all([
    takeLatest(
      "leituras/equipamentoCicleRequest",
      equipamentoCicleRequestWorker
    ),
  ]);
  yield all([
    takeLatest(
      "leituras/leiturasAmostrasSaveRequest",
      leiturasAmostrasSaveRequestWorker
    ),
  ]);
  yield all([
    takeLatest(
      "leituras/startHermeticidadeRequest",
      startHermeticidadeRequestWorker
    ),
    takeLatest("leituras/saveAvaliacaoInicial", saveAvaliacaoInicialWorker),
    takeLatest(
      "leituras/saveLeituraInjecaoRequest",
      saveLeituraInjecaoRequestWorker
    ),
    takeLatest(
      "leituras/avaliacoesIniciaisRequest",
      avaliacoesIniciaisRequestWorker
    ),
    takeLatest(
      "leituras/avaliacaoInicialRequest",
      avaliacaoInicialRequestWorker
    ),
    takeLatest(
      "leituras/saveAvaliacaoInicialRequest",
      saveAvaliacaoInicialRequestWorker
    ),
    takeLatest(
      "leituras/deleteAvaliacaoInicialRequest",
      deleteAvaliacaoInicialRequestWorker
    ),
    takeLatest("leituras/preparacaoInicialDados", preparacaoInicialDadosWorker),
    takeLatest(
      "leituras/preparacoesIniciaisRequest",
      preparacoesIniciaisRequestWorker
    ),
    takeLatest(
      "leituras/preparacaoInicialRequest",
      preparacaoInicialRequestWorker
    ),
    takeLatest("leituras/savePreparacaoInicial", savePreparacaoInicialWorker),
    takeLatest(
      "leituras/deletePreparacaoInicial",
      deletePreparacaoInicialWorker
    ),
  ]);
}
