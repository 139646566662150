export const tipoQuestao = () => {
  return [
    {
      name: "AvaliacaoInicialQuestaoEditSinaleiroInicial",
      value: 1,
    },
    {
      name: "AvaliacaoInicialQuestaoEditTeto",
      value: 2,
    },
    {
      name: "AvaliacaoInicialQuestaoEditCorpo",
      value: 3,
    },
    {
      name: "AvaliacaoInicialQuestaoEditBase",
      value: 4,
    },
    {
      name: "AvaliacaoInicialQuestaoEditPredio",
      value: 5,
    }
  ];
};
