import { Box, Flex } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { DashboardUltimaLeitura } from "./DashboardUltimaLeitura";
import { DashboardConcentracaoMedia } from "./DashboardConcentracaoMedia";
import { DashboardSemaforo } from "./DashboardSemaforo";
import { DashboardHermeticidade } from "./DashboardHermeticidade";
import { DashboardAmostras } from "./DashboardAmostras";
import { DashboardEficienciaReducao } from "./DashboardEficienciaReducao";
import { DashboardPontosLeitura } from "./DashboardPontosLeitura";

export const DashboardTopo = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  interface IProps {
    children: any;
    width: string;
    id?: string;
    paddingBottom?: number;
  }

  const Item = ({ children, width, id, paddingBottom = 1 }: IProps) => {
    return (
      <Box id={id} width={width} p={1}>
        <Box bg={"white"} borderRadius={25} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} pb={paddingBottom}>
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Flex width={"full"} p={0} wrap={"wrap"}>
      </Flex>
      <Flex width={"full"} p={0} wrap={"wrap"}>
        <Item width={isMobile ? "100%" : "42%"}>
          <DashboardUltimaLeitura />
        </Item>
        <Flex wrap={"wrap"} width={isMobile ? "100%" : "42%"} id="dashboard-ultima-leitura">
          <Item width="100%">
            <DashboardPontosLeitura />
          </Item>
        </Flex>
        <Flex wrap={"wrap"} width={isMobile ? "100%" : "16%"} id="dashboard-bloco-concentracao">
          <Item width="100%">
            <DashboardConcentracaoMedia />
          </Item>
          {eFosfina && <Item width="100%">
            <DashboardSemaforo />
          </Item>}
          {!eFosfina && <Item width="100%">
            <DashboardEficienciaReducao />
          </Item>}
          <Item width="100%" paddingBottom={eFosfina && isMobile ? 10 : 0}>
            {eFosfina && <DashboardHermeticidade />}
            {!eFosfina && isMobile && <DashboardAmostras />}
          </Item>
        </Flex>
        {/* <Flex direction={"column"} width={isMobile ? "100%" : "26%"}> */}
        {/* <Flex wrap={"wrap"} width={"100%"}>
            <Item width="70%">
              <DashboardTempoExposicao />
            </Item>
            <Item width="30%">
              <DashboardEquipamentoEdit />
            </Item>
          </Flex> */}
        {/* {isMobile && eFosfina && <Flex wrap={"wrap"} width={"100%"}>
            <Item width="100%">
              <DashboardAplicacao />
            </Item>
          </Flex>}
          <Item width="100%"> */}

        {/* </Item> */}
        {/* </Flex> */}
      </Flex>
    </>
  )
}
