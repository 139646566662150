import { User } from "../types/user";

export const novoUser = (): User => {
  return {
    name: "",
    email: "",
    password: "",
    celular: "",
    ativo: true,
    administrador: false,
    e_desenvolvedor: false,
    e_operador: false,
    cliente_id: "",
    usuario_externo: 0,
  }
}