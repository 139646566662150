import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Badge, Flex } from "@chakra-ui/react";

export const DashboardLegendaCores = ({ mx = '2px', fontSize = '8px' }: { mx?: string; fontSize?: string }) => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const corFosfina = [
    '#FF6347',
    '#FFD700',
    '#00FF7F',
    '#4169E1',
    '#845EC2',
    'red'
  ]

  const corOzonio = [
    '#FF6347',
    '#FFD700',
    '#00FF7F',
    '#4169E1',
    '#8a2be2',
  ]

  const escalaFosfina = [
    '0 - 399',
    '400 - 1199',
    '1200 - 1999',
    '2000 - 3000',
    '3001 - 4000',
    '4001 - 5000',
  ]

  const escalaOzonio = [
    '0 - 40',
    '41 - 50',
    '51 - 60',
    '61 - 80',
    '> 81',
  ]

  const cores = eFosfina ? corFosfina : corOzonio;
  const escala = eFosfina ? escalaFosfina : escalaOzonio;

  return (
    <>
      {!isMobile && <Flex width={"100%"} p={2} px={6} justifyContent={"space-between"} zIndex={999}>
        <Badge fontSize={fontSize} mx={mx} bg={cores[0]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}> {escala[0]}</Flex></Badge>
        <Badge fontSize={fontSize} mx={mx} bg={cores[1]} color={"black"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[1]}</Flex></Badge>
        <Badge fontSize={fontSize} mx={mx} bg={cores[2]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[2]}</Flex></Badge>
        <Badge fontSize={fontSize} mx={mx} bg={cores[3]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[3]}</Flex></Badge>
        {cores[4] && <Badge mx={mx} fontSize={fontSize} bg={cores[4]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[4]}</Flex></Badge>}
        {cores[5] && <Badge mx={mx} fontSize={fontSize} bg={cores[5]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[5]}</Flex></Badge>}
      </Flex>}
      {isMobile && <Flex width={"100%"} p={4} justifyContent={"space-between"}>
        <Badge bg={cores[0]} mx={mx} fontSize={fontSize} color={"white"} py={1} textAlign={"center"} borderRadius={3}>{escala[0]}</Badge>
        <Badge bg={cores[1]} mx={mx} fontSize={fontSize} color={"black"} py={1} textAlign={"center"} borderRadius={3}>{escala[1]}</Badge>
        <Badge bg={cores[2]} mx={mx} fontSize={fontSize} color={"white"} py={1} textAlign={"center"} borderRadius={3}>{escala[2]}</Badge>
        <Badge bg={cores[3]} mx={mx} fontSize={fontSize} color={"white"} py={1} textAlign={"center"} borderRadius={3}>{escala[3]}</Badge>
        {cores[4] && <Badge mx={mx} bg={cores[4]} fontSize={fontSize} color={"white"} py={1} textAlign={"center"} borderRadius={3}>{escala[4]}</Badge>}
        {cores[5] && <Badge mx={mx} fontSize={fontSize} bg={cores[5]} color={"white"} px={2} borderRadius={3}><Flex alignItems={"center"} height={"full"}>{escala[5]}</Flex></Badge>}
      </Flex>}
    </>
  )
}