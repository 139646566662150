import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { Box, Button, Flex, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { ReactECharts } from "./ReactECharts";
import { EChartsOption } from "echarts";
import ReactSpeedometer from "react-d3-speedometer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { FiCheck, FiEye, FiInfo, FiPlay } from "react-icons/fi";
import config from "../../../config";
import { leituraActions } from "../reducer";
import { formatData } from "../../utils/data";
import { useTranslation } from 'react-i18next';
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import { getBgColor } from "./Silo";
import { useState } from "react";
import { LeituraDadosSelectsInputs } from "../types/leitura_dados_selects_inputs";
import { getDadosInjetado } from "./DashboardUltimaLeitura";
import { formatValor } from "../../utils/formatValor";
import imagesUrl from "../../utils/imagesUrl";

const rostoAmarelo = imagesUrl + "rostoAmarelo.png";
const rostoLaranja = imagesUrl + "rostoLaranja.png";
const rostoVerdeClaro = imagesUrl + "rostoVerdeClaro.png";
const rostoVerdeEscuro = imagesUrl + "rostoVerdeEscuro.png";
const rostoVermelho = imagesUrl + "rostoVermelho.png";
const siloPontoCentral = imagesUrl + "armazenamentos/silo_ponto_central.png";

export const DashboardHermeticidade = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const { t } = useTranslation();

  const hermeticidades = dashboard?.hermeticidades ?? {
    '0h': 0,
    '6h': 0,
    '12h': 0,
    '18h': 0,
    '24h': 0,
    '30h': 0,
    '36h': 0,
    '42h': 0,
    '48h': 0,
    '54h': 0,
    '60h': 0,
    '66h': 0,
    '72h': 0,
    '78h': 0,
    '84h': 0,
    '90h': 0,
    '96h': 0,
    'atual': 0,
  }

  const hexVermelho = '#d53a23'
  const hexLaranja = '#f78208'
  const hexAmarelo = '#facd11'
  const hexVerde = '#b0cc05'
  const hexVerdeEscuro = '#5ebc0d'

  const getCor = (valor: number) => {
    if (valor <= 19) {
      return hexVerdeEscuro;
    }

    if (valor <= 49) {
      return hexVerde;
    }

    if (valor <= 59) {
      return hexAmarelo;
    }

    if (valor <= 69) {
      return hexLaranja;
    }

    return hexVermelho;
  }

  const getDescricao = (valor: number) => {
    if (valor <= 19) {
      return t('DashboardHermeticidade5Titulo') + ` (${t('DashboardHermeticidade5Descricao')})`;
    }

    if (valor <= 49) {
      return t('DashboardHermeticidade4Titulo') + ` (${t('DashboardHermeticidade4Descricao')})`;
    }

    if (valor <= 59) {
      return t('DashboardHermeticidade3Titulo') + ` (${t('DashboardHermeticidade3Descricao')})`;
    }

    if (valor <= 69) {
      return t('DashboardHermeticidade2Titulo') + ` (${t('DashboardHermeticidade2Descricao')})`;
    }

    return t('DashboardHermeticidade1Titulo') + ` (${t('DashboardHermeticidade1Descricao')})`;
  }

  const horasHermeticidade = [
    0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96
  ]

  const getLabelsGrafico = () => {
    return horasHermeticidade.map((i) => `${i}H`)
  }

  const getPorcentagem = (valorMaior: number, valorMenor: number) => {
    return 100 - ((valorMenor * 100) / valorMaior)
  }

  const getDataGrafico = () => {


    const retorno = [];

    for (const hermeticidade of horasHermeticidade) {
      if (hermeticidade == 0) {
        retorno.push({
          value: hermeticidades["0h"],
          itemStyle: {
            color: 'cyan'
          }
        })
        continue;
      }

      retorno.push({
        value: (hermeticidades as any)[`${hermeticidade}h`],
        itemStyle: {
          color: getCor(getPorcentagem((hermeticidades as any)[`0h`], (hermeticidades as any)[`${hermeticidade}h`])),
        }
      })
    }
    return retorno;
  }

  const data: EChartsOption = {
    backgroundColor: 'transparent',
    title: {
      show: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '1%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: getLabelsGrafico(),
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: t('DashbordConcentracao') + ' (ppm)',
        type: 'bar',
        barWidth: '80%',
        data: getDataGrafico(),
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 200;
        },
      }
    ]
  };

  const imgSize = isMobile ? '20px' : "13px";

  const getHermeticidadeDados = () => {
    for (const hermeticidade of [96, 90, 84, 78, 72, 66, 60, 54, 48, 42, 36, 30, 24, 18, 12, 6]) {
      if ((hermeticidades as any)[`${hermeticidade}h`] > 0) {
        return {
          inicio: (hermeticidades as any)[`0h`],
          fim: (hermeticidades as any)[`${hermeticidade}h`],
        }
      }
    }

    return {
      inicio: 0,
      fim: 0,
    }
  }

  const getHermeticidadeAtualPpm = () => {
    for (const hermeticidade of [96, 90, 84, 78, 72, 66, 60, 54, 48, 42, 36, 30, 24, 18, 12, 6]) {
      if ((hermeticidades as any)[`${hermeticidade}h`] > 0) {
        return (hermeticidades as any)[`0h`] - (hermeticidades as any)[`${hermeticidade}h`]
      }
    }

    return 0;
  }

  const getHermeticidadeAtual = () => {
    const dados = getHermeticidadeDados();
    if (dados.inicio == 0) return 0;
    const porcentagem = (dados.fim * 100) / dados.inicio;

    // for (const hermeticidade of [96, 90, 84, 78, 72, 66, 60, 54, 48, 42, 36, 30, 24, 18, 12, 6]) {
    //   if ((hermeticidades as any)[`${hermeticidade}h`] > 0) {
    //     return (hermeticidades as any)[`0h`] - (hermeticidades as any)[`${hermeticidade}h`]
    //   }
    // }
    const retorno = 100 - (porcentagem ?? 0);
    return retorno < 0 ? 0 : retorno;
  }

  const imagemHermeticidadeAtual = () => {
    const valor = getHermeticidadeAtual();

    if (valor <= 19) {
      return rostoVerdeEscuro;
    }

    if (valor <= 49) {
      return rostoVerdeClaro;
    }

    if (valor <= 59) {
      return rostoAmarelo;
    }

    if (valor <= 69) {
      return rostoLaranja;
    }

    return rostoVermelho;
  }

  const dataStart = dashboard?.last_leitura?.data_inicio_hermeticidade ?? null;

  const dispatch = useDispatch();
  const onStartHermeticidade = () => {
    if (!dashboard?.last_leitura) return;
    dispatch(leituraActions.startHermeticidadeRequest(dashboard.last_leitura.id as any))
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const getTotalM3Perdido = () => {
    const varianteAtual = getHermeticidadeAtualPpm() / 722;

    const valor = varianteAtual * (dashboard?.last_leitura?.volume ?? 0);

    return (valor < 0 ? 0 : valor / 10).toFixed(2);
  }

  const getTotalKgPerdido = () => {
    const valor = parseFloat(getTotalM3Perdido()) / 100;

    return (valor < 0 ? 0 : valor).toFixed(2);
  }

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  interface IQuedaPorPonto {
    ponto: number;
    queda: number;
  }
  const getQuedaPorPontoAtivo = () => {
    const retorno: IQuedaPorPonto[] = [];

    for (let i = 1; i <= 16; i++) {
      if (!dashboard || (dashboard.data.length > 1)) {
        if (temOPontoConfigurado(i) && dashboard?.last_leitura?.data_inicio_hermeticidade) {
          let pontoReferencia: any = null;
          (dashboard?.data ?? []).forEach((k) => {
            if (new Date(k.data) >= new Date(dashboard?.last_leitura?.data_inicio_hermeticidade) && !pontoReferencia) {
              pontoReferencia = k;
            }
          })

          const queda = !pontoReferencia ? 0 : 100 - (((dashboard?.data[dashboard.data.length - 1] as any)[`c${i}`] * 100) / (pontoReferencia as any)[`c${i}`])

          retorno.push({
            ponto: i,
            queda: queda < 0 ? 0 : queda,
          })
        }
      }
    }

    return retorno;
  }

  const imagemSilo = () => {
    return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;

    // if (dashboard?.last_leitura?.silo && dashboard?.last_leitura?.silo?.tipo_silo.trim() == "Graneleiro") {
    //   return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;
    // }

    // if (dashboard?.last_leitura?.silo && dashboard?.last_leitura?.silo?.tipo_silo.trim() == "Prédio") {
    //   return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;
    // }

    // return "";
  }


  const CardQueda = ({ item }: { item: IQuedaPorPonto }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Box position={"relative"} pb={4}>
        <ReactSpeedometer
          value={item.queda}
          minValue={0}
          maxValue={100}
          needleColor="#888"
          valueTextFontSize="0"
          startColor={hexVerdeEscuro}
          endColor={hexVermelho}
          segments={5}
          customSegmentStops={[0, 20, 40, 60, 80, 100]}
          segmentColors={[hexVerdeEscuro, hexVerde, hexAmarelo, hexLaranja, hexVermelho]}
          height={isMobile ? 100 : 80}
          width={isMobile ? 170 : 130}
          needleHeightRatio={0.6}
          ringWidth={30}
          needleTransitionDuration={1000}
          labelFontSize="0"
        />
        <Flex minW={"120px"} wrap={"wrap"} left={"50%"} bottom={"-10px"} transform={"translateX(-50%)"} position={"absolute"} direction={"column"}>
          <Tooltip label={getDescricao(item.queda)} isOpen={isOpen}>
            <Text onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              onClick={() => setIsOpen(true)} cursor={"pointer"} bg={getBgColor(item.ponto)} px={3} color="white" borderRadius={"10px"} mx={"auto"} fontSize={"12px"} fontWeight={600}>
              {t('DashboardPonto')} {item.ponto}
            </Text>
          </Tooltip>
          <Flex width={"full"}>
            <Text mx={"auto"} fontSize={"10px"} fontWeight={500}>
              {t('DashboardHermeticidadeQueda', { valor: `${item.queda.toFixed(0)}%` })}
            </Text>
          </Flex>
        </Flex>
      </Box>
    )
  }

  const leiturasState = useSelector((state: RootState) => state.leituras);
  const dadosSelects = useSelector((state: RootState) => state.leituras?.leituraDadosSelectsInputs);
  const injecoes = leiturasState?.injecoes ?? [];

  const [tooltipPercaAteOMomento, setTooltipPercaAteOMomento] = useState(false)


  return (
    <Flex id="dashboard-indice-hermeticidade" width={"full"} direction={"column"} maxH={isMobile ? '1000px' : '100px'} minH={"100px"} >
      <Flex height={"20%"} justifyContent={"center"}>
        <Text px={1} pt={2} position={"relative"} fontSize="0.8rem" color="#404244" fontWeight="700">{t('DashboardIndiceDeHermeticidade')}</Text>
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalCloseButton />
          <ModalHeader>
            <ListHeaderSecondary label={t("DashboardIndiceDeHermeticidade") + ' / ' + t("DashboardIndicadorQuedaGas")}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalBody my={2}>
            <Box mx={"auto"} maxW="950px">
              <Flex wrap="wrap" mb={12} pl={1} height={isMobile ? 'auto' : "170px"} width={"full"} direction={"inherit"} justifyContent={"center"}>
                <Flex width={isMobile ? '100%' : "50%"}>
                  <Flex position={"relative"} ml={1} width={"full"} justifyContent={"center"}>
                    <ReactSpeedometer
                      value={getHermeticidadeAtual()}
                      minValue={0}
                      maxValue={100}
                      needleColor="#888"
                      valueTextFontSize="0"
                      startColor={hexVerdeEscuro}
                      endColor={hexVermelho}
                      segments={5}
                      customSegmentStops={[0, 20, 40, 60, 80, 100]}
                      segmentColors={[hexVerdeEscuro, hexVerde, hexAmarelo, hexLaranja, hexVermelho]}
                      height={isMobile ? 140 : 140}
                      width={isMobile ? 210 : 250}
                      needleHeightRatio={0.9}
                      ringWidth={40}
                      needleTransitionDuration={1000}
                      labelFontSize="0"
                    />
                    <Flex borderColor={"rgb(136, 136, 136)"} borderWidth={"2px"} zIndex={10} position={"absolute"} bottom={isMobile ? 4 : 4} bgColor={"white"} p={2} pt={2} borderRadius={"1000px 1000px 1000px 1000px"}>
                      <Text px={2} color={"#404244"} fontWeight={"700"} fontSize={isMobile ? '22px' : "35px"}>
                        {getHermeticidadeAtual() < 0 ? 0 : getHermeticidadeAtual().toFixed(0)}%
                      </Text>
                    </Flex>
                    <Flex zIndex={10} position={"absolute"} bottom={isMobile ? -4 : -4} p={2} pt={2} borderRadius={"1000px 1000px 1000px 1000px"}>
                      <Text px={2} color={"#404244"} fontWeight={"500"} fontSize={isMobile ? '14px' : "20px"}>
                        {t('DashboardHermeticidadeAtual')}
                      </Text>
                    </Flex>

                  </Flex>
                </Flex>
                <Flex width={isMobile ? '100%' : "50%"} direction={"column"}>
                  <Flex width={"full"} pb={0.5} mt={isMobile ? 3 : 0} alignItems={"center"} zIndex={15}>
                    <Img width={"18px"} height={"18px"} src={rostoVermelho} />
                    <Text fontSize={isMobile ? '10px' : "14px"} ml={1}>{t('DashboardHermeticidade1Titulo')} <small>({t('DashboardHermeticidade1Descricao')})</small></Text>
                  </Flex>
                  <Flex width={"full"} pb={0.5} alignItems={"center"}>
                    <Img width={"18px"} height={"18px"} src={rostoLaranja} />
                    <Text fontSize={isMobile ? '10px' : "14px"} ml={1}>{t('DashboardHermeticidade2Titulo')} <small>({t('DashboardHermeticidade2Descricao')})</small></Text>
                  </Flex>
                  <Flex width={"full"} pb={0.5} alignItems={"center"}>
                    <Img width={"18px"} height={"18px"} src={rostoAmarelo} />
                    <Text fontSize={isMobile ? '10px' : "14px"} ml={1}>{t('DashboardHermeticidade3Titulo')} <small>({t('DashboardHermeticidade3Descricao')})</small></Text>
                  </Flex>
                  <Flex width={"full"} pb={0.5} alignItems={"center"}>
                    <Img width={"18px"} height={"18px"} src={rostoVerdeClaro} />
                    <Text fontSize={isMobile ? '10px' : "14px"} ml={1}>{t('DashboardHermeticidade4Titulo')} <small>({t('DashboardHermeticidade4Descricao')})</small></Text>
                  </Flex>
                  <Flex width={"full"} pb={0.5} alignItems={"center"}>
                    <Img width={"18px"} height={"18px"} src={rostoVerdeEscuro} />
                    <Text fontSize={isMobile ? '10px' : "14px"} ml={1}>{t('DashboardHermeticidade5Titulo')} <small>({t('DashboardHermeticidade5Descricao')})</small></Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex mb={2} mt={4} wrap={"wrap"} width={"full"} position={isMobile ? 'relative' : "absolute"} top={isMobile ? '-100px' : "200px"} maxW={"850px"}>
                <ReactECharts option={data} style={{ height: '220px' }} />
              </Flex>

              {!isMobile && <Flex pb={"110px"} width={"full"}></Flex>}

              <Flex position={"relative"} top={isMobile ? '-50px' : '10px'} color={"white"} fontWeight={700} width={"full"} py={2} borderRadius={"10px"} justifyContent={"center"} wrap={"wrap"} bg={getCor(getHermeticidadeAtual())}>
                {t('DashboardHermeticidadePerdaAteOMomento')}:&nbsp;{getHermeticidadeAtual() < 0 ? 0 : getHermeticidadeAtual().toFixed(0)}%&nbsp; / {getHermeticidadeAtualPpm() < 0 ? 0 : getHermeticidadeAtualPpm().toFixed(0)}ppm &nbsp; <Flex justifyContent={"center"} alignItems={"center"} textAlign={"center"} bg={"white"} borderRadius={"50px"} p={1}><img style={{ width: '20px', height: '20px', margin: 'auto' }} src={imagemHermeticidadeAtual()} />
                </Flex>
                <Flex my={"auto"} ml={2} wrap={"wrap"}>
                  <Tooltip isOpen={tooltipPercaAteOMomento} label={t('PerdaAteOMomentoInformativo', { value: getHermeticidadeAtual().toFixed(0) })}>
                    <Flex fontSize={"10px"} fontWeight={"700"} overflowWrap={"break-word"} onMouseEnter={() => setTooltipPercaAteOMomento(true)}
                      onMouseLeave={() => setTooltipPercaAteOMomento(false)}
                      onClick={() => setTooltipPercaAteOMomento(true)} cursor={"pointer"}>
                      <FiInfo size="25px" />
                    </Flex>
                  </Tooltip>
                </Flex>
              </Flex>

              <Text mt={4} fontWeight={600} width={"full"}>
                {t('DashboardInjecaoColumeGasph3Acumulado')}: {formatValor(getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).volumeAcumulado ?? '0', 0)}m³
              </Text>
              {/* <Text fontWeight={600} width={"full"}>
                {t('DashboardInjecaoQTFosfinaInjetada')}: {getDadosInjetado(dadosSelects as LeituraDadosSelectsInputs, injecoes, dashboard?.last_leitura?.volume ?? 0, dashboard?.last_leitura?.aplicacoes ?? []).quantidadeKgInjetada} kg
              </Text> */}
              <Text fontWeight={600} width={"full"}>
                {t('DashboardHermeticidadeM3Perdido')}: {dashboard?.last_leitura?.id == 28 ? '35.000' : formatValor(getTotalM3Perdido())}m³
              </Text>
              <Text fontWeight={600} width={"full"}>
                {t('DashboardHermeticidadeKgPerdido')}: {dashboard?.last_leitura?.id == 28 ? '49' : formatValor(getTotalKgPerdido())}Kg
              </Text>

              <Flex justifyContent={"center"} width={"full"} wrap={"wrap"}>
                {getQuedaPorPontoAtivo().map((i) => (
                  <CardQueda key={`${i.ponto}`} item={i} />
                ))}
              </Flex>

              <Flex mt={6} width={"full"} justifyContent={"center"}>
              {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <img src={imagemSilo()} width={"70%"} />}
                {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <Img src={siloPontoCentral} width={"70%"} />}
              </Flex>

            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


      <Flex position={"relative"} wrap="wrap" pl={1} height={isMobile ? 'auto' : "190px"} width={"full"} direction={"inherit"} justifyContent={"center"}>
        <Flex ml={1} width={"full"} justifyContent={"center"}>
          <Flex pl={1} direction={"column"} position={"absolute"} left={"5px"} top={'10px'}>
            <Tooltip label={t('DashboardExpandir')}>
              <Button className="no-print" zIndex={"1000"} color={"white"} bg={config.defaultColor} _hover={{ backgroundColor: "#0c3a14" }} mb={1} size={"xs"} onClick={onOpen}><FiEye />{isMobile && <>&nbsp;{t('DashboardExpandir')}</>}</Button>
            </Tooltip>
            {!dataStart && !!dashboard?.last_leitura && <Tooltip label={t('DashboardStartHermetificade')}>
              <Button className="no-print" zIndex={"1000"} colorScheme="green" size={"xs"} onClick={onStartHermeticidade}><FiPlay />{isMobile && <>&nbsp;{t('DashboardStartHermetificade')}</>}</Button>
            </Tooltip>}
            {!!dataStart && <Tooltip label={`${t('DashbordIniciadoEm')}: ${formatData(dataStart, 'dd/MM/yyyy HH:mm')}`}>
              <Button className="no-print" colorScheme="green" size={"xs"}><FiCheck />{isMobile && <>&nbsp; <Text fontSize={isMobile ? '9px' : '12px'}>{formatData(dataStart, 'dd/MM/yyyy HH:mm')}</Text></>}</Button>
            </Tooltip>}
          </Flex>
          <Flex position={isMobile ? 'absolute' : "absolute"} top={'0'}>
            <Flex position={"relative"} justifyContent={"center"}>
              <ReactSpeedometer
                value={getHermeticidadeAtual()}
                minValue={0}
                maxValue={100}
                needleColor="#888"
                valueTextFontSize="0"
                startColor={hexVerdeEscuro}
                endColor={hexVermelho}
                segments={5}
                customSegmentStops={[0, 20, 40, 60, 80, 100]}
                segmentColors={[hexVerdeEscuro, hexVerde, hexAmarelo, hexLaranja, hexVermelho]}
                height={isMobile ? 120 : 70}
                width={isMobile ? 180 : 130}
                needleHeightRatio={0.7}
                ringWidth={isMobile ? 40 : 20}
                needleTransitionDuration={1000}
                labelFontSize="0"
              />
              <Flex borderColor={"rgb(136, 136, 136)"} borderWidth={"1px"} zIndex={10} position={"absolute"} bottom={isMobile ? 4 : -1} bgColor={"white"} p={1} borderRadius={"1000px 1000px 1000px 1000px"}>
                <Text color={"#404244"} fontWeight={"700"} fontSize={"12px"}>
                  {getHermeticidadeAtual() < 0 ? 0 : getHermeticidadeAtual().toFixed(0)}%
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex >
  )
}