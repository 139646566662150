import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import en from "./en.json";
import es from "./es.json";
import de from "./de.json";
import ptBR from "./ptBR.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ptBR",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      es,
      ptBR,
      de,
    },
  });

export default i18n;
