import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sistemaSlice from "../sistema/recucer";
import leiturasSlice from "../leituras/reducer";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "ecozone_app",
  storage,
};
const rootReducer = combineReducers({
  sistema: sistemaSlice.reducer,
  leituras: leiturasSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares: any = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  // middlewares = [...middlewares, logger];
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
