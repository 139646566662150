import { DefaultButton } from "./DefaultButton"
import { Flex, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ListHeaderSecondary } from "./ListHeaderSecondary"
import { Divisor } from "./Divisor"
import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { FiPaperclip } from "react-icons/fi"
import imagesUrl from "../../utils/imagesUrl"

const desenvolvimentoCapa = imagesUrl + "temporario/dev_primario.png"
const desenvolvimento1 = imagesUrl + "temporario/dev_1.png"
const desenvolvimento2 = imagesUrl + "temporario/dev_2.png"
const desenvolvimento3 = imagesUrl + "temporario/dev_3.png"
const desenvolvimento4 = imagesUrl + "temporario/dev_4.png"

export const DesenvolvimentoButton = () => {
  const user = useSelector((state: RootState) => state.sistema.user);

  const userIsAdm = () => {
    return user?.administrador == 1 || user?.e_operador == 1;
  }

  const { isOpen: isOpenLocal, onOpen: onOpenLocal, onClose: onCloseLocal } = useDisclosure()
  const { isOpen: isOpenDetalhe, onOpen: onOpenDetalhe, onClose: onCloseDetalhe } = useDisclosure()

  const [currentImage, setCurrentImage] = useState('');
  const { t } = useTranslation();
  return (
    <>
      {userIsAdm() && <DefaultButton mb={1} size={'xs'} onClick={onOpenLocal}><FiPaperclip />&nbsp;
        Em desenvolvimento
      </DefaultButton>}

      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenLocal} onClose={onCloseLocal} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Em desenvolvimento'}>
              <DefaultButton onClick={onCloseLocal} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"} justifyContent={"center"}>
              <Img src={desenvolvimentoCapa} mx={"auto"} width={"70%"} height={"auto"} />
              <Divisor />

              <DefaultButton mt={1} mx={1} onClick={() => { setCurrentImage(desenvolvimento1); onOpenDetalhe() }}>
                Detalhes Performance Geral
              </DefaultButton>

              <DefaultButton mt={1} mx={1} onClick={() => { setCurrentImage(desenvolvimento2); onOpenDetalhe() }}>
                Detalhes Infestação Silo
              </DefaultButton>

              <DefaultButton mt={1} mx={1} onClick={() => { setCurrentImage(desenvolvimento3); onOpenDetalhe() }}>
                Detalhes Condição Infestação Geral
              </DefaultButton>

              <DefaultButton mt={1} mx={1} onClick={() => { setCurrentImage(desenvolvimento4); onOpenDetalhe() }}>
                Detalhes Body Center
              </DefaultButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal blockScrollOnMount={false} autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpenDetalhe} onClose={onCloseDetalhe} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Em desenvolvimento'}>
              <DefaultButton onClick={onCloseDetalhe} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>
            <Flex width={"full"} wrap={"wrap"}>
              <Img src={currentImage} mx={"auto"} height={"auto"} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}