import { Form, Formik } from "formik";
import { User } from "../types/user"
import { Button, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputField from "./InputField";
import { DefaultButton } from "./DefaultButton";
import InputCheck from "./InputCheck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from 'react'
import { sistemaActions } from "../recucer";
import InputSelect from "./InputSelect";
import InputMultiSelect from "./InpurMultiSelect";
import InputNumber from "./InputNumber";
import { useTranslation } from 'react-i18next'
import InputCheckInteiro from "./InputCheckInteiro";

interface IProps {
  currentItem: User | null;
  onClose?: () => void;
}

export const UserForm = ({ currentItem, onClose }: IProps) => {
  const todosClientes = useSelector((state: RootState) => state.sistema.todosClientes || []);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const { t } = useTranslation();

  const eAdminsitrador = useSelector((state: RootState) => (state.sistema.user as any)?.administrador == 1)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sistemaActions.todosClientesRequest())
  }, [dispatch])

  const tamanhoCampo = isMobile ? '100%' : '50%';
  const tamanhoCampoCheck = isMobile ? '100%' : '25%';

  return (
    <>
      {currentItem && <Formik
        enableReinitialize
        initialValues={currentItem}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ name: 'required' }, val, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(sistemaActions.saveUsuarioRequest(val));
        }}
      >
        {({ values }) => (
          <Form>

            <Flex width="full" wrap="wrap">
              <InputField label={t('UsuarioNome')} name="name" width={tamanhoCampo} />
              <InputField label={t('UsuarioCelular')} name="celular" type="tel" width={tamanhoCampo} />
              <InputField label={t('UsuarioEmail')} name="email" type="email" width={tamanhoCampo} />
              <InputField label={t('UsuarioSenha')} name="password" type="password" width={tamanhoCampo} />
              <InputSelect label={t('UsuarioCliente')} name="cliente_id" width={tamanhoCampo}>
                {todosClientes && todosClientes.map((i) => (
                  <option value={i.id} key={i.id}>{i.nome}</option>
                ))}
              </InputSelect>
              {values.unidades && <InputMultiSelect label={t('UsuarioResponsávelQuaisUnidadesCliente')} name="unidades" options={
                !todosClientes ? [] : (todosClientes.find((i: any) => i.id == values.cliente_id)?.unidades ?? []).map((i) => {
                  return {
                    label: i.nome_referencia,
                    value: i.id as number,
                  }
                })
              } width={tamanhoCampo} />}
              {eAdminsitrador && <InputNumber decimalPlaces={0} disabled width={tamanhoCampo} label={t('UsuarioQuantidadeAcessos')} name="quantidade_acessos" />}
              <hr style={{ width: '100%' }} />
              <InputCheck width={tamanhoCampoCheck} label={t('UsuarioAtivo')} name="ativo" />
              {eAdminsitrador && <InputCheck width={tamanhoCampoCheck} label={t('UsuarioAdministrador')} name="administrador" />}
              {eAdminsitrador && <InputCheck width={tamanhoCampoCheck} label={t('UsuarioDesenvolvedor')} name="e_desenvolvedor" />}
              {eAdminsitrador && <InputCheck width={tamanhoCampoCheck} label={t('UsuarioOperador')} name="e_operador" />}
              {eAdminsitrador && <InputCheckInteiro width={tamanhoCampoCheck} label={t('UsuarioExterno')} name="usuario_externo" />}

              <hr style={{ width: '100%' }} />
              <DefaultButton type="submit" mt={4}>{t('DashboardSalvar')}</DefaultButton>
              {typeof onClose === 'function' && <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('DashboardVoltar')}</Button>}
            </Flex>
          </Form>)}
      </Formik>}
    </>
  )
}