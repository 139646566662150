import { Button, Flex } from "@chakra-ui/react"
import { FaFilePdf } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Form, Formik, useFormikContext } from "formik";
import InputField from "../../sistema/components/InputField";
import { leituraActions } from "../reducer";
import InputSelect from "../../sistema/components/InputSelect";
import { Leitura } from "../types/leitura";
import { DashboardFiltro } from "../types/dashboard_filtro";
import config from "../../../config";
import { novoDashboardFiltro } from "../data/dashboard_filtro";
import InputCheck from "../../sistema/components/InputCheck";
import { apiCall } from "../../utils/apiCall";
import { useState } from "react";
import { mensagemErro } from "../../utils/toasts";
import Loader from "../../sistema/components/Loader";
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import domtoimage from 'dom-to-image';
import { getDensidade } from "./DashboardSilo";
import { useTranslation } from 'react-i18next'
import { formatData } from "../../utils/data";

export const DashboardFiltros = ({ onClose }: { onClose: () => void; }) => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const filtros_atuais = useSelector((state: RootState) => state.leituras?.filtros_atuais as DashboardFiltro);
  const dashboard = useSelector((state: RootState) => state.leituras?.dashboard_leituras);
  const dashboardLeitura = useSelector((state: RootState) => state.leituras);
  const dispatch = useDispatch();
  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)
  const user = useSelector((state: RootState) => state.sistema.user);

  const ClienteUnidadeInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_id) {
      return (
        <InputSelect label={t('DashboardUnidade')} name="cliente_unidade_id" width={'50%'}>
          <option value={""}>{t('DashboardSelecioneOCliente')}</option>
        </InputSelect>
      );
    }

    return (
      <InputSelect label={t('DashboardUnidade')} name="cliente_unidade_id" width={'50%'}>
        {leituraDados && leituraDados.clientes_unidades.filter((i) => i.cliente_id == values.cliente_id).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {i.cpf_cnpj} - {i.cidade} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
        ))}
      </InputSelect>
    );
  }

  const ClienteUnidadeSiloInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_unidade_id) {
      return (
        <InputSelect label={t('DashboardSilo')} name="cliente_unidade_silo_id" width={'50%'}>
          <option value={""}>{t('DashboardSelecioneAUnidade')}</option>
        </InputSelect>
      );
    }

    return (
      <InputSelect label={t('DashboardSilo')} name="cliente_unidade_silo_id" width={'50%'}>
        {leituraDados && leituraDados.clientes_unidades_silos.filter((i => i.cliente_unidade_id == values.cliente_unidade_id)).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {i.tipo_silo}</option>
        ))}
      </InputSelect>
    );
  }

  const getQuantidadeFiltrosAtivos = (values: DashboardFiltro) => {
    let soma = 0;;

    if (values.data_inicial) {
      soma += 1;
    }
    if (values.hora_inicial) {
      soma += 1;
    }
    if (values.data_final) {
      soma += 1;
    }
    if (values.hora_final) {
      soma += 1;
    }
    if (values.cliente_id) {
      soma += 1;
    }
    if (values.cliente_unidade_id) {
      soma += 1;
    }
    if (values.cliente_unidade_silo_id) {
      soma += 1;
    }
    if (values.equipamento_id) {
      soma += 1;
    }
    if (values.ordem_servico) {
      soma += 1;
    }

    return soma;
  }

  const onClickLimpar = () => {
    dispatch(leituraActions.dashboardLeiturasRequest(novoDashboardFiltro()))
  }

  const userAdmin = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const imagemSilo = () => {
    return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;
  }

  function getBase64FromImageUrl(imagePath: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', imagePath, true);
      // xhr.withCredentials = true;
      xhr.responseType = 'blob';

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const reader = new FileReader();
          reader.onloadend = function () {
            const dataURL = reader.result;
            resolve(dataURL);
          };
          reader.onerror = function () {
            reject(new Error(`Não foi possível converter a imagem em data URL: ${imagePath}`));
          };
          reader.readAsDataURL(blob);
        } else {
          reject(new Error(`Não foi possível carregar a imagem: ${imagePath}`));
        }
      };

      xhr.onerror = function () {
        reject(new Error(`Não foi possível carregar a imagem: ${imagePath}`));
      };

      xhr.send();
    });
  }

  const [isLoading, setIsLoading] = useState(false);

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const getCapacidadeSilo = () => {
    const altura: any = dashboard?.last_leitura?.silo?.altura ?? 0;
    const diametro: any = dashboard?.last_leitura?.silo?.diametro ?? 0;
    const produto: any = dashboard?.last_leitura?.produto ?? 0;

    if (!altura || !diametro || !getDensidade(produto)) return 0;

    const diametro2 = altura * ((diametro / 2) * (diametro / 2) * 3.1415)

    return diametro2 * getDensidade(produto);
  }

  const downloadPDF = async () => {
    const element: any = document.getElementById('grafico');
    const elementUltimaLeitura: any = document.getElementById('dashboard-ultima-leitura');

    const elements: any = document.getElementsByClassName("no-print");

    for (const e of elements) {
      e.style.background = "white"
    }

    setTimeout(async () => {
      setIsLoading(true)

      const imgData = await new Promise((resolve) => {
        domtoimage.toPng(element, { quality: 1 }).then((dataUrl: any) => {
          resolve(dataUrl)
        });
      });

      const imgUltimaLeitura = await new Promise((resolve) => {
        domtoimage.toPng(elementUltimaLeitura, { quality: 1 }).then((dataUrl: any) => {
          resolve(dataUrl)
        });
      });

      // const elementimgDadosSilo: any = document.getElementById('dashboard-dados-silo');
      // const imgDadosSilo = await new Promise((resolve) => {
      //   domtoimage.toPng(elementimgDadosSilo, { quality: 1 }).then((dataUrl) => {
      //     resolve(dataUrl)
      //   });
      // });
      const elementimgEficiencia: any = document.getElementById('dashboard-eficiencia');
      const imgEficiencia = await new Promise((resolve) => {
        domtoimage.toPng(elementimgEficiencia, { quality: 1 }).then((dataUrl: any) => {
          resolve(dataUrl)
        });
      });
      const elementimgBlocoConcentracao: any = document.getElementById('dashboard-bloco-concentracao');
      const imgBlocoConcentracao = await new Promise((resolve) => {
        domtoimage.toPng(elementimgBlocoConcentracao, { quality: 1 }).then((dataUrl: any) => {
          resolve(dataUrl)
        });
      });

      const elementimgMortalidadeInsetos: any = document.getElementById('dashboard-mortalidade-insetos');
      let imgMortalidadeInsetos = "";
      let mortalidadeArray: any = [];
      if (eFosfina) {
        const getIsBlocked = (hours: number, ppm: number) => {
          if (ppm == 400) {
            return (parseFloat(dashboardLeitura?.dashboard_leituras?.tempo_exposicao_400 ?? '0')) > hours;
          }
      
          if (ppm == 600) {
            return (parseFloat(dashboardLeitura?.dashboard_leituras?.tempo_exposicao_600 ?? '0')) > hours;
          }
      
          if (ppm == 800) {
            return (parseFloat(dashboardLeitura?.dashboard_leituras?.tempo_exposicao_800 ?? '0')) > hours;
          }
          return false;
        }

        mortalidadeArray = (dashboardLeitura?.leituraDadosSelectsInputs?.insetos ?? []).map((i) => ({
          nome: i.nome,
          imagem: i.imagem,
          ppmText: `> ${i.ppm_mortalidade}ppm`.replace('>', t('InsetosEditMaiorQue')),
          hoursText: `> ${i.horas_mortalidade}h`.replace('>', t('InsetosEditMinimoDe')),
          isBlocked: getIsBlocked(i.horas_mortalidade, i.ppm_mortalidade),
          morto: getIsBlocked(i.horas_mortalidade, i.ppm_mortalidade) ? t('InsetosEditParaMorto') : '',
          imagem_grande: i.imagem_grande == 1,
        }))

        // imgMortalidadeInsetos = await new Promise((resolve) => {
        //   domtoimage.toPng(elementimgMortalidadeInsetos, { quality: 1 }).then((dataUrl) => {
        //     resolve(dataUrl)
        //   });
        // });
      }

      try {
        var regex = /\/images\/(.*)/;

        var resultadoImagemSiloUrl = regex.exec(imagemSilo());
    
        let imagemSiloUrl = null;
        if (resultadoImagemSiloUrl && resultadoImagemSiloUrl.length > 1) {
          imagemSiloUrl = resultadoImagemSiloUrl[1]; // Retorna o texto capturado dentro dos parênteses
        }

        const response = await apiCall({
          url: 'gerar-pdf',
          method: 'POST',
          data: {
            ...dashboard,
            imagem_silo: `/images/${imagemSiloUrl}`,
            imagem_grafico: imgData,
            iamgem_ultima_leitura: imgUltimaLeitura,
            imagem_eficiencia: imgEficiencia,
            imagem_mortalidade_insetos: imgMortalidadeInsetos,
            imagem_bloco_concentracao: imgBlocoConcentracao,
            mortalidadeArray,
            capacidade_silo: (getCapacidadeSilo() ?? 0).toFixed(2),
            DashboardPdfCliente: t('DashboardPdfCliente'),
            DashboardPdfUnidade: t('DashboardPdfUnidade'),
            DashboardPdfEndereço: t('DashboardPdfEndereço'),
            DashboardPdfProduto: t('DashboardPdfProduto'),
            DashboardPdfVolume: t('DashboardPdfVolume'),
            DashboardPdfOrdermServico: t('DashboardPdfOrdermServico'),
            DashboardPdfDataInicioLeitura: t('DashboardPdfDataInicioLeitura'),
            DashboardPdfUltimaLeitura: t('DashboardPdfUltimaLeitura'),
            DashboardPdfPOSICAO: t('DashboardPdfPOSICAO'),
            DashboardPdfDATAHORA: t('DashboardPdfDATAHORA'),
            DashboardPdfVALOR: t('DashboardPdfVALOR'),
            DashboardPdfPonto: t('DashboardPdfPonto'),
            DashboardPdfTempoConcentracaoAcimaDe: t('DashboardPdfTempoConcentracaoAcimaDe') + ' ' + dashboard?.last_leitura?.range_calculo_concentracao + 'ppm',
            DashboardPdfResultado: t('DashboardPdfResultado'),
            DashboardPdfResultadoPh3Descricao1: t('DashboardFosfinaResultado1Descricao'),
            DashboardPdfResultadoPh3Descricao2: t('DashboardFosfinaResultado2Descricao'),
            DashboardPdfResultadoPh3Descricao3: t('DashboardFosfinaResultado3Descricao'),
            DashboardPdfResultadoPh3Descricao4: t('DashboardFosfinaResultado4Descricao'),
            DashboardPdfResultadoOznDescricao1: t('DashboardOzonioResultado1Descricao'),
            DashboardPdfResultadoOznDescricao2: t('DashboardOzonioResultado2Descricao'),
            DashboardPdfResultadoOznDescricao3: t('DashboardOzonioResultado3Descricao'),
            DashboardPdfResultadoOznDescricao4: t('DashboardOzonioResultado4Descricao'),
            DashboardPdfHoras: t('DashboardPdfHoras'),
            DashboardPdfGraficoDuranteTempoAplicacao: t('DashboardPdfGraficoDuranteTempoAplicacao'),
            DashboardPdfLeituras: t('DashboardPdfLeituras'),
            DashboardPdfUltimaLeituraRealizadaAHorasExposicao: t('DashboardPdfUltimaLeituraRealizadaAHorasExposicao') + dashboard?.tempo_exposicao_string + t('DashboardPdfUltimaLeituraRealizadaAHorasExposicao2'),
            DashboardPdfTempoExposicaoIndicadorReaplicacaoCondicaoHermeticidade: eFosfina ? t('DashboardPdfTempoExposicaoIndicadorReaplicacaoCondicaoHermeticidade') : t('DashboardPdfTempoExposicaoEficienciaReducaoAmostras'),
            DashboardPdfInsetosComMortalidadePrecisaNoTempo: t('DashboardPdfInsetosComMortalidadePrecisaNoTempo'),
            DashboardPdfEficiencaAplicacaoConcentracaoEspecificada: t('DashboardPdfEficiencaAplicacaoConcentracaoEspecificada'),
            DashboardPdfSensores: t('DashboardPdfSensores'),
            DashboardPdfDadosTipoArmazenamento: t('DashboardPdfDadosTipoArmazenamento'),
            DashboardPdfSiloNome: t('DashboardNome'),
            DashboardPdfSiloAltura: t('DashboardAltura'),
            DashboardPdfSiloDiâmetro: t('DashboardDiâmetro'),
            DashboardPdfSiloLargura: t('DashboardLargura'),
            DashboardPdfSiloComprimento: t('DashboardComprimento'),
            DashboardPdfSiloCapacidade: t('DashboardCapacidade'),
            DashboardPdfSiloCapacidadeComBaseEm: t('DashboardPdfSiloCapacidadeComBaseEm') + ' ' + t('Dashboard' + (dashboard?.last_leitura?.produto ?? '').trim()),
            DashboardPdfAmostras: t('DashboardPdfAmostras'),
            DashboardPdfOrdem: t('DashboardPdfOrdem'),
            DashboardPdfTipo: t('DashboardPdfTipo'),
            DashboardPdfConcentração: t('DashboardPdfConcentração'),
            DashboardPdfData: t('DashboardPdfData'),
            DashboardPdfSemFoto: t('DashboardPdfSemFoto'),
            DashboardPdfEficienciaReducao: t('DashboardPdfEficienciaReducao'),
            DashboardPdf0hInicial: t('DashboardPdf0hInicial'),
            DashboardPdfAplicada: t('DashboardPdfAplicada'),
            DashboardPdfParaMaisInformacoes: t('DashboardPdfParaMaisInformacoes'),
            DashboardPdfNomeProduto: t('Dashboard' + (dashboard?.last_leitura?.produto ?? '').trim()),
            DashboardMédia: t('DashboardMédia'),
            DashboardPdfDadosLeitura: t('DashboardPdfDadosLeitura'),
          },
          responseType: 'blob',
        })
        setIsLoading(false)

        // Crie um URL temporário para o Blob
        const url = URL.createObjectURL(response.data);

        // Crie um link para iniciar o download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'detalhado.pdf';

        // Simule o clique no link para iniciar o download
        link.click();

        // Limpe o URL temporário
        URL.revokeObjectURL(url);

        for (const e of elements) {
          e.style.background = config.defaultColor
        }
      } catch (err) {
        mensagemErro("Erro ao gerar PDF")
        setIsLoading(false)
        console.log(err)
      }
    }, 500)

    // try {
    //   
    //   const response = await apiCall({
    //     url: 'gerar-pdf',
    //     method: 'POST',
    //     data: {
    //       ...dashboard,
    //       imagem_silo: await getBase64FromImageUrl(imagemSilo()),
    //     },
    //     responseType: 'blob',
    //   })
    //   setIsLoading(false)

    //   // Crie um URL temporário para o Blob
    //   const url = URL.createObjectURL(response.data);

    //   // Crie um link para iniciar o download
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = 'detalhado.pdf';

    //   // Simule o clique no link para iniciar o download
    //   link.click();

    //   // Limpe o URL temporário
    //   URL.revokeObjectURL(url);
    // } catch (err) {
    //   mensagemErro("Erro ao gerar PDF")
    //   setIsLoading(false)
    //   console.log(err)
    // }
  }

  const { t } = useTranslation();

  return (
    <>
      {dashboard?.last_leitura && userAdmin() && <DefaultButton size={"sm"} ml={1} onClick={downloadPDF}><FaFilePdf />&nbsp; Download PDF</DefaultButton>}
      {filtros_atuais && <Formik
        enableReinitialize
        initialValues={{
          ...filtros_atuais,
          leitura_id: typeof filtros_atuais.leitura_id != 'string' ? '' : filtros_atuais.leitura_id,
        }}
        onSubmit={(val, { setErrors }) => {
          dispatch(leituraActions.dashboardLeiturasRequest(val))
        }}
      >
        {({ values }) => (
          <Form>
            <Flex wrap={"wrap"} width={"full"} alignItems={"center"} height={"full"} justifyContent={"center"} alignContent={"center"} justifyItems={"center"}>
              <Loader isLoading={isLoading} />
              <Flex width="full" wrap="wrap">
                <InputField width={isMobile ? "50%" : "25%"} name="data_inicial" label={t('DashboardDataInicial')} type="date" />
                <InputField width={isMobile ? "50%" : "25%"} name="hora_inicial" label={t('DashboardHoraInicial')} type="time" />
                <InputField width={isMobile ? "50%" : "25%"} name="data_final" label={t('DashboardDataFinal')} type="date" />
                <InputField width={isMobile ? "50%" : "25%"} name="hora_final" label={t('DashboardHoraFinal')} type="time" />

                {!userAdmin() && <InputSelect label={t('DashboardStatusLeitura')} name="leitura_id" width={'100%'}>
                  {leituraDados && (leituraDados.leituras ?? []).filter((i) => (user?.unidades ?? []).some((k) => k.value == i.cliente_unidade_id)).map((i) => (
                    <option value={i.id} key={i.id}>{i.cliente?.nome}- {i.silo?.nome_referencia} - {i.unidade?.cidade} - {formatData(i.data)} - {!i.tipo_leitura ? '' : i.tipo_leitura.trim() == 'PH3 (Fosfina)' ? t('DashboardNomeFosfina') : t('DashboardNomeOzonio')}</option>
                  ))}
                </InputSelect>}

                {userAdmin() && (<InputSelect label={t('DashboardCliente')} name="cliente_id" width={'50%'}>
                  {leituraDados && leituraDados.clientes.map((i) => (
                    <option value={i.id} key={i.id}>{i.nome} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
                  ))}
                </InputSelect>)}
                {userAdmin() && (<ClienteUnidadeInput />)}
                {userAdmin() && (<ClienteUnidadeSiloInput />)}
                {userAdmin() && (<InputSelect label={t('DashboardEquipamento')} name="equipamento_id" width={'50%'}>
                  {leituraDados && leituraDados.equipamentos.map((i) => (
                    <option value={i.id} key={i.id}>{i.nome_referencia} ({i.endereco_registro})</option>
                  ))}
                </InputSelect>)}
                {userAdmin() && <InputField label={t('DashboardOrdemServiço')} name="ordem_servico" width={'50%'} />}
                {userAdmin() && <InputSelect label={t('DashboardStatusLeitura')} name="leitura_id" width={'50%'}>
                  {leituraDados && (leituraDados.leituras ?? []).map((i) => (
                    <option value={i.id} key={i.id}>{i.cliente?.nome} - {i.silo?.nome_referencia} - {i.unidade?.cidade} - {formatData(i.data)} - {!i.tipo_leitura ? '' : i.tipo_leitura.trim() == 'PH3 (Fosfina)' ? t('DashboardNomeFosfina') : t('DashboardNomeOzonio')}</option>
                  ))}
                </InputSelect>}
                {userAdmin() && (<InputCheck
                  name='somente_em_aberto'
                  width={"100%"}
                  label={t('DashboardSomenteLeiturasEmAberto')}
                />)}
                <DefaultButton type="submit" mt={4}>{t('DashboardFiltrar')}</DefaultButton>
                <Button ml={2} colorScheme="red" mt={4} onClick={onClickLimpar}>
                  {t('DashboardLimpar')}
                </Button>
                <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('DashboardFechar')}</Button>
              </Flex>
            </Flex>
          </Form>)}
      </Formik>}
    </>
  )
}