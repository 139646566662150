import { Box, Button, Flex, Grid, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Form, Formik, useField } from "formik";
import { novaAvaliacaoInicialImagem } from "../data/avaliacao_inicial_imagem";
import { useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db-hook";
import { AvaliacaoInicialImagem } from "../types/avaliacao_inicial_imagem";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import InputImage from "../../sistema/components/InputImage";
import { FiX } from "react-icons/fi";
import InputNumber from "../../sistema/components/InputNumber";
import InputField from "../../sistema/components/InputField";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import InputImageCameraDireta from "../../sistema/components/InputImageCameraDireta";

export const AvaliacaoInicialEstruturalForm = ({ usaCameraDireta }: { usaCameraDireta: boolean }) => {
  const { add, getAll } = useIndexedDB('images');

  const salvarImagem = async (base64: string) => {
    return new Promise((resolve) => {
      add({ name: base64 }).then(
        (event: any) => {
          console.log("ID Generated: ", event);
          resolve(event)
        },
        (error) => {
          resolve('');
          console.log(error);
        },
      );
    })
  }

  const [, { value: imagens }, { setValue: setImagens }] = useField('imagens');

  const [imagesDb, setImagesDb] = useState<any>([]);

  let ultimaImagem = "";
  useEffect(() => {
    const getData = async () => {
      if (ultimaImagem != JSON.stringify(imagens)) {
        const data = await getAll();
        setImagesDb(data);
        ultimaImagem = JSON.stringify(imagens);
      }
    }
    console.log(`getData AvaliacaoEstruturalForm`)
    getData();
  }, [imagens])

  const [currentItem, setCurrentItem] = useState<AvaliacaoInicialImagem | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSaveImagem = async (values: AvaliacaoInicialImagem) => {
    console.log(values, `onSaveImagem`);
    setImagens([
      {
        ...values,
        imagemId: await salvarImagem(values.imagem),
        imagem: '',
        changed: true,
      },
      ...(imagens ?? []),
    ])
    setCurrentItem(null);
    setTimeout(() => {
      setCurrentItem({
        ...novaAvaliacaoInicialImagem(),
        campo_referencia: values.campo_referencia,
      })
    }, 100)
  }

  const onOpenImagens = (campo: string) => {
    setCurrentItem({
      ...novaAvaliacaoInicialImagem(),
      campo_referencia: campo,
    })
    onOpen()
  }

  const { t } = useTranslation();

  const getQuantidadeImagem = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name).length;
  }

  const getImagensDoTipo = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name);
  }

  const getImagensDoTipoNaoDeletadas = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia == name).filter((i) => !i.deleted);
  }

  const getImagensDiferenteDoTipo = (name: string, imagens: AvaliacaoInicialImagem[]) => {
    return ((imagens ?? []) as AvaliacaoInicialImagem[]).filter((i) => i.campo_referencia != name);
  }

  const onDeleteIamge = (index: number, name: string, imagens: AvaliacaoInicialImagem[], setImagens: any) => {
    const values = JSON.parse(JSON.stringify(getImagensDoTipo(name, imagens))).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(getImagensDoTipo(name, imagens))).filter((i: any) => i?.deleted);
    const resto = JSON.parse(JSON.stringify(getImagensDiferenteDoTipo(name, imagens)));

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setImagens([
        ...values,
        ...valuesDeleted,
        ...resto,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setImagens([
      ...values,
      ...valuesDeleted,
      ...resto,
    ])
  }

  const silosInputs = [
    {
      name: 'silo.valvulas_quantidade',
      label: t('AvaliacaoInicialValvulasQuantidade'),
      type: 'number',
    },
    {
      name: 'silo.valvulas_diametro',
      label: t('AvaliacaoInicialValvulasDiametro'),
      type: 'number',
      decimals: 2,
    },
    {
      name: 'silo.tipo_transporta',
      label: t('AvaliacaoInicialTipoTransportador'),
      type: 'text',
    },
    {
      name: 'silo.quantidade_acessos',
      label: t('AvaliacaoInicialQuantidadeAcessosTunel'),
      type: 'number',
    },
    {
      name: 'silo.quantidade_ventiladores',
      label: t('AvaliacaoInicialQuantidadeVentiladores'),
      type: 'number',
    },
    {
      name: 'silo.quantidade_cabos_termometria',
      label: t('AvaliacaoInicialQuantidadeCabosTermometria'),
      type: 'number',
    },
    {
      name: 'silo.quantidade_respiros',
      label: t('AvaliacaoInicialQuantidadeRespiros'),
      type: 'number',
    },
  ]

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const tamanhoCampo = isMobile ? '50%' : '33.33%';

  return (
    <Flex width={"full"} wrap={"wrap"}>
      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('AvaliacaoInicialFotos')}>
              <DefaultButton onClick={() => { setCurrentItem(null); onClose(); }} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                const validation = validateForm({
                  imagem: 'required',
                }, values, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveImagem(values)
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" direction={"column"} wrap={"wrap"} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
                    {usaCameraDireta ? <InputImageCameraDireta label={t(`AvaliacaoInicialFoto`)} name="imagem" /> : <InputImage label={t(`AvaliacaoInicialFoto`)} name="imagem" />}
                    <DefaultButton mt={4} type="submit">
                      {t('AvaliacaoInicialAdicionar')}
                    </DefaultButton>
                  </Flex>
                </Form>)}

            </Formik>}

            {getImagensDoTipoNaoDeletadas(currentItem?.campo_referencia ?? '', imagens).length > 0 && <>
              <ListHeaderSecondary label={t(`AvaliacaoInicialJaAdicionadas`)} />
              <Box width={`full`}></Box>
              <Grid templateColumns='repeat(2, 1fr)' gap={3} width={"full"}>
                {getImagensDoTipoNaoDeletadas(currentItem?.campo_referencia ?? '', imagens).map((i: AvaliacaoInicialImagem, index: number) => (
                  <Flex key={`${index}`} p={3} position={"relative"}>
                    <Img src={!i.imagemId ? i.imagem : imagesDb.find((j: any) => j.id == i.imagemId)?.name} />
                    <Button onClick={() => onDeleteIamge(index, i.campo_referencia, imagens, setImagens)} title={t(`AvaliacaoInicialRemoverFoto`)} size={"sm"} colorScheme="red" position={"absolute"} top={"5px"} right={"5px"}>
                      <FiX />
                    </Button>
                  </Flex>
                ))}
              </Grid>
            </>}
          </ModalBody>
        </ModalContent>
      </Modal>

      <ListHeaderSecondary label={t('AvaliacaoInicialAvaliacaoEstrutural')} />
      <Flex width={"full"} wrap={"wrap"} />
      {silosInputs.map((i) => (
        <Flex width={tamanhoCampo} key={i.name} pr={isMobile ? 0 : 8}>
          {i.type == 'number' && <InputNumber decimalPlaces={i?.decimals ? i.decimals : 0} type="tel" label={i.label} name={i.name} />}
          {i.type == 'text' && <InputField label={i.label} name={i.name} />}
          <DefaultButton onClick={() => onOpenImagens(i.name)} mt={9} position={"relative"}>
            {t('AvaliacaoInicialFotos')}
            <Text fontWeight={700} borderRadius={"50px"} color={"black"} p={1} px={2} bg={"white"} fontSize={"11px"} position={"absolute"} top={"-7px"} right={"-3px"}>
              {getQuantidadeImagem(i.name, imagens)}
            </Text>
          </DefaultButton>
        </Flex>
      ))}
    </Flex>
  )
}