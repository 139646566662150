import { AvaliacaoInicialQuestao } from "../types/avaliacao_inicial_questao";

export const novaAvaliacaoInicialQuestao = (): AvaliacaoInicialQuestao => {
  return {
    nome_questao: "",
    ordem: 0,
    tipo: 0,
    e_sinaleiro: false,
    ativo: true,
    imagem: '',
    nao_mostra_no_predio: 0
  };
};
