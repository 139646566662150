import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useTranslation } from 'react-i18next'

export const DashboardNomeLeitura = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras)

  const { t } = useTranslation()

  let nomeLeitura = dashboard?.last_leitura?.tipo_leitura ?? 'PH3 (Fosfina)';
  if (nomeLeitura == 'PH3 (Fosfina)') {

    return (
      t('DashboardNomeFosfina')
    )
  }
  if (nomeLeitura == 'O3 (Ozônio)') {
    return (
      t('DashboardNomeOzonio')
    )
  }
}