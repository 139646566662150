import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary"
import { useTranslation } from "react-i18next"
import { Flex } from "@chakra-ui/react";
import { AvaliacaoInicialProdutoArmazenadoInsetos } from "./AvaliacaoInicialProdutoArmazenadoInsetos";

export const AvaliacaoInicialProdutoArmazenado = ({ nameInsetos, nameTitulo }: { nameInsetos: string; nameTitulo: string }) => {
  const { t } = useTranslation();

  return (
    <Flex wrap={"wrap"} width={"full"} mt={4}>
      <ListHeaderSecondary label={t(nameTitulo)} />
      <Flex width={"full"} />
      <AvaliacaoInicialProdutoArmazenadoInsetos name={nameInsetos} />
    </Flex>
  )
}