import { LeituraDados } from "../types/leitura_dados";

export const novaLeituraDados = (): LeituraDados => {
  return {
    data: "",
    leitura_id: "",
    c1: 0,
    t1: "",
    c2: 0,
    t2: "",
    c3: 0,
    t3: "",
    c4: 0,
    t4: "",
    c5: 0,
    t5: "",
    c6: 0,
    t6: "",
    c7: 0,
    t7: "",
    c8: 0,
    t8: "",
    c9: 0,
    t9: "",
    c10: 0,
    t10: "",
    c11: 0,
    t11: "",
    c12: 0,
    t12: "",
    c13: 0,
    t13: "",
    c14: 0,
    t14: "",
    c15: 0,
    t15: "",
    c16: 0,
    t16: "",
  };
};
