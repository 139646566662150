import Layout from "../../sistema/components/Layout"
import { useEffect, useState } from 'react';
import { DashboardGraficoLinha } from "../components/DashboardGraficoLinha";
import { Box, Flex } from "@chakra-ui/react";
import { DashboardGraficoPizza } from "../components/DashboardGraficoPizza";
import { useIsAuth } from "../../hooks/useIsAuth";
import { DashboardTopo } from "../components/DashboardTopo";
import { useDispatch, useSelector } from "react-redux";
import { leituraActions } from "../reducer";
import Loader from "../../sistema/components/Loader";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../../sistema/recucer";
import { DashboardInsetos } from "../components/DashboardInsetos";
import { DashboardDadosCliente } from "../components/DashboardDadosCliente";
import { DashboardToxinas } from "../components/DashboardToxinas";
import { DashboardSilo } from "../components/DashboardSilo";
import { DashboardAvaliacaoInicial } from "../components/DashboardAvaliaçãoInicial";
import { DashboardEquipamentoEdit } from "../components/DashboardEquipamentoEdit";
import { DesenvolvimentoButton } from "../../sistema/components/DesenvolvimentoButton";
import { QrCodeAcesso } from "../components/QrCodeAcesso";
import { useQuery } from "../../hooks/useQuery";

export const Dashboard = () => {
  useIsAuth();
  const query = useQuery();

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const networkOnline = useSelector((state: RootState) => !!state.sistema?.networkOnline);
  const isLoading = useSelector((state: RootState) => state.leituras.isLoading ?? false);
  const dashboard = useSelector((state: RootState) => state.leituras?.dashboard_leituras);

  const dispatch = useDispatch();

  useEffect(() => {
    if (networkOnline) {
      if (user?.usuario_externo == 1) {
        dispatch(leituraActions.dashboardLeiturasRequest({ leitura_token: query.get('token') ?? '' } as any))
      } else {
        dispatch(leituraActions.dashboardLeiturasRequest(null))
      }
      dispatch(leituraActions.leituraDadosSelectsInputs())
    }
  }, [dispatch])

  useEffect(() => {
    if (!dashboard?.last_leitura?.equipamento_id) return;

    if (networkOnline) {
      dispatch(sistemaActions.equipamentoRequest(dashboard?.last_leitura?.equipamento_id))
    }
  }, [dispatch, dashboard?.last_leitura?.equipamento_id])

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const user = useSelector((state: RootState) => state.sistema.user);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Função para atualizar o estado com a largura da janela
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Adiciona um ouvinte de evento para o evento de redimensionamento da janela
    window.addEventListener('resize', updateWindowWidth);

    // Remove o ouvinte de evento quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []); // O array vazio garante que o efeito é executado apenas uma vez durante a montagem


  return (
    <Layout id="graficos" padding={0.5}>
      <Loader isLoading={isLoading} />

      <Flex width={"full"} wrap={"wrap"}>
        {isMobile && <Flex my={2} width={"full"} justifyContent={"space-around"} wrap={"wrap"}>
          <DashboardAvaliacaoInicial />
          <DashboardEquipamentoEdit />
          <Flex width={"full"} my={1} />
          <DesenvolvimentoButton />

          {dashboard?.last_leitura?.token && <Flex flexDir={"column"} wrap={"wrap"}>
            <QrCodeAcesso leitura_token={dashboard?.last_leitura?.token ?? ''} />

            {/* {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://drive.google.com/drive/u/2/folders/1LdnGlcq5xYkh8aGeWM3xI_YYgA6fGyOZ">Planejamento e documentação</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSf7_TTqER7_0bVl0rSyCO1bijDrOUrTtMK3AZjStHhiWFoYpg/viewform?usp=sf_link">Formulário de Planejamento Executivo</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSeZXSHyVQxuwbD7K4PMSeOHb9cezv6MPXKLwjpt0Fly00rg1w/viewform?usp=sf_link">Formulário de Inspeções e Manutenções</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSd23NUhErEJLz16UGyNhhNlffEbyB7zgWq2YITRg3VCV_jC5g/viewform">Revisão de itens obrigatórios caminhões</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLSfkx-5S375dvnFFTWBXGYRn5fGnhJefof8ELfGIxtJTXEFHIQ/viewform?usp=sf_link">Avaliação preliminar de riscos - Fosfina (TecZ)</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/e/1FAIpQLScUNMqort-R7fw8qdj772qERGzZC0axDgdcg-h5RpsPNk1BYw/viewform?usp=sf_link">Avaliação preliminar de riscos - Profilaxia</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/forms/d/13zgecfaxF3KRen7CerXkxn0r78rIupWsx-t3qreMQqE/edit?usp=drivesdk">Verificação de EPI's</ButtonLink>}
            {(user?.administrador == 1 || user?.e_operador == 1 || user?.e_desenvolvedor == 1) && <ButtonLink href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQlDtDlyyLceN1mSZjZzVq72Vxl-tkoQEKLJ-PokEh3hd7XKXy3ADL7pd9gAEjjJbfytu1lL5p9iBv5/pubhtml">Planejamento Executivo Fumigação - Versão do Cliente</ButtonLink>}
            {(user?.administrador == 0 && user?.e_operador == 0 && user?.e_desenvolvedor == 0) && <ButtonLink href="https://drive.google.com/drive/u/2/folders/1XQCcMYz9f2sG5t2KFGFj1WwX56f1LrBf">Documentação</ButtonLink>}
            {(user?.administrador == 0 && user?.e_operador == 0 && user?.e_desenvolvedor == 0) && <ButtonLink href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQlDtDlyyLceN1mSZjZzVq72Vxl-tkoQEKLJ-PokEh3hd7XKXy3ADL7pd9gAEjjJbfytu1lL5p9iBv5/pubhtml">Planejamento Executivo Fumigação</ButtonLink>} */}
          </Flex>}
        </Flex>}

        {isMobile && <Box width={"full"} bg={"white"} borderRadius={25} p={1} pb={6} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"}>
          <DashboardDadosCliente />
        </Box>}
        {isMobile && eFosfina && <Box my={2} bg={"white"} borderRadius={25} p={1} pb={6} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"}>
          <DashboardInsetos />
        </Box>}
        {isMobile && !eFosfina && <Box my={2} bg={"white"} borderRadius={25} p={1} pb={6} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"}>
          <DashboardToxinas />
        </Box>}
        <DashboardTopo />
        <DashboardGraficoLinha />
        <DashboardSilo />
        <DashboardGraficoPizza />
      </Flex>
    </Layout >
  )
}