import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Formik, Form } from 'formik'
import validateForm from "../../utils/validateForm";
import InputField from "../components/InputField";
import InputCheck from "../components/InputCheck";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import InputNumber from "../components/InputNumber";

export const TipoFosfinaEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const value = useSelector((state: RootState) => state.sistema.tipoFosfina)
  const error = useSelector((state: RootState) => state.sistema.error)
  const success = useSelector((state: RootState) => state.sistema.success)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.tipoFosfinaRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  return (
    <Layout>
      <ListHeader label={t('TiposFosfinaEditTitulo')}>
        <DefaultButton ml={4} to="/tipo-fosfina"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {value && <Formik
        initialValues={value}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            'nome': 'required',
            'gramas_por_garrafa': 'required',
          }, values, t);

          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }
          console.log("tipo fosfina form", values);
          dispatch(sistemaActions.saveTipoFosfinaRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              <InputField label={t('ClienteEditInputNome')} name="nome" width={'50%'} autoFocus />
              <InputNumber decimalPlaces={2} name="gramas_por_garrafa" width={'50%'} label={t('TiposFosfinaEditQuantidadeGramasPorGarrada')} />
              <InputCheck label={t('AvaliacaoInicialQuestaoEditAtivo')} name="ativo" />
            </Flex>
            <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('TiposFosfinaEditTitulo')}</DefaultButton>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}