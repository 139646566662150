import { format, subDays } from "date-fns";
import { DashboardFiltro } from "../types/dashboard_filtro";

export const novoDashboardFiltro = (): DashboardFiltro => {
  return {
    data_inicial: format(subDays(new Date(), 60), "yyyy-MM-dd"),
    hora_inicial: "08:00",
    data_final: "",
    hora_final: "",
    cliente_id: "",
    cliente_unidade_id: "",
    cliente_unidade_silo_id: "",
    leitura_id: "",
    equipamento_id: "",
    ordem_servico: "",
    somente_em_aberto: true,
    leitura_token: "",
  };
};
