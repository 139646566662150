import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { getBgColor } from "./Silo";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { getCurrentData } from "../../utils/data";
import { EquipamentoCicloItem } from "../../sistema/types/equipamento_ciclo_item";
import { EquipamentoCiclo } from "../../sistema/types/equipamento_ciclo";
import { addMinutes, differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { DashboardCiclo } from "./DashboardCiclo";
import { useTranslation } from 'react-i18next';
import { leituraActions } from "../reducer";

export const DashboardStatus = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const equipamento_ciclo_atual = useSelector((state: RootState) => state.leituras.equipamento_ciclo_atual);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const user = useSelector((state: RootState) => state.sistema.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leituraActions.equipamentoCicleRequest(dashboard?.last_leitura?.equipamento?.endereco_registro as string))

    const interval = setInterval(() => {
      if (!dashboard?.last_leitura?.equipamento_id) return;
      if ((user as any).administrador == 0 && (user as any).e_operador == 0) return;
      dispatch(leituraActions.equipamentoCicleRequest(dashboard?.last_leitura?.equipamento?.endereco_registro as string))
    }, 5000);

    // Limpeza do intervalo quando o componente é desmontado
    return () => {
      clearInterval(interval);
    };
  }, [dashboard?.last_leitura?.equipamento?.endereco_registro, dispatch]);

  const { t } = useTranslation();

  const pontos = [
    {
      name: 'P1',
      color: getBgColor(1),
      pointModBus: 17,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 1',
    },
    {
      name: 'P2',
      color: getBgColor(2),
      pointModBus: 18,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 2',
    },
    {
      name: 'P3',
      color: getBgColor(3),
      pointModBus: 19,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 3',
    },
    {
      name: 'P4',
      color: getBgColor(4),
      pointModBus: 20,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 4',
    },
    {
      name: 'P5',
      color: getBgColor(5),
      pointModBus: 21,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 5',
    },
    {
      name: 'P6',
      color: getBgColor(6),
      pointModBus: 22,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 6',
    },
    {
      name: 'P7',
      color: getBgColor(7),
      pointModBus: 23, // Desse pra baixo ainda nao funciona
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 7',
    },
    {
      name: 'P8',
      color: getBgColor(8),
      pointModBus: 24,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 8',
    },
    {
      name: 'P9',
      color: getBgColor(9),
      pointModBus: 25,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 9',
    },
    {
      name: 'P10',
      color: getBgColor(10),
      pointModBus: 26,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 10',
    },
    {
      name: 'P11',
      color: getBgColor(11),
      pointModBus: 27,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 11',
    },
    {
      name: 'P12',
      color: getBgColor(12),
      pointModBus: 28,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 12',
    },
    {
      name: 'P13',
      color: getBgColor(13),
      pointModBus: 29,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 13',
    },
    {
      name: 'P14',
      color: getBgColor(14),
      pointModBus: 30,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 14',
    },
    {
      name: 'P15',
      color: getBgColor(15),
      pointModBus: 31,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 15',
    },
    {
      name: 'P16',
      color: getBgColor(16),
      pointModBus: 32,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 16',
    },
    {
      name: 'Purge',
      color: '#FFA500',
      isOn: 'TURN_ON_SOLENOID_CLEANING',
      ifOff: 'TURN_OFF_SOLENOID_CLEANING',
      info: t('DashboardStatusPurge'),
    },
    {
      name: 'Bomba',
      color: 'gray',
      isOn: 'TURN_ON_PUMP',
      ifOff: 'TURN_OFF_PUMP',
      info: t('DashboardStatusBomba'),
    },
  ]



  const { isOpen, onOpen, onClose } = useDisclosure()

  const getCicloAtual = () => {
    return equipamento_ciclo_atual;
  }

  const getStatusAtual = (): any => {
    return getCicloAtual()?.itens ?? [];
  }

  interface Ponto {
    name: string;
    color: string;
    isOn: string;
    ifOff: string;
    info: string;
    pointModBus?: number;
  }


  function checkPumpStatus(array: EquipamentoCicloItem[], action_true: string, action_false: string, modbus_point?: string) {
    let turnOnFound = false;
    for (let i = 0; i < array.length; i++) {
      if (modbus_point) {
        if (array[i].action == action_true && array[i].value == modbus_point) {
          turnOnFound = true;
        }

        if (array[i].action == action_false && array[i].value == modbus_point) {
          turnOnFound = false;
        }
        continue;
      }
      if (array[i].action == action_true) {
        turnOnFound = true;
      }

      if (array[i].action == action_false) {
        turnOnFound = false;
      }
    }

    return turnOnFound;
  }

  const getDataFinalizacao = () => {
    return getCicloAtual()?.itens?.find((i) => i.action == 'FINISH_MACHINE')?.date ?? ''
  }

  const [tempoProximaExecucao, setTempoProximaExecucao] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (getStatusAtual().length == 0) return setTempoProximaExecucao('00:00:00')

      const dataFinalizacao = getDataFinalizacao();

      if (!dataFinalizacao) return setTempoProximaExecucao(t('DashboardStatusAguardandoCicloAtual'))

      const tempoEspera = (dashboard?.last_leitura?.equipamento?.tempo_espera_um_cilo_e_outro ?? 0);

      const dataAtual = new Date(getCurrentData('yyyy-MM-dd HH:mm:ss'));
      const dataProximaExecucao = addMinutes(new Date(dataFinalizacao), tempoEspera);

      const difference = differenceInSeconds(dataProximaExecucao, dataAtual);

      if (difference < 0) return setTempoProximaExecucao('00:00:00')

      const hours = Math.floor(difference / 3600);
      const minutes = Math.floor((difference % 3600) / 60);
      const seconds = difference % 60;

      setTempoProximaExecucao(`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`)
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getDataFinalizacao, dashboard?.last_leitura?.equipamento?.tempo_espera_um_cilo_e_outro]);

  const getPontoAtivo = (item: Ponto) => {
    const statuses = getStatusAtual();

    return checkPumpStatus(statuses, item.isOn, item.ifOff, item.pointModBus as any);
  }


  const temOPontoConfigurado = (ponto: any) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  return (
    <>
      <Flex mt={2} justifyContent={"center"}>
        <Text mx={"auto"} pl={isMobile ? 2 : 0} fontSize="1.1rem" color="#404244" fontWeight="700">Status</Text>
      </Flex>
      <Flex wrap={"wrap"} width={"full"} p={2}>
        {pontos.filter((i) => i.name == 'Purge' || i.name == 'Bomba' || temOPontoConfigurado(i.name.replace('P', ''))).map((item, index) => (
          <Flex key={`${index}`} width={"50%"} mb={1}>
            <Flex
              width={"50%"}
              px={1}
              py={0}
              borderRadius={5}
              bg={item.color}
              textAlign={"center"}
              justifyContent={"center"}
            >
              <Text fontSize={"10px"} fontWeight={"500"} color={"white"}>{item.name}</Text>
            </Flex>

            <Flex width={"50%"} pl={1} alignItems={"center"}>
              <Box mr={1} width={"10px"} height={"10px"} borderRadius={"50%"} bg={getPontoAtivo(item) ? "green" : "red"} />
              {getPontoAtivo(item) && <Text fontSize={"10px"}>ON</Text>}
              {!getPontoAtivo(item) && <Text fontSize={"10px"}>OFF</Text>}
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Text fontSize={"12px"} fontWeight={"400"}>&bull; {t('DashboardStatusPurge')}: {dashboard?.last_leitura?.equipamento?.tempo_espera_purge} {t('DashboardSegundos')} </Text>
      <Text fontSize={"12px"} fontWeight={"400"}>&bull; {t('DashboardStatusLeitura')}: {dashboard?.last_leitura?.equipamento?.tempo_espera_inicio_leitura} {t('DashboardSegundos')}</Text>
      <Text fontSize={"12px"} fontWeight={"400"}>&bull; {t('DashboardStatusCiclo')}: {dashboard?.last_leitura?.equipamento?.tempo_espera_um_cilo_e_outro} {t('DashboardMinutos')}</Text>
      <Text fontSize={"12px"} fontWeight={"400"}>&bull; {t('DashboardStatusProximaLeituraEm')} {tempoProximaExecucao}</Text>
      <Flex width={"full"} justifyContent={"center"}>
        {getCicloAtual() && <Button onClick={onOpen} mt={1} size={"sm"} colorScheme="blackAlpha">{t('DashboardStatusHistórico')}</Button>}
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardStatusHistórico')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {getCicloAtual() && <DashboardCiclo pontos={pontos} ciclo={getCicloAtual() as EquipamentoCiclo} onClose={onClose} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}