import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "formik";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export type InputEditorType = {
  name: string;
  label: string;
};

const InputEditor: React.FC<InputEditorType> = ({
  label,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  return (
    <FormControl isInvalid={!!error} p={1}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <ReactQuill theme="snow" value={field.value} onChange={(v) => setValue(v)} />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputEditor;
