import { LeituraSensor } from "../types/leitura_sensor";

export const novaLeituraSensor = (): LeituraSensor => {
  return {
    leitura_id: "",
    profundidade: 0,
    sensor: "",
    posicao: 0,
    configuracao: "",
  };
};
