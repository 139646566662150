import axios, { AxiosError } from "axios";
import { AxiosRequestConfig } from "axios";
import { store } from "../app/mainReducer";
import getEnv from "./getEnv";

export const APIURL: string = getEnv("REACT_APP_API_HOST");

export const getToken = () => store.getState().sistema.token;


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status == 401) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export const apiCall = (config: AxiosRequestConfig, token: string | null = null) =>
  axios({
    ...config,
    baseURL: APIURL,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${!!token ? token : getToken()}`,
    },
  });
