import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { Flex, Heading, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DashboardVerMais } from "./DashboardVerMais";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { formatCep } from "../../utils/formatCep";

export const DashboardDadosCliente = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const user = useSelector((state: RootState) => state.sistema?.user);

  const userAdmin = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure()

  const ModalData = ({ maior = false }) => {
    return (
      <Flex width={"full"} wrap={'wrap'} pt={2}>
        <Flex width={maior && !isMobile ? '70%' : "full"} direction={"column"}>
          <Text mb={1}><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteNome')}:</span> {dashboard?.last_leitura?.cliente?.nome}</Text>
          <Text mb={1}><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteUnidade')}:</span> {dashboard?.last_leitura?.unidade?.nome_referencia}</Text>
          <Text mb={1}><span style={{ fontWeight: '700' }}>{t('ClienteEditUnidadeListCep')}:</span> {formatCep(dashboard?.last_leitura?.unidade?.cep ?? '')} - {dashboard?.last_leitura?.unidade?.cidade} ({dashboard?.last_leitura?.unidade?.uf})</Text>
          <Text mb={1}><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteCNPJ')}:</span> {formatCpfCnpj(dashboard?.last_leitura?.unidade?.cpf_cnpj ?? '')}</Text>
          {(dashboard?.last_leitura?.unidade?.responsaveis ?? []).length > 0 && userAdmin() && <Text mb={1}><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteResponsaveisUnidade')}:</span> {dashboard?.last_leitura?.unidade?.responsaveis?.map((i) => i.usuario?.name).join(', ')}</Text>}
          {(dashboard?.last_leitura?.responsaveis ?? []).length > 0 && <Text mb={1}><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteResponsaveisEcozone')}:</span> {dashboard?.last_leitura?.responsaveis?.map((i) => i.usuario?.name).join(', ')}</Text>}
          {dashboard?.last_leitura?.produto && <Text><span style={{ fontWeight: '700' }}>{t('DashboardDadosClienteProduto')}:</span> {dashboard?.last_leitura?.produto ? t(`Dashboard${dashboard?.last_leitura?.produto}`) : ''}</Text>}
          <Text><span style={{ fontWeight: '700' }}>{t('LeiturasEditSilo')}:</span> {dashboard?.last_leitura?.silo?.numero} - {dashboard?.last_leitura?.silo?.nome_referencia}</Text>
        </Flex>

        {maior && <Flex width={isMobile ? "100%" : '30%'} justifyContent={"center"}>
          {dashboard?.last_leitura?.unidade?.imagem_logo && <img style={{ margin: 'auto' }} width={isMobile ? '50%' : "100%"} height={"auto"} src={dashboard?.last_leitura?.unidade?.imagem_logo} />}
        </Flex>}

        {maior && <Flex wrap={"wrap"} width={"full"}>
          <Flex mt={4} width={isMobile ? '100%' : "50%"} direction={"column"} justifyContent={isMobile ? 'center' : 'flex-start'} alignItems={"center"}>
            <Heading mx={'auto'} mb={2} size={"md"}>
              {t('DashboardClienteLocalizacao')}
            </Heading>

            <Flex width={"full"}></Flex>

            {dashboard?.last_leitura?.unidade?.latitude ? <iframe
              width="300"
              height="170"
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              src={`https://maps.google.com/maps?q=${dashboard.last_leitura.unidade.latitude.toString().trim()},${dashboard.last_leitura.unidade.longitude.toString().trim()}&hl=es;z=14&output=embed`}
            >
            </iframe> : <Text mx={"auto"}>
              {t('DashboardNaoRegistrado')}
            </Text>}

          </Flex>
          <Flex mt={4} width={isMobile ? '100%' : "50%"} direction={"column"} justifyContent={isMobile ? 'center' : 'flex-start'} alignItems={"center"}>
            <Heading mx={'auto'} mb={2} size={"md"}>
              {t('DashboardClienteArmazenamentoTrabalho')}
            </Heading>

            <Flex width={"300px"} height={"170px"}>
            {dashboard?.last_leitura?.silo?.imagem ? <Img height={"170px"} margin={"auto"}  src={dashboard?.last_leitura?.silo?.imagem} /> : <Text mx={"auto"}>
              {t('DashboardNaoRegistrado')}
            </Text>}
            </Flex>
          </Flex>
        </Flex>}
      </Flex>
    )
  }

  return (
    <Flex boxShadow={isMobile ? '' : "0 0 8px rgba(0, 0, 0, 0.2)"} borderRadius={"10px"} pl={2} mt={4} direction={"column"} fontSize={isMobile ? "13px" : "10px"}>

      <ModalData />
      <Flex mx={"auto"} mb={1}>
        <DashboardVerMais onOpen={onOpen} />
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardDadosDaLeitura')}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={2}>

            <ModalData maior={true} />

          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}