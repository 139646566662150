import { Box, Flex, Grid, GridItem, Img, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { formatValor } from '../../utils/formatValor';
import imagesUrl from '../../utils/imagesUrl';

const SiloImage = imagesUrl + 'silo.png'

interface IProps {
  selecteds: {
    value: number;
    sensor: string;
    altura?: number;
  }[];
  onChange?: (position: number) => void;
  eDashboard?: boolean;
  imagem?: string;
  imagem2?: string;
  children?: ReactNode;
  somentePontos?: boolean;
  injecoes?: {
    ponto: number;
    nome: string;
  }[]
}

export const getBgColor = (posicao: number) => {
  if (posicao == 1) {
    return '#05acbf';
  }
  if (posicao == 2) {
    return '#bf601d';
  }
  if (posicao == 3) {
    return '#bf1111';
  }
  if (posicao == 4) {
    return '#b8b900';
  }
  if (posicao == 5) {
    return '#0018bf';
  }
  if (posicao == 6) {
    return "#9b0089";
  }
  if (posicao == 7) {
    return '#FF69B4';
  }
  if (posicao == 8) {
    return '#00FFFF';
  }
  if (posicao == 9) {
    return '#FFA500';
  }
  if (posicao == 10) {
    return '#FF1493';
  }
  if (posicao == 11) {
    return '#00FF00';
  }
  if (posicao == 12) {
    return "#FFD700";
  }
  if (posicao == 13) {
    return '#FF4500';
  }
  if (posicao == 14) {
    return '#008080';
  }
  if (posicao == 15) {
    return '#800080';
  }
  if (posicao == 16) {
    return "#006400";
  }
  return "";
}

export const Silo = ({ selecteds, onChange, eDashboard = true, imagem, imagem2, children, somentePontos = false, injecoes }: IProps) => {
    const dashboard = useSelector((state: RootState) => state.leituras?.dashboard_leituras);

  const sensores = [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
    },
    {
      value: 6,
    },
    {
      value: 7,
    },
    {
      value: 8,
    },
    {
      value: 9,
    },
    {
      value: 10,
    },
    {
      value: 11,
    },
    {
      value: 12,
    },
    {
      value: 13,
    },
    {
      value: 14,
    },
    {
      value: 15,
    },
    {
      value: 16,
    },
  ];

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const siloPontoCentral = imagesUrl + "armazenamentos/silo_ponto_central.png";

  if (eDashboard) {
    return (
      <Flex width={"100%"} wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
        {!somentePontos && <Flex width={"100%"} justifyContent={"center"}>
          {(dashboard?.last_leitura?.e_leitura_de_6_silos as any ?? 0) == 0 && imagem2 && <Img src={imagem2} maxHeight={"130px"} />}
          {(dashboard?.last_leitura?.e_leitura_de_6_silos as any ?? 0) == 0 && <Img src={imagem ? imagem : SiloImage} maxHeight={imagem2 ? "150px" : "180px"} />}
          {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <Img src={siloPontoCentral} mx={"auto"} maxHeight={"130px"} />}
        </Flex>}

        <Flex width={"full"} wrap={isMobile ? "wrap-reverse" : "wrap"} pt={1}>
          {!somentePontos && <Box height={"100%"} width={isMobile ? "100%" : "40%"} p={isMobile ? 4 : 0} alignItems={"center"}>
            {children}
          </Box>}
          <Box width={isMobile ? "100%" : (somentePontos ? "100%" : "60%")} pr={4} pl={isMobile ? (somentePontos ? 0 :4) : 0} pb={1}>
            <Grid templateColumns='repeat(4, 1fr)' gap={1} justifyContent={"center"} alignItems={"center"}>
              {sensores.map((sensor) => (
                <GridItem key={sensor.value}>
                  {selecteds.find((i) => i.value == sensor.value) != undefined &&
                    <> <Flex
                      p={1}
                      justifyContent={"space-between"}
                      borderRadius={5}
                      onClick={() => typeof onChange === 'function' && onChange(sensor.value)}
                      cursor={typeof onChange === 'function' ? 'pointer' : 'default'}
                      bg={getBgColor(sensor.value)}
                      alignItems={"center"}
                    >
                      <Text fontSize={somentePontos ? '15px' : "9px"} fontWeight={"700"} color={"white"}>{selecteds.find((i) => i.value == sensor.value)?.sensor} </Text>
                      {selecteds.find((i) => i.value == sensor.value) && <small style={{ marginLeft: '4px', fontSize: somentePontos ? '12px' :  '7px', fontWeight: '600', color: 'white' }}>{`${formatValor(parseFloat(selecteds.find((i) => i.value == sensor.value)?.altura as any)?.toFixed(2))}m`}</small>}
                    </Flex>
                    </>}
                </GridItem >
              ))}
            </Grid>

            {injecoes && injecoes.length > 0 && injecoes.map((item, index) => (
              <Flex key={`${index}`} width={"full"} direction={"column"}>
                <Flex
                  width={"full"}
                  p={1}
                  wrap={"wrap"}
                  borderRadius={5}
                  bg={getBgColor(item.ponto)}
                  alignItems={"center"}
                  my={1}
                >
                  <Text fontSize={somentePontos ? '14px' : "12px"} fontWeight={"700"} color={"white"}>{selecteds.find((i) => i.value == item.ponto)?.sensor} </Text>
                  {selecteds.find((i) => i.value == item.ponto) && <small style={{ marginLeft: '4px', fontSize: '14px', fontWeight: '600', color: 'white' }}>{`${parseFloat(selecteds.find((i) => i.value == item.ponto)?.altura as any)?.toFixed(2)}m`}</small>}
                  <span style={{ fontSize: '14px', color: 'white', fontWeight: '700' }}>
                    &nbsp;({item.nome})
                  </span>
                </Flex>
              </Flex>
            ))}
          </Box>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex width={"100%"} wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
      <Img width={"100%"} src={imagem ? imagem : SiloImage} />
      <Flex width={"100%"} wrap={"wrap"} mb={1} alignItems={"center"} justifyContent={"center"}>
        {sensores.map((sensor) => (
          <Box
            mt={2}
            position={"relative"}
            mr={2}
            px={3}
            py={1}
            borderRadius={5}
            onClick={() => typeof onChange === 'function' && onChange(sensor.value)}
            cursor={typeof onChange === 'function' ? 'pointer' : 'default'}
            key={sensor.value}
            bg={getBgColor(sensor.value)}
          >
            {selecteds.find((i) => i.value == sensor.value) && <Box top={"-5px"} left={"-5px"} position={"absolute"} px={sensor.value >= 10 ? "20px" : "16.5px"} py={"14px"} borderRadius={8} borderColor={"#404244"} borderWidth={"4px"}></Box>}
            <Text fontSize={"12px"} fontWeight={"700"} color={"white"}>{sensor.value}</Text>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
