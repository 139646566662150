import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { Equipamento } from "../types/equipamento";
import { FiTrash } from "react-icons/fi";
import { ConfirmButton } from "../components/ConfirmButton";
import { useTranslation } from "react-i18next";

export const EquipamentoList = () => {
  useIsAuth();

  const equipamentos = useSelector((state: RootState) => state.sistema.equipamentos);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  useEffect(() => {
    dispatch(sistemaActions.equipamentosRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.equipamentosRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const { t } = useTranslation();

  const headers: TableHeaders<Equipamento>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('EquipamentoListNome'),
      wrapped: true,
      render: (item) => item.nome_referencia,
    },
    {
      label: t('EquipamentoListMAC'),
      wrapped: true,
      render: (item) => item.endereco_registro
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(sistemaActions.deleteEquipamentosRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={t('EquipamentoListTitulo')}>
        <DefaultButton ml={4} to="/equipamentos/novo"> {t('SistemaNovo')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label={t('EquipamentoListTermo')} onSubmit={setTermo} />

      {equipamentos && <ResponsiveTable onClick={(item: any) => navigate(`/equipamentos/${item.item.id}`)} headers={headers} data={equipamentos.data} />}

      {equipamentos && <Pagination info={equipamentos} paginate={setPage} />}
    </Layout>
  )
}