import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { leituraActions } from "../reducer";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Leitura } from "../types/leitura";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";

export const LeituraList = () => {
  useIsAuth();

  const leituras = useSelector((state: RootState) => state.leituras.leituras);
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading);
  const success = useSelector((state: RootState) => !!state.leituras.success);
  const dispatch = useDispatch()
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(leituraActions.leiturasRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(leituraActions.leiturasRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<Leitura>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('LeiturasListData'),
      wrapped: true,
      render: (item) => formatData(item.data),
    },
    {
      label: t('LeiturasListCliente'),
      wrapped: false,
      render: (item) => item?.cliente?.nome,
    },
    {
      label: t('LeiturasListFinalizado'),
      wrapped: true,
      render: (item: any) => item.finalizado == 1 ? t('SistemaSim') : t('SistemaNão'),
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(leituraActions.deleteLeituraRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={t('LeiturasListTitulo')}>
        <DefaultButton ml={4} to="/leituras/novo"> {t('SistemaNova')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label={t('LeiturasListTermo')} onSubmit={setTermo} />

      {leituras && <ResponsiveTable onClick={(item: any) => navigate(`/leituras/${item.item.id}`)} headers={headers} data={leituras.data} />}

      {leituras && <Pagination info={leituras} paginate={setPage} />}
    </Layout>
  )
}