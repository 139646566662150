import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { getCurrentData } from "../../utils/data";
import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { ReactECharts } from "./ReactECharts";
import { LeituraDados } from "../types/leitura_dados";
import { DashboardNomeLeitura } from "./DashboardNomeLeitura";
import { useTranslation } from 'react-i18next'
import { addHours, differenceInHours, format, subHours } from "date-fns";
import { Leitura } from "../types/leitura";
import ptBrLocale from "date-fns/locale/pt-BR";

export const getDados = (data: LeituraDados[], leitura: Leitura) => {
  if (!data) return [];
  if (!leitura) return [];

  if (data.length == 0) return [];

  const dataInicial = data[0].data;
  const dataFinal = leitura.data_fechamento ? leitura.data_fechamento : getCurrentData();

  const diferencaEmHoras = differenceInHours(new Date(dataFinal), new Date(dataInicial));
  const quantidadePeriodos = parseInt((diferencaEmHoras / 2).toFixed(0));

  const retorno = [];
  let ultimoItem: LeituraDados = data[0];
  for (let index = 0; index <= quantidadePeriodos; index++) {
    const dataFim = format(addHours(new Date(ultimoItem.data), 2), 'yyyy-MM-dd HH:mm', { locale: ptBrLocale });

    if (index == 0) {
      retorno.push({
        ...ultimoItem,
        c1: 0,
        c2: 0,
        c3: 0,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
        c8: 0,
        c9: 0,
        c10: 0,
        c11: 0,
        c12: 0,
        c13: 0,
        c14: 0,
        c15: 0,
        c16: 0,
        hora: 0,
      })
    } else {
      const dadosEncontrados = data.filter((i) => new Date(i.data) >= new Date(ultimoItem.data) && new Date(i.data) <= new Date(dataFim))

      const dadoEncontrado = dadosEncontrados.length ? dadosEncontrados[dadosEncontrados.length - 1] : null;

      if (dadoEncontrado) {
        const item = {
          ...dadoEncontrado,
          data: dataFim,
          hora: index * 2,
        }

        retorno.push(item)
        ultimoItem = item;
      } else {
        const item = {
          ...ultimoItem,
          data: dataFim,
          naoIdentificado: true,
          hora: index * 2,
        }

        retorno.push(item)
        ultimoItem = item;
      }
    }
  }

  const ret: LeituraDados[] = [];
  const increments = {
    c1: 0,
    c2: 0,
    c3: 0,
    c4: 0,
    c5: 0,
    c6: 0,
    c7: 0,
    c8: 0,
    c9: 0,
    c10: 0,
    c11: 0,
    c12: 0,
    c13: 0,
    c14: 0,
    c15: 0,
    c16: 0,
  }
  let incrementado = false;
  for (let index = 0; index < retorno.length; index++) {
    const item = retorno[index];

    if (index == 0) {
      ret.push(item);
      continue;
    };

    const itemAnterior = retorno[index - 1];

    if (!itemAnterior.naoIdentificado && item.naoIdentificado) {
      incrementado = false;
      let qt = 0;
      for (let indexLocal = index; indexLocal < retorno.length; indexLocal++) {
        const itemLocal = retorno[indexLocal];
        qt += 1;

        if (!itemLocal.naoIdentificado && !incrementado) {
          increments.c1 = (itemAnterior.c1 - itemLocal.c1) / qt;
          increments.c2 = (itemAnterior.c2 - itemLocal.c2) / qt;
          increments.c3 = (itemAnterior.c3 - itemLocal.c3) / qt;
          increments.c4 = (itemAnterior.c4 - itemLocal.c4) / qt;
          increments.c5 = (itemAnterior.c5 - itemLocal.c5) / qt;
          increments.c6 = (itemAnterior.c6 - itemLocal.c6) / qt;
          increments.c7 = (itemAnterior.c7 - itemLocal.c7) / qt;
          increments.c8 = (itemAnterior.c8 - itemLocal.c8) / qt;
          increments.c9 = (itemAnterior.c9 - itemLocal.c9) / qt;
          increments.c10 = (itemAnterior.c10 - itemLocal.c10) / qt;
          increments.c11 = (itemAnterior.c11 - itemLocal.c11) / qt;
          increments.c12 = (itemAnterior.c12 - itemLocal.c12) / qt;
          increments.c13 = (itemAnterior.c13 - itemLocal.c13) / qt;
          increments.c14 = (itemAnterior.c14 - itemLocal.c14) / qt;
          increments.c15 = (itemAnterior.c15 - itemLocal.c15) / qt;
          increments.c16 = (itemAnterior.c16 - itemLocal.c16) / qt;
          incrementado = true;
        }
      }
    }

    if (item.naoIdentificado && incrementado) {
      const itemAnteriorRet = ret[ret.length - 1];
      ret.push({
        ...item,
        c1: parseInt((itemAnteriorRet.c1 - increments.c1).toFixed(0)),
        c2: parseInt((itemAnteriorRet.c2 - increments.c2).toFixed(0)),
        c3: parseInt((itemAnteriorRet.c3 - increments.c3).toFixed(0)),
        c4: parseInt((itemAnteriorRet.c4 - increments.c4).toFixed(0)),
        c5: parseInt((itemAnteriorRet.c5 - increments.c5).toFixed(0)),
        c6: parseInt((itemAnteriorRet.c6 - increments.c6).toFixed(0)),
        c7: parseInt((itemAnteriorRet.c7 - increments.c7).toFixed(0)),
        c8: parseInt((itemAnteriorRet.c8 - increments.c8).toFixed(0)),
        c9: parseInt((itemAnteriorRet.c9 - increments.c9).toFixed(0)),
        c10: parseInt((itemAnteriorRet.c10 - increments.c10).toFixed(0)),
        c11: parseInt((itemAnteriorRet.c11 - increments.c11).toFixed(0)),
        c12: parseInt((itemAnteriorRet.c12 - increments.c12).toFixed(0)),
        c13: parseInt((itemAnteriorRet.c13 - increments.c13).toFixed(0)),
        c14: parseInt((itemAnteriorRet.c10 - increments.c10).toFixed(0)),
        c15: parseInt((itemAnteriorRet.c10 - increments.c10).toFixed(0)),
        c16: parseInt((itemAnteriorRet.c10 - increments.c10).toFixed(0)),
        naoIdentificado: true,
      })
    } else {
      ret.push(item);
    }
  }

  // console.log(ret)
  return ret;
}



export const DashboardGraficoLinha = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const { t } = useTranslation()

  const getData = (origem: string) => {
    if (!dashboard) return [];

    return getDados(dashboard.data, dashboard.last_leitura).map((i: any) => i[origem]);
  }


  const getPontosAtivos = (last_data: any) => {
    let somaPontos = 0;
    let qtPontos = 0;

    if (!dashboard?.sensores || !dashboard?.last_data) {
      return {
        somaPontos,
        qtPontos,
      }
    }

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        somaPontos += parseFloat(last_data[`c${i}`]);
        qtPontos += 1;

      }
    }

    return {
      somaPontos,
      qtPontos,
    }
  }

  const getDatas = (): any => {
    if (!dashboard) return [];

    return getDados(dashboard.data, dashboard.last_leitura).map((i) => `${i.hora}h (${t('DashboardMédia')} ${(getPontosAtivos(i).somaPontos / getPontosAtivos(i).qtPontos).toFixed(0)})`);
  }

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const cores = {
    'Aplicação': 'rgba(0, 255, 0, 0.2)',
    'Reaplicação': 'rgba(255, 255, 0, 0.2)',
    'Exposição': 'rgba(0, 0, 255, 0.2)',
  }

  const createArray = (tamanho: number) => {
    let itens = [];
    for (let index = 0; index < tamanho; index++) {
      itens.push(index)
    }
    return itens;
  }

  const getTempos = (tipo: string) => {
    if (!eFosfina) {
      return []
    }
    const info = getDados(dashboard.data, dashboard.last_leitura);
    let intervalos = (dashboard?.last_leitura?.aplicacoes ?? []).filter((j) => {
      if (tipo == 'Exposição') {
        return true;
      }

      return j.tipo == tipo
    });

    const intervalosConst = intervalos;

    let retorno: any = [];

    // const quantidadeItens = tipo == 'Reaplicação' ? intervalosConst.length : (intervalosConst.length > 0 ? 1 : 0);
    const quantidadeItens = tipo == 'Reaplicação' ? intervalosConst.length : (tipo == "Aplicação" ? (intervalosConst.length > 0 ? 1 : 0) : 1);

    // const quantidadeItens = tipo == 'Reaplicação' ? intervalosConst.length : 1;

    (createArray(quantidadeItens)).forEach((_: any, _index: any) => {
      if (tipo == 'Reaplicação') {
        intervalos = [{ ...intervalosConst[_index] as any }]
      } else {
        intervalos = intervalosConst;
      }

      let encontrados: any = []
      let encontradosLocal: LeituraDados[] = [];
      info.forEach((i) => {
        let novo = false;
        intervalos.forEach((j, index) => {
          const data_fim = !j.data_fim ? new Date(getCurrentData()) : addHours(new Date(j.data_fim), 1);
          let data_inicio = new Date(j.data_inicio);
          if (tipo == 'Reaplicação') {
            data_inicio = subHours(data_inicio, 1);
          }
          if (tipo == 'Exposição' && index > 0) {
            data_inicio = subHours(data_inicio, 1);
          }

          if (tipo == 'Exposição' && (data_inicio < new Date(i.data) && data_fim > new Date(i.data))) {
            novo = true;
          }
          if (tipo != 'Exposição' && !(data_inicio < new Date(i.data) && data_fim > new Date(i.data))) {
            novo = true;
          }
        })

        if (novo) {
          encontradosLocal.push(i)

          if (encontradosLocal.length > 1) {
            encontrados.push([
              {
                name: '',
                xAxis: `${encontradosLocal[0].hora}h (${t('DashboardMédia')} ${(getPontosAtivos(encontradosLocal[0]).somaPontos / getPontosAtivos(encontradosLocal[0]).qtPontos).toFixed(0)})`
              },
              {
                xAxis: `${encontradosLocal[encontradosLocal.length - 1].hora}h (${t('DashboardMédia')} ${(getPontosAtivos(encontradosLocal[encontradosLocal.length - 1]).somaPontos / getPontosAtivos(encontradosLocal[encontradosLocal.length - 1]).qtPontos).toFixed(0)})`
              }
            ])
          }

          encontradosLocal = [];
        } else {

          encontradosLocal.push(i)
        }
      })

      if (encontradosLocal.length) {
        encontrados.push([
          {
            name: '',
            xAxis: `${encontradosLocal[0].hora}h (${t('DashboardMédia')} ${(getPontosAtivos(encontradosLocal[0]).somaPontos / getPontosAtivos(encontradosLocal[0]).qtPontos).toFixed(0)})`
          },
          {
            xAxis: `${encontradosLocal[encontradosLocal.length - 1].hora}h (${t('DashboardMédia')} ${(getPontosAtivos(encontradosLocal[encontradosLocal.length - 1]).somaPontos / getPontosAtivos(encontradosLocal[encontradosLocal.length - 1]).qtPontos).toFixed(0)})`
          }
        ])
      }

      retorno.push(...encontrados)
    })

    return retorno;
  }

  const sensoresAtivos = () => {
    let grafico: any = [];
    let labels: any = [];

    if (!dashboard?.sensores) {
      return {
        grafico,
        labels,
      }
    }
    // console.log(getTempos('Reaplicação'))

    let tipos = 0;
    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        grafico.push({
          name: `${t('DashboardPonto')} ${i} (P${i})`,
          type: 'line',
          stack: `${t('DashboardPonto')} ${i}`,
          data: getData(`c${i}`),
          smooth: true,
          markArea: {
            itemStyle: {
              color: tipos == 0 ? cores.Aplicação : tipos == 1 ? cores.Reaplicação : tipos == 2 ? cores.Exposição : ''
            },
            data: tipos == 0 ? getTempos('Aplicação') : tipos == 1 ? getTempos('Reaplicação') : tipos == 2 ? getTempos('Exposição') : [],
          }
        })

        tipos++

        labels.push(`${t('DashboardPonto')} ${i} (P${i})`)
      }
    }


    return {
      grafico,
      labels,
    }
  }

  const data: any = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (value: string) => `${parseFloat(value)} ppm`,
    },
    legend: {
      data: sensoresAtivos().labels,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getDatas(),
    },
    yAxis: {
      type: 'value'
    },
    series: sensoresAtivos().grafico,
  }


  const { isOpen, onOpen, onClose } = useDisclosure()

  const LegendaLocal = () => {
    if (!eFosfina) {
      return <></>
    }
    return (
      <Box m={"auto"} width={"full"} minH={"20px"} pl={14} pr={7}>
        <Flex wrap={"wrap"} width={"full"} minH={"20px"}>
          <Flex p={"2px"} width={"33.3%"} height={"20px"}>
            <Flex borderRadius={"10px"} alignItems={"center"} justifyContent={"center"} textAlign={"center"} width={"full"} height={"full"} border="1px solid gray" bg={cores.Aplicação}>
              <Text fontSize={"10px"} fontWeight={"700"} color={"#404244"}>
                {t('DashboardAplicação')}
              </Text>
            </Flex>
          </Flex>
          <Flex p={"2px"} width={"33.3%"} height={"20px"}>
            <Flex borderRadius={"10px"} alignItems={"center"} justifyContent={"center"} textAlign={"center"} fontSize={"12px"} width={"full"} height={"full"} border="1px solid gray" bg={cores.Reaplicação}>
              <Text fontSize={"10px"} fontWeight={"700"} color={"#404244"} marginY={"auto"}>
                {t('DashboardReaplicação')}
              </Text>
            </Flex>
          </Flex>
          <Flex p={"2px"} width={"33.3%"} height={"20px"}>
            <Flex borderRadius={"10px"} alignItems={"center"} justifyContent={"center"} textAlign={"center"} fontSize={"12px"} width={"full"} height={"full"} border="1px solid gray" bg={cores.Exposição}>
              <Text fontSize={"10px"} fontWeight={"700"} color={"#404244"} marginY={"auto"}>
                {t('DashboardExposição')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    )
  }
  return (
    <Box width={isMobile ? "100%" : "44%"} position={"relative"} p={1}>
      <Box bg={"white"} minH={isMobile ? 'auto' : '340px'} borderRadius={25} p={1} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"}>
        <DefaultButton className="no-print" size={"xs"} mb={2} onClick={onOpen} ml={isMobile ? 1 : 4} mt={isMobile ? 8 : 4}>{t('DashboardExpandir')}</DefaultButton>

        <Flex position={"absolute"} left={"50%"} top={4} transform={"translateX(-50%)"} zIndex={1}>
          <Text fontSize="1rem" color="#404244" fontWeight="700">{t('DashboardLeiturasDe')} {<DashboardNomeLeitura />}</Text>
        </Flex>

        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ListHeaderSecondary label={t('DashboardLeituras')}>
                <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
              </ListHeaderSecondary>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody my={2}>
              <ReactECharts option={data} style={{ height: '600px' }} />
              <LegendaLocal />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Flex id="grafico" direction={"column"} width={"full"}>
          <ReactECharts option={data} style={{ height: eFosfina ? '266px' : '286px' }} />
          <LegendaLocal />
        </Flex>
      </Box>
    </Box>
  )
}